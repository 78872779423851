import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import VirtualList from 'react-tiny-virtual-list';
import withLoading from "../withLoading";
import TemplateListItem from './TemplateListItem';
import _ from 'lodash';

const VirtualListWithLoading = withLoading(VirtualList);

const styles = theme => ({
	list: {
		overflow: "hidden",
		width: "100%",
		height: "calc(100vh - 270px)",
	},
	listItem: {
		cursor: 'pointer',
		'&:hover': {
			border: '2px solid black',
		},
		paddingTop: '8px',
		paddingBottom: '8px',
	},
});

class TemplateListFeaturePanel extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			anchor: 'left',
			selectedItem: null,
			clientHeight: 1,
			clientWidth: 1,
		};
	}

	componentDidMount() {
		this.setState({
			clientHeight: this.refs.listContainer.clientHeight,
			clientWidth: this.refs.listContainer.clientWidth
		});
	}

	listItemRenderer = ({ index, style }) => {
		const { classes, assets, selectedItem, selectedFeature, setSelectedItem } = this.props;
		const item = assets[index];

		if (_.isEmpty(item)) return null;

		return (
			<>
				<TemplateListItem
					key={Math.random()}
					index={index}
					style={style}
					classes={classes}
					item={item}
					selectedFeature={selectedFeature}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>
			</>
		);

		
	}

	getSelectedIndex(item) {
		for (let i = 0; i < this.props.assets.length; i++) {
			if (item && this.props.assets[i].id == item.id)
				return i;
		}
		return null;
	}

	render() {
        const { classes, isLoading, assets, model, selectedFeature, selectedItem } = this.props;
        const { clientWidth, clientHeight } = this.state;

        const selectedFeatureIndex = this.getSelectedIndex(model.selectedItem);
        
		return (
            <div className={classes.list} ref="listContainer">
                <VirtualListWithLoading isLoading={isLoading}
                    width={clientWidth}
                    height={clientHeight}
                    items={assets}
                    itemCount={assets.length}
                    itemSize={50}
                    renderItem={this.listItemRenderer}
                    scrollToIndex={selectedFeatureIndex}
                    scrollToAlignment={'auto'}
                    extraData={this.props}
                />
            </div>
		);
	}
}

TemplateListFeaturePanel.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TemplateListFeaturePanel);
