import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dashboard from '@material-ui/icons/Dashboard';
import { Link } from 'react-router-dom';
import withQuery from '../../withQuery';
import withTenantName from '../../withTenantName';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

const LinkWithTenantName = withTenantName(Link);
const LinkWithTenantNameWithQuery = withQuery(LinkWithTenantName);

const styles = theme => ({
    header: {
        "&:focus": {
            backgroundColor: "transparent"
        },
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    nested: {
        paddingLeft: theme.spacing(5),
        paddingTop: "0px",
        paddingBottom: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    text: {
        fontFamily: "Roboto",
        color: "white",
    },
    listItem: {
        borderRadius: "200px",
        padding: "8px",
        paddingLeft: "13px",
        paddingRight: "13px",
        fontFamily: "Roboto",
    }
});

class DashboardsSubMenu extends React.Component {
    constructor(props){
        super(props);
    }

    isOverflown = (element) => {
        return _.isEmpty(element) ? false : (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
    }

    render ()
    {
        const { classes, menuContext, routeContext, dashboards, loadDashboard, selectedDashboard } = this.props;
        
        const getListItem = (dashboard) => {
            return (
                <Tooltip title={this.isOverflown(document.getElementById(dashboard.id)) ? dashboard.dashboardName : ""}>
                    <ListItem button className={classes.nested} onClick={() => loadDashboard(dashboard.dashboardCards, dashboard)} key={dashboard.id} style={{ textDecoration: 'none' }}>
                        <div
                            id={dashboard.id}
                            className={classes.listItem}
                            title={dashboard.dashboardName}
                            style={{
                                backgroundColor: selectedDashboard?.id == dashboard.id ? "rgba(0, 0, 0, 0.34)" : 'transparent',
                                fontSize: (dashboard.dashboardName.length >= 20) ? '.8rem' : '1rem',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                pointerEvents: 'none'
                            }}>
                            {dashboard.dashboardName}
                        </div>
                    </ListItem>
                </Tooltip>
            );
        }

        let availableDashboards = dashboards.filter(x => x.tags != null && x.tags.includes('Dashboards'));
        return (
            <div style={{cursor: 'pointer'}}>
                {/*Top Level Menu with Icon*/}
                <ListItem button className={classes.header} onClick={menuContext.toggleDashboards}>
                    <ListItemIcon className={classes.text}>
                        <Dashboard />
                    </ListItemIcon>
                    <ListItemText variant = "inset" disableTypography className={classes.text}>Dashboards</ListItemText>
                    {menuContext.state.dashboardsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                
                {/*Sub Menu*/}
                <Collapse in={menuContext.state.dashboardsExpanded} timeout="auto">
                    <List component="div" disablePadding>
                        {availableDashboards.map(dashboard => (
                            dashboard.tags.includes("PrecipiCAST")
                            ?
                                <LinkWithTenantName to={"/{tenantName}/dashboards/PrecipiCAST"} style={{ textDecoration: 'none' }}>
                                    {getListItem(dashboard)}
                                </LinkWithTenantName>
                            : dashboard.tags.includes("DataExplorer")
                                ?  
                                    <LinkWithTenantName to={"/{tenantName}/dashboards/DataExplorer"} style={{ textDecoration: 'none' }}>
                                        {getListItem(dashboard)}
                                    </LinkWithTenantName>
                                : 
                                    <LinkWithTenantNameWithQuery key={Math.random()} to={`/{tenantName}/dashboards/performance/${dashboard.dashboardName}/${routeContext.state.layerName}${routeContext.state.assetName ? `/${routeContext.state.assetName}` : ''}`} style={{ textDecoration: 'none' }}>
                                        {getListItem(dashboard)}
                                    </LinkWithTenantNameWithQuery>
                        ))}
                    </List>
                </Collapse>
            </div>
        ) 
    }
}

DashboardsSubMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardsSubMenu);
