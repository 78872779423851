import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import { ThemeContext } from '../../contexts/ThemeContext';
import { UserContext } from "../../contexts/UserContext";
import AddIcon from '@material-ui/icons/Add';
import { useContext } from 'react';


const styles = theme => ({
    addRowButton: {
        position: "absolute",
        right: "95px",
        top: "0",
        zIndex: "6001" // The fullscreen modal is 6000
    },
    paper: {
        position: 'fixed',
        left: "12.5%",
        top: "10%",
        width: document.documentElement.clientWidth * 0.75,
        height: document.documentElement.clientHeight * 0.80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

const AddRowButton = (props) => {

    const userContext = useContext(UserContext);

    const { csoAutomatedPublishing, isDataAdmin } = userContext

    if (!isDataAdmin || !csoAutomatedPublishing) return (<></>);

    const { classes, handleClick } = props;

    return (
        <div className={classes.addRowButton} data-html2canvas-ignore="true">
            <IconButton title='add row' onClick={handleClick} >
                <ThemeContext.Consumer>
                    {({ theme, getColor, updateTheme }) => (
                        <AddIcon style={{ color: getColor("primary.Primary") }}></AddIcon>
                    )}
                </ThemeContext.Consumer>

            </IconButton>
        </div>
    )
}

AddRowButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddRowButton);;