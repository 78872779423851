import * as d3 from 'd3';

import moment from 'moment-timezone';

export function createTimeScale(start, end, margin, width) {
    return d3.scaleTime()
        .domain([start.valueOf(), end.valueOf()])
        .range([0, width - margin.left - margin.right])
}

export function getRepresentedDates(start, end) {
    let ticks = [];
    var currentDate = start.clone();
    while (currentDate <= end) {
        ticks.push(currentDate.format());
        currentDate.add(1, 'd');
    }
    return ticks;
}

export function createBandedTimeScale(start, end, margin, width, ticks = null) {
    if (!ticks) {
        ticks = getRepresentedDates(start, end);
    }
    return d3.scaleBand()
        .domain(ticks)
        .range([0, width - margin.left - margin.right])
        .padding(.2);
}

export function createValueScale(min, max, margin, height) {
    return d3.scaleLinear()
        .domain([min, max])
        .range([height - margin.top - margin.bottom, 0]);
}

export function createXValueScale(min, max, margin, height) {
    return d3.scaleLinear()
        .domain([min, max])
        .range([0, height - margin.left - margin.right]);
}

export function createXValueLogScale(min, max, margin, height, tickCount = 5) {
    var scale = d3.scaleLog()
        .domain([min, max])
        .range([0, height - margin.left - margin.right]);
    scale.tickFormat(tickCount, "d");
    return scale;
}

export function createBand(domain, x0Scale) {
    return d3.scaleBand()
        .domain(domain)
        .range([0, x0Scale.bandwidth()]);
}

export function getX1Domain(domain, hiddenList) {
    return (domain || []).filter(a => hiddenList.indexOf(a.tooltipLabel) === -1);
}

export function getDomainedBounds(data, hiddenList = []) {
    const toReturn = {
        minLeft: 0,
        maxLeft: 0,
    };

    let values = [];
    for (const d of data) {
        d.Data.forEach((datum) => {
            for (var property in datum) {
                if (hiddenList.indexOf(property) == -1 && property !== 'Date')
                    values.push(datum[property]);
            }
        });
    }

    toReturn.minLeft = Math.min(...values);
    toReturn.maxLeft = Math.max(...values);

    return [toReturn.minLeft, toReturn.maxLeft]
}

export function getBrushedBounds(data, startDate, endDate, dataProp, hiddenSeriesList = []) {

    const toReturn = {
        minLeft: Infinity,
        minRight: Infinity,
        maxLeft: -Infinity,
        maxRight: -Infinity
    };

    if (data) {
        for (const d of data) {
            if (hiddenSeriesList.indexOf(d.Name) == -1 && (d.Axis == 'left' || d.Axis == 'right')) {

                const series = d[dataProp].filter(v => v.value !== null && v.value !== '' && !isNaN(v.value));

                // it is possible that due to data simplification, a line may exist with two points, the first being in front of
                // our start date and the second being behind our end date
                if (!series.find(value => value.date >= startDate && value.date <= endDate)) {
                    let startIdx = 0,
                        start,
                        endIdx = series.length - 1,
                        end;

                    for (; startIdx < endIdx; startIdx++, endIdx--) {
                        if (series[startIdx].date <= startDate && series[startIdx].date <= endDate && (!start || series[startIdx].date > start.date)) {
                            start = series[startIdx];
                        }

                        if (series[endIdx].date >= startDate && series[endIdx].date >= endDate && (!end || series[endIdx].date < end.date)) {
                            end = series[endIdx];
                        }
                    }

                    for (; startIdx < endIdx && (!start || !end); startIdx++, endIdx--) {
                        if (series[startIdx].date <= startDate && series[startIdx].date <= endDate && (!start || series[startIdx].date > start.date)) {
                            start = series[startIdx];
                        }
                        if (series[endIdx].date >= startDate && series[endIdx].date >= endDate && (!end || series[endIdx].date < end.date)) {
                            end = series[endIdx];
                        }
                    }

                    if (start && end) {
                        if (d.Axis == 'left') {
                            toReturn.minLeft = Math.min(start.value, end.value, toReturn.minLeft)
                            toReturn.maxLeft = Math.max(start.value, end.value, toReturn.maxLeft)
                        } else if (d.Axis == 'right') {
                            toReturn.minRight = Math.min(start.value, end.value, toReturn.minRight)
                            toReturn.maxRight = Math.max(start.value, end.value, toReturn.maxRight)
                        }
                    }
                } else {
                    var values = series.filter(value => value.date >= startDate && value.date <= endDate);
                    for (const value of values) {
                        switch (d.Axis) {
                            case 'left':
                                toReturn.minLeft = Math.min(value.value, toReturn.minLeft)
                                toReturn.maxLeft = Math.max(value.value, toReturn.maxLeft)
                                break;
                            case 'right':
                                toReturn.minRight = Math.min(value.value, toReturn.minRight)
                                toReturn.maxRight = Math.max(value.value, toReturn.maxRight)
                        }
                    }
                }
            }
        }
    }

    toReturn.maxLeft = toReturn.maxLeft == -Infinity ? 0 : toReturn.maxLeft;
    toReturn.maxRight = toReturn.maxRight == -Infinity ? 0 : toReturn.maxRight;

    return [toReturn.minLeft, toReturn.maxLeft, toReturn.minRight, toReturn.maxRight]
}

export function getNumericBrushedBounds(data, minValue, maxValue, dataProp, hiddenSeriesList = []) {
    const toReturn = {
        minLeft: Infinity,
        minRight: Infinity,
        maxLeft: -Infinity,
        maxRight: -Infinity
    };

    if (data) {
        for (const d of data) {
            if (hiddenSeriesList.indexOf(d.Name) == -1 && (d.Axis == 'left' || d.Axis == 'right')) {

                const series = d[dataProp];

                // it is possible that due to data simplification, a line may exist with two points, the first being in front of
                // our min value and the second being behind our end date
                if (!series.find(value => value.key >= minValue && value.key <= maxValue)) {
                    let startIdx = 0,
                        start,
                        endIdx = series.length - 1,
                        end;

                    for (; startIdx < endIdx; startIdx++, endIdx--) {
                        if (series[startIdx].key <= minValue && series[startIdx].key <= maxValue && (!start || series[startIdx].key > start.key)) {
                            start = series[startIdx];
                        }

                        if (series[endIdx].key >= minValue && series[endIdx].key >= maxValue && (!end || series[endIdx].key < end.key)) {
                            end = series[endIdx];
                        }
                    }

                    for (; startIdx < endIdx && (!start || !end); startIdx++, endIdx--) {
                        if (series[startIdx].key <= minValue && series[startIdx].key <= maxValue && (!start || series[startIdx].key > start.key)) {
                            start = series[startIdx];
                        }

                        if (series[endIdx].key >= minValue && series[endIdx].key >= maxValue && (!end || series[endIdx].key < end.key)) {
                            end = series[endIdx];
                        }
                    }

                    if (start && end) {
                        if (d.Axis == 'left') {
                            toReturn.minLeft = Math.min(start.value, end.value, toReturn.minLeft)
                            toReturn.maxLeft = Math.max(start.value, end.value, toReturn.maxLeft)
                        } else if (d.Axis == 'right') {
                            toReturn.minRight = Math.min(start.value, end.value, toReturn.minRight)
                            toReturn.maxRight = Math.max(start.value, end.value, toReturn.maxRight)
                        }
                    }
                } else {
                    var values = series.filter(value => value.key >= minValue && value.key <= maxValue);
                    for (const value of values) {
                        switch (d.Axis) {
                            case 'left':
                                toReturn.minLeft = Math.min(value.value, toReturn.minLeft)
                                toReturn.maxLeft = Math.max(value.value, toReturn.maxLeft)
                                break;
                            case 'right':
                                toReturn.minRight = Math.min(value.value, toReturn.minRight)
                                toReturn.maxRight = Math.max(value.value, toReturn.maxRight)
                        }
                    }
                }
            }
        }
    }

    toReturn.maxLeft = toReturn.maxLeft == -Infinity ? 0 : toReturn.maxLeft;
    toReturn.maxRight = toReturn.maxRight == -Infinity ? 0 : toReturn.maxRight;

    return [toReturn.minLeft, toReturn.maxLeft, toReturn.minRight, toReturn.maxRight]
}