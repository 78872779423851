import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import withLoading from "../withLoading";
import _ from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import FlexTable from '../flexTable/FlexTable';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading("div");

const styles = theme => ({
});

class TableCard extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            orderBy: null,
            orderDirection: 'desc',
            data: [],
            loading: false,
            columns: [],
        };
    }

    componentDidMount () {
        this.props.alertContext.state.selectedEvent && this.getData();
    }
    
    componentDidUpdate (prevProps, prevState) { 
        if(this.props.alertContext.state.selectedEvent != this.state.selectedAlert) {
            this.props.alertContext.state.selectedEvent && this.getData();
        }
    }

    getData() {
        const selectedAlert = this.props.alertContext.state.selectedEvent;

        const allEvents = this.props.alertContext.state.events;

        const data = allEvents.filter(e => e.eventType == selectedAlert.eventType && e.asset == selectedAlert.asset);

        for(const evt of data) {
            let start = moment.utc(evt.startDate);
            let end = moment.utc(evt.endDate);

            evt["displayTime"] = moment.tz(evt.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD hh:mm a z');
            evt["Value"] = Math.round(evt.value * 100) / 100;
            evt["duration"] = Math.round(moment.duration(end.diff(start)).asHours() * 100) / 100;
        }

        const fields = [
            {
                extraData: null,
                header: "Start Date",
                units: null,
                dataKey: "displayTime",
                dataType: 'date',
                order: 0,
                width: 100
            },
            {
                extraData: null,
                header: selectedAlert.valueLabel,
                units: selectedAlert.valueUnits,
                dataKey: "Value",
                dataType: 'number',
                order: 1,
                width: 100
            },
            {
                extraData: null,
                header: "Duration (hrs)",
                units: 'hours',
                dataKey: "duration",
                dataType: 'number',
                order: 2,
                width: 100
            }
        ]
        
        this.setState({
            loading: false,
            data: data, 
            columns: fields,
            selectedAlert
        });
    }
    
    handlePrintClick() {
    }

    handleDownloadClick() {
        var csvContent = "data:text/csv;charset=utf-8,";
        var dataKeys = this.state.columns.map(e => e.dataKey);
        csvContent += this.state.columns.map(e => e.header).join(",") + "\r\n";
        this.state.data.forEach(function(data) {
            dataKeys.forEach(function(key) {
                csvContent += data[key] + ","
            });
            csvContent += "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        var filename = "table_" + this.props.title;
        if (this.props.model.selectedItem) {
            filename += "_" + this.props.model.selectedItem.properties[this.props.model.selectedFeature.displayField];
        }
        filename += ".csv";
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    }

    sort(orderBy) {
        var orderDirection = this.state.orderDirection;
        if (orderBy === this.state.orderBy && orderDirection === 'desc') {
            orderDirection = 'asc';
        } else {
            orderDirection = 'desc';
        }

        var data = this.sortByKey(this.state.data, orderBy, orderDirection);

        this.setState({
            orderBy: orderBy,
            orderDirection: orderDirection,
            data: data,
            loading: false
        });
    }

    sortByKey (data, orderBy, orderDirection) {
        data = data.sort(function(a, b) {
            var x = a[orderBy]; 
            var y = b[orderBy];

            if ((!x || ((typeof x) == 'string' && x.trim() == '')) && y)       //Sorts empty data cells to the bottom
                return 1
            else if ((!x || ((typeof x) == 'string' && x.trim() == '')) && (!y || ((typeof y) == 'string' && y.trim() == '')))
                return 0
            else if (x && (!y || ((typeof y) == 'string' && y.trim() == '')))
                return -1

            if (orderDirection === 'desc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            else {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
            
        });

        return data;
    }

    render() {
        const { columns, data } = this.state;

        const { selectedEvent } = this.props.alertContext.state;

        const {history, model, dateContext} = this.props;

        const setSelectedEvent = selectedEvent => {
            let timescale = 'timescale=' + dateContext.timescale;
            let startDate = dateContext.timescale == 'custom' && dateContext.startDate ? "&startDate=" + dateContext.startDate : "";
            let endDate = dateContext.timescale == 'custom' && dateContext.endDate ? "&endDate=" + dateContext.endDate : "";

            history.push(selectedEvent.startDate + "?" + timescale + startDate + endDate); 
        }

        const subheader = this.state.selectedAlert ? {
            header: `${data.length}  ${this.state.selectedAlert.eventType}s`,
            subheader: `at ${this.state.selectedAlert.asset} between ${this.props.dateContext.startDate} and ${this.props.dateContext.endDate}`
        } : {
            header: ``,
            subheader: `Unable to locate resource`
        }

        return (
            <LoadingDiv forwardedRef={this.containerRef} style={{top:0, bottom:0, left:0, right:0, position:'absolute'}} isLoading={this.state.loading}>
                <CardContent>
                    <Typography variant="h6" ref="header" style={{position: 'absolute', top: '.5rem', left: '1.5rem', fontSize: '20px', fontFamily: 'Roboto' }} >
                        <span>{selectedEvent ? selectedEvent.eventType : 'Event'} History</span>
                    </Typography>
                    {subheader &&
                        <div style={{position: 'absolute', top: '3rem', left: '1.5rem', fontSize: '18px', fontFamily: 'Roboto', display: "flex"}}>
                            <Typography variant="h6" ref="header" style={{ whiteSpace: 'nowrap' }} >
                                <span>
                                    {subheader.header}
                                </span>
                            </Typography>
                            <Typography variant="body1" ref="header" style={{ lineHeight: 2.1, paddingLeft: '4px', whiteSpace: 'nowrap' }}>
                                <span>
                                    {subheader.subheader}
                                </span>
                            </Typography>
                        </div>
                    }
                    <div style={{ position: 'absolute', top: subheader ? '5rem' : '2.5rem', left: '0', right: '0', bottom: '0', width: "100%", height: subheader ? "calc(100% - 5.5rem)" : "calc(100% - 4rem)",}}>
                        { data && columns && columns.length !== 0 && data && <AutoSizer>
                            {({ width, height }) => (
                                <FlexTable 
                                    width={width} 
                                    height={height} 
                                    selectedItem={selectedEvent} 
                                    setSelectedItem={setSelectedEvent}
                                    columns={this.state.columns}
                                    data={this.state.data}></FlexTable>
                            )}
                        </AutoSizer>}
                    </div>
                </CardContent>
            </LoadingDiv>
        );
    }
}

TableCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

const MuiVirtualizedTableCard = withStyles(styles)(TableCard);
export default MuiVirtualizedTableCard;