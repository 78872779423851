import _ from 'lodash'
import React from 'react'
import { Input, InputNumber, Modal, Button } from 'rsuite'
import { ExplorerContext } from '../../../contexts/ExplorerContext'

const ReferenceLineModal = (props) => {

    const { referenceLines, setReferenceLines, referenceMetric, open, setOpen } = props
    const explorerContext = React.useContext(ExplorerContext)
    const {openNotification } = explorerContext


    const [name, setName] = React.useState(null)
    const [value, setValue] = React.useState(null)
    const [thickness, setThickness] = React.useState(5)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const onConfirm = () => {
        
        if (!name || !value || !referenceMetric.current) { openNotification('error', 'ref'); return }
        if (isNaN(value)) { openNotification('error', 'ref'); return }
        
        let intValue = parseInt(value)

        let copy = _.cloneDeep(referenceLines)
        copy[name] = {
            metric: referenceMetric.current,
            value: intValue,
            thickness: thickness
        }
        
        setReferenceLines(copy)
        handleClose()
    }

    return (
        
        <Modal
            open={open}
            onClose={handleClose}
            backdrop={true}
        >

            <Modal.Header>
                <Modal.Title> Add Reference Line </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <Input style={{margin: '1rem 0 0rem 0'}} defaultValue={''} placeholder='name it...' onChange={(newName) => { setName(newName) }}></Input>
                    <span style={{ margin: '1rem 0rem 0rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        {`value (${referenceMetric.current})`}
                        <InputNumber placeholder='value...' onChange={(newValue) => { setValue(newValue)}} />
                    </span>
                    <span style={{ margin: '1rem 0rem 1rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        thickness
                        <InputNumber addonBefore='px' defaultValue={thickness} onChange={(newThickness) => { setThickness(newThickness) }} />
                    </span>
                    <span style={{ height: '.4rem', backgroundColor: '#7373b9', margin: '2rem 3rem 1rem 3rem'}}/> 
                </span>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onConfirm} appearance="primary">
                    Confirm
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>

      </Modal>
    )
}

export default ReferenceLineModal


