import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, Radio, TextField, List, ListItem, ListItemText, Divider, Switch, FormControlLabel, MenuItem, Typography, Link } from '@material-ui/core';
import SearchBar from '../featurepanel/SearchBar';
import AlertDialogButton from '../AlertDialogButton';
import _ from 'lodash';
import { deleteExternalDataEndpoint, updateExternalDataEndpoint } from '../../api/ApiWorker';
import withLoading from '../withLoading';
import QueryParams from './QueryParams';

const LoadingDiv = withLoading('div');

const endpointsClasses = theme => ({
    loadingDiv: {
        height: '100%'
    },
    mainGrid: {
        height: '100%'
    },
    endpointEditorContainer: {
        height: '50vh',
        overflowY: 'auto'
    },
    endpointsListContainer: {
        height: '49vh',
        overflowY: 'auto'
    },
    endpointsList: {
        width: '100%',
        padding: '0px',
    },
    endpointsListItem: {
        paddingLeft: '32px'
    },
    searchBar: {
        paddingTop: '0px',
        paddingLeft: '24px'
    }
});
const styles = {
    mainGrid: {
        margin: '0px -8px'
    },
    endpointsContainer: {
        padding: '0px'
    },
    dataSourceSelect: {
        paddingLeft: '24px',
        paddingRight: '12px',
        paddingBottom: '12px'
    }
}

const radioButtonColor = 'primary.Primary';

var loadingMessageKey;

const Endpoints = (props) => {
    const { classes } = props;

    const themeContext = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    const snackbarContext = useContext(SnackbarContext);

    const [dataSourceNames, setDataSourceNames] = useState([]);
    const [currentDataSourceName, setCurrentDataSourceName] = useState('');
    const [currentEndpoint, setCurrentEndpoint] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [endpointFilter, setEndpointFilter] = useState('');
    const [endpointEnabled, setEndpointEnabled] = useState(null);

    const [endpointNameField, setEndpointNameField] = useState(null);
    const [endpointNameFieldError, setEndpointNameFieldError] = useState('');

    const [endpointAssetNameField, setEndpointAssetNameField] = useState(null);
    const [endpointAssetNameFieldError, setEndpointAssetNameFieldError] = useState('');

    const [endpointSuccessIntervalMinutesField, setEndpointSuccessIntervalMinutesField] = useState(null);
    const [endpointSuccessIntervalMinutesFieldError, setEndpointSuccessIntervalMinutesFieldError] = useState('');

    const [endpointFailureIntervalMinutesField, setEndpointFailureIntervalMinutesField] = useState(null);
    const [endpointFailureIntervalMinutesFieldError, setEndpointFailureIntervalMinutesFieldError] = useState('');

    const [endpointUnitsField, setEndpointUnitsField] = useState(null);
    const [endpointUnitsFieldError, setEndpointUnitsFieldError] = useState(null);

    const [endpointMetricField, setEndpointMetricField] = useState(null);
    const [endpointMetricFieldError, setEndpointMetricFieldError] = useState('')

    const [endpointQueryParams, setEndpointQueryParams] = useState({});
    const [endpointQueryParamsError, setEndpointQueryParamsError] = useState();

    const [endpointCommentField, setEndpointCommentField] = useState('');

    useEffect( () => {
        setDataSourceNames(props.dataSources.map(x => x.name));
        setCurrentDataSourceName(props.dataSources[0].name);
        
        if (!_.isEmpty(props.endpoints)) {
            if (!_.isEmpty(props.endpoints.filter(x => x.tenantApi == props.dataSources[0].name))) {
                setSelectedEndpoint(props.endpoints.filter(x => x.tenantApi == props.dataSources[0].name)[0]); 
            }
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(props.defaultDataSource)) {
            setCurrentDataSourceName(props.defaultDataSource.name);
            if (!_.isEmpty(props.endpoints.find(x => x.tenantApi == props.defaultDataSource.name))) {
                setSelectedEndpoint(props.endpoints.find(x => x.tenantApi == props.defaultDataSource.name));
            }
        } 
    }, [props.defaultDataSource]);

    const isValidNumberMinutes = (input) => {
        if (typeof(input) === 'string') {
            return input.match(/^[0-9]+$/);
        }
        return true;
    }

    const updateDataEndpoint = async (newEndpoint) => {
        loadingMessageKey = snackbarContext.addMessage('Updating endpoint...');
        setIsLoading(true);

        try {
            const tenant = userContext.getTenant();
            const ac = new AbortController();
    
            var result = await updateExternalDataEndpoint(tenant.tenantName, newEndpoint, ac);
    
            //If API response success
            if (result.ok) {
                let newEndpoints = props.endpoints;
        
                let endpointToUpdate = newEndpoints.find(x => x.isTemp || x.name == newEndpoint.oldName);
                newEndpoints[newEndpoints.indexOf(endpointToUpdate)] = newEndpoint;
                newEndpoints.forEach(x => x.isTemp = false);
    
                setCurrentEndpoint(newEndpoint);
        
                props.setData({
                    endpoints: newEndpoints,
                    dataSources: props.dataSources,
                    supportedDataSources: props.supportedDataSources
                });
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('Endpoint updated.', 'success');
            } else {
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('An error occurred. Please try again.', 'error');
            }
        } catch (e) {
            snackbarContext.removeMessage(loadingMessageKey);
            snackbarContext.addMessage('An error occurred. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
      }
    
      const addNewEndpoint = (dataSourceName) => {
        if (props.endpoints.filter(x => x.isTemp).length > 0) {
            //Probably show a pop up or message here stating that
            //User must finish editing the current 'New' endpoint before being able to create a new one
            //This prevents spamming the 'New' button to create an infinite amount of endpoints in the UI
            return;
        }; 
    
        const tempEndpoint = {
          isTemp: true, //Flag denoting whether an endpoint was temporary (newly created, but changes haven't been saved to database yet)
          name: '',
          assetName: '',
          tenantApi: dataSourceName,
          successIntervalMinutes: '5',
          failureIntervalMinutes: '15',
          metric: 'depth',
          units: 'ft',
          comment: '',
          queryParams: {}
        };
    
        let tempEndpoints = props.endpoints;
        tempEndpoints.push(tempEndpoint);
        setSelectedEndpoint(tempEndpoint);

        props.setData({
            endpoints: tempEndpoints,
            dataSources: props.dataSources,
            supportedDataSources: props.supportedDataSources
        });
      }
    
      const deleteEndpoint = async (currentEndpoint) => {
        if (!currentEndpoint.isTemp) {
            loadingMessageKey = snackbarContext.addMessage('Deleting endpoint...');
            setIsLoading(true);
            try {
                const tenant = userContext.getTenant();
                const ac = new AbortController();
                var result = await deleteExternalDataEndpoint(tenant.tenantName, currentEndpoint.name, ac);
        
                //If API call response successful
                if (result.ok) {
                    let newEndpoints = props.endpoints.filter(x => !_.isEqual(x.name, currentEndpoint.name));
                    let newEndpoint = newEndpoints.find(x => x.tenantApi == currentDataSourceName);
                    if (!_.isEmpty(newEndpoint)) {
                        setSelectedEndpoint(newEndpoint);
                    } else {
                        setCurrentEndpoint(null);
                    }
                    props.setData({
                        endpoints: newEndpoints,
                        dataSources: props.dataSources,
                        supportedDataSources: props.supportedDataSources
                    });
                    snackbarContext.removeMessage(loadingMessageKey);
                    snackbarContext.addMessage('Endpoint deleted.');
                } else {
                    snackbarContext.removeMessage(loadingMessageKey);
                    snackbarContext.addMessage('An error occurred. Please try again.', 'error');
                }
            } catch (e) {
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('An error occurred. Please try again.', 'error');
            } finally {
                setIsLoading(false);
            } 
        } else {
            let newEndpoints = props.endpoints.filter(x => !_.isEqual(x.name, currentEndpoint.name));
            let newEndpoint = newEndpoints.find(x => x.tenantApi == currentDataSourceName);
            if (!_.isEmpty(newEndpoint)) {
                setSelectedEndpoint(newEndpoint);
            } else {
                setCurrentEndpoint(null);
            }
            props.setData({
                endpoints: newEndpoints,
                dataSources: props.dataSources,
                supportedDataSources: props.supportedDataSources
            });
        }
      }

    const setFilter = (input) => {
        setEndpointFilter(input);
    }

    const applyFilter = (data) => {
        return data.filter(x => x.name.toLowerCase().includes(endpointFilter.toLowerCase())).filter(x => x.tenantApi == currentDataSourceName);
    }

    //Enabled switch (uses built-in prev?)
    const toggleEndpointEnabled = event => {
        setEndpointEnabled((prev) => !prev);
    }

    //Name field
    const handleNameChange = event => {
        let text = event.target.value;
        setEndpointNameField(text);

        if (text == '') {
            setEndpointNameFieldError('Name cannot be empty');
        } else if (props.endpoints.find(x =>  x.name.toLowerCase() == text.toLowerCase()) != null) {
            setEndpointNameFieldError(`An endpoint with this name already exists`);
        } else {
            setEndpointNameFieldError(null);
        }
    }

    //Asset Name field
    const handleAssetNameChange = event => {
        setEndpointAssetNameField(event.target.value);
        setEndpointAssetNameFieldError(_.isEmpty(event.target.value) ? 'Asset name cannot be empty' : null);
    }

    //Success Interval Minutes Field
    const handleSuccessIntervalMinutesChange = event => {
        setEndpointSuccessIntervalMinutesField(event.target.value);
        if (_.isEmpty(event.target.value)) {
            setEndpointSuccessIntervalMinutesFieldError('Interval cannot be empty.')
        } else if (!isValidNumberMinutes(event.target.value) || parseInt(event.target.value) <= 0) {
            setEndpointSuccessIntervalMinutesFieldError('Interval must be a positive number');
        } else {
            setEndpointSuccessIntervalMinutesFieldError(null);
        }
    }

    //Failure Interval Minutes Field
    const handleFailureIntervalMinutesChange = event => {
        setEndpointFailureIntervalMinutesField(event.target.value);
        if (typeof(event.target.value) === 'string' && _.isEmpty(event.target.value)) {
            setEndpointFailureIntervalMinutesFieldError(null);
        }
        else if (typeof(event.target.value) === 'string' && (!isValidNumberMinutes(event.target.value) || parseInt(event.target.value) <= 0)) {
            setEndpointFailureIntervalMinutesFieldError('Interval must be a positive number');
        } else {
            setEndpointFailureIntervalMinutesFieldError(null);
        }
    }

    //Units field 
    const handleUnitsChange = event => {
        setEndpointUnitsField(event.target.value);
        setEndpointUnitsFieldError(_.isEmpty(event.target.value) ? 'Units cannot be empty' : null);
    }

    //Metric field
    const handleMetricChange = event => {
        setEndpointMetricField(event.target.value);
        setEndpointMetricFieldError(_.isEmpty(event.target.value) ? 'Metric cannot be empty' : null);
    }

    //Comment field
    const handleCommentChange = event => {
        setEndpointCommentField(event.target.value);
    }

    const handleDropdownChange = event => {
        if (!_.isEmpty(props.endpoints.filter(x => x.tenantApi == event.target.value))) {
            setSelectedEndpoint(props.endpoints.filter(x => x.tenantApi == event.target.value)[0]);
        }

        setCurrentDataSourceName(event.target.value);
    }
    
    const setSelectedEndpoint = (endpoint) => {
        setCurrentEndpoint(endpoint);
        setEndpointEnabled(!!endpoint.enabled);

        setEndpointNameField(endpoint.name);
        setEndpointNameFieldError(_.isEmpty(endpoint.name) ? 'Name cannot be empty' : null);

        setEndpointAssetNameField(endpoint.assetName);
        setEndpointAssetNameFieldError(_.isEmpty(endpoint.assetName) ? 'Asset name cannot be empty' : null);

        setEndpointSuccessIntervalMinutesField(endpoint.successIntervalMinutes);
        if (typeof(endpoint.successIntervalMinutes) === 'string' && _.isEmpty(endpoint.successIntervalMinutes)) {
            setEndpointSuccessIntervalMinutesFieldError('Interval cannot be empty.')
        } else if (!isValidNumberMinutes(endpoint.successIntervalMinutes) || parseInt(endpoint.successIntervalMinutes) <= 0) {
            setEndpointSuccessIntervalMinutesFieldError('Interval must be a positive number');
        } else {
            setEndpointSuccessIntervalMinutesFieldError(null);
        }
        setEndpointFailureIntervalMinutesField(endpoint.failureIntervalMinutes || '0');
        if (!isValidNumberMinutes(endpoint.failureIntervalMinutes) || parseInt(endpoint.failureIntervalMinutes) <= 0) {
            setEndpointFailureIntervalMinutesFieldError('Interval must be a positive number');
        } else {
            setEndpointFailureIntervalMinutesFieldError(null);
        }
        
        setEndpointUnitsField(endpoint.units);
        setEndpointUnitsFieldError(_.isEmpty(endpoint.units) ? 'Units cannot be empty' : null);

        setEndpointMetricField(endpoint.metric);
        setEndpointMetricFieldError(_.isEmpty(endpoint.metric) ? 'Metric cannot be empty' : null);

        setEndpointCommentField(endpoint.comment || '');
    }

    const createEndpointListItems = () => {
        var endpointListItems = [];

        applyFilter(props.endpoints).map(endpoint => {
            endpointListItems.push(
                <ListItem 
                    button={false}
                    style={props.style}
                    divider={true}>
                    <Grid container direction='row' justify='space-between' alignItems='center'>
                        <Radio
                            style={{cursor: 'pointer', color: themeContext.getColor(radioButtonColor)}}
                            checked={_.isEqual(endpoint, currentEndpoint)}
                            onChange={() => setSelectedEndpoint(endpoint)}
                            value={endpoint.name} />
                        <Grid item xs>
                            <ListItemText primary={endpoint.name} />
                            <Typography style={{color: 'gray'}} variant='body2'>
                                {endpoint.assetName}
                            </Typography>
                        </Grid>
                    </Grid>
                </ListItem>
            );
        });

        return endpointListItems;
    }

    const changeTab = () => {
        props.setCurrentTab({index: 0, defaultDataSource: props.dataSources.find(x => x.name == currentDataSourceName)});
    }

    const testEndpoint = () => {
    }

    const updateEndpoint = () => {
        const updatedEndpoint = {
            tenantApi: currentEndpoint.tenantApi,
            oldName: currentEndpoint.isTemp ? endpointNameField : currentEndpoint.name,
            name: endpointNameField,
            assetName: endpointAssetNameField,
            successIntervalMinutes: endpointSuccessIntervalMinutesField,
            failureIntervalMinutes: endpointFailureIntervalMinutesField,
            metric: endpointMetricField,
            units: endpointUnitsField,
            queryParams: endpointQueryParams,
            comment: endpointCommentField,
            enabled: !!endpointEnabled
        };
        updateDataEndpoint(updatedEndpoint);
    }

    const Content = (_.isEmpty(props.endpoints.find(x => x.tenantApi == currentDataSourceName)) || _.isEmpty(currentEndpoint))
    ? <div style={{ marginLeft: '24px' }}>No endpoints to display.</div>
    :
    <Grid container item xs={7} sm={8} md={8} lg={8} spacing={2}>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-start' alignItems='center'>
            <Typography variant='h6'>
                {currentEndpoint.name}
            </Typography>
        </Grid>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-end' alignItems='center'>
            <FormControlLabel
                control={<Switch color='primary' checked={endpointEnabled} onChange={toggleEndpointEnabled} />}
                label='Enabled'
                labelPlacement='start'
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{ display: 'flex' }}>
                <Typography variant='subtitle2'>
                    {`Data source: ${currentDataSourceName}`}
                </Typography>
                <Typography variant='subtitle2' style={{ cursor: 'pointer', paddingLeft: '8px' }}>
                    <Link onClick={changeTab}>
                        Configure &rarr;
                    </Link>
                </Typography>
            </div>
        </Grid>
        <Grid className={classes.endpointEditorContainer} container item spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Name'
                    defaultValue={currentEndpoint.name}
                    value={endpointNameField}
                    onChange={handleNameChange}
                    error={endpointNameFieldError}
                    helperText={endpointNameFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Asset name'
                    defaultValue={currentEndpoint.assetName}
                    value={endpointAssetNameField}
                    onChange={handleAssetNameChange}
                    error={endpointAssetNameFieldError}
                    helperText={endpointAssetNameFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Query Interval on Success (minutes)'
                    defaultValue={currentEndpoint.successIntervalMinutes}
                    value={endpointSuccessIntervalMinutesField}
                    onChange={handleSuccessIntervalMinutesChange}
                    error={endpointSuccessIntervalMinutesFieldError}
                    helperText={endpointSuccessIntervalMinutesFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Query Interval on Failure (minutes)'
                    defaultValue={currentEndpoint.failureIntervalMinutes}
                    value={endpointFailureIntervalMinutesField}
                    onChange={handleFailureIntervalMinutesChange}
                    error={endpointFailureIntervalMinutesFieldError}
                    helperText={endpointFailureIntervalMinutesFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Metric'
                    defaultValue={currentEndpoint.metric}
                    value={endpointMetricField}
                    onChange={handleMetricChange}
                    error={endpointMetricFieldError}
                    helperText={endpointMetricFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Units'
                    defaultValue={currentEndpoint.units}
                    value={endpointUnitsField}
                    onChange={handleUnitsChange}
                    error={endpointUnitsFieldError}
                    helperText={endpointUnitsFieldError}
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                    label='Comments'
                    defaultValue={''}
                    value={endpointCommentField}
                    onChange={handleCommentChange}
                    variant='outlined'
                    fullWidth
                    multiline
                    rows={2}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid item>
                    <Typography variant='subtitle2' style={{paddingBottom: '16px'}}>
                        {`Query Parameters`}
                    </Typography>
                </Grid>
                <Grid item>
                    <QueryParams queryParams={currentEndpoint.queryParams} setQueryParams={setEndpointQueryParams} hasError={endpointQueryParamsError} setError={setEndpointQueryParamsError} />
                </Grid>
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-start' alignItems='center'>
                <AlertDialogButton
                    buttonColor={'secondary'}
                    buttonText={'Remove this endpoint'}
                    dialogTitle={'Remove this endpoint?'}
                    dialogText={'This endpoint will be removed from PipeCAST.'}
                    confirmOnClick={() => deleteEndpoint(currentEndpoint)} />
            </Grid>
            <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-end' alignItems='center'>
                {
                    false && // The Test Configuration will take some more planning. This will have to test every single supported data source.
                    // This should be broken out into its own user story.
                    <Button color='primary' onClick={testEndpoint}>
                        Test Configuration
                    </Button>
                }
                <Button disabled={
                    endpointNameFieldError || endpointAssetNameFieldError || endpointSuccessIntervalMinutesFieldError || endpointFailureIntervalMinutesFieldError || endpointUnitsFieldError || endpointMetricFieldError || endpointQueryParamsError
                }
                color='primary' onClick={updateEndpoint}>
                    Save Changes
                </Button>
            </Grid>
        </Grid>
    </Grid>;
        
    return (
        <LoadingDiv isLoading={isLoading}>
            <Grid style={styles.mainGrid} className={classes.mainGrid} container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
                <Grid container item direction='column' justify='flex-start' alignItems='flex-start' xs={4} sm={3} md={3} lg={3}>
                    <Grid container item justify='flex-start'>
                        <TextField
                            select
                            label='Data Sources'
                            defaultValue={currentDataSourceName}
                            value={currentDataSourceName}
                            onChange={handleDropdownChange}
                            style={styles.dataSourceSelect}
                            variant='outlined'
                            InputLabelProps={{ style: { paddingLeft: '32px' } }}
                            fullWidth
                            >
                            {dataSourceNames.map(dataSourceName => (
                                <MenuItem value={dataSourceName}>
                                    {dataSourceName}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item justify='flex-start'>
                        <Grid container item xs={8} sm={9} md={9} lg={9} display='grid' justify='flex-start' alignItems='center'>
                            <SearchBar customStyle={classes.searchBar} placeholderText={'Find an endpoint'} setFilter={setFilter}/>
                        </Grid>
                        <Grid container item xs={4} sm={3} md={3} lg={3} display='grid' justify='center' alignItems='center'>
                            <Button variant='contained' color='primary' onClick={() => addNewEndpoint(currentDataSourceName)}>
                                New
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid className={classes.endpointsListContainer} container item direction='row' justify='flex-start'>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List className={classes.endpointsList}>
                                {createEndpointListItems()}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation='vertical' flexItem />
                {Content}
            </Grid>
        </LoadingDiv>
    );
}
export default withStyles(endpointsClasses)(Endpoints);