import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { scales, scaleName, handSide, transform } = props;   

    function drawYAxisGroup(newScales) {
        scales = newScales;
        draw();
    }

    function draw() {
        console.log("draw y axis")
        const axisFnName = `axis${handSide[0].toUpperCase() + handSide.slice(1)}`;
        d3.select(primaryChartRef.current)
            .select(`.${handSide}-y-axis-group`)
            .call(
                d3[axisFnName](scales[scaleName]).ticks(7)
            );
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawYAxisGroup);
        draw();
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(), [scales]);

    const className = `${handSide}-y-axis-group yAxisGroup`;

    return (
        <g className={className} 
            transform={transform}
            style={{ font: "1rem Roboto", strokeOpacity: "0.38", shapeRendering: "crispEdges", color: "rgba(0,0,0,0.6)" }}>
        </g>
    )
})
