import React from 'react';
import InteractiveMap from './InteractiveMap';
import GeolocatControls from './GeolocateControl';
import NavigationControls from './NavigationControls';
import _ from "lodash";
import turf from 'turf';
import mapboxgl from 'mapbox-gl';
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import "../../css/DashboardMap.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import MapControls from './MapControls';
import setFeatureState from './setFeatureState';
import { withRouter } from 'react-router-dom';
import { ErrorContext } from '../../contexts/ErrorContext';
import jsPDF from 'jspdf';

class CMOMMap extends React.Component {
    
    constructor(props) 
    {
        super(props);

        this.state = {
            showSystem: false,
            showModelSystem: false,
            isPrinting: false,
            layers: {},
            viewType: "Map" //Default view
        }

        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.sources = {};
        this.layers = {};
        this.modelLayers = null;
        this.selectedFeature = null;
        this.mapLoaded = false;
        this.selectedStatus = null;
        this.assets = null;
        this.layerCtx = { abort: false, complete: false};
    }

    toggleViewType = (viewType) => 
    {
        this.setState({viewType: viewType});
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }

    toggleModelSystem = () => {

        const cmomLayer = this.props.model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));
        const layers = Object.keys(this.layers).filter(layerId => layerId !== this.props.model.selectedFeature.id && layerId !== cmomLayer.layer.id);


        layers.forEach(layerGroupKey => {
            const layerGroup = this.layers[layerGroupKey];
            if(this.state.showModelSystem) {
                this.map.hideExternalLayer(layerGroup);
            } else {
                this.map.showExternalLayer(layerGroup);
            }
        });
        this.setState({
            showModelSystem: !this.state.showModelSystem
        });
    }

    toggleSystem = () => {
        this.map.map.getStyle().layers.forEach(layer => {
            if(layer["source"] == "system") {
                this.map.map.setLayoutProperty(layer.id, 'visibility', this.state.showSystem ? 'none' : 'visible');
            }
        });
        this.setState({
            showSystem: !this.state.showSystem
        });
    }

    updateSource = (map, source, data) => {
        let mapSource = map.getSource(source);
        if(typeof mapSource === "undefined" || mapSource == null) {
            map.addSource(source, {
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a=>{
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        } else {
            mapSource.setData({
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a=>{
                    a.properties.tempId = a.id;
                    return a;
                })
            });
                
        }
    }

    updateModelLayers = async () => {
        const interactiveMap = this.map;
        let { layers, selectedFeature } = this.props.model;
        const {itemType} = this.props;

        for(const layer of layers) {
            if(this.layers[layer.layer.id]) continue;

            let layerStyles = null;

            if(layer.layer.options && layer.layer.options.styles) {
                layerStyles = layer.layer.options.styles;
            }

            const layerId = interactiveMap.addExternalLayerToMap({
                "type": "FeatureCollection",
                "generateId": true,
                "features": []
            }, layerStyles, { hide: layer.layer.id !== selectedFeature.id });

            this.layers[layer.layer.id] = layerId;

            const data = await layer.data;

            interactiveMap.updateExternalLayerSource(layerId, {
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });

            if (layer.layer.maintenanceCollectionFieldName != null)
            {
                if (this.layers[layer.layer.layerName]) continue;
                let cmomLayer = this.props.model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));
                let cmomData = data.map(x => x.properties[layer.layer.maintenanceCollectionFieldName]).flat();
                cmomData = cmomData.map(x => x.Feature);

                let cmomFeatures = cmomData.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                });

                const newId = interactiveMap.addExternalLayerToMap({
                    "type" : "FeatureCollection",
                    "generateId" : true,
                    "features" : cmomFeatures
                }, cmomLayer.layer.options.styles, {hide : layer.layer.id !== selectedFeature.id});

                this.layers[layer.layer.layerName] = newId;

                let cmomStyleLayers = Object.keys(interactiveMap.map.style._layers).filter(x => x.includes(newId));
                
                cmomStyleLayers.forEach(x => {
                    interactiveMap.map.moveLayer(x, this.layers[selectedFeature.id] + '.0')
                });
                this.mapLoaded = true;
            }
        }
    }

    hideLayers = (layerId) => {
        if(this.layers && this.layers[layerId]) {
            this.map.hideExternalLayer(this.layers[layerId]);
        } 
    }


    showLayers = (layerId) => {
        if(this.layers && this.layers[layerId]) {
            this.map.showExternalLayer(this.layers[layerId]);
        } 
    }

    componentDidMount() {
        if(!_.isUndefined(this.props) && !_.isUndefined(this.props.model) && !_.isUndefined(this.props.model.layers)) {            
            const updateModelLayers = this.updateModelLayers.bind(this);
            this.map.map.on("load", updateModelLayers);
        }
    }

    componentWillUnmount() {
        const updateModelLayers = this.updateModelLayers.bind(this);
        this.map.map.off("load", updateModelLayers);
    }

    setFeatureState = () => {
        const {
            model, 
            alertContext,
            maintenanceContext,
            itemType
        } = this.props;
        try {
            setFeatureState[itemType](
                model.assets,
                model.selectedStatus,
                this.layers,
                this.getColor,
                this.map.map,
                model.selectedFeature,
                model,
                model.assetFilter,
                maintenanceContext,
                this,
                alertContext.state ? alertContext.state.alertTypes : []
            );
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {model, maintenanceContext} = this.props;
        const {selectedFeature} = model;

        if (!_.isEqual(model.assetFilter, prevProps.model.assetFilter))
        {
            const assetFilter = model.assetFilter;
            const currentLayerId = model.selectedFeature.id;
            const displayField = model.selectedFeature.displayField;

            let visibleAssets = model.assets.filter(a => {
                try {
                    return a.properties[displayField].toLowerCase().indexOf(assetFilter.trim().toLowerCase()) != -1;
                } catch {
                    return false;
                }
            });

            let visibleAssetsIds = visibleAssets.map(a => a.id);
            this.map.map.setFilter(this.layers[currentLayerId] + '.0', ['in', 'tempId', ...visibleAssetsIds]);

            //Filter downstream trunk layer for each downstream trunk that belongs to a regulator that matches the above asset filter
            if (selectedFeature.maintenanceCollectionFieldName != null)
            {
                let cmomLayer = this.props.model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));
                const cmomLayerId = cmomLayer.layer.id;
                const cmomDisplayField = cmomLayer.layer.displayField;
                let maintenanceAssets = [];

                visibleAssets.forEach(x => {
                    if (!_.isEmpty(x.properties[selectedFeature.maintenanceCollectionFieldName])) {
                        maintenanceAssets = maintenanceAssets.concat(x.properties[selectedFeature.maintenanceCollectionFieldName]);
                    }
                });

                maintenanceAssets = maintenanceAssets.map(x => x.Feature.properties[cmomDisplayField]);
    
                let cmomStyleLayers = Object.keys(this.map.map.style._layers).filter(x => x.includes(this.layers[selectedFeature.layerName]))
    
                cmomStyleLayers.forEach(x => {
                    this.map.map.setFilter(x, ['in', cmomDisplayField, ...maintenanceAssets]);
                });
            }
        }
        if(!this.mapLoaded) return;

        const setFeatureState = this.setFeatureState.bind(this);

        //Add listeners to recolor the map at the end of every zoom or click & drag event
        // if (this.mapLoaded && !prevProps.mapLoaded)
        // {
        //     this.map.map.on('zoomend', setFeatureState);
        //     this.map.map.on('dragend', setFeatureState);
        // } 
        
        this.updateModelLayers();
        
        if(prevProps.model.selectedFeature) {
            this.hideLayers(prevProps.model.selectedFeature.id);
        }
        if(prevProps.model.selectedFeature.maintenanceCollectionFieldName) {
            this.hideLayers(prevProps.model.selectedFeature.layerName);
        }

        this.showLayers(this.props.model.selectedFeature.id);

        if (this.props.model.selectedFeature.maintenanceCollectionFieldName != null)
        {
            this.showLayers(this.props.model.selectedFeature.layerName);
        }

        this.map.map.off('idle', setFeatureState);
        this.map.map.once('idle', setFeatureState);
    }

    getAlertLayerLegend = (getColor) => {
        const {alertContext} = this.props;
        const {alertTypes} = alertContext.state;

        let layers;

        //Format alerts into format used for layer legend
        layers = alertTypes.map((alert, index) => {
            let severity;
            switch (alert.severity)
            {
                case 1:
                    severity = 'Critical';
                break;
                case 2: 
                    severity = 'Medium';
                break;
                default:
                    severity = 'Low';
                break;
            }
            let layer = {
                layerName: severity,
                iconColor: getColor(alert.displayColor)
            };
            return layer;
        });

        //Remove duplicates from layer list (if there are multiple alerts with same severity color scheme)
        layers = _.uniqWith(layers, _.isEqual);

        return layers;
    }

    setIsSelected = (interactiveMap, feature) => {
        const {model} = this.props;
        const {selectedFeature} = model;

        let selectableLayers = (_.isUndefined(this.layers) || _.isNull(this.layers) ) ? [] : Object.keys(this.layers).reduce((layerIds, layerId, idx) => {
            if(layerId === selectedFeature.id || layerId === selectedFeature.layerName) {
                return layerIds.concat(this.layers[layerId]);
            }
            return layerIds;
        }, [])

        selectableLayers = selectableLayers.reduce((layers, layer) => {
            if(interactiveMap.isExternalLayer(layer)) {
                return layers.concat(interactiveMap.getExternalLayers(layer));
            } else {
                return layers.push(layer);
            }
        }, []);

        const featureId = feature ? feature.id : null;
        let maintenanceAssetsField = selectedFeature.maintenanceCollectionFieldName;
        let cmomLayer = model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));

        let maintenanceAssets = (feature && feature.properties && feature.properties[maintenanceAssetsField]) ? feature.properties[maintenanceAssetsField] : [];
        maintenanceAssets = (typeof maintenanceAssets == 'string') ? JSON.parse(maintenanceAssets) : maintenanceAssets;
        maintenanceAssets = maintenanceAssets.map(x => x.Feature.properties[cmomLayer.layer.displayField]);

        for(const layer of selectableLayers) {
            const source = interactiveMap.map.getSource(layer.source);

            source._data.features.forEach(f => {
                if(featureId && f.properties && (f.properties.tempId == featureId || maintenanceAssets.includes(f.properties[cmomLayer.layer.displayField]))) {
                    f.properties.isSelected = '1';
                } else {
                    f.properties.isSelected = '0';
                }
            });
            source.setData(source._data);
        }
    }

    updateSelectedFeatures = async (interactiveMap, feature, options) => {
        const {model, routeContext, history, dateContext, maintenanceContext} = this.props;
        const {tenant, pageType, layoutType, viewType, layerName} = routeContext.state;
        const {selectedFeature} = model;

        if (feature) {
            let features = feature;

            let cmomLayer = model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));
            if (_.isEmpty(cmomLayer)) return;
            let data = await cmomLayer.data;

            //When selecting a line/pipe of a maintenance collection, find the parent 'Regulator' of said collection and set isSelected
            if (!features.properties[selectedFeature.maintenanceCollectionFieldName] && features.properties[cmomLayer.layer.displayField])
            {
                let assets = model.assets.filter(x => x.properties && x.properties[selectedFeature.maintenanceCollectionFieldName].map(x => x.Feature.properties && x.Feature.properties[cmomLayer.layer.displayField]).includes(features.properties[cmomLayer.layer.displayField]));
                features = assets[0];
            }

            if(_.isArray(features)) {
                for(const f of features) {
                    this.setIsSelected(interactiveMap, f)
                }
            } else {
                this.setIsSelected(interactiveMap, features);
            }

            let center = turf.centroid(features);
            interactiveMap.map.flyTo({ center: center.geometry.coordinates, zoom: 14 });

            let maintenanceAssets = features.properties[selectedFeature.maintenanceCollectionFieldName] || [];
            maintenanceAssets = (typeof maintenanceAssets == 'string') ? JSON.parse(maintenanceAssets) : maintenanceAssets;
            maintenanceAssets = maintenanceAssets.map(x => x.Feature.properties[cmomLayer.layer.displayField]);

            data = data.filter(x => maintenanceAssets.includes(x.properties[cmomLayer.layer.displayField]));

            let coordinateList = [];
            data.forEach(x => {
                coordinateList = coordinateList.concat(x.geometry.coordinates[0]);
            });

            // Placeholder for when selecting a line on the map that is a child of a collection (ex. Downstream Trunk)
            if (_.isEmpty(coordinateList)) return;

            var bounds = coordinateList.reduce(function(bounds, coord) {
                return bounds.extend(coord);
                }, new mapboxgl.LngLatBounds(coordinateList[0], coordinateList[0]));
                 
                interactiveMap.map.fitBounds(bounds, {
                padding: 20
                });
        } else {            
            this.setIsSelected(interactiveMap, null);
            if(options && options.zoomOnEmpty)
            {
                const {center, zoom} = this.props.options;
                const {lat, lng} = center;
                interactiveMap.map.flyTo({ 
                    center: [lng, lat],
                    zoom: zoom
                });
            }
        }
    }

    render() {
        const component = this;
        const {maintenanceContext} = this.props;
        let cmomLayer = this.props.model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));

        return (            
            <MapContextProvider>
                <ErrorContext.Consumer>
                    {(error) =>

                <MapContext.Consumer>
                    {(mapContext) => {
                        const updateSelectedItem = (feature) => 
                        {                
                            const {model, routeContext, history, dateContext} = component.props;
                            const {tenant, pageType, layoutType, viewType, layerName} = routeContext.state;
                            const {selectedFeature} = model;

                            let displayName = feature.properties[this.props.model.selectedFeature.displayField];

                            if (!displayName && feature.properties[cmomLayer.layer.displayField])
                            {
                                let assets = model.assets.filter(x => x.properties && x.properties[selectedFeature.maintenanceCollectionFieldName].map(x => x.Feature.properties && x.Feature.properties[cmomLayer.layer.displayField]).includes(feature.properties[cmomLayer.layer.displayField]));
                                feature = assets[0];
                                displayName = feature.properties[selectedFeature.displayField];
                            }
                            const basePath = `/${tenant}/${pageType}/${layoutType}/${viewType}/${layerName}/${displayName}`;
                    
                            const timescale = 'timescale=' + dateContext.timescale;
                            const startDate = dateContext.timescale == 'custom' && dateContext.startDate ? '&startDate=' + dateContext.startDate : '';
                            const endDate = dateContext.timescale == 'custom' && dateContext.endDate ? '&endDate='+ dateContext.endDate : '';
                            
                            history.push(basePath + '?' + timescale + startDate + endDate);

                            model.updateHighlightedAsset(feature)
                            routeContext.updateValues({assetName: displayName});
                        };
                        return(
                            <>
                                <ThemeContext.Consumer>
                                    {({getColor}) => {
                                        component.getColor = getColor;
                                    }}
                                </ThemeContext.Consumer>
                                <InteractiveMap {...component.props} viewType = {component.state.viewType}
                                    ref={(element) => {
                                        component.map = element; 
                                        mapContext.updateMap(element);
                                    }}
                                    zoomOnSelect={14}
                                    mapContext={mapContext}
                                    itemType={this.props.itemType}
                                    selectedFeature={component.props.model.selectedFeature}
                                    layers={component.layers}
                                    selectableLayers={(_.isUndefined(component.layers) || _.isNull(component.layers) ) ? [] : Object.keys(component.layers).reduce((layerIds, layerId, idx) => {
                                        if(layerId === component.props.model.selectedFeature.id || layerId === component.props.model.selectedFeature.layerName) {
                                            return layerIds.concat(component.layers[layerId]);
                                        }
                                        return layerIds;
                                    }, []) }
                                    selectedItem={component.props.model.state.value.selectedItem} 
                                    updateSelectedItem={updateSelectedItem}
                                    setIsSelected={component.setIsSelected}
                                    updateSelectedFeatures={component.updateSelectedFeatures} >
                                
                                    { <GeolocatControls /> && <NavigationControls /> }

                                </InteractiveMap>

                                <ThemeContext.Consumer>
                                    {({getColor}) => (
                                        <MapControls
                                        layers= {
                                            this.props.itemType == 'alert'
                                            ? !_.isEmpty(this.props.alertContext.state.alertTypes) && this.getAlertLayerLegend(getColor)
                                            : !_.isEmpty(this.props.model.selectedStatus) && this.props.model.selectedStatus.classes.sort((a,b) => b.value - a.value).map((statusClass, idx) => {
                                                let layer = {
                                                    layerName: statusClass.name,
                                                    iconColor: getColor(statusClass.color)
                                                };
                                                return layer;
                                                })
                                        }
                                        legendTitle={this.props.itemType == 'alert' ? 'Alerts' : 'Layers'}
                                        legendSubtitle={this.props.itemType == 'alert' ? 'Severity' : this.props.model.selectedStatus.displayName}
                                        toggleSystem={() => component.toggleSystem()}
                                        showSystem={component.state.showSystem}
                                        toggleModel={() => component.toggleModelSystem()}
                                        showModelSystem={component.state.showModelSystem}
                                        mapOptions={component.props.options}
                                        viewType={component.state.viewType}
                                        toggleViewType={component.toggleViewType}
                                        handlePrintClick={component.handlePrintClick}>
                                     </MapControls>
                                    )}
                                </ThemeContext.Consumer>

                            </>
                        );
                    }}
                </MapContext.Consumer>
                }</ErrorContext.Consumer>
            </MapContextProvider>
            
        )
    }
}

export default withRouter(CMOMMap);