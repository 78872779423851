import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import UserSubMenu from './UserSubMenu';
import Divider from '@material-ui/core/Divider';
import { UserContext } from '../../contexts/UserContext';

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: "rgba(255,255,255,0)",
        position: 'absolute',
        bottom: 0,
        left: 0,
        paddingBottom: 0,
    },
});

class ListMenuBottom extends React.Component {

    render() {
        const { classes, menuContext } = this.props;

        return (
            <List component="nav" className={classes.root}>
                <Divider />
                <UserContext.Consumer>{
                    (model) => {
                        return(<UserSubMenu model={model} menuContext={this.props.menuContext} defaultDashboard={this.props.defaultDashboard}/>);
                    }
                }</UserContext.Consumer>
                
            </List>
        );
    }
}

ListMenuBottom.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListMenuBottom);
