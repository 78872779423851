import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {Link} from 'react-router-dom';
import withTenantName from '../../withTenantName';
import Tooltip from '@material-ui/core/Tooltip';
import { MenuContext } from '../../../contexts/MenuContext';
import { RouteContext } from '../../../contexts/RouteContext';
import withQuery from '../../withQuery';

const LinkWithTenantName = withTenantName(Link);
const LinkWithTenantNameWithQuery = withQuery(LinkWithTenantName);

const styles = theme => ({
    header: {
        "&:focus": {
            backgroundColor: "transparent"
        },
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    nested: {
        paddingLeft: theme.spacing(5),
        paddingTop: "0px",
        paddingBottom: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    text: {
        fontFamily: "Roboto",
        color: "white",
    },
    listItem: {
        borderRadius: "200px",
        padding: "8px",
        paddingLeft: "13px",
        paddingRight: "13px",
        fontFamily: "Roboto",
    }
});

const ReportingSubMenu = (props) => {
    const { dashboards, loadDashboard, selectedDashboard } = props;
    const menuContext = useContext(MenuContext);
    const routeContext = useContext(RouteContext);
    const {classes} = props;

    const getListItem = (dashboard) => {
        return (
            <Tooltip title=''>
                <ListItem button className={classes.nested} onClick={() => loadDashboard(dashboard.dashboardCards, dashboard)} key={dashboard.id} style={{ textDecoration: 'none' }}>
                    <div
                        id={dashboard.id}
                        className={classes.listItem}
                        title={dashboard.dashboardName}
                        style={{
                            backgroundColor: selectedDashboard?.id === dashboard.id ? "rgba(0, 0, 0, 0.34)" : 'transparent',
                            fontSize: (dashboard.dashboardName.length >= 20) ? '.8rem' : '1rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            pointerEvents: 'none'
                        }}>
                        {dashboard.dashboardName}
                    </div>
                </ListItem>
            </Tooltip>
        );
    }

    return (
        <div>
            {/*Top Level Menu with Icon*/}
            <ListItem button className={classes.header} onClick={menuContext.toggleReporting}>
                <ListItemIcon className={classes.text}>
                    <AssessmentIcon />
                </ListItemIcon>
                <ListItemText variant = "inset" disableTypography className={classes.text}> Reporting </ListItemText>
                {menuContext.state.reportingExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/*Sub Menu*/}
            <Collapse in={menuContext.state.reportingExpanded} timeout="auto">
                    <List component="div" disablePadding>
                    {dashboards.map(dashboard => (
                         <LinkWithTenantNameWithQuery to={`/{tenantName}/dashboards/reports/${dashboard.dashboardName}/${routeContext.state.layerName}${routeContext.state.assetName ? `/${routeContext.state.assetName}` : ''}`} style={{ textDecoration: 'none' }}>
                          {getListItem(dashboard)}
                        </LinkWithTenantNameWithQuery>                       
                    ))}
                    </List>
            </Collapse>
        </div>
    )
}

ReportingSubMenu.proptypes = {
    classes : PropTypes.object.isRequired
}

export default withStyles(styles)(ReportingSubMenu);