import * as d3 from 'd3';

export default function setBrush(chart, timeScale, maxExtent, brushStartDate, brushEndDate, callbacks) {
    const brushStartIdx = timeScale(brushStartDate);
    const brushEndIdx = timeScale(brushEndDate);

    const brushIdxArr = [brushStartIdx, brushEndIdx];

    let brush = chart.selectAll('.brush')
        .call(
            d3.brushX()
                .extent([[0, 0], maxExtent])
                .on("start", _onBrushStart)
                .on("brush", _onBrush)
                .on("end", _onBrushEnd)
        )
        .call(d3.brushX().move, brushIdxArr);

        brush.exit().remove();

    //Select and edit brush handles
    brush.selectAll(".handle")
         .attr("fill", "#555")
         .style("width", "2px")
    
    const handleW = brush.selectAll(".resize")
        .data([{type: "w"}])
        .enter().append("rect")
        .attr("class", "handle handle--w handle-mini")
        .attr("fill", "white")
        .attr('stroke', '#555')
        .attr('stroke-width', 2)
        .attr("x", brushStartIdx - 8)
        .attr("y", '12%')
        .attr("height", '15%')
        .attr("width", 13)
        .style("cursor", "ew-resize");
    
    
    const handleE = brush.selectAll(".resize")
        .data([{type: "e"}])
        .enter().append("rect")
        .attr("class", "handle handle--e handle-mini")
        .attr("fill", "white")
        .attr('stroke', '#555')
        .attr('stroke-width', 2)
        .attr("x", brushEndIdx - 8)
        .attr("y", '12%')
        .attr("height", '15%')
        .attr("width", 13)
        .style("cursor", "ew-resize");


    function updateHandles(xMargin) {
        handleE.attr("x", +handleE.attr('x') - xMargin)   
            .attr("y", '12%')
            .attr("height", '15%')
            .attr("width", 13);

        handleW.attr("x", +handleW.attr('x') - xMargin)
            .attr("y", '12%')
            .attr("height", '15%')
            .attr("width", 13);
    }

    function _onBrushStart() {
        updateHandles(5);
        callbacks.onBrushStart();
    }

    function _onBrush() {
        updateHandles(5);
        if(!d3.event || !d3.event.selection) return;
        const [startIdx, endIdx] = d3.event.selection;
        callbacks.onBrush(startIdx, endIdx);  
    }

    function _onBrushEnd() {
        updateHandles(0);
        if (!d3.event || !d3.event.selection) return callbacks.onBrushEnd(-1, -1);
        const [startIdx, endIdx] = d3.event.selection;
        callbacks.onBrushEnd(startIdx, endIdx);
    }
}