import React, { useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Select from 'react-select';
import _ from 'lodash';
import {ModelContext} from '../../contexts/ModelContext';
import { makeStyles, emphasize, useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import { Chip } from '@material-ui/core';
import { List } from 'react-virtualized';
import { Link } from 'react-router-dom';
import withQuery from '../withQuery';
import withTenantName from '../withTenantName';

const LinkWithTenantNameWithQuery = withQuery(withTenantName(Link));

const theme = createMuiTheme({
    overrides: {
        MuiInput: {
            underline: {
                borderBottom: '0px',
                '&:before': {
                    borderBottom: '0px',
                },
                '&:after': {
                    borderBottom: '0px',
                },
                '&:hover': {
                    borderBottom: '0px',
                },
                '&&&:hover:before': {
                    borderBottom: '0px',
                },
            }
        },
    },
});

const useStyles = makeStyles(theme => ({
    root: {
          flexGrow: 1,
          height: 250,
          minWidth: 300,
          zIndex: 9999,
      },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
  
    },
    formControl: {
        margin: theme.spacing(3),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 150,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 100,
    },
    input: {
        display: 'flex',
        padding: 0,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '24px',
        lineHeight: '28px',
        color: '#3f51b5',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    singleValue: {
        color: 'inherit',
        position: 'absolute',
    },
    paper: {
        position: 'absolute',
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(4),
    },
    indicatorContainer: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
  }));

  const SingleValue = props => {
      return (
          <Typography variant='h5' className={props.selectProps.classes.singleValue}
              {...props.innerProps}>
              {props.children}
          </Typography>
      )
  }

  SingleValue.propTypes = {
      children: PropTypes.node,
      selectProps: PropTypes.object.isRequired,
  };

const inputComponent = ({ inputRef, ...props}) => {
    return <div ref ={inputRef} {...props} />;
}

inputComponent.propTypes = {
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]),
};

const Control = props => {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: {classes, TextFieldProps},
    } = props;

    return (
        <TextField
        style={{width: '105px', marginLeft: '15px'}}
        InputProps={{
            inputComponent,
            inputProps: {
                className: classes.input,
                ref: innerRef,
                children,
                ...innerProps,
            },
        }}
        {...TextFieldProps}
        />
    );
}

Control.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.shape({
        onMouseDown: PropTypes.func.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    selectProps: PropTypes.object.isRequired,
};

const Option = props => {
    return (
        <LinkWithTenantNameWithQuery to={'/{tenantName}/dashboards/operations/Event/'.concat(props.children)} style={{textDecoration: 'none'}}>
            <MenuItem
            key={Math.random()}
            ref={props.innerRef}
            selected={props.isFocused}
            component='div'
            style={{
                fontWeight: props.isSelected? 500 : 400,
                color: 'black',
                zIndex: 999,
            }}
            {...props.innerProps}
            >
            {props.children}
            </MenuItem>
        </LinkWithTenantNameWithQuery>
    );
}

Option.propTypes = {
    children: PropTypes.node,
    innerProps: PropTypes.shape({
        id: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        onMouseMove: PropTypes.func.isRequired,
        onMouseOver: PropTypes.func.isRequired,
        tabIndex: PropTypes.number.isRequired,
    }).isRequired,
    innerRef: PropTypes.oneOfType([
        PropTypes.oneOf([null]),
        PropTypes.func,
        PropTypes.shape({
            current: PropTypes.any.isRequired,
        }),
    ]).isRequired,
    isFocused: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
};

const ValueContainer = props =>{
    return <div className={props.selectProps.classes.valueContainer}> {props.children} </div>;
}

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object.isRequired,
};

const MenuList = props => {
    const rows = props.children;
    const rowRenderer = ({ key, index, isScrolling, isVisible, style }) => 
    (
      <div key={key} style={style}>{rows[index]}</div>
    );
  
    return (
      <List
        className= {props.innerProps}
        style={{ zIndex: 9999, width: '100%' }}
        width={2000}
        height={300}
        rowHeight={50}
        rowCount={rows.length}
        rowRenderer={rowRenderer}>
        {props.children}    
        </List>
    )
  }

  MenuList.propTypes = {
    children: PropTypes.element.isRequired,
    innerProps: PropTypes.object.isRequired,
    selectProps: PropTypes.object.isRequired,
};

const components = {
    Control,
    SingleValue,
    Option,
    ValueContainer,
    MenuList,
};

export default function VirtualizedAssetList(props) {
    const classes = useStyles();


    const { options, value } = props;
    const selectRef = useRef();
    const model = useContext(ModelContext);

    const handleAssetChange = event => {
        props.setFilter(event.label);
    }

    return (
        <MuiThemeProvider theme={theme}>
            <Select
            isSearchable={false}
            ref={selectRef}
            classes={classes}
            inputId={'react-select-multiple'}
            TextFieldProps={{
                InputLabelProps: {
                    htmlFor: 'react-select-multiple',
                    shrink: true,
                },
            }}
            options={options}
            value = {value}
            components={components}
            onChange={handleAssetChange}
            isDisabled={props.disabled}
            />
        </MuiThemeProvider>
    );
}