import React, {useContext, useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import AccountIcon from '@material-ui/icons/AccountCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { UserContext } from '../../contexts/UserContext';
import { MenuContext } from '../../contexts/MenuContext';
import { RouteContext } from '../../contexts/RouteContext';
import { Link } from 'react-router-dom';
import withQuery from '../withQuery';
import withTenantName from '../withTenantName';

const LinkWithTenantNameWithQuery = withQuery(withTenantName(Link));

const styles = theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    root: {
        background: "#052838",
        boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)",
        color:"white",
        paddingTop: '8px',
        paddingBottom: '8px',
    },
    text: {
        color:"white",
        fontFamily:"Roboto",
        textTransform: 'none'
    },
    listItem: {
        borderRadius: "200px",
        padding: "8px",
        paddingLeft: "13px",
        paddingRight: "13px",
        fontFamily: "Roboto",
    }
});

const UserSubMenu = (props) => {

    const userContext = useContext(UserContext);
    const menuContext = useContext(MenuContext);
    const routeContext = useContext(RouteContext);

    const switchTenant = (newTenant) => {
        userContext.switchTenant(newTenant.id);
        routeContext.updateValues({
            tenant: newTenant.tenantName
        });

        menuContext.toggleUsers();
    }

    const handleLogout = () => {
        userContext.logout();
    }

    const openSettingsPanel = () => {
        menuContext.toggleSettingsPanel();
    }

    const openAdminPanel = () => {
        menuContext.toggleAdminPanel();
    }

    const getTenantBasePath = (tenant) => {
        const {tenantName} = tenant;
        const {pageType, layoutType} = routeContext.state;

        let {viewType} = routeContext.state;

        viewType = (viewType == 'Event') ? 'Overview' : viewType;

        let basePath;

        // Directs user to default dashboard of tenant. We want to direct to default dashboard here regardless of whether they are switching tenants or are in settings.
        basePath = `/${tenantName}/dashboards/performance/${props.defaultDashboard?.dashboardName}`;
        
        return basePath;
    }

    return (
        <div className={props.classes.root}>
            {/*Top Level Menu with Icon*/}
            <ListItem button onClick={menuContext.toggleUsers}>
                <ListItemIcon className={props.classes.text}>
                    <AccountIcon />
                </ListItemIcon>
                <ListItemText variant = "inset" disableTypography className={props.classes.text}>{userContext.currentUser.tokenParsed.preferred_username}</ListItemText>
                {menuContext.state.usersExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            {/*Sub Menu*/}
            <Collapse in={menuContext.state.usersExpanded} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {userContext.users.map(tenant => (
                        <LinkWithTenantNameWithQuery key={tenant.tenantName} to={getTenantBasePath(tenant)} style={{textDecoration: 'none'}}>
                            <ListItem button className={props.classes.nested} 
                                                onClick={() => switchTenant(tenant)}
                                                key={tenant.tenantName}>
                                <div className ={props.classes.listItem} style={{backgroundColor: tenant.id == sessionStorage.getItem('tenant') ? "rgba(0, 0, 0, 0.34)" : 'transparent'}}>
                                    <ListItemText variant = "inset" disableTypography className={props.classes.text}>{tenant.tenantName}</ListItemText>
                                </div>
                            </ListItem>
                        </LinkWithTenantNameWithQuery>
                    ))}
                    <ListItem button className={props.classes.nested}>
                        <Button variant='text' className={props.classes.text} onClick={openSettingsPanel}> View Account </Button>
                    </ListItem>
                    {
                        userContext.isDataAdmin() &&
                        <ListItem button className={props.classes.nested}>
                            <Button variant='text' className={props.classes.text} onClick={openAdminPanel}> Organization Settings </Button>
                        </ListItem>
                    }
                    <ListItem button className={props.classes.nested}>
                        <Button variant='text' className={props.classes.text} onClick={handleLogout}> Sign Out </Button>
                    </ListItem>
                </List>
            </Collapse>
        </div>
    )
    
}

export default withStyles(styles)(UserSubMenu);
