import React from 'react';
import withLoading from '../../withLoading';
import { withStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import {
    generateUrl,
    formatBarData,
    formatQueryResults
} from './dataUtil';
import { ReactComponent as EmptyChart } from "../../../assets/images/emptyChart.svg";
import MainChart from './MainChart';
import ChartLegend from '../chartComponents/ChartLegend';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment-timezone';
import UseQueries from '../../caching/UseQueries';
import { getGenericRequestData } from '../../../api/ApiWorker';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

const styles = theme => ({
    chartContainer: {
        height: '100%',
        width: '100%',
        position: "absolute",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    highlightedAssetName: {
        position: "absolute",
        top: "2.5rem",
        left: "4rem",
        fontFamily: "Roboto",
    },
    text: {
        position: "absolute",
        fontFamily: "Roboto",
        fontSize: "2rem",
        top: "50%",
        left: "50%",
        zIndex: "1",
        transform: "translate(-50%,-50%)"
    },
    progress: {
        top: "35%",
        left: "35%",
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    emptyChart: {
        position: 'absolute',
        top: "15%",
        left: "5%",
        height: "80%",
        width: "90%",
    },
    mainChart: {
        position: 'absolute',
        top: '6.5rem',
        left: '1.5rem',
        bottom: '0',
        right: '1.5rem',
        backgroundColor: 'lightblue'
    },
    loadingDiv: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute'
    }
});

const LoadingDiv = withLoading('div');

class BarChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clipPathId: 'clip' + Math.round((Math.random() * 1000000))
        }
    }

    formatData(rawData) {
        if (!rawData) return;

        const formattedData = rawData.map(rd => {
            const toReturn = Object.assign({}, rd);
            toReturn._Data = rd.Data.map(d => Object.assign({}, d));
            toReturn._formattedData = formatBarData(rd.Data)
            return toReturn;
        });

        return formattedData;
    }

    // ************************************
    // updates triggered by changes to hidden series list are
    // triggered by a state change in ChartLegend propagated
    // through the chart via redraw functions which are handled 
    // by d3 exclusively to avoid triggering awkward react redraws
    // of the chart components
    //
    // this is the callback function called when the ChartLegend's
    // Hidden Series List changes. It propagates changes downward through
    // the child refs
    // ************************************
    redrawChildren = hiddenSeriesList => {
        const redraw = this.mainChartRef.current.redraw;
        redraw(hiddenSeriesList);
    }

    getMargin = (domain) => {
        return {
            top: 50,
            right: 60,
            bottom: 20 + (20 * domain.length),
            left: 60
        }
    }

    getBottomPadding = (domain, hideLegend) => {
        let paddingBottom = 8;

        if (hideLegend) return paddingBottom;

        let legendGroupCount = 0;
        const legendGroups = [];

        domain.forEach(d => {
            if (legendGroups.indexOf(d.legendGroup) == -1) {
                legendGroups.push(d.legendGroup);
                legendGroupCount++;
            }
        });

        const legendHeight = legendGroupCount * 35;

        if (legendHeight) {
            paddingBottom += legendHeight;
        }

        return paddingBottom;
    }

    handlePrintClick() {
        const { width, height, cardRef } = this.props;

        const aspectRatio = width / height;
        const newWidth = 850;
        const newHeight = newWidth / aspectRatio;

        html2canvas(cardRef.current, { scale: 2 })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/PNG');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt'
                });
                pdf.addImage(imgData, 'PNG', 0, newHeight / 5, newWidth, newHeight);
                pdf.save("BarChart.pdf");
            });
    }

    generateQueries = () => {
        let { model, api, userContext, dateContext, extraData, colors, getColor } = this.props;

        if (_.isEmpty(model)) return null;

        let generatedQueries = [];

        let models = [model.baseModel, model.selectedModel];
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        const replacements = {
            assetId: (model.selectedItem && Object.entries(model.selectedItem).length !== 0) ? model.selectedItem.id : null,
            modelId: model.selectedModel.id,
            startDate: moment.tz(dateContext.startDate, tz).format(),
            endDate: moment.tz(dateContext.realEndDate, tz).format(),
            layerName: model.selectedFeature.layerName,
            modelIds: models.reduce((a, b) => {
                if (b == null) {
                    return a;
                }
                if (a == null) {
                    return b.id;
                } else {
                    return a + "," + b.id;
                }
            }, null)
        };

        if (model && model.selectedItem && model.selectedItem.id) {
            let propertyName = model.selectedFeature.idField;
            replacements.assetId = encodeURIComponent(model.selectedItem.properties[propertyName]);
        }

        Object.keys(api).map(apiKey => {
            const apiObj = {
                title: apiKey,
                ...api[apiKey]
            };
            const { ...parameters } = apiObj.parameters;

            var url = generateUrl(apiObj.urlPattern, model, userContext, { ...replacements, ...parameters });

            generatedQueries.push({
                queryKey: url.toString(),
                queryFn: async () => await getGenericRequestData(url),
                select: data => this.formatResults(apiObj, data, { ...replacements, ...parameters }, extraData, dateContext, colors, getColor)
            });
        });
        return generatedQueries;
    }

    formatResults(apiObj, data, replacements, extraData, dateContext, colors, getColor) {
        const response = formatQueryResults(apiObj, data, replacements, extraData, dateContext, colors, getColor);

        const rawData = response[0];
        const errors = response[1];

        return {
            rawData,
            errors,
            formattedData: this.formatData([rawData]),
            title: apiObj.title,
        }
    }

    render() {
        const { containerRef, classes, model, title, extraData } = this.props;

        const hideLegend = extraData && extraData.find(x => x.name === 'HideLegend').value;

        this.mainChartRef = React.createRef();

        const Content = !_.isEmpty(model) &&
            (
                <UseQueries queries={this.generateQueries()}>
                    {(queryResults) => {
                        let chartContent;
                        const isLoading = queryResults.find(x => x.isLoading);
                        const errors = queryResults.find(x => x.isError);

                        if (isLoading || errors) {
                            chartContent =
                                <>
                                    <Typography className={classes.text}>Data Not Available</Typography>
                                    <EmptyChart className={classes.emptyChart} />
                                </>
                        } else {
                            const { formattedData } = queryResults[0].data;
                            const domain = formattedData[0].Domain || [];

                            chartContent =
                                <div id={title} className={classes.chartContainer}>
                                    <MainChart
                                        className={classes.mainChart}
                                        ref={this.mainChartRef}
                                        margin={this.getMargin(domain)}
                                        clipPathId={this.state.clipPathId}
                                        data={formattedData}
                                        domain={domain}
                                        height={this.props.height - this.getBottomPadding(domain, hideLegend)}
                                        {...this.props}
                                    />

                                    {!hideLegend &&
                                        <ChartLegend
                                            key={Math.random()}
                                            containerStyle={{ zIndex: 1000 }}
                                            data={formattedData}
                                            grouped={true}
                                            onChange={this.redrawChildren.bind(this)}
                                        />
                                    }
                                </div>;
                        }

                        return (
                            <LoadingDiv forwardedRef={containerRef} className={classes.loadingDiv} isLoading={isLoading}>
                                <Typography variant="h6" className={this.props.classes.title}>
                                    <span>{title}</span>
                                </Typography>
                                {chartContent}
                            </LoadingDiv>
                        )
                    }}
                </UseQueries>
            );

        return Content;
    }
}

export default withStyles(styles)(BarChart);