import React, { useEffect, useState } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button } from '@material-ui/core';
import _ from 'lodash';

const styles = theme => ({
});

const QueryParams = (props) => {
    const { queryParams, setQueryParams, hasError, setError } = props;

    const [dataRows, setDataRows] = useState([{}]);

    useEffect(() => {
        let newQueryParams = convertObjToKVP(queryParams);

        setDataRows(newQueryParams);
        setQueryParams(convertKVPToObj(newQueryParams));
    }, [queryParams]);

    useEffect(() => {
        var hasErrors = dataRows.filter(x => !_.isEmpty(x.keyError) || !_.isEmpty(x.valueError)).length > 0;
        setError(hasErrors);
    }, [dataRows]);

    const convertObjToKVP = o => {
        if (_.isEmpty(o)) return [];
        return Object.keys(o).map((k) => ({
            key: k,
            value: o[k],
            keyError: null,
            valueError: null
        }));
    }

    const convertKVPToObj = list => {
        let obj = {};
        list.forEach(item => {
            obj[item.key] = item.value;
        });
        return obj;
    }

    const addNewRow = () => {
        let newRows = _.cloneDeepWith(dataRows);
        newRows.push({ key: null, keyError: 'Cannot be empty', value: null, valueError: 'Cannot be empty' });

        setDataRows(newRows);
        setQueryParams(convertKVPToObj(newRows));
    }

    const removeRow = () => {
        let newRows = _.cloneDeepWith(dataRows);
        newRows.pop();

        setDataRows(newRows);
        setQueryParams(convertKVPToObj(newRows));
    }

    const getRowKey = (index) => {
        return dataRows[index].key;
    }

    const setRowKey = (index, event) => {
        let text = event.target.value;
        let newDataRows = _.cloneDeepWith(dataRows);

        newDataRows[index].key = text;
        newDataRows[index].keyError = _.isEmpty(text) ? 'Cannot be empty' : null;

        setDataRows(newDataRows);
        setQueryParams(convertKVPToObj(newDataRows));
    }

    const getRowValue = (index) => {
        return dataRows[index].value;
    }

    const setRowValue = (index, event) => {
        let text = event.target.value;
        let newDataRows = _.cloneDeepWith(dataRows);

        newDataRows[index].value = text;
        newDataRows[index].valueError = _.isEmpty(text) ? 'Cannot be empty' : null;

        setDataRows(newDataRows);
        setQueryParams(convertKVPToObj(newDataRows));
    }

    const getContentRows = () => {
        let rows = [];

        dataRows.forEach(row => {
            var index = dataRows.indexOf(row);
            rows.push(
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            label='Key'
                            defaultValue={row.key}
                            onChange={e => setRowKey(index, e)}
                            value={getRowKey(index)}
                            error={dataRows[index].keyError}
                            helperText={dataRows[index].keyError}
                            variant={'outlined'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            defaultValue={row.value}
                            onChange={e => setRowValue(index, e)}
                            label='Value'
                            value={getRowValue(index)}
                            error={dataRows[index].valueError}
                            helperText={dataRows[index].valueError}
                            variant={'outlined'}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            );
        });

        return rows;
    }

    return (
        <>
            {getContentRows()}
            <div style={{ display: 'flex' }}>
                {
                    dataRows.length > 0 &&
                    <Grid container item xs={6} justify='flex-start' alignItems='center'>
                        <Button color='secondary' onClick={removeRow}>
                            {'Remove'}
                        </Button>
                    </Grid>
                }
                <Grid container item xs={6} justify='flex-end' alignItems='center'>
                    <Button disabled={hasError} color='primary' onClick={addNewRow}>
                        {`Add new`}
                    </Button>
                </Grid>
            </div>
        </>
    );
}

export default withStyles(styles)(QueryParams);