import React from 'react';
import InteractiveMap from './InteractiveMap';
import GeolocatControls from './GeolocateControl';
import NavigationControls from './NavigationControls';
import _ from "lodash";
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import "../../css/DashboardMap.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import MapControls from './MapControls';
import { withRouter } from 'react-router-dom';
import { ErrorContext } from '../../contexts/ErrorContext';
import jsPDF from 'jspdf';
import { getGenericRequestData } from '../../api/ApiWorker';
import ApiHelper from '../../api/ApiHelper';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';
import UseQueries from '../caching/UseQueries';

class SanitarySewerMap extends React.Component {
    
    constructor(props) 
    {
        super(props);

        this.state = {
            showSystem: false,
            isPrinting: false,
            layers: {},
            viewType: "Map"
        }

        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.abortController = null;
        this.sources = {};
        this.layers = {};
        this.mapLoaded = false;
        this.selectedStatus = null;
        this.assets = null;
        this.layerCtx = { abort: false, complete: false};
    }

    toggleViewType = (viewType) => 
    {
        this.setState({viewType: viewType});
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }

    formatUrl(pattern, replacements)
    {
        const { dateContext, userContext } = this.props;
        const { startDate, endDate } = dateContext;
        const tz = sessionStorage.getItem(TENANT_TIMEZONE);
        pattern = pattern.split("[startDate]").join(moment.tz(startDate, tz).format());
        pattern = pattern.split("[endDate]").join(moment.tz(endDate, tz).format());
        const tenantId = sessionStorage.getItem('tenant');
        pattern = pattern.split("[tenantId]").join(tenantId);
        const tenant = userContext.getTenant();
        pattern = pattern.split("[tenantName]").join(tenant.tenantName);

        Object.keys(replacements)
            .filter(k => replacements[k] != null)
            .forEach(key => {
                pattern = pattern
                    .split("[" + key + "]")
                    .join(replacements[key]);
            });

        return (new URL(pattern, ApiHelper.getUrlPath())).toString();
    }

     hexToRgb = (hex) => {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function(m, r, g, b) {
          return r + r + g + g + b + b;
        });
      
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      }

    // This implements `StyleImageInterface`
    // to draw a pulsing dot icon on the map.
    // https://docs.mapbox.com/mapbox-gl-js/example/add-image-animated/
    pulsingDot = (color) => {
        const map = this.map.map;
        const hexToRgb = this.hexToRgb;
        const getColor = this.props.getColor;
        const size = 50;

        const pipecastColor = getColor(color);
        const rgbColor = hexToRgb(pipecastColor); 

        return {
            width: size,
            height: size,
            data: new Uint8Array(size * size * 4),

            // When the layer is added to the map,
            // get the rendering context for the map canvas.
            onAdd: function () {
                const canvas = document.createElement('canvas');
                canvas.width = this.width;
                canvas.height = this.height;
                this.context = canvas.getContext('2d');
            },

            // Call once before every frame where the icon will be used.
            render: function () {
                const duration = 4000;
                const t = (performance.now() % duration) / duration;
                const opacity = 1 - t > 0.75 ? 0.75 : 1 - t;

                const radius = (size / 2) * 0.4;
                const outerRadius = (size / 2) * 0.6 * t + radius;
                const context = this.context;

                // Draw the outer circle.
                context.clearRect(0, 0, this.width, this.height);
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    outerRadius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${opacity})`;
                context.fill();

                // Draw the inner circle.
                context.beginPath();
                context.arc(
                    this.width / 2,
                    this.height / 2,
                    radius,
                    0,
                    Math.PI * 2
                );
                context.fillStyle = `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, 1)`;
                context.strokeStyle = 'white';
                context.lineWidth = 2;
                context.fill();
                context.stroke();

                // Update this image's data with data from the canvas.
                this.data = context.getImageData(
                    0,
                    0,
                    this.width,
                    this.height
                ).data;

                // Continuously repaint the map, resulting
                // in the smooth animation of the dot.
                map.triggerRepaint();

                // Return `true` to let the map know that the image was updated.
                return true;
            }
        };
    }

    addLayerData(key, features) {
        try {
            console.log(key, features.length);

            const interactiveMap = this.map;

            const { routeContext, options, colors, getColor } = this.props;
            const { assetName } = routeContext.state;
            const { layers } = this.state;
            const { layers:layerOptions } = options;
            const displayProperty = layerOptions[key].DisplayProperty;

            let selected = null;
            for (const feat of features) {
                feat.properties.tempId = feat.id;
                feat.properties.displayName = feat.properties[displayProperty]
                if (assetName == feat.properties[displayProperty]) {
                    selected = feat;
                }

                const ssoCauses = feat.properties.events
                    .map(r => r.attributes.cause)
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });

                if (ssoCauses.length == 1) {
                    if (Object.keys(colors).indexOf(ssoCauses[0]) > -1) {
                        feat.properties.ssoCause = ssoCauses[0];
                        feat.properties.color = getColor(colors[ssoCauses[0]]);         
                        feat.properties.causeIcon = `${feat.properties.ssoCause.toLowerCase().replace(' ', '-')}-pulsing-dot`;
                    } else {
                        feat.properties.ssoCause = ssoCauses[0];
                        feat.properties.color = getColor(colors['Other']);            
                        feat.properties.causeIcon = 'other-pulsing-dot';
                    }
                } else {
                    feat.properties.ssoCause = 'Multiple'
                    feat.properties.color = getColor(colors['Multiple']);                
                    feat.properties.causeIcon = 'multiple-pulsing-dot';
                }
            }
            
            const layer = {
                "type": "FeatureCollection",
                "features": features,
                "generateId": false,
                "id": key
            };

            const layerId = interactiveMap.addExternalLayerToMap(
                layer, 
                layerOptions[key].Styles
            , { hide: false });

            if (selected) setTimeout(() => interactiveMap.updateSelectedFeatures(selected), 1000);
            
            layers[layerId] = layer;
            this.setState({ layers });
        } catch (e) {
            console.log(`failed to load later data: ${e} `)
        }
    }

    componentDidMount() {
        const { colors } = this.props;
        const map = this.map.map;
        map.once('idle', () => {
            for (const cause of Object.keys(colors)) {
                const pulsingDot = this.pulsingDot(colors[cause]);
                map.addImage(`${cause.toLowerCase().replace(' ', '-')}-pulsing-dot`, pulsingDot, { pixelRatio: 2 });
            }
        })
    }

    shouldComponentUpdate(prevProps) {
        return prevProps.dateContext.startDate != this.props.dateContext.startDate ||
                 prevProps.dateContext.endDate != this.props.dateContext.endDate;
    }

    generateQueries = () => {
        const comp = this;
        const { api } = comp.props;
        const { layers } = comp.state;

        const generatedQueries = [];

        Object.keys(api).map(apiKey => {
            if (Object.keys(layers).indexOf(apiKey) > -1) {
                comp.map.removeExternalLayer(apiKey);
            }

            const url = comp.formatUrl(api[apiKey].urlPattern, api[apiKey].parameters);

            generatedQueries.push({
                queryKey: url.toString(),
                queryFn: async () => await getGenericRequestData(url),
                select: data => comp.addLayerData(apiKey, data)
            });
        });
        return generatedQueries;
    }

    render() {
        const component = this;
        return (         
            
            <UseQueries queries={this.generateQueries()}>          
            {queryResults => (
                <MapContextProvider>
                    <ErrorContext.Consumer>
                        {(error) =>

                    <MapContext.Consumer>
                        {(mapContext) => {
                            const updateSelectedItem = (feature) => 
                            {                
                                error.updateValues({errors: []});
                                
                                const { routeContext, history, dateContext, setSelectedItem, model } = component.props;
                                const {tenant, pageType, layoutType, viewType } = routeContext.state;

                                if (routeContext.state.assetName == feature?.properties.displayName) {
                                    feature = null;
                                }

                                if (feature && !feature?.properties.color) return;

                                component.map.updateSelectedFeatures(feature, { zoomOnEmpty: true });
                                
                                const basePath = feature?.properties.displayName 
                                    ? `/${tenant}/${pageType}/${layoutType}/${viewType}/${feature?.properties.displayName}`
                                    : `/${tenant}/${pageType}/${layoutType}/${viewType}`;
                                
                                const timescale = 'timescale=' + dateContext.timescale;
                                const startDate = dateContext.timescale == 'custom' && dateContext.startDate ? '&startDate=' + dateContext.startDate : '';
                                const endDate = dateContext.timescale == 'custom' && dateContext.endDate ? '&endDate='+ dateContext.endDate : '';
                                
                                history.push(basePath + '?' + timescale + startDate + endDate);

                                routeContext.updateValues({assetName: feature?.properties.displayName ?? null });
                                model.updateValues({ selectedItem: feature })
                            };
                            return(
                                <>
                                    <ThemeContext.Consumer>
                                        {({getColor}) => {
                                            component.getColor = getColor;
                                        }}
                                    </ThemeContext.Consumer>
                                    <InteractiveMap {...component.props} viewType = {component.state.viewType}
                                        ref={(element) => {
                                            component.map = element; 
                                            mapContext.updateMap(element);
                                        }}
                                        zoomOnSelect={18}
                                        mapContext={mapContext}
                                        itemType={this.props.itemType}
                                        modelLayers={[]}
                                        layers={[]}
                                        selectableLayers={queryResults.filter(q => q.isLoading).length > 0 ? [] : Object.keys(this.props.api)}
                                        updateSelectedItem={updateSelectedItem}
                                        >
                                    
                                        { <GeolocatControls /> && <NavigationControls /> }

                                    </InteractiveMap>

                                    <ThemeContext.Consumer>
                                        {({getColor}) => (
                                            <MapControls
                                            layers= {
                                                Object.keys(component.props.colors).map(c => {
                                                    return {
                                                        layerName: c,
                                                        iconColor: getColor(component.props.colors[c])
                                                    };
                                                })
                                            }
                                            legendTitle={'SSO'}
                                            legendSubtitle={"Causes"}
                                            toggleSystem={() => {}}
                                            showSystem={false}
                                            toggleModel={() => {}}
                                            showModelSystem={false}
                                            mapOptions={component.props.options}
                                            viewType={component.state.viewType}
                                            toggleViewType={component.toggleViewType}
                                            handlePrintClick={component.handlePrintClick}
                                            hideNetworkTracing={true}
                                            hideModelSystemGroups={true}
                                            routeContext={component.props.routeContext}
                                            updateSelectedItem={updateSelectedItem}
                                            isLoading={queryResults.filter(q => q.isLoading).length > 0}>
                                        </MapControls>
                                        )}
                                    </ThemeContext.Consumer>

                                </>
                            );
                    }}
                    </MapContext.Consumer>
                    }</ErrorContext.Consumer>
                </MapContextProvider>
            )}
            </UseQueries>
        )
    }
}

export default withRouter(SanitarySewerMap);