import React from 'react';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const styles = {
    root: {
        padding: "1rem",
    },
    Title: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "2.2rem",
        lineHeight: "1.5rem"
    },
    Value: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "2.4rem",
        fontWeight: "500",
        marginRight: ".5rem",
    },
    Label: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "1.5rem"
    },
    Notes: {
        position: "absolute",
        left: "4rem",
        bottom: "1.2rem",
        fontFamily: "Roboto",
        fontSize: "1.2rem",
        lineHeight: "1rem",
        color: "rgba(0, 0, 0, 0.6)",
    },
    Icon: {
        position: "absolute",
        width: "1.6rem",
        height: "1.6rem",
        bottom: "1.2rem"
    },
    progress: {
        top: 25,
        left: 150,
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    flexColumn: {
        display: 'flex', 
        flexDirection: 'column' 
    },
    flexRow: {
        display: 'flex', 
        flexDirection: 'row'
    },
    flexColumnCentered: {
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center'
    },
    flexRowCentered: {
        display: 'flex', 
        flexDirection: 'row',
        justifyContent: 'center'
    }
};

const ValueContainer = props => {
    const {
        classes,
        unit, 
        value,
        label
    } = props;

    return (
        <div className={classes.flexColumnCentered}>
            <div className={classes.flexRowCentered}>
                <Typography variant="overline" style={{ lineHeight: '0.75rem', marginTop: '0.75rem' }}>
                    <span>{label}</span>
                </Typography>
            </div>
            <div className={classes.flexRowCentered}>
                <Typography variant="h6">
                    <span>{value} {unit}</span>
                </Typography>
            </div>
        </div>
    );
}

class AlertKpi extends React.Component {
    render() {
        const { classes, alertContext } = this.props;
        const alert = alertContext.state.selectedEvent;
        
        if(!alert) {
            return (
                <CardContent className={classes.root}>
                    <div className={classes.flexColumnCentered}>
                        <Typography variant="h5">
                            Unable to locate resource
                        </Typography>
                    </div>
                </CardContent>
            );
        }
        
        let start = moment.utc(alert.startDate);
        let end = moment.utc(alert.endDate);
        let value = Math.round(alert.value * 100) / 100;
        
        const duration = Math.round(moment.duration(end.diff(start)).asHours()* 100) / 100;
        
        return (
            <CardContent className={classes.root}>
                <div className={classes.flexColumn}>
                    <div key={Math.random()} className={classes.flexRow} style={{ justifyContent: 'space-between' }}>
                        <Typography key={Math.random()} variant="h5" style={{ fontWeight: '500' }}>
                            <span>{alert.eventType}</span>
                        </Typography>

                        <Typography key={Math.random()} variant="body1" >
                            <span>{moment.tz(alert.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).calendar()}</span>
                        </Typography>
                    </div>

                    <div key={Math.random()} className={classes.flexRow} style={{ justifyContent: 'space-evenly' }}>
                        <ValueContainer key={Math.random()} classes={classes} value={value} label={alert.valueLabel} unit={alert.valueUnits}></ValueContainer>
                        <ValueContainer key={Math.random()} classes={classes} value={duration} label={"Duration"} unit={"hours"}></ValueContainer>
                    </div>
                </div>
            </CardContent>
        );
    }
}

AlertKpi.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertKpi);