import Keycloak from 'keycloak-js';
import { getTenantId } from './ApiWorker';
class ApiHelper {
    static fillModel(url, modelPattern = '[modelId]', model = null, context = null) {
        if(model == null) {
            if(context == null) {
                return url;
            }

            model = context.state.value;
        }
        
        return new URL(url.href.split(modelPattern).join(model));
    }

    static fillFromApi(url, api) {
        Object.keys(api.parameters).forEach((key, idx, keys) => {
            url = new URL(url.href.split('['+key+']').join(api.parameters[key]));
        });
        return url;
    }

    static fillFromArray(url, params) {
        params.forEach((param, idx, orig) => {
            url = new URL(url.href.split('['+param.key+']').join(param.value));
        });
        return url;
    }

    static fillFromObj(url, params) {
        Object.keys(params).forEach((key, index, keys) => {
            url = new URL(url.href.split('['+key+']').join(params[key]));
        });
        return url;
    }

    static fillWithBaseModel(urlPattern, parameters, model) {
        parameters.tenantId = getTenantId();
        parameters.modelId = model.baseModel.id;
        parameters.modelName = model.baseModel.name;
        parameters.selectedModelName = model.selectedModel.name;

        Object.keys(parameters)
            .filter(k => parameters[k] != null)
            .forEach(key => {
                urlPattern = urlPattern
                    .split("[" + key + "]")
                    .join(parameters[key]);
            });

        return urlPattern;
    }

    static fillWithSelectedModel(urlPattern, parameters, model) {
        parameters.tenantId = getTenantId();
        parameters.modelId = model.selectedModel.id;
        parameters.modelName = model.selectedModel.name;
        parameters.selectedModelName = model.selectedModel.name;

        Object.keys(parameters).forEach(key => {
            urlPattern = urlPattern
            .split("[" + key + "]")
            .join(parameters[key]);
        });
        return urlPattern;
    }

    static getUrlPath() {
        var urlPath = new URL(process.env.REACT_APP_PIPECAST_API, window.location.origin);
        return urlPath;
    }

    static getDashboardVersion() {
        var version = process.env.REACT_APP_VERSION;
        return version;
    }

    static getUserToken() {
        const userTokens = localStorage.getItem('kcTokens');
        if(!userTokens) return null;
        const sessionUser = JSON.parse(userTokens);
        return sessionUser.token;
    }
}

export default ApiHelper;
