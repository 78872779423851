import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StreetViewIcon from '@material-ui/icons/Streetview';
import {ModelContext} from "../../contexts/ModelContext";
import _ from 'lodash';

const styles = theme => ({
    root: {
        fontFamily: "Roboto",
        marginLeft: "0px",
        position: "absolute",
        top: 0,
        height: "calc(100vh - 64px)",
        minWidth: "300px",
        maxWidth : "300px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        zIndex : 4000,
        overflow: "auto"
    },    
    featureName: {
        position: "relative",
        left: "5%",
        width: "95%"
    },
    tableTitle: {
        width: '288px',
        height: '36px',
        marginLeft: '16px',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '28px',
        display: 'flex',
        alignItems: 'flex-end',
        letterSpacing: '.5px'
    },
    featureTable : {
        width : "283px", //300 - 17 for the scrollbar
        minWidth : "283px",
        maxWidth : "283px",
        padding: "4px",
        tableLayout: "fixed"
    },
    attributeName : {
        position: "relative",
        fontSize: ".75rem",
        alignItems: 'center',   
        display: 'flex',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    attributeInfo : {
        position: "relative",
        fontSize: ".7rem",
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    backButton : {
        color: "#526DCE",
    },
    streetViewButton : {
        fontSize: ".9rem",
        position: "absolute",
        top: "0rem",
        right: "0rem",
        color: "#526DCE"
    }
} )

class CMOMAttributeTable extends React.Component {
    static contextType = ModelContext;

    getSummaryData() {
        const { classes, featureData, maintenanceContext } = this.props;
        const { selectedFeature } = this.context.state.value;
        const displayField = featureData.properties[selectedFeature.displayField];

        if (maintenanceContext.state.isLoadingSummaryData == false) {
            let items = [];
            let sections = [];

            const assetSummaryData = maintenanceContext.state.summaryData[displayField];

            if (maintenanceContext.state.summaryDataError || _.isEmpty(assetSummaryData))
            {
                return <td className={classes.attributeName}> {maintenanceContext.state.summaryDataError} </td>
            }

            const assetSummarySections = Object.keys(assetSummaryData);

            assetSummarySections.map(section => { 
                let x = {};
                x.title = section;
                x.data = [];
                for (const property in assetSummaryData[section])
                {
                    x.data.push(
                        <>
                            <tr key={Math.random()}>
                                <td className={classes.attributeName}> {property} </td>
                                <td className={classes.attributeInfo}> {assetSummaryData[section][property] ? assetSummaryData[section][property] : 'No data'} </td>
                            </tr>
                            <Divider />
                        </>
                    );
                }
                sections.push(x);
            });

            for (let i = 0; i < sections.length; i++) {
                items.push(
                    <>
                        <Typography className={classes.tableTitle}> {sections[i].title} </Typography>
                        <table className={classes.featureTable}>
                            <tbody>
                                {sections[i].data}
                            </tbody>
                        </table>
                    </>
                );
            }
            return items;
        }
        return null;
    }

    openStreetViewWindow = () => {
        let coordinates = this.props.featureData.geometry.coordinates 
        let streetViewUrl = "http://www.google.com/maps?q&layer=c&cbll=" + coordinates[1]+ "," + coordinates[0]

        window.open(streetViewUrl, '_blank')
    }

    render () { 
        const { classes, featureData } = this.props;
        const { selectedFeature } = this.context.state.value;

        if (!featureData) return null;

        let data;
        const displayField = featureData.properties[selectedFeature.displayField];
        try {
            data = this.props.summaryData.find(obj => obj[selectedFeature.layerName] === displayField);
        } catch (e) {
            data = null;
        }

        const summaryData = this.getSummaryData();

        return (
            <Paper elevation = {2} className={classes.root}>
                <Button className={classes.backButton} onClick ={this.props.close}>
                    <ChevronLeftIcon/>Back
                </Button>
                <Button className={classes.streetViewButton} onClick = {this.openStreetViewWindow}>
                    Street View
                    <StreetViewIcon/>
                </Button>
                <Typography className={classes.featureName} variant="h5">{displayField}</Typography>
                {summaryData}
            </Paper>
        );
    }
}

CMOMAttributeTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CMOMAttributeTable);