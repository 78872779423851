import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Build from '@material-ui/icons/Build';
import {Link} from 'react-router-dom';
import withQuery from '../../withQuery';
import withTenantName from '../../withTenantName';

const LinkWithTenantNameWithQuery = withQuery(withTenantName(Link));

const styles = theme => ({
    header: {
        "&:focus": {
            backgroundColor: "transparent"
        },
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    nested: {
        paddingLeft: theme.spacing(5),
        paddingTop: "0px",
        paddingBottom: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    text: {
        fontFamily: "Roboto",
        color: "white",
    },
    listItem: {
        borderRadius: "200px",
        padding: "8px",
        paddingLeft: "13px",
        paddingRight: "13px",
        fontFamily: "Roboto",
    }
});

class OperationsSubMenu extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isSelected: false
        };
    }
    

    render ()
    {
        const { classes, menuContext, routeContext, dashboards, selectedDashboard} = this.props;
        return (
            <div>
                {/*Top Level Menu with Icon*/}
                <ListItem button className={classes.header} onClick={menuContext.toggleOperations}>
                    <ListItemIcon className={classes.text}>
                        <Build />
                    </ListItemIcon>
                    <ListItemText variant = "inset" disableTypography className={classes.text}> Operations </ListItemText>
                    {this.props.menuContext.state.operationsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                
                {/*Sub Menu*/}
                <Collapse in={this.props.menuContext.state.operationsExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {dashboards.map(dashboard => (
                            <LinkWithTenantNameWithQuery key={Math.random()} to={'/{tenantName}/dashboards/operations/' + (dashboard.dashboardName == 'SSO Dashboard' ? 'SSO' : 'Overview')} style={{textDecoration: 'none'}}>
                                <ListItem button className={classes.nested} onClick={() => this.props.loadDashboard(dashboard.dashboardCards, dashboard)} key={dashboard.id}>
                                <div className={classes.listItem} style={{backgroundColor: (dashboard.dashboardName === selectedDashboard?.dashboardName) ?  "rgba(0,0,0,0.34)" : 'transparent'}}>{dashboard.dashboardName}</div>
                                </ListItem>
                            </LinkWithTenantNameWithQuery>
                        ))}
                    </List>
                </Collapse>
            </div>
        ) 
    }
}

OperationsSubMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationsSubMenu);
