import React from 'react';
import InteractiveMap from './InteractiveMap';
import GeolocatControls from './GeolocateControl';
import NavigationControls from './NavigationControls';
import _ from "lodash";
import turf from 'turf';
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import "../../css/DashboardMap.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import MapControls from './MapControls';
import setFeatureState from './setFeatureState';
import { withRouter } from 'react-router-dom';
import { ErrorContext } from '../../contexts/ErrorContext';
import jsPDF from 'jspdf';

class DashboardMap extends React.Component {
    
    constructor(props) 
    {
        super(props);

        this.state = {
            showSystem: false,
            showModelSystem: false,
            isPrinting: false,
            layers: {},
            viewType: "Map" //Default view
        }

        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.sources = {};
        this.layers = {};
        this.modelLayers = null;
        this.selectedFeature = null;
        this.mapLoaded = false;
        this.selectedStatus = null;
        this.assets = null;
        this.layerCtx = { abort: false, complete: false};
    }

    toggleViewType = (viewType) => 
    {
        this.setState({viewType: viewType});
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }

    toggleModelSystem = () => {

        const layers = Object.keys(this.layers).filter(layerId => layerId !== this.props.model.selectedFeature.id);
        layers.forEach(layerGroupKey => {
            const layerGroup = this.layers[layerGroupKey];
            if(this.state.showModelSystem) {
                this.map.hideExternalLayer(layerGroup);
            } else {
                this.map.showExternalLayer(layerGroup);
            }
        });
        this.setState({
            showModelSystem: !this.state.showModelSystem
        });
    }

    toggleSystem = () => {
        this.map.map.getStyle().layers.forEach(layer => {
            if(layer["source"] == "system") {
                this.map.map.setLayoutProperty(layer.id, 'visibility', this.state.showSystem ? 'none' : 'visible');
            }
        });
        this.setState({
            showSystem: !this.state.showSystem
        });
    }

    updateSource = (map, source, data) => {
        let mapSource = map.getSource(source);
        if(typeof mapSource === "undefined" || mapSource == null) {
            map.addSource(source, {
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a=>{
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        } else {
            mapSource.setData({
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a=>{
                    a.properties.tempId = a.id;
                    return a;
                })
            });
                
        }
    }

    updateModelLayers = async () => {
        this.mapLoaded = true;
        const interactiveMap = this.map;
        let { layers, selectedFeature } = this.props.model;
        const {itemType} = this.props;

        for(const layer of layers) {
            if(this.layers[layer.layer.id]) continue;

            let layerStyles = null;

            if(layer.layer.options && layer.layer.options.styles) {
                layerStyles = layer.layer.options.styles;
            }

            const layerId = interactiveMap.addExternalLayerToMap({
                "type": "FeatureCollection",
                "generateId": true,
                "features": []
            }, layerStyles, { hide: layer.layer.id !== selectedFeature.id });

            this.layers[layer.layer.id] = layerId;

            const data = await layer.data;

            interactiveMap.updateExternalLayerSource(layerId, {
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        }

    }

    hideLayers = (layerId) => {
        if(this.layers && this.layers[layerId]) {
            this.map.hideExternalLayer(this.layers[layerId]);
        } 
    }


    showLayers = (layerId) => {
        if(this.layers && this.layers[layerId]) {
            this.map.showExternalLayer(this.layers[layerId]);
        } 
    }

    componentDidMount() {
        if(!_.isUndefined(this.props) && !_.isUndefined(this.props.model) && !_.isUndefined(this.props.model.layers)) {            
            const updateModelLayers = this.updateModelLayers.bind(this);
            this.map.map.on("load", updateModelLayers);
        }
    }

    componentWillUnmount() {
        const updateModelLayers = this.updateModelLayers.bind(this);
        this.map.map.off("load", updateModelLayers);
    }

    setFeatureState = () => {
        const {
            model, 
            alertContext,
            itemType
        } = this.props;
        try {
            setFeatureState[itemType](
                model.assets,
                model.selectedStatus,
                this.layers,
                this.getColor,
                this.map.map,
                model.selectedFeature,
                model,
                model.assetFilter,
                this,
                alertContext.state ? alertContext.state.alertTypes : []
            );
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(this.props.model.assetFilter, prevProps.model.assetFilter))
        {
            const assetFilter = this.props.model.assetFilter;
            const currentLayerId = this.props.model.selectedFeature.id;
            const displayField = this.props.model.selectedFeature.displayField;

            let visibleAssets = this.props.model.assets.filter(a => {
                try {
                    return a.properties[displayField].toLowerCase().indexOf(assetFilter.trim().toLowerCase()) != -1;
                } catch {
                    return false;
                }
            });

            let visibleAssetsIds = visibleAssets.map(a => a.id);
            this.map.map.setFilter(this.layers[currentLayerId] + '.0', ['in', 'tempId', ...visibleAssetsIds]);

        }
        if(!this.mapLoaded) return;
        
        this.updateModelLayers();
        
        if(prevProps.model.selectedFeature) {
            this.hideLayers(prevProps.model.selectedFeature.id);
        }

        this.showLayers(this.props.model.selectedFeature.id);

        const setFeatureState = this.setFeatureState.bind(this);
        this.map.map.off('idle', setFeatureState);
        this.map.map.once('idle', setFeatureState);
    }

    getAlertLayerLegend = (getColor) => {
        const {alertContext} = this.props;
        const {alertTypes} = alertContext.state;

        let layers;

        //Format alerts into format used for layer legend
        layers = alertTypes.map((alert, index) => {
            let severity;
            switch (alert.severity)
            {
                case 1:
                    severity = 'Critical';
                break;
                case 2: 
                    severity = 'Medium';
                break;
                default:
                    severity = 'Low';
                break;
            }
            let layer = {
                layerName: severity,
                iconColor: getColor(alert.displayColor)
            };
            return layer;
        });

        //Remove duplicates from layer list (if there are multiple alerts with same severity color scheme)
        layers = _.uniqWith(layers, _.isEqual);

        return layers;
    }

    render() {
        const component = this;
        return (            
            <MapContextProvider>
                <ErrorContext.Consumer>
                    {(error) =>

                <MapContext.Consumer>
                    {(mapContext) => {
                        const updateSelectedItem = (feature) => 
                        {                
                            error.updateValues({errors: []});
                            
                            if(component.props.itemType == 'alert') {
                                
                                const currentSelectedFeature = this.props.model.selectedFeature;
                
                                const selectedEvent = component.props.alertContext.state.events.find(e => e.asset == feature.properties[currentSelectedFeature.idField]);
                                
                                if(selectedEvent) {
                                    component.props.model.updateHighlightedAssetFromName(selectedEvent.asset.trim())
                                    component.props.history.push('/dashboards/operations/Event/'
                                            .concat(selectedEvent.asset.trim())
                                            .concat('/')
                                            .concat(selectedEvent.eventType)
                                            .concat('/')
                                            .concat(selectedEvent.startDate));
                                }
                            } else {
                                const {model, routeContext, history, dateContext} = component.props;
                                const {tenant, pageType, layoutType, viewType, layerName} = routeContext.state;
                                const displayName = feature.properties[this.props.model.selectedFeature.displayField];

                                const basePath = `/${tenant}/${pageType}/${layoutType}/${viewType}/${layerName}/${displayName}`;
                                
                                const timescale = 'timescale=' + dateContext.timescale;
                                const startDate = dateContext.timescale == 'custom' && dateContext.startDate ? '&startDate=' + dateContext.startDate : '';
                                const endDate = dateContext.timescale == 'custom' && dateContext.endDate ? '&endDate='+ dateContext.endDate : '';
                                
                                history.push(basePath + '?' + timescale + startDate + endDate);

                                model.updateHighlightedAsset(feature)
                                routeContext.updateValues({assetName: displayName});
                            }
                        };
                        return(
                            <>
                                <ThemeContext.Consumer>
                                    {({getColor}) => {
                                        component.getColor = getColor;
                                    }}
                                </ThemeContext.Consumer>
                                <InteractiveMap {...component.props} viewType = {component.state.viewType}
                                    ref={(element) => {
                                        component.map = element; 
                                        mapContext.updateMap(element);
                                    }}
                                    zoomOnSelect={14}
                                    mapContext={mapContext}
                                    itemType={this.props.itemType}
                                    selectedFeature={component.props.model.selectedFeature}
                                    modelLayers={this.props.model.layers}
                                    layers={component.layers}
                                    selectableLayers={(_.isUndefined(component.layers) || _.isNull(component.layers)) ? [] : Object.keys(component.layers).reduce((layerIds, layerId, idx) => {
                                        if(layerId === component.props.model.selectedFeature.id) {
                                            return layerIds.concat(component.layers[layerId]);
                                        }
                                        return layerIds;
                                    }, []) }
                                    selectedItem={component.props.model.state.value.selectedItem} 
                                    updateSelectedItem={updateSelectedItem}>
                                
                                    { <GeolocatControls /> && <NavigationControls /> }

                                </InteractiveMap>

                                <ThemeContext.Consumer>
                                    {({getColor}) => (
                                        <MapControls
                                        layers= {
                                            this.props.itemType == 'alert'
                                            ? !_.isEmpty(this.props.alertContext.state.alertTypes) && this.getAlertLayerLegend(getColor)
                                            : !_.isEmpty(this.props.model.selectedStatus) && this.props.model.selectedStatus.classes.map((statusClass, idx) => {
                                                let layer = {
                                                    layerName: statusClass.name,
                                                    iconColor: getColor(statusClass.color)
                                                };
                                                return layer;
                                                })
                                        }
                                        legendTitle={this.props.itemType == 'alert' ? 'Alerts' : 'Layers'}
                                        legendSubtitle={this.props.itemType == 'alert' ? 'Severity' : this.props.model.selectedStatus.displayName}
                                        toggleSystem={() => component.toggleSystem()}
                                        showSystem={component.state.showSystem}
                                        toggleModel={() => component.toggleModelSystem()}
                                        showModelSystem={component.state.showModelSystem}
                                        mapOptions={component.props.options}
                                        viewType={component.state.viewType}
                                        toggleViewType={component.toggleViewType}
                                        handlePrintClick={component.handlePrintClick}>
                                     </MapControls>
                                    )}
                                </ThemeContext.Consumer>

                            </>
                        );
                    }}
                </MapContext.Consumer>
                }</ErrorContext.Consumer>
            </MapContextProvider>
            
        )
    }
}

export default withRouter(DashboardMap);