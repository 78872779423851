import React from "react";
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import ReloadIcon from '@rsuite/icons/Reload';
import PlusIcon from '@rsuite/icons/Plus'
import MinusIcon from '@rsuite/icons/Minus'
import { ExplorerContext } from "../../contexts/ExplorerContext";
import $ from 'jquery'
import CreateViewModal from "./modals/CreateViewModal";
import ViewsModal from "./modals/ViewsModal";
import SimpleModal from "./modals/SimpleModal";
import { Dropdown, IconButton } from "rsuite";
import { withStyles } from "@material-ui/styles";


const styles = theme => ({
    chartModal: {
        position: 'fixed !important',
        left: '240px',
        top: '64px',
        height: 'calc(100% - 64px - 8rem) !important',
        width: 'calc(100% - 240px - 8rem) !important',
        margin: '4rem 4rem !important',
        background: 'rgba(0, 0, 0, 0.7)',
        zIndex: 100,
        boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',
    }
})

export const ChartEdit  = (props) => {

    const { classes, chart, chartID, seriesObjects, setSeriesObjects, setReferenceLines, properties, isModal, setIsModal } = props

    const explorerContext = React.useContext(ExplorerContext)
    const { removeChart } = explorerContext

    const selectedView = React.useRef(null)
    const [createViewModalOpen, setCreateViewModalOpen] = React.useState(false)
    const [viewModalOpen, setViewModalOpen] = React.useState(false)
    const [resetModalOpen, setResetModalOpen] = React.useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)

    const onDelete = (e) => setDeleteModalOpen(true)
    const onReset = (e) => setResetModalOpen(true)
    const onSaveViewClick = (e) => setCreateViewModalOpen(true)

    const onViewClick = (value) => {
        selectedView.current = value
        setViewModalOpen(true)
    }

    const ViewsDropdown = ({ ...props }) => (
        <Dropdown {...props}>
            { localStorage.getItem('views') && Object.keys(JSON.parse(localStorage.getItem('views'))).map((view, i) => {
                return ( 
                    <Dropdown.Item key={i} value={view} onSelect={() => { onViewClick(view)} }> {view} </Dropdown.Item> 
                )
            })}
        </Dropdown>
    )

    const onExportClick = (value) => {
        
        if (value.includes('CSV')) {
            chart.current.downloadCSV()
        } else if (value.includes('XLS')) {
            chart.current.downloadXLS()
        } else if (value.includes('PNG')) {
            chart.current.downloadPNG()
        } else if (value.includes('PDF')) {
            chart.current.downloadPDF()
        } else if (value.includes('SVG')) {
            chart.current.downloadSVG()
        } 
    }

    const ExportDropdown = ({...props}) => (
        <Dropdown {...props} >
            <Dropdown.Item divider />
            <Dropdown.Item onSelect={() => { onExportClick('downloadCSV')} } key={0}>
                <span> downloadCSV </span>
            </Dropdown.Item >
            <Dropdown.Item onSelect={() => { onExportClick('downloadXLS')} }  key={1}>
                <span> downloadXLS </span>
            </Dropdown.Item>
            <Dropdown.Item divider />
            <Dropdown.Item onSelect={() => { onExportClick('downloadPNG')} }  key={2}>
                <span> downloadPNG </span>
            </Dropdown.Item >
            <Dropdown.Item onSelect={() => { onExportClick('downloadPDF')} }  key={3}>
                <span> downloadPDF </span>
            </Dropdown.Item>
            <Dropdown.Item onSelect={() => { onExportClick('downloadSVG')} }  key={4}>
                <span> downloadSVG </span>
            </Dropdown.Item>
        </Dropdown>
    )

    const renderIconButton = (props, ref) => {
        return (
            <IconButton {...props} ref={ref} icon={<FileDownloadIcon style={{ fontSize: '20px', color: 'white'}}/>} color='violet' circle appearance="primary" />
        );
    };


    return (
        <React.Fragment>
            <span style={{ padding: '0 1rem 0 1rem', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '4rem', backgroundColor: 'white', gap: '1rem'}}>
                {/*<span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%', padding: '0 0rem'}}>
                    <ViewsDropdown title='views' trigger='hover'/>
                    <IconButton onClick={onSaveViewClick} icon={<PlusIcon color='white'/>} style={{ backgroundColor: 'dimgray', marginLeft: '.2rem'}}/>
                </span> */}
                <span style={{ width: '100%', height: '100%', flexGrow: 1}}/>
                { chartID !== 0 &&
                    <span style={{ height: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}} >
                        <IconButton color="red" circle appearance="primary" icon={<MinusIcon style={{ fontSize: '20px', color: 'white'}} onClick={onDelete}/>}></IconButton>
                    </span>
                }
                { seriesObjects.length !== 0 &&
                    <span style={{ height: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={onReset}>
                        <IconButton color="cyan" circle appearance="primary" icon={<ReloadIcon style={{ fontSize: '20px', color: 'white'}}/>}></IconButton>
                    </span>
                }
                <span style={{ height: '100%', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => { setIsModal(!isModal); chart.current.reflow();}}>
                    <IconButton color='blue' circle appearance="primary" icon={<ExpandOutlineIcon style={{ fontSize: '20px', color: 'white'}}/>}></IconButton>
                </span>
                <ExportDropdown 
                    trigger='hover' 
                    renderToggle={renderIconButton}
                    placement="bottomEnd"
                />
            </span>
            <CreateViewModal seriesObjects={seriesObjects} open={createViewModalOpen} setOpen={setCreateViewModalOpen} properties={properties} />
            <ViewsModal chart={chart} selectedView={selectedView.current} setSeriesObjects={setSeriesObjects} open={viewModalOpen} setOpen={setViewModalOpen} />
            <SimpleModal open={resetModalOpen} setOpen={setResetModalOpen} title={'Reset'} description={'are you sure you would like to reset this chart?'} onConfirm={() => { setSeriesObjects([]); setReferenceLines({}) }}/>
            <SimpleModal open={deleteModalOpen} setOpen={setDeleteModalOpen} title={'Delete'} description={'are you sure you would like to delete this chart?'} onConfirm={() => { chart.current.showLoading('loading...'); removeChart(chartID) }}/>
        </React.Fragment>
    )
}

export default withStyles(styles, { withTheme: true })(ChartEdit)