import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { FormControl, FormLabel, FormControlLabel, List, ListItem, Card, CardContent, CardActions, Typography, Paper, Collapse, IconButton, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getTenantId, getHeaders} from '../../../api/ApiWorker';
import Grid from '@material-ui/core/Grid';
import turf from 'turf';
import { lineString, featureCollection, multiLineString } from '@turf/helpers';
import { coordAll, flattenEach, flattenReduce  } from '@turf/meta';
import { getType } from '@turf/invariant';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import NetworkTracingToolResultCard from './NetworkTracingToolResultCard';
import _ from 'lodash';
import ApiHelper from '../../../api/ApiHelper';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        overflow: 'hidden', 
        height: `calc(100% - ${theme.spacing(2)}px)`,
        maxHeight: `100%`
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
    header: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: 48,
    },
    container: {
        height: `calc(100% - 48px)`,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      width: '50%',
      maxWidth: 350,
    },
    buttonProgress: {
      position: 'absolute',
      top: 'calc(50% - 12px)',
      left: 'calc(50% - 12px)',
      
    },
    title: {
        textAlign: "center",
        fontFamily: "Roboto",
        textTransform: "uppercase",
        color: "rgba(0,0,0,0.6)",
        fontSize: "1rem",
    },
});

class NetworkTracingTool extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            showOutput: false,
            results: null,
            tracingDirection: "4",
            loading: false,
            layerId: null,
        };
    }

    createLineString = (assetList) => {
        let coords = [];

        const flatAssets = flattenReduce(featureCollection(assetList), (previousValue, currentFeature, featureIndex, multiFeatureIndex) => {
            if(getType(currentFeature) == "LineString") {
                return featureCollection(previousValue.features.concat([currentFeature]));
            } else {
                return previousValue;
            }
        }, featureCollection([]));

        const flatAssetCollection = turf.combine(flatAssets);

        if(flatAssetCollection && flatAssetCollection.features && flatAssetCollection.features.length > 0) {
            return flatAssetCollection.features[0];
        } else {
            return flatAssetCollection;
        }
    }

    getAssetId = (item, model, feature) => { 
        const configs = _.concat([], model.network.nodeConfigs, model.network.edgeConfigs);
        const config = configs.find((config) => {
            return config.modelLayer === feature.layerName;
        });

        return item.properties[config.identityProperty];
    };

    getAssetStartCoord = (item) => {
         if(item.geometry.type === "Point") {
             return item.geometry.coordinates;
         } else if(item.geometry.type === "LineString") {
             return turf.along(item.geometry, 0);
         } else {
             return turf.center(item.geometry);
         }
    }

    runTrace = () => {
        const { model, mapContext } = this.props;
        const { selectedItem, selectedModel, selectedFeature } = model;
        const { tracingDirection } = this.state;
        const { createLineString, getAssetId, getAssetStartCoord } = this;

        if(selectedItem && selectedModel && selectedFeature) {
            const assetId = getAssetId(selectedItem, selectedModel, selectedFeature);
            const startCoord = getAssetStartCoord(selectedItem);
            const url = new URL(`api/networkanalysis?tenantId=${getTenantId()}&modelId=${selectedModel.id}&assetId=${assetId}`, ApiHelper.getUrlPath());
            this.setState({
                loading: true
            });
            fetch(url, {
                method: 'post',
                credentials: "same-origin", 
                headers: new Headers(getHeaders()),
                body: JSON.stringify({Depth: 500, Direction: tracingDirection, Start: startCoord })
            }).then(res => {
                return res.json();
            }).then(res => {
                return res.map((result, idx)=> {
                    return {
                        ...result,
                        feature: createLineString(result.results)
                    }
                });
            }).then(response => {
                const features = featureCollection(response.map(result => result.feature));
                const layerId = mapContext.interactiveMap.addExternalLayerToMap(features, [{
                    type: "line",
                    paint: {
                        "line-color": "#8DFCFF",
                        "line-opacity": 0.2,
                        "line-width": 7.5
                    },
                    layout: {
                        "line-cap": "round",
                        "line-join": "round",
                        "visibility": 'visible',
                    }
                }], { zoomTo: true });
                this.setState({
                    results: response,
                    showOutput: true,
                    loading: false,
                    layerId: layerId,
                });
            }).catch(err => {
                this.setState({
                    results: null,
                    showOutput: false,
                    loading: false,
                });
            })
        }
    }

    closeTraceResults = () => {
        if(this.state.layerId !== null) {
            this.props.mapContext.interactiveMap.removeExternalLayer(this.state.layerId);
        }
        this.setState({
            results: null,
            showOutput: false,
            loading: false,
            layerId: null,
        });
    }

    hideTracing = () => {
        if(this.state.layerId !== null) {
            this.props.mapContext.interactiveMap.removeExternalLayer(this.state.layerId);
        }
        this.setState({
            results: null,
            showOutput: false,
            loading: false,
            layerId: null,
        });

        this.props.mapContext.toggleTracing();
    }

    componentWillUnmount() {
        const { layerId } = this.state;
        const { mapContext } = this.props;
        if(layerId) {
            try {
                mapContext.interactiveMap.removeExternalLayer(layerId);
            } catch {
                //This is expected
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Paper elevation={2} className={classes.root}>
                <div className={classes.header}>
                    {this.state.showOutput && <IconButton style={{ position: 'absolute', left: '0px', top: '0px'}} onClick={() => this.closeTraceResults()}>
                            <ArrowBackIcon color="primary" />
                        </IconButton>}
                        
                        <Typography variant="overline" className={classes.title}>
                            Network Tracing Tool
                        </Typography>

                        <IconButton style={{ position: 'absolute', right: '0px', top: '0px'}} onClick={() => this.hideTracing()}>
                            <CloseIcon color="primary" />
                        </IconButton>
                </div>
                <Grid container className={classes.container}>
                    {this.state.showOutput && 
                        (<List style={{width: '100%'}} >
                            {this.state.results.map((result, idx) => 
                                (<NetworkTracingToolResultCard index={idx} result={result} mapContext={this.props.mapContext}/>))}
                        </List>)}

                    {!this.state.showOutput && 
                        (<div style={{width: '100%', display: 'block'}}>
                            <FormControl component='fieldset'>
                                <FormLabel component='legend'>Direction</FormLabel>
                                <RadioGroup 
                                    aria-label="Direction"
                                    name="direction-1"
                                    className={classes.group} onChange={(e, value) => this.setState({tracingDirection: value})}
                                    value={this.state.tracingDirection}>
                                    <FormControlLabel value="2" control={<Radio color="primary" />} label="Upstream" />
                                    <FormControlLabel value="4" control={<Radio color="primary" />} label="Downstream" />    
                                </RadioGroup>
                            </FormControl>
                            <div className={classes.wrapper}>
                                    <Button variant="contained" color="primary" className={classes.button} onClick={() => this.runTrace()}>
                                        <span>Run Trace</span>
                                        {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
                                    </Button>
                                    
                                </div>
                        </div>)}
                </Grid>
            </Paper>
        );
        
    }
}

export default withStyles(styles)(NetworkTracingTool);