import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { colors, height, width, getColor, extraData } = props;
    
    const legendPosition = extraData.find(x => x.name === 'LegendPosition')?.value;
    if(!colors) colors = {};
    const gId = 'piechart-legend-group';

    /* helpers */
    // calc x position for legend circles
    const getCXPos = () => legendPosition == "left" 
        ? (-width/2) - 50
        : (width/2) * .7;

    // calc x position for legend labels
    const getXPos = () => legendPosition == "left" 
        ? (-width/2) - 40
        : (width/2) * .7 + 10;

    // calc y position for both
    const getYPos = (d,i) => (-height/2 + 50) + (i+1)*20;

    // anchor left unless legend postion is to the right
    const getTextAnchor = () => legendPosition == 'right' ? 'right' : 'left';

    function draw() {
        let { data } = props;
        const primaryChart = d3.select(primaryChartRef.current);
        const g = primaryChart.select("#" + gId);

        var circles = g.selectAll("circle.legend-circles")
            .data(data)
            .attr("cx", getCXPos)
            .attr("cy", getYPos)
            .attr("r", 6)
            .style("fill", d => colors[d.label] ? getColor(colors[d.label]) : '#A9A9A9');

        circles.enter()
            .append("circle")
            .attr("class", "legend-circles")
            .attr("cx", getCXPos)
            .attr("cy", getYPos)
            .attr("r", 6)
            .style("fill", d => colors[d.label] ? getColor(colors[d.label]) : '#A9A9A9');

        circles.exit().remove();

        var txt = g.selectAll("text.legend-txt")
            .data(data)
            .attr("class", "legend-txt")
            .attr("x", getXPos)
            .attr("y", getYPos)
            .attr("text-anchor", getTextAnchor)
            .text(d => d.label)
            .style("font-size", "15px")
            .attr("alignment-baseline","middle");

        txt.enter()
            .append("text")
            .attr("class", "legend-txt")
            .attr("x", getXPos )
            .attr("y", getYPos)
            .attr("text-anchor", getTextAnchor)
            .text(d => d.label)
            .style("font-size", "15px")
            .attr("alignment-baseline","middle");

        txt.exit().remove();
    }

    function init() {
        draw();
    }

    useEffect(init, [primaryChartRef]);

    return (
        <g id={gId}></g>
    )
})
