import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css'
import { v4 as uuid } from 'uuid';
import turf from "turf";
import _ from 'lodash';
import ApiHelper from '../../api/ApiHelper';

const defaultMapStyle = {
    "version" : 8,
    "sprite" : "mapbox://sprites/mapbox/bright-v8",
    "glyphs" : "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    "sources" : {
        "basemap-tiles" : {
            "type" : "raster",
            "tiles" : [ 
                "http://a.tile.openstreetmap.org/{z}/{x}/{y}.png", 
                "http://b.tile.openstreetmap.org/{z}/{x}/{y}.png"
            ],
            "tileSize" : 256
        }
    },
    "layers" : [ 
        {
            "id" : "basemap",
            "type" : "raster",
            "source" : "basemap-tiles",
            "minzoom" : 0,
            "maxzoom" : 22
        }
    ]
};

class InteractiveMap extends React.PureComponent {
    constructor(props) {
        super(props);

        let options = props.options || {};

        this.state = {
            defaultLng: options.center ? options.center.lng : -90,
            defaultLat: options.center ? options.center.lat : 45,
            defaultZoom: options.zoom || 12,
            lng: options.center ? options.center.lng : -90,
            lat: options.center ? options.center.lat : 45,
            zoom: options.zoom || 12,
            style: options.style ? options.style : defaultMapStyle,
            clientHeight: -1,
            clientWidth: -1,
            map: null,
            hoveredAsset: null
        };

        this.externalLayers = [];
    }

    componentDidMount() {
        const { lng, lat, zoom, style } = this.state;

        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: style,
            center: [lng, lat],
            zoom: zoom,
            preserveDrawingBuffer: true,
            transformRequest: (url, resourceType) => {
                if(url.indexOf(window.location.hostname) >= 0) {
                    const token = ApiHelper.getUserToken();
                    if(token) {
                        return {
                            url: url,
                            headers: { 
                                'Authorization': 'Bearer ' + token
                            }
                        };
                    }
                }
            }
        });

        map.on('move', () => this.handleMove());

        map.on('mousemove', (e) => this.props.handleMouseMove ? this.props.handleMouseMove(e) : this.handleMouseMove(e));

        map.on('click', (e) =>  this.props.handleClick ? this.props.handleClick(e) : this.handleClick(e));

        const comp = this;

        map.once('idle', () => {
            comp.props.updateSelectedFeatures ? comp.props.updateSelectedFeatures(this, comp.props.selectedItem) : this.updateSelectedFeatures(comp.props.selectedItem);
        });

        map.on('style.load', () => this.addSatelliteOverlay(map));

        this.map = map;

        this.clientHeight = this.mapContainer.clientHeight;
        this.clientWidth = this.mapContainer.clientWidth;

        this.setState({
            map: map
        });

    }

    addSatelliteOverlay(map)
    {
        map.addSource("satellite-tiles",
        {
            "type" : "raster",
            "tiles" : 
            [ 
                "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/jpg?app_id=ftqYcepXNmS7qfbYaMUE&app_code=z8_TcwlRL1LrWT-Y563WQg",
                "https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/jpg?app_id=ftqYcepXNmS7qfbYaMUE&app_code=z8_TcwlRL1LrWT-Y563WQg",
                "https://3.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/jpg?app_id=ftqYcepXNmS7qfbYaMUE&app_code=z8_TcwlRL1LrWT-Y563WQg",
                "https://4.aerial.maps.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/512/jpg?app_id=ftqYcepXNmS7qfbYaMUE&app_code=z8_TcwlRL1LrWT-Y563WQg"
            ],
            "tileSize" : 512
        });

        //Add the overlay before the [1] element in the layers array => [0] Basemap [1] Satellite Overlay [2] MDCTowns...
        map.addLayer({
            "id" : "satelliteOverlay",
            "type" : "raster",
            "source" : "satellite-tiles",
            "minzoom" : 0,
            "maxzoom" : 22
        }, map.style.stylesheet.layers[1] ? map.style.stylesheet.layers[1].id : undefined); 

        this.hideLayer("satelliteOverlay");
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        let {selectedItem, model, viewType} = this.props;
        let { clientHeight, clientWidth } = this.getContainerSize();
        if(clientHeight != this.clientHeight || clientWidth != this.clientWidth) {
            this.clientHeight = clientHeight;
            this.clientWidth = clientWidth;
            this.resizeMap()
        }

        if(selectedItem != prevProps.selectedItem) {
            this.props.updateSelectedFeatures ? this.props.updateSelectedFeatures(this, selectedItem) : this.updateSelectedFeatures(selectedItem);
        }

        if (model && model.selectedFeature != prevProps.model.selectedFeature) {
            this.props.setIsSelected ? this.props.setIsSelected(this, null) : this.setIsSelected(null);
        }

        if (viewType != prevProps.viewType)
        {
            this.changeViewType(viewType);
        }
    }

    changeViewType(viewType)
    {
        switch (viewType)
        {
            case "Map":
                this.showLayer("basemap");
                this.hideLayer("satelliteOverlay");
                break;

            case "Satellite":
                this.showLayer("satelliteOverlay");
                this.hideLayer("basemap");
                break; 
        }
    }

    addLayer(map, source, layerName, options) {
        map.addLayer({
            id: layerName,
            type: options.type,
            source: source,
            paint: options.paint,
            layout: options.layout,
            filter: options.filter,
        }, options.beforeLayer);
    }

    addGeoJsonLayer(map, source, layerName, options) {
        this.addLayer(map, source, layerName + "_poly", {
            type: "fill",
            paint: {
                "fill-color": options.color ? options.color : "#000",
                "fill-opacity": options.opacity ? options.opacity : 1.0,
            },
            layout: {
                "visibility": typeof(options.visibility) === "undefined" ? 'visible' : options.visibility,
            },
            filter: ["==", "$type", "Polygon"]
        }, options.beforeLayer);

        this.addLayer(map, source, layerName + "_line", {
                type: "line",
                paint: {
                    "line-color": typeof options.color !== "undefined" ? options.color : "#000",
                    "line-opacity": typeof options.opacity !== "undefined" ? options.opacity : 1.0,
                    "line-width": typeof options.lineWidth !== "undefined" ? options.lineWidth : 5.0
                },
                layout: {
                    "line-cap": "round",
                    "line-join": "round",
                    "visibility": typeof(options.visibility) === "undefined" ? 'visible' : options.visibility,
                },
                filter: ["==", "$type", "LineString"]
        }, options.beforeLayer);

        this.addLayer(map, source, layerName + "_point", {
            type: "symbol",
            paint: {
                "icon-color": typeof(options.color) !== "undefined" ? options.color : "#000",
                "icon-opacity": options.opacity ? options.opacity : 1.0,
            },
            layout: {
                "icon-image": options.iconImage ? options.iconImage : "circle-11",
                "icon-size": options.iconSize ?  options.iconSize : 1,
                "icon-optional": typeof(options.optional) === "undefined" ? false : options.optional,
                "icon-allow-overlap": typeof(options.allowOverlap) === "undefined" ? true : options.allowOverlap,
                "icon-ignore-placement": typeof(options.allowOverlap) === "undefined" ? true : options.allowOverlap,
                "visibility": typeof(options.visibility) === "undefined" ? 'visible' : options.visibility,
            },
            filter: ["==", "$type", "Point"]
        }, options.beforeLayer);

        
    }

    addExternalLayerToMap(data, layers, options) {
        let map = this.map;
        let newLayerId = data.generateId ? uuid() : data.id;

        try {
            map.addSource(`${newLayerId}`, {
                "type": "geojson",
                "generateId": true,
                "data": data
            });
    
            if(typeof layers !== "undefined" && layers != null) {
                layers.forEach((layer, idx, arr) => {
                    map.addLayer({
                        id: `${newLayerId}.${idx}`,
                        source: `${newLayerId}`,
                        ...layer
                    });
                });
            }
    
            if(options && options.zoomTo) {
                let bbox = turf.bbox(data);
                map.fitBounds(bbox, 20);
            }
    
            if(options && options.hide) {
                this.hideExternalLayer(newLayerId);
            }
    
            this.externalLayers.push(newLayerId);
    
            return `${newLayerId}`;
        } catch (e) {
            console.debug(e);
        }
    }

    removeExternalLayer(layerId) {
        let map = this.map;

        let allLayers = map.getStyle().layers;
        let externalLayers = allLayers.filter(layer => layer.id.indexOf(layerId) >= 0);

        externalLayers.forEach((layer, idx) => {
            map.removeLayer(layer.id);
        });

        map.removeSource(layerId);

        let removedLayer = _.remove(this.externalLayers, a => a === layerId);
    }

    hideExternalLayer(layerId) {
        let map = this.map;

        let allLayers = map.getStyle().layers;
        let externalLayers = allLayers.filter(layer => layer.id.indexOf(layerId) >= 0);

        externalLayers.forEach((layer, idx) => {
            this.hideLayer(layer.id);
        });
    }

    showExternalLayer(layerId) {
        let map = this.map;

        let allLayers = map.getStyle().layers;
        let externalLayers = allLayers.filter(layer => layer.id.indexOf(layerId) >= 0);

        externalLayers.forEach((layer, idx) => {
            this.showLayer(layer.id);
        });
    }

    hideLayer = (layerName) => {
        let map = this.map;
        
        map.setLayoutProperty(layerName, 'visibility', 'none');
    }

    showLayer = (layerName) => {
        let map = this.map;
        
        map.setLayoutProperty(layerName, 'visibility', 'visible');
    }

    updateExternalLayerSource(layerId, data) {
        try {
            let map = this.map;
            let source = map.getSource(layerId);
            if(typeof source !== "undefined" && source != null) {
                source.setData({
                    generateId: true,
                    ...data
                });
            }
        } catch (e)
        {
            console.debug(e);
        }
    }

    isExternalLayer = (layerId) => {
        return this.externalLayers.indexOf(layerId) >= 0;
    }

    getExternalLayers = (layerId) => {
        let map = this.map;
        let layers = map.getStyle().layers.filter((layer, idx, layers) => {
            return layer.source === layerId;
        });

        return layers;
    }

    componentWillUnmount() {        
        try { this.props.setIsSelected ? this.props.setIsSelected(this, null) : this.setIsSelected(null); } catch { /* noop */ }
        try { this.map.off('move', () => this.handleMove()); } catch { /* noop */ }
        try { this.map.off('mousemove', (e) => this.props.handleMouseMove ? this.props.handleMouseMove(e) : this.handleMouseMove(e)); } catch { /* noop */ }
        try { this.map.off('click', (e) => this.props.handleClick ? this.props.handleClick(e) : this.handleClick(e)); } catch { /* noop */ }
        try { this.map.off('style.load', () => this.addSatelliteOverlay(this.map)); } catch { /* noop */ }
        try { this.map.remove(); } catch { /* noop */ }
    }

    getContainerSize() {
        let { clientHeight, clientWidth } = this.mapContainer;
        return { clientHeight, clientWidth };
    }

    resizeMap() {
        if(this.map) {
            let mapBounds = this.map.getBounds();
            this.map.resize();
            this.map.fitBounds(mapBounds);
        }
    }

    handleMove() {
        let { lng, lat } = this.map.getCenter();

        this.setState({
            lng: lng.toFixed(4),
            lat: lat.toFixed(4),
            zoom: this.map.getZoom().toFixed(2)
        });
    }

    handleMouseMove(e) {
        let {selectableLayers} = this.props;

        var bbox = [[e.point.x - 5, e.point.y - 5], [e.point.x + 5, e.point.y + 5]];
        selectableLayers = selectableLayers.reduce((layers, layer, idx, selectableLayers) => {
            if(this.isExternalLayer(layer)) {
                return layers.concat(this.getExternalLayers(layer).map(a => a.id));
            } else {
                return layers.push(layer);
            }
        }, []);
        var features = this.map.queryRenderedFeatures(bbox, {layers: selectableLayers});

        if(features && features.length > 0) {
            var hoveredFeature = features[0];
            this.setIsHovered(hoveredFeature);
        }
        else {
            this.setIsHovered(null);
        }
    }

    handleClick(e) {
        let {selectableLayers} = this.props;

        var bbox = [[e.point.x - 5, e.point.y - 5], [e.point.x + 5, e.point.y + 5]];
        selectableLayers = selectableLayers.reduce((layers, layer, idx, selectableLayers) => {
            if(this.isExternalLayer(layer)) {
                return layers.concat(this.getExternalLayers(layer).map(a => a.id));
            } else {
                return layers.push(layer);
            }
        }, []);
        var features = this.map.queryRenderedFeatures(bbox, {layers: selectableLayers});

        if(features && features.length > 0) {
            const feature = features[0];
            feature.id = feature.properties.tempId;
            delete feature.properties.tempId;
            this.props.updateSelectedItem(feature);
        }
    }

    updateSelectedFeatures = (feature, options) => {
        const {zoomOnSelect} = this.props;
        if (feature) {
            let features = feature;
            if(_.isArray(features)) {
                for(const f of features) {
                    this.props.setIsSelected ? this.props.setIsSelected(this, f) : this.setIsSelected(f);
                }
            } else {
                this.props.setIsSelected ? this.props.setIsSelected(this, features) : this.setIsSelected(features);
            }

            if (!!zoomOnSelect) {
                let center = turf.centroid(features);
                this.map.flyTo({ center: center.geometry.coordinates, zoom: zoomOnSelect });
            }
            
        } else {            
            this.props.setIsSelected ? this.props.setIsSelected(this, null) : this.setIsSelected(null);
            if(options && options.zoomOnEmpty)
            {
                this.map.flyTo({ 
                    center: [this.state.defaultLng, this.state.defaultLat],
                    zoom: this.state.defaultZoom
                });
            }
        }
    }

    setIsHovered = (feature) => {
        const selectableLayers = this.props.selectableLayers.reduce((layers, layer) => {
            if(this.isExternalLayer(layer)) {
                return layers.concat(this.getExternalLayers(layer));
            } else {
                return layers.push(layer);
            }
        }, []);

        const featureId = feature ? feature.properties.tempId: null;

        for(const layer of selectableLayers) {
            const source = this.map.getSource(layer.source);

            source._data.features.forEach(f => {
                if(featureId && f.properties && (f.properties.tempId == featureId)) {
                    f.properties.isHovered = '1';
                } else {
                    f.properties.isHovered = '0';
                }
            });
            source.setData(source._data);
        }
    }

    setIsSelected = (feature) => {
        const selectableLayers = this.props.selectableLayers.reduce((layers, layer) => {
            if(this.isExternalLayer(layer)) {
                return layers.concat(this.getExternalLayers(layer));
            } else {
                return layers.push(layer);
            }
        }, []);

        const featureId = feature ? feature.id : null;

        for(const layer of selectableLayers) {
            const source = this.map.getSource(layer.source);

            source._data.features.forEach(f => {
                if(featureId && f.properties && (f.properties.tempId == featureId)) {
                    f.properties.isSelected = '1';
                } else {
                    f.properties.isSelected = '0';
                }
            });
            source.setData(source._data);
        }
    }

    render() {
        const style = {
            height: '100%',
            width: '100%'
        };

        return (
            <div style={style} 
                ref={element => this.mapContainer = element}>
                {
                    React.Children.map(this.props.children, child => React.cloneElement(child, {map: this.state.map}))
                }
            </div> 
        )
    }
}

export default InteractiveMap;