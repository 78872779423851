import { getHeaders } from '../../../../api/ApiWorker';
import _ from 'lodash';

async function getUrl(urlPattern, replacements) {
    urlPattern = urlPattern.split("[assetId]").join(replacements.assetId);
    urlPattern = urlPattern.split("[tenantId]").join(replacements.tenantId);

    Object.keys(replacements).forEach(key => {
        urlPattern = urlPattern
            .split("[" + key + "]")
            .join(replacements[key]);
    });

    return new URL(urlPattern);
}

async function getSingleSeriesData(apiObj, replacements, abortSignal, dateContext) {
    const url = await getUrl(apiObj.urlPattern, replacements);
    let response;
    try {
        response = await fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: getHeaders(),
            signal: abortSignal
        })
    } catch (e) {
        if (e.name === 'AbortError') {
            return null;
        }
        throw e;
    }

    if (!response.ok) throw new Error(response.status)

    const data = await response.json();
    if (data.error) throw new Error(data.error);
    if (data == null) return null;

    var entries = [];
    Object.keys(data).forEach(date => {
        var formattedDate = date;
        if (date.indexOf("T00:00:00") === -1) {
            formattedDate = date + "T00:00:00";
        }

        var toPush = {
            dataKey: apiObj.title,
            tooltipLabel: apiObj.title,
            date: formattedDate,
            value: data[date]
        };

        toPush[apiObj.title] = data[date];

        entries.push(toPush);
    });

    var formattedEntries = [];

    Object.keys(data).forEach(date => {
        let matches = entries.filter(entry => entry.date === date);
        let dateObj = { 'Date': new Date(Date.parse(date)) };
        matches.forEach((datum) => {
            var key = datum.tooltipLabel;
            dateObj[key] = datum[key];
        });

        formattedEntries.push(dateObj);
    });
    return { "data": formattedEntries };
}

function inputDataIsValid(api) {
    return !!api;
}

export function getData(api, assetId, extraData, abortController, userContext, dateContext, colors, getColor) {
    if (!inputDataIsValid(api)) {
        console.error('Input data invalid; Returning empty array');
        return [];
    }

    const replacements = {
        assetId
    };

    return Object.keys(api).map(apiKey => {
        return new Promise(async resolve => {
            const apiObj = {
                title: apiKey,
                ...api[apiKey]
            }
            const { ...parameters } = apiObj.parameters || {};

            let data;
            try {
                var results = await getSingleSeriesData(apiObj, { ...replacements, ...parameters }, abortController.signal, userContext, dateContext);
                data = results && results.data ? results.data : null;
            } catch (e) {
                console.log('An error occurred retrieving chart data');
                return resolve([null, {
                    errors: e,
                    cardType: apiObj.cardType,
                    apiCall: apiObj.title,
                    metric: apiObj.metric
                }]);
            }

            if (!data) return resolve([null, null]);

            const extraDataFormatted = {};

            for (const key of Object.keys(extraData)) {
                try {
                    if (!_.isArray(extraData[key])) {
                        if (_.isArray(extraData[key].value)) {
                            extraDataFormatted[extraData[key].name] = extraData[key].value.find(v => v.name == apiObj.title).value
                        } else {
                            extraDataFormatted[extraData[key].name] = extraData[key].value;
                        }
                    } else {
                        extraData[key].forEach(value => {
                            if (_.isArray(value)) {
                                const matchingValue = value.find(v => v.name == apiObj.title);
                                extraDataFormatted[matchingValue.name] = matchingValue.value;
                            } else {
                                extraDataFormatted[value.name] = value.value;
                            }
                        });
                    }
                } catch {
                    //noop
                }
            }

            const result = {
                Name: apiObj.title,
                Url: "",
                Data: data ? data : [],
                Id: Math.random(),
                ...extraDataFormatted
            };

            resolve([result, null]);
        });
    });
}

export function formatBarData(data) {
    const results = [];
    let start;

    if (data[0].value) {
        start = data[0].date;
    }

    for (let i = 1; i < data.length; i++) {
        const prevValue = data[i - 1].value;
        const currentValue = data[i].value;
        const nextValue = data[i + 1] ? data[i + 1].value : 0;

        if (currentValue != prevValue) {
            if (!start) {
                if (currentValue) {
                    if (currentValue == nextValue) {
                        start = data[i].date;
                    } else if (data[i + 1] && currentValue != nextValue) {
                        results.push({
                            value: data[i].value,
                            start: data[i].date,
                            end: data[i + 1].date,
                            date: new Date(+data[i].date + (+data[i + 1].date - +data[i].date))
                        });
                    }
                }
            } else {
                results.push({
                    value: prevValue,
                    start,
                    end: data[i - 1].date,
                    date: new Date(start + (+data[i - 1].date - start))
                });
                start = null;
            }
        }
    }
    return results;
}