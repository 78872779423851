import React from 'react';
import { getStatusTypes, getStatusResults } from "../api/ApiWorker";
import _ from "lodash";
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../constants/TimezoneConstants';

export const ModelContext = React.createContext(null);
const today = new Date().toISOString().split('T')[0];

// for easy testing
// const today = new Date('9/7/2017').toISOString().split('T')[0];

const stateValue = {
    baseModel: null,
    allModels: [],
    selectedModel: null,
    selectedFeature: {},
    highlightedAsset: null,
    token: null,
    assets: [],
    layers: [],
    selectedItem: null,
    statusTypes: [],
    selectedStatus: {},
    statusResults: {},
    assetsError: null,
    attributesShown: false,
    assetFilter: '',
    baseModelExclusive: null
};

class ModelContextProvider extends React.Component {

    constructor(props) {
        super(props);
    }
    state = {
        //WARNING
        //1. when you update value, you must include ALL props. It is not like updating state,
        //      where some can be left out
        //2. you NEED value as the state property or the components relying on the context won't re-render.
        //3. if you update the individual value properties back to back (updateStartDate then updateEndDate),
        //      it will usually batch update these for performance and only the last one will persist because
        //      we shouldn't be reading from this.state during a state update, as it isn't always reliable.
        value: stateValue
    }

    sortAssets(sortField, data, isSummaryData, isAsc) {
        return data.sort(function (a, b) {
            const direction = isAsc ? 1 : -1;
            let nameA, nameB;
            if (isSummaryData) {
                nameA = a[sortField] + '';
                nameB = b[sortField] + '';
            } else {
                nameA = a.properties[sortField] + '';
                nameB = b.properties[sortField] + '';
            }
            if (typeof (nameA) == 'undefined' || nameA == null || nameA.trim() == '') {
                return 1;
            }
            if (typeof (nameB) == 'undefined' || nameB == null || nameB.trim() == '') {
                return -1
            }

            return direction * nameA.localeCompare(nameB, [], {
                sensitivity: "base",
                numeric: false
            });
        });
    }

    async getAssets() {
        let { layers, selectedFeature, statusResults, selectedStatus } = this.state.value;
        const currentLayer = layers ? layers.find(element => element.layer === selectedFeature) : null;
        if (!currentLayer) return;

        let assets = await currentLayer.data
        //add status
        for (let i = 0; i < assets.length; i++) {

            let matchingClass;
            try {
                matchingClass = statusResults[assets[i].properties[selectedFeature.idField]].class;
            } catch (e) {
                matchingClass = -1
            }

            assets[i].properties.class = matchingClass;

            try {
                assets[i].properties.statusClassType = selectedStatus.classes.find(o => o.value === matchingClass).statusClassType;
            } catch (e) {
                assets[i].properties.statusClassType = 0;
            }

            assets[i].properties.id = assets[i].id;
        }

        //sort
        if (!selectedStatus || selectedStatus.displayName === 'None') {
            assets = this.sortAssets(currentLayer.layer.layerName, assets, false, false);
        } else {
            assets = this.sortAssets('class', assets, false, false);
        }
        
        return assets;
            
    }
    
    async setAssets() {
        try {
            const assets = await this.getAssets();

            const newState = Object.assign({}, this.state.value, {
                assets,
                assetsError: assets.length == 0 ? 'No Assets were found for the layer' : null
            });

            await this.updateValues(newState);

            if (this.state.value.highlightedAsset) {
                // if hightlighted asset name is set before assets exist, update now
                const selectedItem = this.getHighlightedAssetFromName(this.state.value.highlightedAsset);
                this.updateValues({ selectedItem });
            }
        } catch (e) {
            const newState = Object.assign({}, this.state.value, {
                assetsError: 'An Error Occurred Loading the Assets'
            });

            this.updateValues(newState);
        }
    }

    updateModelFeature(newModel, newFeature) {
        const tempVal = {};
        if (newModel != this.state.value.selectedModel || newFeature != this.state.value.selectedFeature) {
            tempVal.selectedModel = newModel;
            tempVal.selectedFeature = newFeature;
            this.updateValues(tempVal);
        }
    }

    getHighlightedAssetFromName(assetName) {
        const {assets, selectedFeature} = this.state.value;
        if(!assets || !selectedFeature) return null;

        const highlightedAsset = assets.find(a => 
            a && 
            a.properties && 
            (a.properties[selectedFeature.idField] == assetName || a.properties[selectedFeature.displayField] == assetName));

        return highlightedAsset;
    }

    updateHighlightedAssetFromName(assetName) {
        const highlightedAsset = this.getHighlightedAssetFromName(assetName);

        this.updateValues({
            highlightedAsset: assetName,
            selectedItem: highlightedAsset
        });
    }

    updateHighlightedAsset(highlightedAsset, attributesShown) {
        let assetName = null;
        if (highlightedAsset != null) {
            assetName = highlightedAsset.properties[this.state.value.selectedFeature.displayField];
        }
        this.updateValues({
            selectedItem: highlightedAsset,
            highlightedAsset: assetName,
            attributesShown: !!attributesShown
        });
    }

    async setStatusTypes(){
        const requestId = Math.random();
        try {
            let {
                layers,
                selectedFeature,
                statusTypes,
                selectedStatus
            } = this.state.value;

            const {userContext} = this.props;

            const currentLayer = layers ? layers.find(element => element.layer === selectedFeature) : null;
            const tenant = userContext.getTenant();

            let statusTypesResults = null;

            try {            
                this.statusTypesAbortController = new AbortController();
                this.statusTypesAbortController.id = requestId;

                this.statusTypesAbortController.layer = currentLayer.layer;

                statusTypesResults = await getStatusTypes(tenant.tenantName, currentLayer.layer, this.statusTypesAbortController);
            } catch (e) {
                // if aborted, cancel execution
                if(e.name == 'AbortError') return;
                throw e;
            }

            const noHighlight = {
                "order": statusTypesResults.length,
                "layerName": "Regulators",
                "statusName": null,
                "displayName": "None",
                "classes" : [{name: 'None', value: 0, color: "#000000", statusClassType: 3}],
                "id": -1
            };

            statusTypes = statusTypesResults.sort((a, b) => {
                if(a.order > b.order) return 1;
                if(a.order < b.order) return -1;
                return 0;
            });

            statusTypes.push(noHighlight);
            selectedStatus = (statusTypes && statusTypes.length > 0) ? statusTypes[0] : noHighlight;

            const newState = Object.assign({}, this.state.value, {
                statusTypes: statusTypes,
                selectedStatus: selectedStatus
            });

            //ensure we're still current before updating state
            this.statusTypesAbortController.id == requestId && this.updateValues(newState);
        } catch (e) {
            /* This segment sets statusTypes to [] when switching between performance and o&m dashboards
                const newState = Object.assign({}, this.state.value, {
                    statusTypes: [],
                    selectedStatus: {}
                });

                //ensure we're still current before updating state
                this.statusTypesAbortController.id == requestId && this.updateValues(newState);
            */
        }   
    }

    componentWillUnmount() {
        this.statusResultsAbortController && this.statusResultsAbortController.abort();
        this.statusTypesAbortController && this.statusTypesAbortController.abort();
    }

    async setStatusResults() {
        let requestId = Math.random();
        try {
            let {baseModel, selectedModel,selectedStatus, selectedFeature, baseModelExclusive} = this.state.value;
            let {startDate,endDate} = this.props.date;
            let {userContext} = this.props;

            const currentModel = baseModelExclusive ? baseModel : selectedModel;

            if (_.isEmpty(currentModel) || _.isEmpty(selectedStatus) || _.isEmpty(selectedFeature)) return;

            const tenant = userContext.getTenant();
            
            let statusResults;
            try {            
                this.statusResultsAbortController = new AbortController();

                this.statusResultsAbortController.id = requestId;

                statusResults = await getStatusResults(tenant.tenantName, currentModel.name, selectedFeature, moment.tz(startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format(), moment.tz(endDate, sessionStorage.getItem(TENANT_TIMEZONE)).format(), selectedStatus, this.statusResultsAbortController);
            } catch (e) {
                // if aborted, cancel execution
                if(e.name == 'AbortError') return;
                throw e;
            }

            const newState = Object.assign({}, this.state.value, {
                statusResults: statusResults[currentModel.name] || []
            });

            //ensure we're still current before updating state
            this.statusResultsAbortController.id == requestId && this.updateValues(newState);        
        } catch (e) {
            const newState = Object.assign({}, this.state.value, {
                statusResults: []
            });

            //ensure we're still current before updating state
            this.statusResultsAbortController.id == requestId && this.updateValues(newState);
        }
    }

    setEmergentUpdateValues = (nextState, key) => {
        const resetAssetData = () => {
            nextState.assetsError = null;
        }
        switch (key) {
            case 'selectedFeature':            
                nextState.selectedItem = null;
                nextState.statusTypes = null;
                nextState.assets = null;
                this.statusTypesAbortController && this.statusTypesAbortController.abort();

                nextState.selectedStatus = null;
                nextState.statusResults = null;
                this.statusResultsAbortController && this.statusResultsAbortController.abort();

            case 'selectedModel':            
            case 'selectedStatus':
                nextState.assets = null;
            case 'startDate':
            case 'endDate':
            case 'realEndDate':
                nextState.statusResults = null;
                this.statusResultsAbortController && this.statusResultsAbortController.abort();
                resetAssetData();
                break;
            default:
                break;
        }
    }

    updateValue = async (key, value) => {
        const nextState = this.state.value;
        nextState[key] = value;
        this.setEmergentUpdateValues(nextState, key);
        this.setState({
            value: nextState
        });
    }

    updateValues = async toUpdate => {
        const nextState = this.state.value;

        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && nextState[key] != value) {
                nextState[key] = value;
                this.setEmergentUpdateValues(nextState, key);
            }
        }

        this.setState({
            value: nextState
        });
    }
    
    shouldComponentUpdate(nextProps, nextState) {
        const stateIsIncomplete =
            !this.state.value.selectedModel ||
            !this.state.value.selectedFeature ||
            !this.state.value.layers ||
            !this.props.date.startDate ||
            !this.props.date.endDate;

        return !stateIsIncomplete;
    }

    async componentDidUpdate(prevProps, prevState) {

        const {selectedStatus, statusTypes, statusResults, assets, assetsError} = this.state.value;

        const shouldUpdateStatusResults = selectedStatus && 
            selectedStatus.displayName !== 'None' && 
            Object.keys(selectedStatus).length > 0 && 
            !statusResults;

        // If on performance dashboards:
        // 1) Update assets on date change OR 
        // 2) Update assets when date was changed on a different dashboard (o&m) and then user switched back to performance
        // 3) The Route/URL Context was updated with information of a different tenant
        // 4) The Route/URL Context was updated with information of a different layer
        const {routeContext, date} = this.props;
        const {layoutType} = routeContext.state;
            
        if (layoutType == 'performance' && (
           ((date.startDate != prevProps.date.startDate) || (date.endDate != prevProps.date.endDate)) ||
           ((layoutType && prevProps.layoutType && (layoutType != prevProps.layoutType))) ||
           ((routeContext.tenant && prevProps.routeContext.tenant && (routeContext.tenant != prevProps.routeContext.tenant))) ||
           ((routeContext.layerName && prevProps.routeContext.layerName && (routeContext.layerName != prevProps.routeContext.tenant)))) ) 
           {
                this.updateValues({
                    assets: [],
                    statusResults: {},
                    assetsError: null
                });
        
                await this.setStatusResults();
                await this.setAssets();
                return;
           }

        if (!statusTypes || !selectedStatus) {
            return this.setStatusTypes();
        } else if(shouldUpdateStatusResults) {
            return this.setStatusResults();
        } else if(_.isEmpty(assets) && !assetsError) {
            return this.setAssets();
        } 
    }

    render() {
        return (
            <ModelContext.Provider value={{
                getAssets: this.getAssets,
                getStatusResults: this.getStatusResults,
                updateHighlightedAsset: this.updateHighlightedAsset,
                updateHighlightedAssetFromName: this.updateHighlightedAssetFromName,
                getHighlightedAssetFromName: this.getHighlightedAssetFromName,
                updateModelFeature: this.updateModelFeature,
                updateUser: this.updateUser,
                updateValue: this.updateValue,
                updateValues: this.updateValues,

                state: this.state,
                layers: this.state.value.layers,
                baseModel: this.state.value.baseModel,
                allModels: this.state.value.allModels,
                assetFilter: this.state.value.assetFilter,

                selectedModel: this.state.value.selectedModel,
                get errors() {return this.state.value.errors || [] },
                get selectedItem() { return this.state.value.selectedItem || {} },
                get selectedStatus() { return this.state.value.selectedStatus || {} },
                get selectedFeature() { return this.state.value.selectedFeature || {} },
                get statusTypes() { return this.state.value.statusTypes || [] },
                get assets() { return this.state.value.assets || [] },
            }}>
                {this.props.children}
            </ModelContext.Provider>
        )
    }
}

export default ModelContextProvider;
