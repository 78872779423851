import React from 'react';
import mapboxgl from 'mapbox-gl';

class GeolocatControls extends React.Component {
    constructor(props) {
        super(props);

        this.loaded = false;
    }

    updateMap() {
        const { map } = this.props;

        if(!map) {
            return;
        }

        map.addControl(this.control);
        this.loaded = true;

    }

    componentDidMount() {
        this.control = new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true
        });

        this.updateMap();

    }

    componentWillUnmount() {
        const { map } = this.props;
        try
        {
            map.removeControl(this.control);
        } catch {}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.map != this.props.map) {
            this.updateMap();
        }
    }

    render() { return null; }
}

export default GeolocatControls;