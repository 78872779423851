import React from 'react';
import InteractiveMap from './InteractiveMap';
import { ModelContext } from '../../contexts/ModelContext';
import GeolocatControls from './GeolocateControl';
import NavigationControls from './NavigationControls';
import _ from "lodash";
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import LayerLegendTool from './tools/LayerLegendTool';
import "../../css/DashboardMap.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import StylesButton from "../cardcontainer/StylesButton";
import { IconButton } from '@material-ui/core';
import { Layers } from '@material-ui/icons';
import { Print } from '@material-ui/icons';
import jsPDF from 'jspdf';
import setFeatureState from './setFeatureState';

class TemplateMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSystem: false,
            showModelSystem: false,
            layers: {},
            viewType: "Map", //Default view
            shouldRedirect: false
        }
        
        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.sources = {};
        this.layers = {};
        this.modelLayers = null;
        this.selectedFeature = null;
        this.mapLoaded = false;
        this.assets = null;
    }

    toggleViewType = (viewType) => {
        this.setState({ viewType: viewType });
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }

    toggleModelSystem = () => {
        const {modelContext} = this.props;
        const {selectedFeature} = modelContext;

        Object.keys(this.layers).filter(layerId => layerId !== selectedFeature.id).forEach(layerGroupKey => {
            const layerGroup = this.layers[layerGroupKey];
            if (this.state.showModelSystem) {
                this.map.hideExternalLayer(layerGroup);
            } else {
                this.map.showExternalLayer(layerGroup);
            }
        });
        this.setState({
            showModelSystem: !this.state.showModelSystem
        });
    }

    toggleSystem = () => {
        this.map.map.getStyle().layers.forEach(layer => {
            if (layer["source"] == "system") {
                this.map.map.setLayoutProperty(layer.id, 'visibility', this.state.showSystem ? 'none' : 'visible');
            }
        });
        this.setState({
            showSystem: !this.state.showSystem
        });
    }

    updateSource = (map, source, data) => {
        let mapSource = map.getSource(source);
        if (typeof mapSource === "undefined" || mapSource == null) {
            map.addSource(source, {
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": (typeof data === "undefined") ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        } else {
            mapSource.setData({
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": (typeof data === "undefined") ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });

        }
    }

    updateModelLayers = async () => {
        if(!this.map || !this.map.map) return;

        try {
            this.map.map.crossSourceCollisions = false;
            this.mapLoaded = true;
    
            const {modelContext} = this.props;
            let {layers, selectedFeature} = modelContext;
    
            for(const layer of layers) {
                if(this.layers[layer.layer.id]) continue;
                
                let layerStyles = null;
    
                if(layer.layer.options && layer.layer.options.styles) {
                    layerStyles = layer.layer.options.styles;
                }
    
                const layerId = this.map.addExternalLayerToMap({
                    "type": "FeatureCollection",
                    "generateId": true,
                    "features": []
                }, layerStyles, { hide: layer.layer.id !== selectedFeature.id });
    
                this.layers[layer.layer.id] = layerId;
    
                const data = await layer.data;
    
                //Check here for MultiPolygon label palcement?
    
                this.map && this.map.updateExternalLayerSource(layerId, {
                    "type": "FeatureCollection",
                    "generateId": true,
                    "features": (typeof data === "undefined") ? [] : data.map(a => {
                        a.properties.tempId = a.id;
                        return a;
                    })
                });
            }
    
            this.setState({layers: this.layers});
        } catch { }

        
    }

    hideLayers = (layerId) => {
        if (this.layers && this.layers[layerId]) {
            this.map.hideExternalLayer(this.layers[layerId]);
        }
    }


    showLayers = (layerId) => {
        if (this.layers && this.layers[layerId]) {
            this.map.showExternalLayer(this.layers[layerId]);
        }
    }

    removeLayer = (layer) => {
        return new Promise((resolve, reject) => {
            const map = this.map;

            if (this.layers[layer.id]) {
                map.removeExternalLayer(this.layers[layer.id]);
                this.layers[layer.id] = undefined;
            }

            resolve();
        });
    }

    removeLayers = (layers) => {
        return new Promise((resolve, reject) => {
            layers.forEach(layer => {
                try {
                    this.removeLayer(layer)
                } catch { }
            });
            resolve();
        });
    }

    componentDidMount() {
        const {modelContext} = this.props;

        if (!_.isUndefined(this.props) && !_.isUndefined(modelContext) && !_.isUndefined(modelContext.layers)) {
            this.map.map.on("load", this.updateModelLayers);
        }
    }
    
    componentWillUnmount() {
        const updateModelLayers = this.updateModelLayers.bind(this);
        this.map.map.off("load", updateModelLayers);
    }

    updateSelectedItem(selected) {
        const {modelContext} = this.props;
        const currentSelectedFeature = modelContext.selectedFeature;
        const featureName = selected.properties[currentSelectedFeature.idField] && selected.properties[currentSelectedFeature.idField].trim();

        modelContext.updateHighlightedAssetFromName(featureName)
    }

    setFeatureState = () => {
        const {modelContext} = this.props;
        const {assets, statusResults, selectedStatus, selectedFeature} = modelContext.state.value;

        if (_.isEmpty(assets) || _.isEmpty(statusResults) || _.isEmpty(selectedStatus) || _.isEmpty(selectedFeature)) return;

        let assetResults = [];

        assets.forEach(x => {
            let assetName = x.properties[selectedFeature.displayField];
            let statusResult = statusResults[assetName];
            let toAdd = x;
            toAdd.properties.class = statusResult.class;
            assetResults.push(toAdd);
        });

        try {
            setFeatureState['status'](
                assetResults,
                selectedStatus,
                this.layers,
                this.getColor,
                this.map.map,
                selectedFeature,
                modelContext
            );
        } catch (e) {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {modelContext, selectedFeature} = this.props;
        const {statusResults} = modelContext.state.value;

        if (modelContext.layers != prevProps.modelContext.layers) {
            const layersToDelete = prevProps.modelContext.layers;
            const comp = this;
            if (this.mapLoaded) {
                this.removeLayers(layersToDelete).then(() => comp.updateModelLayers.call(comp));
            } else {
                this.map.map.once("load",  () => comp.updateModelLayers.call(comp));
            }
        }

        if (!this.mapLoaded) return;

        const setFeatureState = this.setFeatureState.bind(this);
        this.map.map.off('idle', setFeatureState);
        this.map.map.once('idle', setFeatureState);
    }

    render() {
        const {modelContext} = this.props;

        return (
            <MapContextProvider>
                <MapContext.Consumer>
                    {(mapContext) => {
                        const mapViewBox = mapContext.interactiveMap ? [mapContext.interactiveMap.clientWidth, mapContext.interactiveMap.clientHeight] : [0, 0];
                        return (
                            <>

                                <ThemeContext.Consumer>
                                    {({ theme, getColor, updateTheme }) => {
                                        this.getColor = getColor;
                                    }}
                                </ThemeContext.Consumer>

                                <ModelContext.Consumer>
                                    {(model) => {
                                        return (
                                            <div style={{ position: "relative", height: "calc(100vh - 225px)" }}>

                                                <div style=
                                                {{
                                                    id: 'Print Button',
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    zIndex: 6000,
                                                    width: '40px',
                                                    height: '40px',
                                                    boxShadow: '0px 0px 0px 2px rgba(0,0,0,.1)',
                                                    backgroundColor: 'white',
                                                    borderRadius: '16px'
                                                }}>
                                                    <IconButton
                                                        aria-label='Print Button'
                                                        style={{padding: '8px', color: this.getColor('primary.Primary')}}
                                                        onClick={this.handlePrintClick}>
                                                        <Print />
                                                    </IconButton>
                                                </div>

                                                <InteractiveMap {...this.props} options={this.props.mapOptions} viewType={this.state.viewType}
                                                    ref={(element) => {
                                                        this.map = element;
                                                        mapContext.updateMap(element);
                                                    }}
                                                    mapContext={mapContext}
                                                    layers={this.state.layers}
                                                    selectableLayers={(_.isUndefined(this.state.layers) || _.isNull(this.state.layers)) ? [] : Object.keys(this.state.layers).reduce((layerIds, layerId, idx) => {
                                                        if (layerId === modelContext.selectedFeature.id) {
                                                            return layerIds.concat(this.state.layers[layerId]);
                                                        }
                                                        return layerIds;
                                                    }, [])}
                                                    selectedItem={modelContext.state.value.selectedItem}
                                                    updateSelectedItem={this.props.setSelectedItem} >
                                                    <GeolocatControls></GeolocatControls>
                                                    <NavigationControls></NavigationControls>
                                                </InteractiveMap>

                                                <div style= 
                                                    {{
                                                        id: "Layers Button",
                                                        position: 'absolute', 
                                                        left: "5px", 
                                                        top: "5px", 
                                                        zIndex: 6000, 
                                                        width: "40px", 
                                                        height: "40px",
                                                        backgroundColor: 'white',
                                                        boxShadow: "0px 0px 0px 2px rgba(0,0,0,.1)",
                                                        borderRadius: "16px", }}>
                                                        <IconButton aria-label = "Layer Legend" style={{padding: '8px', color: this.getColor('primary.Primary')}}  onClick={() => { mapContext.toggleLegend() }}>
                                                            <Layers />
                                                        </IconButton>
                                                    </div>

                                                <div style={{
                                                    position: 'absolute',
                                                    left: "5px",
                                                    bottom: "5px",
                                                    zIndex: 6000,
                                                    width: "200px",
                                                    height: "33px",
                                                }}>
                                                    <StylesButton viewType={this.state.viewType} toggleViewType={this.toggleViewType} />
                                                </div>
                                                {mapContext.showLegend && //Layers Table When Clicked
                                                    <div style=
                                                        {{
                                                            position: 'absolute',
                                                            left: "5px",
                                                            top: "5px",
                                                            bottom: "5px",
                                                            minWidth: "250px",
                                                            zIndex: 6000,
                                                            backgroundColor: 'white',
                                                            borderRadius: "16px",
                                                        }}>
                                                        <LayerLegendTool
                                                            hideModelSystemGroups={true}
                                                            layers={this.props.layers}
                                                            legendTitle={'Layers'}
                                                            legendSubtitle={modelContext.selectedStatus.displayName}
                                                            toggleSystem={() => { this.toggleSystem() }}
                                                            showSystem={this.state.showSystem}
                                                            toggleModel={() => { this.toggleModelSystem() }}
                                                            showModelSystem={this.state.showModelSystem}
                                                            mapOptions={this.props.mapOptions}
                                                            model={model}
                                                            mapContext={mapContext}></LayerLegendTool>
                                                    </div>}

                                            </div>
                                        );
                                    }
                                    }
                                </ModelContext.Consumer>
                            </>
                        )
                    }}
                </MapContext.Consumer>
            </MapContextProvider>
        )
    }
}

export default TemplateMap;