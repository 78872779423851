import React from 'react';
import { withStyles, Select } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, SvgIcon } from '@material-ui/core';
import { Warning, ExpandLess, ExpandMore, StopRounded, LinearScaleRounded, CategoryRounded } from '@material-ui/icons';
import ReactHtmlParser from 'react-html-parser';

import red from '@material-ui/core/colors/red';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
    spacer: {
        width: `${theme.spacing(1)}px`,
        height: `${theme.spacing(1)}px`,
    },
    card: {
        maxHeight: 350
    },
    elementList: {
        width: '100%',
        maxWidth: 360,
    },
});

const Icon = (props, iconSource) => {
    let svg = atob(iconSource);
    return (<SvgIcon>
            {ReactHtmlParser(svg)}
        </SvgIcon>);
}

const MapIcon = (props, iconName, map) => {
    let image = map.style.getImage(iconName);
    let canvas = document.createElement('canvas');
    canvas.height = image.data.height;
    canvas.width = image.data.width; 
    let context = canvas.getContext('2d');
    context.putImageData(image.data, 0, 0);
    canvas.toDataURL()
    return 
}

class LayerLegendItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    getItemIcon = (layer) => {
        let iconStyle = 'square';
        if(typeof layer.iconStyle !== 'undefined') {
            iconStyle = layer.iconStyle;
        }

        let SelectedIcon = null;
        switch(iconStyle) {
            case "line":
                SelectedIcon = LinearScaleRounded;
                break;
            case "area":
                SelectedIcon = CategoryRounded;
                break;
            case "square":
            default:
                SelectedIcon = StopRounded;
        }

        let iconColor = null;
        if(typeof layer.iconColor !== 'undefined') {
            iconColor = layer.iconColor;
        }

        if(typeof layer.iconSvg !== 'undefined') {
            return Icon({}, layer.iconSvg);
        }
        if(typeof layer.icon !== 'undefined') {
            return (<img src={layer.icon} alt=""></img>);
        }
        if(typeof layer.iconUrl !== 'undefined') {
            return (<img src={layer.iconUrl} alt=""></img>);
        }
        
        return (<SelectedIcon color="primary" style={{ color: iconColor !== null ? iconColor : null }}> </SelectedIcon>)
    }

    render() {
        let { layer, index, group, mapContext, model, classes } = this.props;
        let { isOpen } = this.state;
        return(
            <ListItem style={{width: '100%'}} key={layer.layerName + index}>
                <ListItemIcon>
                    {this.getItemIcon(layer)}
                </ListItemIcon>
                <ListItemText primary={`${layer.layerName}`} >
                </ListItemText>
            </ListItem>);
    }
}

export default withStyles(styles)(LayerLegendItem)