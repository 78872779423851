import ApiHelper from './ApiHelper';

export const getHeaders = () => {

    var toReturn = {
        'Content-Type': 'application/json'
    };

    const token = ApiHelper.getUserToken();
    if (token) 
    {   
        toReturn["Authorization"] = `Bearer ${token}`
    } 

    return toReturn;
}

export const getTenantId = () => 
{
    /*
    let tenantList = Promise.resolve(getTenants());
    tenantList.then(function(tenants){
        return tenants[0].id;
    }); 
    */
    const tenant = sessionStorage.getItem('tenant');
    return tenant;
    //let sessionUser = sessionStorage.getItem('keycloak');
    //if (sessionUser) {
    //    sessionUser = JSON.parse(sessionUser);
    //}
    //return sessionUser.tokenParsed.tenants[0]; 
}

const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
};

const getEmailSubscriberCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            this.postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let {urlTemplate, tenantId, headers} = data;
        let url = new URL(`api/v2/user/preferences?tenantId=${tenantId}`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: 'same-origin',
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            this.postMessage(JSON.stringify(data));
        }).catch((err) => {
            this.postMessage(JSON.stringify(err));
        });
    }
}

const putEmailSubscriberCode = () => {
    onmessage = function(e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            this.postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let {urlTemplate, tenantId, headers, body} = data;

        let url = new URL(`api/v2/user/preferences?tenantId=${tenantId}`, urlTemplate);

        fetch(url, {
            method: 'put',
            credentials: 'same-origin',
            headers: headers,
            body: body,
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            this.postMessage(JSON.stringify(err));
        });
    }
}

const getDashboardsCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantId, headers } = data;

        let url = new URL(`api/dashboards?tenantId=${tenantId}`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}

const getAlertsCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantName, headers } = data;

        let url = new URL(`api/v2/${tenantName}/Alerts`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}


const getEventsCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantName, headers, startDate, endDate } = data;

        let url = new URL(`api/v2/${tenantName}/EventAlerts?startDate=${startDate}&endDate=${endDate}`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}

const getTenantsCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantId, headers } = data;

        let url = new URL(`api/tenants`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}


const getModelsCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantId, headers } = data;

        let url = new URL(`api/models?tenantId=${tenantId}`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}

const getAssetsCode = () => {
    onmessage = function (e) {
        const sortAssets = (layer, data) => {
            var sortField = layer.displayField;
            return data.sort(function (a, b) {
                var nameA = a.properties[sortField];
                var nameB = b.properties[sortField];
                if (typeof (nameA) == 'undefined' || nameA == null || nameA.trim() == '') {
                    return 1;
                }
                if (typeof (nameB) == 'undefined' || nameB == null || nameB.trim() == '') {
                    return -1
                }
                return nameA.localeCompare(nameB, [], {
                    sensitivity: "base",
                    numeric: true
                });
            });
        };

        let data = null;

        if (e.data) {
            if (e.data == 'CANCELLED') {
                onmessage.controller.abort();
                return;
            } else {
                data = JSON.parse(e.data);
            }
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { modelId, layer, urlTemplate, tenantId, headers } = data;

        let url = new URL(`api/assets/features?tenantId=${tenantId}&layerName=${layer.collectionName}`, urlTemplate);

        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: new Headers(headers),
            signal: onmessage.controller.signal
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            return sortAssets(layer, data);
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            if (onmessage.controller.signal.aborted) {
                onmessage.controller = new AbortController();
                postMessage(JSON.stringify('CANCELLED'));
            } else {
                postMessage(JSON.stringify(err));
            }
        });
    }

    onmessage.controller = new AbortController();
}

const getThemeCode = () => {
    onmessage = function (e) {
        let data = null;
        if (e.data) {
            data = JSON.parse(e.data);
        } else {
            postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let { urlTemplate, tenantId, headers } = data;

        let url = new URL(`api/colors?tenantId=${tenantId}`, urlTemplate);
        fetch(url, {
            method: 'get',
            credentials: "same-origin",
            headers: headers,
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            postMessage(JSON.stringify(data));
        }).catch((err) => {
            postMessage(JSON.stringify(err));
        });
    }
}

const workerify = (func) => {
    let code = func.toString();
    code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));
    const blob = new Blob([code], { type: "application/javascript" });
    const worker_script = URL.createObjectURL(blob);

    return worker_script;
}

export const modelsWorker = workerify(getModelsCode);
export const assetsWorker = workerify(getAssetsCode);
export const themeWorker = workerify(getThemeCode);
export const dashboardWorker = workerify(getDashboardsCode);
export const tenantWorker = workerify(getTenantsCode);
export const alertWorker = workerify(getAlertsCode);
export const eventWorker = workerify(getEventsCode);
export const emailSubscriberWorker = workerify(getEmailSubscriberCode);
export const putEmailSubscriberWorker = workerify(putEmailSubscriberCode);

export const urlPath = ApiHelper.getUrlPath();

export function getKeycloakIdentity() {
    return new Promise((resolve, reject) => {
    let url = new URL(`api/configuration/auth`, urlPath);
    fetch(url, {
        method: 'get',
        credentials: 'same-origin'
    }).then(result => {
        if (result.ok) {
            resolve(result.json())
        } else {
            reject(result.statusText);
        }
    });
})
}

export function getAPIVersion() {
    return new Promise((resolve, reject) => {
    let url = new URL(`api/configuration/version`, urlPath);
    fetch(url, {
        method: 'get',
        credentials: 'same-origin'
    }).then(result => {
        if (result.ok) {
            resolve(result.json())
        } else {
            reject(result.statusText);
        }
    });
})
}

export function getDashboards() {
    return new Promise((resolve, reject) => {
        let { Url, TenantId } = { Url: urlPath, TenantId: getTenantId() };

        let worker = new Worker(dashboardWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                returnValue = sortByKey(returnValue, 'displayOrder')
                resolve(returnValue);
            }
            worker.terminate();
        }
        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            headers: headers,
        }))
    });
}

export function getEmailSubscriber() {
    return new Promise((resolve, reject) => {
        let {Url, TenantId} = {Url: urlPath, TenantId: getTenantId() };
        let worker = new Worker(emailSubscriberWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }
        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            headers: headers,
        }))
    });
}

export function updateEmailSubscriber(preferences) {
    return new Promise((resolve, reject) => {
        let {Url, TenantId} = { Url: urlPath, TenantId: getTenantId()};;

        let worker = new Worker(putEmailSubscriberWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }

        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            headers: headers,
            body: preferences,
        }))

    });
}


export function getTenants() {
    return new Promise((resolve, reject) => {
        let { Url } = { Url: urlPath };

        let worker = new Worker(tenantWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }
        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            headers: headers,
        }))
    });
}

export function getModels() {
    return new Promise((resolve, reject) => {
        let { Url, TenantId } = { Url: urlPath, TenantId: getTenantId() };

        let worker = new Worker(modelsWorker);

        const requestId = Math.random();
        worker.requestId = requestId;
        worker.tenantId = TenantId;

        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else if(worker.tenantId !== getTenantId() || worker.requestId !== requestId) {
                reject(returnValue.message);
            } else {
                returnValue.forEach(function (model) {
                    var layers = sortByKey(model.layers, 'displayOrder');
                    model.layers = layers;
                });
                returnValue = sortByKey(returnValue, 'displayOrder')
                resolve(returnValue);
            }
            worker.terminate();
        }
        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            headers: headers,
        }))
    });
}

export function getTheme() {
    return new Promise((resolve, reject) => {
        let { Url, TenantId } = { Url: urlPath, TenantId: getTenantId() };;

        let worker = new Worker(themeWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }
        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            headers: headers,
        }))
    });
}

export function getAssets(modelId, layer) {
    return new Promise((resolve, reject) => {
        let { Url, TenantId } = { Url: urlPath, TenantId: getTenantId() };;

        let worker = new Worker(assetsWorker);

        const requestId = Math.random();
        worker.requestId = requestId;
        worker.tenantId = TenantId;
        
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else if(worker.tenantId !== getTenantId() || worker.requestId !== requestId) {
                resolve([]);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }

        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantId: TenantId,
            modelId: modelId,
            layer: layer,
            headers: headers,
        }))
    });
}

export function getAlerts(tenantName) {
    return new Promise((resolve, reject) => {
        let { Url } = { Url: urlPath };;

        let worker = new Worker(alertWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }

        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantName: tenantName,
            headers: headers,
        }))
    });
}

export function getEvents(tenantName,startDate,endDate) {
    return new Promise((resolve, reject) => {
        let { Url } = { Url: urlPath };;

        let worker = new Worker(eventWorker);
        worker.onmessage = (message) => {
            let returnValue = JSON.parse(message.data);
            if (returnValue instanceof Error) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }

        let headers = getHeaders();

        worker.postMessage(JSON.stringify({
            urlTemplate: Url,
            tenantName: tenantName,
            startDate: startDate,
            endDate: endDate,
            headers: headers,
        }))
    });
}

export async function getStatusTypes(tenantName, layer, abortController) {
    const url = new URL(`api/v2/${tenantName}/assets/${layer.layerName}/Status`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: "same-origin",
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    })

    if (results.ok) {
        return results.json()
    } else {
        throw new Error(results.statusText);
    }
}

export async function getStatusResults(tenantName, modelName, selectedFeature, startDate, endDate, selectedStatus, abortController) {
    const url = new URL(`api/v2/${tenantName}/assets/${selectedFeature.layerName}/Status/${selectedStatus.statusName}?modelNames=${modelName}&startDate=${startDate}&endDate=${endDate}`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: "same-origin",
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    if (results.ok) {
        return results.json()
    } else {
        throw new Error(results.statusText);
    }
}

export async function getCMOMSummaryData(tenantName, model, layer, startDate, endDate, abortController) {
    const url = new URL(`api/v2/${tenantName}/${model.name}/${layer.layerName}/Maintenance/SummaryStats`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    })

    if (!results.ok) {
        throw new Error(results.statusText);
    }
    return results.json();
}

export async function getValidSensorDates(tenantName, modelName, layerName, metric, dateRangeLimit) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/${layerName}/Data/ValidDates/Sensor?metric=${metric}&dateRangeLimit=${dateRangeLimit}`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: "same-origin",
        headers: new Headers(getHeaders())
    });

    if (results.ok) {
        return results.json();
    } else {
        throw new Error(results.statusText);
    }
}

function getGenericRequestCode() {
    onmessage = function(ea) {
        let messageData = null;
        if (ea.data) {
            messageData = JSON.parse(ea.data);
        } else {
            this.postMessage(JSON.stringify(new Error("Missing Data")));
        }

        let {url, headers} = messageData;
        
        fetch(url, {
            method: 'get',
            credentials: 'same-origin',
            headers: new Headers(headers)
        }).then(result => {
            if (result.ok) {
                return result.json()
            } else {
                throw new Error(result.statusText);
            }
        }).then(data => {
            this.postMessage(JSON.stringify(data));
        }).catch((err) => {
            err.isError = true;
            this.postMessage(JSON.stringify(err, Object.getOwnPropertyNames(err)));
        });
    }
}

const genericRequestWorker = workerify(getGenericRequestCode);

export function getGenericRequestData(url) {
    const worker = new Worker(genericRequestWorker);

    var promise = new Promise((resolve, reject) => {
        const requestId = Math.random();
        worker.requestId = requestId;

        worker.onmessage = (message) => {
            const returnValue = JSON.parse(message.data);
            if (returnValue.isError === true) {
                reject(returnValue.message);
            } else if(worker.requestId !== requestId) {
                reject(returnValue.message);
            } else {
                resolve(returnValue);
            }
            worker.terminate();
        }

        worker.postMessage(JSON.stringify({
            url,
            headers: getHeaders()
        }))
    });

    return promise;
}

export async function getSupportedDataSources(tenantName, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/SupportedExternalDataApis`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    if (!results.ok) throw new Error(results.statusText);

    const response = await results.json();
    return response;
}

export async function getExternalDataEndpoints(tenantName, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/ExternalDataConfigs`, urlPath);

    const results = await fetch(url, {
        method: 'get',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    if (!results.ok) throw new Error(results.statusText);
    
    const response = await results.json();
    return response;
}

export async function updateExternalDataSource(tenantName, newDataSource, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/ExternalDataApis/${newDataSource.oldName}`, urlPath);

    const result = await fetch(url, {
        method: 'put',
        credentials: 'same-origin',
        body: JSON.stringify(newDataSource),
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    return result;
}

export async function updateExternalDataEndpoint(tenantName, newEndpoint, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/ExternalDataConfigs/${newEndpoint.oldName}`, urlPath);

    const result = await fetch(url, {
        method: 'put',
        credentials: 'same-origin',
        body: JSON.stringify(newEndpoint),
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    return result;
}

export async function deleteExternalDataSource(tenantName, name, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/ExternalDataApis/${name}`, urlPath);

    const result = await fetch(url, {
        method: 'delete',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    return result;
}

export async function deleteExternalDataEndpoint(tenantName, name, abortController) {
    const url = new URL(`api/v2/${tenantName}/ExternalData/ExternalDataConfigs/${name}`, urlPath);

    const result = await fetch(url, {
        method: 'delete',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
        signal: abortController ? abortController.signal : null
    });

    return result;
}





export async function updateCSOIncludedInReport(tenantName, modelName, update) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/Reports/CsoDischargeDataByOutfall/UpdateEventInclusion`, urlPath);

    const result = await fetch(url, {
        method: 'post',
        body: JSON.stringify(update),
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
    });

    return result;
}

export async function updateCSOOutfallItem(tenantName, modelName, toUpdate, id) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/Reports/CsoDischargeDataByOutfall/${id}`, urlPath);

    const result = await fetch(url, {
        method: 'put',
        body: JSON.stringify(toUpdate),
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
    });

    return result;
}

export async function updateCSOOutfallPublishInfo(tenantName, modelName, toUpdate, id) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/Reports/CsoDischargeDataByOutfall/${id}/PublishState`, urlPath);

    const result = await fetch(url, {
        method: 'put',
        body: JSON.stringify(toUpdate),
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
    });

    return result;
}

export async function addCSOOutfallItem(tenantName, modelName, toAdd) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/Reports/CsoDischargeDataByOutfall`, urlPath);

    const result = await fetch(url, {
        method: 'post',
        body: JSON.stringify(toAdd),
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
    });

    return result;
}

export async function deleteCSOOutfallItem(tenantName, modelName, id) {
    const url = new URL(`api/v2/${tenantName}/${modelName}/Reports/CsoDischargeDataByOutfall/${id}`, urlPath);

    const result = await fetch(url, {
        method: 'delete',
        credentials: 'same-origin',
        headers: new Headers(getHeaders()),
    });

    return result;
}

export async function toggleCsoDischargeAutomatedPublishing(tenantName, enabled) {
  const url = new URL(`api/v2/${tenantName}/null/Reports/ToggleCsoDischargeAutomatedPublishing?enabled=${enabled}`, urlPath);

  const result = await fetch(url, {
      method: 'post',
      credentials: 'same-origin',
      headers: new Headers(getHeaders()),
  });

  return result;
}

export async function getCsoDischargeAutomatedPublishing(tenantName) {
  const url = new URL(`api/v2/${tenantName}/null/Reports/CsoDischargeAutomatedPublishingConfig`, urlPath);

  const result = await fetch(url, {
      method: 'get',
      credentials: 'same-origin',
      headers: new Headers(getHeaders()),
  });

  return result;
}