import React, { useState, useEffect, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import VirtualList from 'react-tiny-virtual-list';
import withLoading from "../../withLoading";
import _ from 'lodash';

const VirtualListWithLoading = withLoading(VirtualList);

const styles = theme => ({
	list: {
        overflow: 'hidden',
		width: "100%",
		height: "calc(100vh - 150px)",
	},
	listItem: {
		cursor: 'pointer',
		'&:hover': {
			border: '2px solid black',
		}
	},
});

const ListFeaturePanel = (props) => {

    const {assets, isLoading, listItemRenderer, classes, itemSize, selectedItem} = props;

    const element = useRef(null);

    const [clientHeight, setClientHeight] = useState(1);
    const [clientWidth, setClientWidth] = useState(1);

    useEffect( () => {
        setClientHeight(element.current.clientHeight);
        setClientWidth(element.current.clientWidth);
    }, []);

    const getSelectedIndex = (item) => {
        for (let i = 0; i < props.assets.length; i++) {
            if (item && props.assets[i].id == item.id) {
                return i;
            }
        }
        return null;
    }

    const selectedFeatureIndex = getSelectedIndex(selectedItem);

    return (
        <div className={classes.list} ref={element}>
            <VirtualListWithLoading 
                isLoading={isLoading}
                width={clientWidth}
                height={clientHeight}
                items={assets}
                itemCount={assets.length}
                itemSize={itemSize}
                renderItem={listItemRenderer}
                scrollToIndex={selectedFeatureIndex}
                scrollToAlignment={'auto'}
                extraData={props}
            />
        </div>
    );
}

export default withStyles(styles)(ListFeaturePanel);
