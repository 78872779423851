import React, { useState, useContext, useEffect } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import { UserContext } from '../../contexts/UserContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { withStyles } from '@material-ui/styles';
import { Button, Grid, Radio, TextField, List, ListItem, MenuItem, ListItemText, Divider, Switch, FormControlLabel, Typography, Link } from '@material-ui/core';
import SearchBar from '../featurepanel/SearchBar';
import AlertDialogButton from '../AlertDialogButton';
import _ from 'lodash';
import { updateExternalDataSource, deleteExternalDataSource } from "../../api/ApiWorker";
import withLoading from '../withLoading';

const LoadingDiv = withLoading('div');

const dataSourcesClasses = theme => ({
    loadingDiv: {
        height: '100%'
    },
    mainGrid: {
        height: '100%',
    },
    leftGrid: {
        height: '60vh',
        overflowY: 'auto'
    },
    rightGrid: {
        height: '60vh',
        overflowY: 'auto'
    },
    dataSourcesContainer: {
        height: '100%',
        overflowY: 'auto'
    },
    dataSourcesList: {
        width: '100%',
        padding: '0px'
    },
    dataSourcesListItem: {
        paddingLeft: '32px'
    },
    searchBar: {
        paddingTop: '0px',
        paddingLeft: '24px'
    }
});
const styles = {
    mainGrid: {
        margin: '0px -8px'
    },
    leftGrid: {
        height: '60vh',
        overflowY: 'auto'
    },
    rightGrid: {
        height: '60vh',
        overflowY: 'auto'
    },
    dataSourcesContainer: {
        padding: '0px'
    },
    dataSourcesList: {
    }
}

const radioButtonColor = 'primary.Primary';

var loadingMessageKey;

const DataSources = (props) => {
    const { classes } = props;

    const themeContext = useContext(ThemeContext);
    const userContext = useContext(UserContext);
    const snackbarContext = useContext(SnackbarContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [currentDataSource, setCurrentDataSource] = useState(null);
    const [dataSourceFilter, setDataSourceFilter] = useState('');
    const [dataSourceEnabled, setDataSourceEnabled] = useState(false);
    const [dataSourceNameField, setDataSourceNameField] = useState('');
    const [dataSourceUrlField, setDataSourceUrlField] = useState('');
    const [dataSourceCollectorTypeField, setDataSourceCollectorTypeField] = useState('');
    const [dataSourceKeyField, setDataSourceKeyField] = useState('');
    const [dataSourceCommentField, setDataSourceCommentField] = useState('');
    const [dataSourceNameError, setDataSourceNameError] = useState('');

    useEffect( () => {
        if (!_.isEmpty(props.dataSources[0])) setDataSource(props.dataSources[0]);
    }, []);

    const updateDataSource = async (newDataSource) => {
        loadingMessageKey = snackbarContext.addMessage('Updating data source...');
        setIsLoading(true);

        try {
            const tenant = userContext.getTenant();
            const ac = new AbortController();
        
            var result = await updateExternalDataSource(tenant.tenantName, newDataSource, ac);
    
            if (result.ok) {
                let newDataSources = props.dataSources;
        
                let dataSourceToUpdate = newDataSources.find(x => x.isTemp || x.name == newDataSource.oldName);
                newDataSources[newDataSources.indexOf(dataSourceToUpdate)] = newDataSource;
                newDataSources.forEach(x => x.isTemp = false);
            
                //Update relevant endpoints with new data source name
                let newEndpoints = props.endpoints;
                for (var i in newEndpoints) {
                  if (newEndpoints[i].tenantApi == newDataSource.oldName) {
                    newEndpoints[i].tenantApi = newDataSource.name;
                  }
                }
    
                setDataSource(newDataSource);
                props.setData({
                    dataSources: newDataSources,
                    endpoints: newEndpoints,
                    supportedDataSources: props.supportedDataSources
                });
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('Data source updated.')
            } else {
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('An error occurred. Please try again.', 'error');
            }
        } catch (e) {
            snackbarContext.removeMessage(loadingMessageKey);
            snackbarContext.addMessage('An error occurred. Please try again', 'error');
        } finally {
            setIsLoading(false);
        }
        
      }
    
      const addNewDataSource = () => {
        if (props.dataSources.filter(x => x.isTemp).length > 0) {
            //Probably show a pop up or message here stating that
            //User must finish editing the current 'New' data source before being able to create a new one
            //This prevents spamming the 'New' button to create an infinite amount of data sources in the UI
            return;
        } 
    
        let defaultDataSource = props.supportedDataSources.find(x => x.defaultUrl != null);
        defaultDataSource = defaultDataSource || props.supportedDataSources[1];
    
        const tempDataSource = {
          isTemp: true, //Flag denoting whether a data source was temporary (newly created, but changes haven't been saved to database yet)
          name: '',
          collectorType: defaultDataSource.name,
          key: '',
          url: defaultDataSource.defaultUrl || '',
          comment: ''
        };
    
        let tempDataSources = props.dataSources;
        tempDataSources.push(tempDataSource);

        setDataSource(tempDataSource);
        props.setData({
            dataSources: tempDataSources,
            endpoints: props.endpoints,
            supportedDataSources: props.supportedDataSources
        });
      }
    
      const deleteDataSource = async (dataSource) => {
        loadingMessageKey = snackbarContext.addMessage('Deleting data source and related endpoints...');
        setIsLoading(true);
        
        if (!dataSource.isTemp) {
            try {
                const tenant = userContext.getTenant();
                const ac = new AbortController();
                
                var result = await deleteExternalDataSource(tenant.tenantName, dataSource.name, ac);
        
                if (result.ok) {
                    let newDataSources = props.dataSources.filter(x => !_.isEqual(x, dataSource));
                    if (!_.isEmpty(newDataSources)) {
                        setDataSource(newDataSources[0]);
                    } else {
                        setCurrentDataSource(null);
                    }
                    props.setData({
                        supportedDataSources: props.supportedDataSources,
                        dataSources: newDataSources,
                        endpoints: props.endpoints.filter(x => x.tenantApi != dataSource.name)
                    });
                    snackbarContext.removeMessage(loadingMessageKey);
                    snackbarContext.addMessage('Data source and its endpoints deleted.');
                } else {
                    snackbarContext.removeMessage(loadingMessageKey);
                    snackbarContext.addMessage('An error occurred. Please try again', 'error');
                }
            }
            catch (e) {
                snackbarContext.removeMessage(loadingMessageKey);
                snackbarContext.addMessage('An error occurred. Please try again', 'error');
            } finally {
                setIsLoading(false);
            }
        } else {
            let newDataSources = props.dataSources.filter(x => !_.isEqual(x, dataSource));
            if (!_.isEmpty(newDataSources)) {
                setDataSource(newDataSources[0]);
            } else {
                setCurrentDataSource(null);
            }
            props.setData({
                supportedDataSources: props.supportedDataSources,
                dataSources: newDataSources,
                endpoints: props.endpoints.filter(x => x.tenantApi != dataSource.name)
            });
        }
      }

    useEffect( () => {
        if (!_.isEmpty(props.defaultDataSource)) setDataSource(props.defaultDataSource);
    }, [props.defaultDataSource])

    const setFilter = (input) => {
        setDataSourceFilter(input);
    }

    const applyFilter = (dataSources) => {
        return dataSources.filter(x => x.name.toLowerCase().includes(dataSourceFilter.toLowerCase()));
    }

    //Enabled switch (uses built-in prev?)
    const toggleDataSourceEnabled = event => {
        setDataSourceEnabled((prev) => !prev);
    }

    //Name field
    const handleNameChange = event => {
        let text = event.target.value;
        setDataSourceNameField(text);

        if (text == '') {
            setDataSourceNameError('Name cannot be empty');
        } else if (props.dataSources.find(x => x.id != currentDataSource.id && x.name.toLowerCase() == text.toLowerCase()) != null) {
            setDataSourceNameError(`A data source with this name already exists`);
        } else {
            setDataSourceNameError(null);
        }
    }

    //Url field
    const handleUrlChange = event => {
        setDataSourceUrlField(event.target.value);
    }

    //Key field
    const handleKeyChange = event => {
        setDataSourceKeyField(event.target.value);
    }

    //Comment field
    const handleCommentChange = event => {
        setDataSourceCommentField(event.target.value);
    }

    const setDataSource = (dataSource) => {
        setCurrentDataSource(dataSource);
        let definedExternalDataSource = props.supportedDataSources.find(x => x.name == dataSource.collectorType);
        setDataSourceEnabled(!!dataSource.enabled);
        setDataSourceNameField(dataSource.name || '');
        setDataSourceUrlField(dataSource.url || '');
        setDataSourceCollectorTypeField(definedExternalDataSource ? definedExternalDataSource.displayName : '');
        setDataSourceKeyField(dataSource.key || '');
        setDataSourceCommentField(dataSource.comment || '');
        if (_.isEmpty(dataSource.name)) {
            setDataSourceNameError('Data source name cannot be empty');
        }
        else if (props.dataSources.filter(x => x.name == dataSource.name).length > 1) {
            setDataSourceNameError('A data source with this name already exists');
        } else {
            setDataSourceNameError(null);
        }
    }

    const createSelectMenuItems = () => {
        let menuItems = [];
        Object.values(props.supportedDataSources).filter(x => x.name != 'None').map(x => {
            menuItems.push(
                <MenuItem value={x.displayName}> {x.displayName} </MenuItem>
            )
        });
        return menuItems;
    }

    //Source Type ()
    const handleSelectSourceTypeChange = (event) => {
        setDataSourceCollectorTypeField(event.target.value);
        let defaultUrl = props.supportedDataSources.find(x => x.displayName == event.target.value).defaultUrl;
        setDataSourceUrlField(defaultUrl || '');
    }

    const createDataSourceListItems = () => {
        var dataSourceListItems = [];

        applyFilter(props.dataSources).map(dataSource => {
            dataSourceListItems.push(
                <ListItem 
                button={false}
                style={props.style}
                divider={true}>
                <Grid container direction='row' justify='space-between' alignItems='center'>
                    <Radio
                        style={{cursor: 'pointer', color: themeContext.getColor(radioButtonColor)}}
                        checked={_.isEqual(dataSource, currentDataSource)}
                        onChange={() => setDataSource(dataSource)}
                        value={dataSource.name}
                        />
                    <Grid item xs>
                        <ListItemText primary={dataSource.name} />
                        <Typography style={{color: 'gray'}} variant='body2'>
                            {props.supportedDataSources.find(x => x.name == dataSource.collectorType).displayName}
                        </Typography>
                    </Grid>
                </Grid>	
            </ListItem> 
            );
        });

        return dataSourceListItems;
    }

    const testDataSource = () => {
    }

    const updateSource = () => {
        const updatedDataSource = {
            oldName: currentDataSource.isTemp ? dataSourceNameField : currentDataSource.name,
            name: dataSourceNameField,
            key: dataSourceKeyField,
            url: dataSourceUrlField,
            enabled: !!dataSourceEnabled,
            collectorType: props.supportedDataSources.find(x => x.displayName == dataSourceCollectorTypeField).name, // <-- Insert into db enum string representation instead of display name
            comment: dataSourceCommentField
        };
        updateDataSource(updatedDataSource);
    }

    const changeTab = () => {
        props.setCurrentTab({index: 1, defaultDataSource: currentDataSource});
    }

    const Content = (_.isEmpty(props.dataSources) || _.isEmpty(currentDataSource))
    ? <div style={{marginLeft: '24px'}}>No data sources to display.</div> 
    :
    <Grid style={styles.rightGrid} className={styles.rightGrid} container item xs={7} sm={8} md={8} lg={8} spacing={2}>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-start' alignItems='center'>
            <Typography variant='h6'>
                {currentDataSource.name}
            </Typography>
        </Grid>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-end' alignItems='center'>
            <FormControlLabel
                control={<Switch color='primary' checked={dataSourceEnabled} onChange={toggleDataSourceEnabled} />}
                label='Enabled'
                labelPlacement='start'
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <div style={{display: 'flex'}}>
            <Typography variant='subtitle2'>
                {`Number of active endpoints: ${props.endpoints.filter(x => x.enabled && x.tenantApi == currentDataSource.name).length}`}
            </Typography>
            {
                !currentDataSource.isTemp && 
                <Typography variant='subtitle2' style={{cursor: 'pointer', paddingLeft: '8px'}}>
                    <Link onClick={changeTab}>
                        Configure &rarr;
                    </Link>
                </Typography>   
            }
            </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                label='Name'
                defaultValue={currentDataSource.name}
                value={dataSourceNameField}
                onChange={handleNameChange}
                error={dataSourceNameError}
                helperText={dataSourceNameError}
                variant='outlined'
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                label='Base URL'
                defaultValue={currentDataSource.url}
                value={dataSourceUrlField}
                onChange={handleUrlChange}
                variant='outlined'
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                select
                label='Source Type'
                defaultValue={currentDataSource.collectorType}
                value={dataSourceCollectorTypeField}
                onChange={handleSelectSourceTypeChange}
                variant='outlined'
                fullWidth
            >
                {createSelectMenuItems()}
            </TextField>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}> 
            <TextField
                label='Key Value'
                defaultValue={currentDataSource.key || ''}
                value={dataSourceKeyField}
                onChange={handleKeyChange}
                variant='outlined'
                fullWidth
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
                label='Comments'
                defaultValue={currentDataSource.comment || ''}
                value={dataSourceCommentField}
                onChange={handleCommentChange}
                variant='outlined'
                fullWidth
                multiline
                rows={4}
            />
        </Grid>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-start' alignItems='center'>
            <AlertDialogButton
                buttonColor={'secondary'}
                buttonText={'Remove this data source'}
                dialogTitle={'Remove this data source?'}
                dialogText={'This data source and all related endpoints will be removed from PipeCAST.'}
                confirmOnClick={() => deleteDataSource(currentDataSource)}
            />
        </Grid>
        <Grid container item xs={6} sm={6} md={6} lg={6} display='grid' justify='flex-end' alignItems='center'>
            {
                false && // The Test Configuration will take some more planning. This will have to test every single supported data source.
                         // This should be broken out into its own user story.
                <Button color='primary' onClick={testDataSource}>
                    Test Configuration
                </Button>
            }
            <Button disabled={dataSourceNameError} color='primary' onClick={updateSource}>
                Save Changes
            </Button>
        </Grid>
    </Grid>

    return (
        <LoadingDiv isLoading={isLoading}>
            <Grid style={styles.mainGrid} className={classes.mainGrid} container direction='row' justify='flex-start' alignItems='flex-start' spacing={2}>
                <Grid style={styles.leftGrid} className={classes.leftGrid} container item direction='column' justify='flex-start' alignItems='flex-start' xs={4} sm={3} md={3} lg={3}>
                    <Grid container item justify='flex-start'>
                        <Grid container item xs={8} sm={9} md={9} lg={9} display='grid' justify='flex-start' alignItems='center'>
                            <SearchBar customStyle={classes.searchBar} placeholderText={'Find a data source'} setFilter={setFilter}/>
                        </Grid>
                        <Grid container item xs={4} sm={3} md={3} lg={3} display='grid' justify='center' alignItems='center'>
                            <Button variant='contained' color='primary' onClick={addNewDataSource}>
                                New
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item direction='row' justify='flex-start'>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <List className={classes.dataSourcesList}>
                                {createDataSourceListItems()}
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider orientation='vertical' flexItem />
                {Content}
            </Grid>
        </LoadingDiv>
    );
}
export default withStyles(dataSourcesClasses)(DataSources);