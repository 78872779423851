import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import {
    getNumericBrushedBounds,
    createValueScale,
    createXValueLogScale
} from '../../../charts/chartComponents/util';

import {
    ClipPath,
    YGridLines,
    XAxisGroup,
    YAxisGroup,
    XAxisLabel,
    YAxisLabel,
    NumericLineSeries,
    AreaSeries,
    SingleSeriesBar,
    CurveStep,
    AlertMarker,
    DottedNumericLineSeries,
    Tooltip
} from '../../../charts/chartComponents';

const DATA_THRESHOLD = 10000;

export default forwardRef((props, primaryChartRef) => {
    const tooltipRef = useRef(null);

    const clipPathId = 'clip' + Math.round((Math.random() * 1000000));

    let hiddenSeriesList = [];

    let { height, width, data, margin, extraData } = props;

    const innerWidth = width - margin.left - margin.right;

    const customTicksObj = extraData.find(e => e.name == "CustomTicks");
    const customTicks = customTicksObj ? customTicksObj.value.map(t => t.toString()) : null;

    const leftAxisLabel = (extraData.find(a => a.name == "LeftAxisLabel") || {}).value || '';
    const rightAxisLabel = (extraData.find(a => a.name == "RightAxisLabel") || {}).value || '';
    const xAxisLabel = (extraData.find(a => a.name == "XAxisLabel") || {}).value || '';

    // left y axis label placement
    const yOffset = 75;
    const xOffset = -(height - margin.top - margin.bottom) / 2;

    let scales = getScales();

    setFormattedData(false);

    function getScales() {
        let minValue = Infinity;
        let maxValue = -Infinity;
        if (data.find(d => d.Data)) {
            for (var series of data.filter(d => d.Data)) {
                try {
                    if (minValue == null || minValue > +series.Data[0].key) {
                        minValue = +series.Data[0].key;
                    }

                    if (maxValue == null || maxValue < +series.Data[series.Data.length - 1].key) {
                        maxValue = +series.Data[series.Data.length - 1].key;
                    }
                } catch {
                    // noop
                }
            }
        }

        let [minLeft, maxLeft, minRight, maxRight] = getNumericBrushedBounds(data, minValue, maxValue, 'Data');
        let leftBuffer = (maxLeft - minLeft) * 0.1;
        let rightBuffer = (maxRight - minRight) * 0.1;

        const xScale = createXValueLogScale(minValue, maxValue, margin, width, 12);
        const leftScale = createValueScale(minLeft - leftBuffer, maxLeft + leftBuffer, margin, height);
        const rightScale = createValueScale(minRight - rightBuffer, maxRight + rightBuffer, margin, height);
        const boolScale = createValueScale(0, 1, margin, height);

        return {
            xScale: xScale,
            leftScale,
            rightScale,
            boolScale,
            minLeft: leftScale(minLeft),
            minRight: rightScale(minRight),
            minBool: boolScale(0)
        };
    }

    const generateTooltipInfo = (chartObj, x0, mouseLocation) => {
        const style = `color: ${chartObj.Color}`;
        try {
            var value = chartObj.Data.find(d => +d.key == x0).value;
            return `<li><span style="${style}">${chartObj.Name} ${leftAxisLabel}: ${value}</span></li>`
        } catch (err) {
            return `<li><span style="${style}">${chartObj.Name} ${leftAxisLabel}: - </span></li>`
        }
    }

    function getTooltipData(svgRef) {
        let mouseLocation = d3.mouse(svgRef);
        let x0 = scales.xScale.invert(mouseLocation[0]);
        var ticks = customTicks ? customTicks : scales.xScale.ticks();

        let x = Infinity;
        let min = Infinity;
        
        if(ticks) {
            for(var d of ticks) {
                var diff = Math.abs(+d - x0);
                if(diff < min) {
                    min = diff;
                    x = +d;
                }
            }
        }

        const y1 = scales.boolScale(1);
        const y2 = scales.boolScale(0);

        let html = `<span>${xAxisLabel}: ${x} </span><ul style="list-style-type:none;padding:2px; margin:2px">`;
        data.filter(d => hiddenSeriesList.indexOf(d.Name) == -1).forEach(chartObj => {
            html = html + generateTooltipInfo(chartObj, x, mouseLocation)
        });
        html = html + "</ul>";

        return { html, xValue: scales.xScale(x), y1, y2 };
    }

    function setSimpleData() {
        setFormattedData(true);
        redraw();
    }

    function setFullData(providedHiddenSeriesList) {
        if (providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }
        setFormattedData(false);
        redraw();
    }

    function setFormattedData(brushing) {
        if (data) {
            data.map(series => {
                if (series.ChartType == 'line'
                    || series.ChartType == 'dottedLine'
                    || series.ChartType == 'curveStep'
                    || series.ChartType == 'area') {
                    if (brushing) {
                        series.formattedData = series.brushData;
                    }
                    else {
                        series.formattedData = series.Data;
                    }
                } else {
                    //Non-Linear values
                    series.formattedData = series._formattedData;
                }
            });
        }
    }

    function redrawChildren() {
        const { redrawFns } = primaryChartRef.current;
        redrawFns && redrawFns.forEach(fn => {
            fn(scales, hiddenSeriesList);
        });
    }

    // called by either the parent component or brush bar component
    // to update scales/bounds and redraw the appropriate child components
    // these changes are handled through d3 to avoid awkward react redraws
    function redraw(providedHiddenSeriesList) {
        if (providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }

        scales = getScales();

        redrawChildren();
    }

    function init() {
        primaryChartRef.current.setSimpleData = setSimpleData;
        primaryChartRef.current.setFullData = setFullData;
        primaryChartRef.current.redraw = redraw;
    }

    useEffect(init, [primaryChartRef])

    const setRef = node => {
        if (node) {
            primaryChartRef.current = node;
            primaryChartRef.current.redrawFns = [];
        }
    }

    const seriesList = data ? data.sort((a, b) => {
        if (a.Order > b.Order) return 1;
        if (a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = Math.random();
        if (s.ChartType == 'bar') {
            return (<SingleSeriesBar key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></SingleSeriesBar>);
        } else if (s.ChartType == 'dottedLine') {
            return (<DottedNumericLineSeries key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></DottedNumericLineSeries>);
        } else if (s.ChartType == 'line') {
            return (<NumericLineSeries key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></NumericLineSeries>);
        } else if (s.ChartType == 'curveStep') {
            return (<CurveStep key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></CurveStep>);
        } else if (s.ChartType == 'alert') {
            return (<AlertMarker key={key} clipPathId={clipPathId} innerWidth={innerWidth} scales={scales} key={s.Name} ref={primaryChartRef} series={s} {...props}></AlertMarker>);
        } else if (s.ChartType == 'area') {
            return (<AreaSeries key={key} clipPathId={clipPathId} innerWidth={innerWidth} areaHeight={props.height} scales={scales} key={s.Name} ref={primaryChartRef} series={s} dataProp="formattedData" {...props} ></AreaSeries>);
        } else {
            return (<></>);
        }
    }) : (<></>);

    return (
        <>
            <div ref={tooltipRef} className="tooltip" style={{ position: "absolute" }} />
            <svg width={props.width} height={props.height} >
                <g ref={setRef} width={props.width - margin.left} height={props.height - margin.top} transform={`translate(${margin.left}, ${margin.top})`} >
                    <ClipPath clipPathId={clipPathId} {...props}></ClipPath>
                    <YGridLines scales={scales} {...props} ref={primaryChartRef} scaleName={'leftScale'}></YGridLines>
                    <XAxisGroup scales={scales} {...props} ref={primaryChartRef} isNumeric={true} scaleName={"xScale"} customTicks={data.find(d => d.Data) ? customTicks : []}></XAxisGroup>
                    <XAxisLabel {...props} ref={primaryChartRef} y={props.height - margin.top - margin.bottom + 22} x={innerWidth/2} label={xAxisLabel}></XAxisLabel>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"leftScale"} handSide={"left"}></YAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"rightScale"} handSide={"right"} transform={`translate(${innerWidth},0)`}></YAxisGroup>
                    <YAxisLabel {...props} ref={primaryChartRef} y={-yOffset} x={xOffset} label={leftAxisLabel} handSide={"left"}></YAxisLabel>
                    <YAxisLabel {...props} ref={primaryChartRef} y={innerWidth + yOffset} x={xOffset} label={rightAxisLabel} handSide={"right"}></YAxisLabel>
                    {seriesList}
                    <Tooltip data={seriesList} clipPathId={clipPathId} scales={scales} yMin={scales.boolScale(0)} yMax={scales.boolScale(1)} yOffset={yOffset} {...props} ref={primaryChartRef} tooltipRef={tooltipRef} getTooltipData={getTooltipData}></Tooltip>
                </g>
            </svg>
        </>
    )
})
