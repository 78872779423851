import React from 'react'
import { Button, Checkbox, Input, Modal } from 'rsuite'
import { ExplorerContext } from '../../../contexts/ExplorerContext'

const CreateViewModal = (props) => {

    const { open, setOpen, seriesObjects, properties } = props
    const explorerContext = React.useContext(ExplorerContext)
    const { getChartObject, openNotification } = explorerContext
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let viewName = null 
    let assetIndependant = Object.keys(seriesObjects).length === 1 ? true : false
    let disabled = Object.keys(seriesObjects).length > 1

    const onConfirm = () => {
        if (viewName === '') { openNotification('error', 'save'); return }
        let chartObject = getChartObject(viewName, seriesObjects, properties, { assetIndependant })
        let views = JSON.parse(localStorage.getItem('views')) || {}
        views[viewName] = chartObject
        localStorage.setItem('views', JSON.stringify(views))
        openNotification('success', 'save')
        handleClose()
    }

    return (
        
        <Modal
            open={open}
            onClose={handleClose}
            backdrop={true}
        >

            <Modal.Header>
                <Modal.Title> Create view </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <Input style={{ margin: '1rem 0 0 0'}} placeholder='view name...' onChange={(value) => { viewName = value }}/>
                    <span style={{ margin: '1rem 0rem 1rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        Asset independant 
                        <Checkbox defaultChecked={assetIndependant} disabled={disabled} onChange={(value) => { assetIndependant = value }}> </Checkbox>
                    </span>
                    <span style={{fontWeight: 600}}> Are you sure you would like to save this view? </span>
                    <span style={{ height: '.4rem', backgroundColor: '#7373b9', margin: '2rem 3rem 1rem 3rem'}}/> 
                </span>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onConfirm} appearance="primary">
                    Confirm
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>

      </Modal>
    )
}

export default CreateViewModal


