import React from 'react';
import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import { getTenants, getCsoDischargeAutomatedPublishing } from '../api/ApiWorker';
import { PIPECAST_DATA_ADMIN } from '../constants/AuthorizationConstants';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../constants/TimezoneConstants';

export const UserContext = React.createContext(null);

const initialState = {
    currentUser: null,
    token: null,
    authenticated: false,
    initialized: false,
    users: [],
    tenant: null,
    tenants: [],
    csoAutomatedPublishing: null
};

class UserContextProvider extends React.Component {
    constructor(props) {
        super(props);

        const { keycloak } = props;
        if (!keycloak) {
            throw new Error("KeycloakProvider requires 'keycloak' prop to be defined");
        }

        this.state = {
            ...initialState
        };

        this.updateValue = (name, value) => {
            var tempVal = this.state;
            tempVal[name] = value;
            this.setState(tempVal);
            this.forceUpdate()
        }
    }

    componentDidMount() {
      this._isMounted = true;
      this.init();
    }
  
    componentWillUnmount() {
      this._isMounted = false;
    }

    async componentDidUpdate({ keycloak: prevKeycloak }) {

      if (this.props.keycloak !== prevKeycloak) {
        // De-init previous Keycloak instance
        prevKeycloak.onReady = null;
        prevKeycloak.onAuthSuccess = null;
        prevKeycloak.onAuthError = null;
        prevKeycloak.onAuthRefreshSuccess = null;
        prevKeycloak.onAuthRefreshError = null;
        prevKeycloak.onAuthLogout = null;
        prevKeycloak.onTokenExpired = null;
  
        // Reset state
        this.setState({ ...initialState });
        // Init new Keycloak instance
        this.init();
      }
    }

    loadCsoAutomatedPublishing = (tenantName) => {
      getCsoDischargeAutomatedPublishing(tenantName)
        .then((res) => {
          if (res.ok) return res.json()
        })
        .then((data) => {
          console.log(data)
          this.updateValue('csoAutomatedPublishing', data)
        })
        .catch(() => {})
        .finally(() => {})
    }

    // Actual tenants (not the tenantIDs) are stored in this.state.users, this returns the actual Tenant,
    // as opposed to just the tenantIDs that the keycloak token is using to update state.
    getTenant = () => {
        const user = this.state.users.find(t => t.id == this.state.tenant);
        return user;
    }

    setTimezone = tenant => {
        if(tenant && tenant.timezone) {
            // this is the case for timezone being defined on the Model's class
            sessionStorage.setItem(TENANT_TIMEZONE, tenant.timezone);
        } else if(tenant && tenant.extraData && tenant.extraData.Timezone) {
            // this is the case for timezone not being defined on the Model's class; just in case!
            sessionStorage.setItem(TENANT_TIMEZONE, tenant.extraData.Timezone);
        } else {
            sessionStorage.setItem(TENANT_TIMEZONE, moment.tz.guess());
        }
    }

    switchTenant = async (newTenant) => {
        const tenant = !_.isEmpty(this.state.users) ? this.state.users.find(x => x.id == newTenant) : null;
        this.setTimezone(tenant);

        sessionStorage.setItem('tenant', newTenant);
        this.setState({
            tenant: newTenant
        }, () => {
            this.props.routeContext.updateValues({assetName: null})
            this.loadCsoAutomatedPublishing(tenant.tenantName)
        })
    }

    updateUser = async () => {
        const { keycloak, routeContext } = this.props;
        const { initialized: prevInitialized, token: prevToken } = this.state;
        const { idToken, refreshToken, token: newToken } = keycloak;

        let {users, tenant, tenants, tokenParsed} = this.state;

        // Avoid double-refresh if state hasn't changed
        if (!prevInitialized || newToken !== prevToken) {
            if(newToken !== prevToken) {
                if(newToken && !_.isEmpty(newToken)) {
                    localStorage.setItem('kcTokens', JSON.stringify({
                        idToken: keycloak.idToken,
                        refreshToken: keycloak.refreshToken,
                        token: newToken
                    }));
                } else {
                    localStorage.removeItem('kcTokens');
                }
            }
            if(keycloak && keycloak.authenticated) {
                if(keycloak.tokenParsed) {
                    users = _.isEmpty(users) ? await getTenants() : users;
                    const urlTenant = !_.isEmpty(users) ? users.find(x => window.location.href.includes(encodeURI(x.tenantName))) : null;
                    tenants = _.isEmpty(tenants) ? keycloak.tokenParsed.tenants : tenants;
                    tenant = _.isEmpty(tenant) ? (urlTenant ? urlTenant.id : tenants[0]) : tenant;
                    tokenParsed = keycloak.tokenParsed;
                    if(urlTenant && urlTenant.Timezone) {
                        sessionStorage.setItem(TENANT_TIMEZONE, urlTenant.Timezone);
                    } else if(urlTenant && urlTenant.extraData && urlTenant.extraData.Timezone) {
                        sessionStorage.setItem(TENANT_TIMEZONE, urlTenant.extraData.Timezone);
                    } else {
                        sessionStorage.setItem(TENANT_TIMEZONE, moment.tz.guess());
                    }
                    this.setTimezone(urlTenant);
                    sessionStorage.setItem('tenant', tenant);
                }
            }
            this.setState({
                initialized: true,
                token: newToken,
                tokenParsed: tokenParsed,
                currentUser: keycloak,
                users: users,
                tenant: tenant,
                tenants: tenants,
                authenticated: keycloak.authenticated
            });
            const fullTenant = !_.isEmpty(this.state.users) ? this.state.users.find(x => x.id == tenant) : null;
            this.loadCsoAutomatedPublishing(fullTenant.tenantName)
        }
    }

    isDataAdmin = () => {
        try {
            let roles = this.state.tokenParsed.resource_access[this.getFormattedTenantName()].roles;
            return roles.includes(PIPECAST_DATA_ADMIN);
        } catch {
            return false;
        }
    }

    //Given a tenant name, format it in the same structure as the token (?)
    getFormattedTenantName = () => {
        return this.getTenant().tenantName.toLowerCase().replace(' ', '-');
    }

    refreshKeycloakToken = () => {
        const { keycloak } = this.props;

        // Refresh Keycloak token
        keycloak.updateToken();
    };

    onKeycloakError = error => {
        localStorage.removeItem('kcTokens');
        console.error(error);
    };

    logout = () => {
        this.props.keycloak.logout();
    }

    init = () => {
            const { initConfig, keycloak } = this.props;
            // .onReady is called when the adapter is initialized
            // this.updateUser makes an API call to getTenants()
            // If an API call is made during when the adapter is initialized (but not authenticated)
            // the API call will fail.
            //keycloak.onReady = this.updateUser;
            keycloak.onAuthSuccess = this.updateUser;
            keycloak.onAuthError = this.onKeycloakError;
            keycloak.onAuthRefreshSuccess = this.updateUser;
            keycloak.onAuthRefreshError = this.onKeycloakError;
            keycloak.onAuthLogout = this.updateUser;
            keycloak.onTokenExpired = this.refreshKeycloakToken;

            const tokens = JSON.parse(localStorage.getItem('kcTokens') || '{}');

            if(tokens && tokens.token) {
                const token = jwtDecode(tokens.token);
                var current_time = Date.now() / 1000;
                if ( token.exp < current_time) {
                    tokens.token = undefined;
                    tokens.idToken = undefined;
                }
            }
            if(tokens && tokens.refreshToken) {
                const token = jwtDecode(tokens.refreshToken);
                var current_time = Date.now() / 1000;
                if ( token.exp < current_time) {
                    tokens.refreshToken = undefined;
                }
            }

            keycloak.init({...initConfig, ...tokens});
    }

    render() {

        console.log(this.state.csoAutomatedPublishing)
        return (
            <UserContext.Provider value={{
                state: this.state,
                currentUser: this.state.currentUser,
                token: this.state.token,
                tenant: this.state.tenant,
                tenants: this.state.tenants,
                users: this.state.users,
                switchTenant: this.switchTenant,
                getTenant: this.getTenant,
                updateUser : this.updateUser,
                updateValue: this.updateValue,
                authenticated: this.state.authenticated,
                isDataAdmin: this.isDataAdmin,
                logout: this.logout,
                csoAutomatedPublishing: this.state.csoAutomatedPublishing,
                loadCsoAutomatedPublishing: this.loadCsoAutomatedPublishing
            }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export default UserContextProvider;