import React, { useContext } from 'react';
import { SnackbarContext } from '../contexts/SnackbarContext';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { amber } from '@material-ui/core/colors';

const severityIcons = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};
const styles = theme => ({
    success: {
        backgroundColor: theme.palette.primary.main,
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    severityIcon: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    }
});

const PipecastSnackbar = (props) => {
    var { classes } = props;
    const snackbarContext = useContext(SnackbarContext);

    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        snackbarContext.closeMessage();
    }

    var content;
    if (snackbarContext.message) {
        var SeverityIcon = severityIcons[snackbarContext.message.severity];

        var actions = [];
        // ************* NOTE ****************
        // this commented code adds button support, but doesn't work at the moment.
        // it is being left here because it should be used eventually, probably
        // if (snackbarContext.message.buttonParams) {
        //     actions.push(
        //         <Button key="button" color={snackbarContext.message.buttonParams.color} size="small" onClick={snackbarContext.message.buttonParams.callback}>
        //             {snackbarContext.message.buttonParams.text}
        //         </Button>
        //     );
        // }
        actions.push(
            <IconButton key="close" aria-label="close" color="inherit" onClick={snackbarContext.closeMessage}>
                <CloseIcon className={classes.icon} />
            </IconButton>
        );
        content = (
            <SnackbarContent
                className={classes[snackbarContext.message.severity]}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <SeverityIcon className={clsx(classes.icon, classes.severityIcon)} />
                        {snackbarContext.message.text}
                    </span>
                }
                action={actions}
            />
        );
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            key={content}
            open={snackbarContext.open}
            autoHideDuration={snackbarContext.autoHideDuration}
            transitionDuration={{ exit: snackbarContext.closeTransitionDuration }}
            onClose={handleClose}
        >
            {content}
        </Snackbar>
    );
}

export default withStyles(styles)(PipecastSnackbar);