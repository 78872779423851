import React from 'react';
import InteractiveMap from '../map/InteractiveMap';
import GeolocatControls from '../map/GeolocateControl';
import NavigationControls from '../map/NavigationControls';
import _ from "lodash";
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import "../../css/DashboardMap.css";
import MapControls from '../map/MapControls';
import { withRouter } from 'react-router-dom';
import jsPDF from 'jspdf';

const sourceId = 'stations'; //<-- Defined in mongodb for the Map card. Defines styling instead of using model layers

class PrecipiCASTMap extends React.Component {
    
    constructor(props) 
    {
        super(props);

        this.state = {
            showSystem: false,
            showModelSystem: false,
            isPrinting: false,
            layers: {},
            viewType: "Map" //Default view
        }

        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.sources = {};
        this.layers = [];
        this.mapLoaded = false;
        this.assets = null;
        this.layerCtx = { abort: false, complete: false};
    }

    addFeatures = () => {
        const geoJson = this.props.dashboardAssets.map(x => {
            let id = x.id;
            x = x.geoJsonFeature;
            x.properties.tempId = id;
            return x;
        });

        this.map.map.getSource(sourceId).setData({
            "type": "FeatureCollection",
            "features": geoJson
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedItem, dashboardAssets, filteredDashboardAssets} = this.props;

        if (!_.isEqual(selectedItem, prevProps.selectedItem)) {
            this.setIsSelected(selectedItem);
        }

        if (!_.isEmpty(dashboardAssets) && !_.isEqual(dashboardAssets, prevProps.dashboardAssets)) {
            const addFeatures = this.addFeatures.bind(this);
            this.map.map.once('idle', addFeatures);
        }

        if (!_.isEqual(filteredDashboardAssets, prevProps.filteredDashboardAssets)) {
            let visibleAssetIds = filteredDashboardAssets.map(x => x.id);
            this.map.map.once('idle', () => {
                this.map.map.setFilter(sourceId, ['in', 'tempId', ...visibleAssetIds]);
            })
        }
    }

    setIsSelected = (selectedItem) => {
        if (!_.isEmpty(selectedItem)) {
            this.map.map.flyTo({ 
                center: [selectedItem.longitude, selectedItem.latitude],
                zoom: 7
            });
        } else {
            const {center, zoom} = this.props.options;
            this.map.map.flyTo({
                center: [center.lng, center.lat],
                zoom: zoom
            });
        }

        //Update source data with isSelected
        const source = this.map.map.getSource(sourceId);

        if (source && !_.isEmpty(source._data)) {
            source._data.features.forEach(f => {
                if(selectedItem && _.isEqual(selectedItem.id, f.properties.tempId)) {
                    f.properties.isSelected = '1';
                } else {
                    f.properties.isSelected = '0';
                }
            });
            source.setData(source._data);
        } 
    }

    handleClick = (e) => {
        const bbox = [[e.point.x - 3, e.point.y - 3], [e.point.x + 3, e.point.y + 3]];
        const selectedFeatures = this.map.map.queryRenderedFeatures(bbox, {
            layers: [sourceId]
            });

        if (!_.isEmpty(selectedFeatures)) {
            let newFeature = this.props.dashboardAssets.find(x => _.isEqual(x.geoJsonFeature.properties.name, selectedFeatures[0].properties.name));
            if (!_.isEqual(newFeature, this.props.selectedItem)) {
                this.props.setSelectedItem(newFeature);
            } else {
                this.props.setSelectedItem(null);
            }
        }
    }

    handleMouseMove = (e) => {

    }

    toggleViewType = (viewType) => 
    {
        this.setState({viewType: viewType});
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }



    render() {
        const component = this;
        return (            
            <MapContextProvider>
                <MapContext.Consumer>
                    {(mapContext) => {
                        return(
                            <>
                                <InteractiveMap {...component.props} viewType={component.state.viewType}
                                    ref={(element) => {
                                        component.map = element; 
                                        mapContext.updateMap(element);
                                    }}
                                    mapContext={mapContext}
                                    layers={this.layers}
                                    selectableLayers={Object.keys(this.layers)}
                                    handleMouseMove={component.handleMouseMove}
                                    handleClick={component.handleClick}
                                    selectedItem={this.props.selectedItem} 
                                    updateSelectedItem={() => {alert('updateSelectedItem')}}>
                                
                                    { <GeolocatControls /> && <NavigationControls /> }

                                </InteractiveMap>

                                <MapControls
                                    hideLayerLegend={true}
                                    hideNetworkTracing={true}
                                    viewType={component.state.viewType}
                                    toggleViewType={component.toggleViewType}
                                    handlePrintClick={component.handlePrintClick}>
                                </MapControls>
                            </>
                        );
                    }}
                </MapContext.Consumer>
            </MapContextProvider>
        )
    }
}

export default withRouter(PrecipiCASTMap);