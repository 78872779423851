import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import {getAlerts, getEvents} from '../api/ApiWorker';
import { TENANT_TIMEZONE } from '../constants/TimezoneConstants';

export const AlertContext = React.createContext(null);

const initialSettings = {
    alertTypes: null,
    events: null,
    selectedEvent: null,
    isLoadingEvents: true
};

class AlertContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialSettings
        };
    }

    setSelectedEvent = evt => {
        this.updateValues({ selectedEvent: evt });
    }

    updateValues = async toUpdate => {
        const nextState = {};
        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && this.state[key] != value) {
                nextState[key] = value;
            }
        }

        this.setState(nextState);
    }

    async getAlerts() {
        const tenant = this.props.users.find(u => u.id == sessionStorage.getItem('tenant'));

        const alerts = await getAlerts(tenant.tenantName); 
        var alertTypes = (Object.keys(alerts).length === 0 && alerts.constructor === Object) ? [] : alerts;
        alertTypes.sort((one, two) => one.severity - two.severity);

        this.setState({
            alertTypes
        });
    }

    async getEvents() {
        const tenant = this.props.users.find(u => u.id == sessionStorage.getItem('tenant'));
        const start = moment.tz(this.props.date.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        const end = moment.tz(this.props.date.realEndDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        let events = await getEvents(tenant.tenantName, start, end);

        events = (Object.keys(events).length === 0 && events.constructor === Object) ? [] : events;

        this.setState({
            events,
            isLoadingEvents: false
        });
    }

    componentDidMount() {
        const props = this.props;
        
        this.getAlerts();

        if (props.date && props.date.startDate && props.date.endDate) {
            this.setState({ isLoadingEvents: true });
            this.getEvents();
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (((prevProps.date.startDate != this.props.date.startDate) || (prevProps.date.endDate != this.props.date.endDate)) ) {
            this.setState({ isLoadingEvents: true });
            this.getEvents();
        }
        if (this.props.tenant && !_.isEqual(this.props.tenant, prevProps.tenant)) {
            this.setState({ isLoadingEvents: true });
            this.getAlerts();
            this.getEvents();
        }
    }

    render() {
        return (
            <AlertContext.Provider value={{
                state: this.state,
                updateValues: this.updateValues,
                setSelectedEvent: this.setSelectedEvent
            }}>
                {this.props.children}
            </AlertContext.Provider>
        )
    }
}

export default AlertContextProvider;