import React, { createContext, useCallback, useReducer } from 'react';
import _ from 'lodash';
import { duration } from '@material-ui/core/styles/transitions';
import { v4 as uuidv4 } from 'uuid';

export const SnackbarContext = createContext();

const validSeverities = ["info", "success", "warning", "error"];
const defaultSeverity = "info";

const autoHideDuration = 5000;
const betweenMessagesDuration = duration.leavingScreen + 150;
const ignoreMessageDuration = 10000;
var componentLoggingEnabled = false;

const actionTypes = {
    add: 0,
    remove: 1,
    close: 2,
    removeMessageToIgnore: 3,
    closeAndDisplayNext: 4,
    clearMessageAfterCloseTransition: 5
}

const displayStates = {
    open: 0,
    closing: 1,
    closed: 2
}

const updateFlags = {
    displayNext: 0,
    closeCurrent: 1,
    doNothing: 2
}

const initialState = {
    open: false,
    message: null,
    messages: [],
    messagesToIgnore: [],
    setMessageNull: false,
    displayState: displayStates.closed
};

function log(message) {
    if (componentLoggingEnabled) {
        console.log(message);
    }
}

/*
    action.dispatch: always present, callback to this reducer method

    action.type == actionTypes.add
        action.message: {
            key: generated key for the message
            text: string with the text of the message to add
            severity?: string with the severity of the message to add
                limited by validSeverities
                defaults to defaultSeverity
            buttonParams?: {
                text: text of the button to display
                callback: callback when the button is clicked
                color?: color of the button
                    defaults to inherit
            }
        }

    action.type == actionTypes.remove
        action.key: key of the message to remove

    action.type == actionTypes.close

    action.type == actionTypes.removeMessageToIgnore
        action.text: string with the text of the message to remove from messagesToIgnore

    action.type == actionTypes.update

    action.type == actionTypes.clearMessageAfterCloseTransition
*/
function reducer(state, action) {
    var updateFlag;
    var postActionState = {};

    switch (action.type) {
        case actionTypes.add:
            log(`add - new message key created - key: ${action.message.key} message: ${action.message.text}`);
            // check that the message is valid
            if (typeof (action.message.text) == "string" && action.message.text != "" && !state.messagesToIgnore.includes(action.message.text)) {
                // if the severity isn't set or isn't in the valid set, set to default
                var severity = action.message.severity;
                if (!severity || !validSeverities.includes(severity)) {
                    severity = defaultSeverity;
                }

                // check the validity of the button params
                var buttonParams = action.message.buttonParams;
                if (action.message.buttonParams && (!action.message.buttonParams.text || !action.message.buttonParams.callback)) {
                    log(`add - button was requested but formatted incorrectly - key: ${action.message.key}`);
                    buttonParams = null;
                }
                if (buttonParams && !buttonParams.color) {
                    buttonParams.color = "inherit";
                }

                // add the new message to the array
                var messagesCopy = state.messages.slice();
                messagesCopy.push({ key: action.message.key, text: action.message.text, severity: severity, buttonParams: buttonParams });
                postActionState.messages = messagesCopy;
                log(`add - new message created, add to messages - key: ${action.message.key} message: ${action.message.text}`);
                log("new messages array:");
                log(messagesCopy);

                // add the new message to the ignore array
                var messagesToIgnoreCopy = state.messagesToIgnore.slice();
                messagesToIgnoreCopy.push(action.message.text);
                postActionState.messagesToIgnore = messagesToIgnoreCopy;
                log(`add - add message to messagesToIgnore: ${action.message.text}`);
                log("new messagesToIgnore array:");
                log(messagesToIgnoreCopy);

                // set a timeout to remove the message from the ignore array
                setTimeout(() => {
                    log(`add - dispatch actionTypes.removeMessageToIgnore: ${actionTypes.removeMessageToIgnore}`)
                    action.dispatch({ dispatch: action.dispatch, type: actionTypes.removeMessageToIgnore, text: action.message.text });
                }, ignoreMessageDuration);

                // call update after the state is updated
                // set the flag to handle race conditions
                log("add - set setMessageNull false");
                postActionState.setMessageNull = false;
                updateFlag = updateFlags.displayNext;
            } else {
                log(`add - ignored due to invalid message text or in messagesToIgnore: ${action.message.text}`);
                log("messagesToIgnore array:");
                log(state.messagesToIgnore);

                updateFlag = updateFlags.doNothing;
            }
            break;
        case actionTypes.remove:
            if (state.message && action.key == state.message.key) {
                // if the current message is the one with the key, remove it
                log(`remove - key matches current - text: ${action.text}`);
                updateFlag = updateFlags.closeCurrent;
            } else {
                // else, find the key in the messages queue
                log(`remove - key does not match current, try to remove from messages - key: ${action.key}`);
                var messageWithKey;
                for (var i = 0; i < state.messages.length; i++) {
                    if (state.messages[i].key == action.key) {
                        messageWithKey = state.messages[i];
                        break;
                    }
                }

                // if it's there, remove it
                if (messageWithKey) {
                    log(`remove - key found in messages and removed - key: ${action.key}`);
                    var messagesCopy = state.messages.slice();
                    var index = messagesCopy.indexOf(messageWithKey);
                    messagesCopy.splice(index, 1);
                    postActionState.messages = messagesCopy;
                } else {
                    log(`remove - key not found in messages - key: ${action.key}`);
                    log("messages array:")
                    log(state.messages);
                }

                updateFlag = updateFlags.doNothing;
            }
            break;
        case actionTypes.close:
            log("close");
            updateFlag = updateFlags.closeCurrent;
            break;
        case actionTypes.removeMessageToIgnore:
            log(`removeMessageToIgnore - remove from messagesToIgnore: ${action.text}`);
            var messagesToIgnoreCopy = state.messagesToIgnore.slice();
            var index = messagesToIgnoreCopy.indexOf(action.text);
            if (index >= 0) {
                messagesToIgnoreCopy.splice(index, 1);
                postActionState.messagesToIgnore = messagesToIgnoreCopy;
                log(messagesToIgnoreCopy);
            }
            updateFlag = updateFlags.doNothing;
            break;
        case actionTypes.closeAndDisplayNext:
            log(`closeAndDisplayNext - set displayState to displayStates.closed: ${displayStates.closed}`);
            postActionState.displayState = displayStates.closed;
            updateFlag = updateFlags.displayNext;
            break;
        case actionTypes.clearMessageAfterCloseTransition:
            log(`clearMessageAfterCloseTransition - set displayState to displayStates.closed: ${displayStates.closed}`);
            postActionState.displayState = displayStates.closed;
            if (state.setMessageNull) {
                // if it's still supposed to, then do it
                log(`clearMessageAfterCloseTransition - set displayState to displayStates.closed: ${displayStates.closed}`);
                postActionState.displayState = displayStates.closed;
                log("clearMessageAfterCloseTransition - set setMessageNull to false");
                postActionState.setMessageNull = false;
                log("clearMessageAfterCloseTransition - set message to null");
                postActionState.message = null;
                updateFlag = updateFlags.doNothing;
            } else {
                // another message was added and should be displayed instead
                updateFlag = updateFlags.displayNext;
            }
            break;
        default:
            throw new Error();
    }

    log(`update - flag: ${updateFlag}`);
    postActionState = { ...state, ...postActionState };
    if (updateFlag == updateFlags.doNothing) {
        log("update - do nothing");
        return postActionState;
    }

    // if there is already a message displayed, don't do anything
    // that message's onclose will trigger update to display the next
    if ((postActionState.displayState == displayStates.open || postActionState.displayState == displayStates.closing) && updateFlag == updateFlags.displayNext) {
        log("update - don't update, currently visible and told to display next");
        return postActionState;
    }

    var postUpdateState = {};
    // if there are no messages, display nothing
    if (_.isEmpty(postActionState.messages)) {
        log("update - messages empty");
        // set a flag first to handle race conditions
        log("update - messages empty - set setMessageNull to true");
        postUpdateState.setMessageNull = true;
        log(`update - messages empty - set displayState to displayStates.closing: ${displayStates.closing}`);
        postUpdateState.displayState = displayStates.closing;
        log("update - messages empty - set open to false")
        postUpdateState.open = false;

        // do this after a timeout because if message is set to null then the
        // snackbar displays a black empty box for the transition period
        setTimeout(() => {
            log(`update - messages empty - dispatch actionTypes.clearMessageAfterCloseTransition: ${actionTypes.clearMessageAfterCloseTransition}`)
            action.dispatch({ dispatch: action.dispatch, type: actionTypes.clearMessageAfterCloseTransition });
        }, duration.leavingScreen + 5);

        return { ...postActionState, ...postUpdateState };
    }

    // if it's closed, display immediately
    // otherwise close the current one and display after the fade transition
    if (postActionState.displayState == displayStates.closed) {
        log("update - display next");
        var messagesCopy = postActionState.messages.slice();
        postUpdateState.message = messagesCopy.shift();
        postUpdateState.messages = messagesCopy;
        log(`update - display next - set displayState to displayStates.open: ${displayStates.open}`);
        postUpdateState.displayState = displayStates.open;
        log("update - display next - set open to true")
        postUpdateState.open = true;
    } else {
        // set open to false, which will trigger the fade away transition, then
        // display the next message
        log("update - display next after animation");
        log(`update - display next after animation - set displayState to displayStates.closing: ${displayStates.closing}`);
        postUpdateState.displayState = displayStates.closing;
        log("update - display next after animation - set open to false")
        postUpdateState.open = false;

        setTimeout(() => {
            log(`update - display next after animation - dispatch actionTypes.closeAndDisplayNext: ${actionTypes.closeAndDisplayNext}`)
            action.dispatch({ dispatch: action.dispatch, type: actionTypes.closeAndDisplayNext });
        }, betweenMessagesDuration);
    }

    return { ...postActionState, ...postUpdateState };
}

function SnackbarContextProvider(props) {
    const { loggingEnabled } = props;
    componentLoggingEnabled |= loggingEnabled;

    const [state, dispatch] = useReducer(reducer, initialState);
    const addMessageCallback = useCallback((text, severity, buttonParams) => {
        var key = uuidv4();
        dispatch({ dispatch, type: actionTypes.add, message: { key, text, severity, buttonParams } });
        return key;
    }, []);
    const removeMessageCallback = useCallback((key) => dispatch({ dispatch, type: actionTypes.remove, key }), []);
    const closeMessageCallback = useCallback(() => dispatch({ dispatch, type: actionTypes.close }), []);

    return (
        <SnackbarContext.Provider value={{
            autoHideDuration,
            closeTransitionDuration: duration.leavingScreen,
            open: state.open,
            message: state.message,

            addMessage: addMessageCallback,
            removeMessage: removeMessageCallback,
            closeMessage: closeMessageCallback
        }}>
            {props.children}
        </SnackbarContext.Provider>
    );
}

export default SnackbarContextProvider;