import React, {useContext, useEffect, useState } from 'react'
import { Input, Loader, SelectPicker, Tree, IconButton } from 'rsuite'
import { ExplorerContext } from '../../contexts/ExplorerContext'
import { UserContext } from '../../contexts/UserContext'
import SplitIcon from '@rsuite/icons/Split'
import { getAssets, getHeaders } from '../../api/ApiWorker'

const TOC = (props) => {

    const explorerContext = useContext(ExplorerContext)
    const userContext = useContext(UserContext)

    const { getAttributes, getIcon, addChart, defaultModel, models } = explorerContext
    const { tenant } = userContext

    const [searchedNodes, setSearchedNodes] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [treeData, setTreeData] = useState(null)
    const [model, setModel] = useState(defaultModel)
    const [layers, setLayers] = useState([])
    const [layer, setLayer] = useState({})
    const [assets, setAssets] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setModel(defaultModel)
    }, [defaultModel])


    useEffect(() => {
        if (Object.keys(model).length === 0) return
        let newLayers = model.layers
        setLayers(newLayers)
    }, [model])

    useEffect(() => {
        if (layers.length === 0) return
        let newLayer = layers[0]
        setLayer(newLayer)
    }, [layers])

    useEffect(() => {
        const loadAssets = async () => {
            if (Object.keys(layer).length === 0 || Object.keys(model).length === 0) return
            setLoading(true)
            let res = await fetch(`https://app.stg.pipecast.io/pipecast/api/assets/features?tenantId=${tenant}&layerName=${layer.collectionName}`, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
            })

            if (res.ok) {
                let data = await res.json()
                if (data && data.length !== 0) {
                    console.log(data)

                    let sortedData = data.sort((a,b) => { 
                      if (a.properties[layer.displayField] > b.properties[layer.displayField]) return 1
                      if (b.properties[layer.displayField] > a.properties[layer.displayField]) return -1
                      return 0
                    })

                    console.log(sortedData)
                    setAssets(sortedData)
                } else {
                    setAssets([])
                }
            } else {
                throw new Error(res.statusText);
            }
            setLoading(false)
        }
        loadAssets()
    }, [layer])

    useEffect(() => {

        // transform assets to treeData
        const assetsToTreeData = () => {

            if (!assets || assets.length === 0) return

            let attributes = getAttributes(layer)

            let data = []
            assets.forEach((asset, i) => {

                let name = asset.properties[layer.displayField]
                
                data.push({
                    label: <span key={name} style={{fontSize: '18px', fontWeight: 900, whiteSpace: 'nowrap', textOverflow: 'ellipsis'}} >{name}</span>,
                    value: i,
                    data: {
                        asset: name,
                        layer: layer.layerName,
                        attribute: 'all',
                        model: model.name,
                    },
                    children: attributes.map((attribute, j) => {
                        return {
                            label: (
                                <span key={attribute} style={{ color: 'gray', fontWeight: 700, display: 'flex', flexDirection: 'row', alignItems: 'center', whiteSpace: 'nowrap' }}> 
                                    <img style={{height: '16px', width: '16px', marginRight: '1rem'}} src={getIcon(attribute)} alt='' />
                                    {attribute} 
                                </span> 
                            ),
                            value: i + '-' + j,
                            data: {
                                asset: name,
                                layer: layer.layerName,
                                attribute: attribute,
                                model: model.name,
                            }
                        }
                    })
                })
            })
            
            return data
        }

        assets && setTreeData(assetsToTreeData())

    }, [assets])

    // dragstart handling
    const onDragStart = (node, e) => {
        let data = node.data
        e.dataTransfer.setData('foo', JSON.stringify(data));
    }

    const onSearchChange = (search) => { // searching thru second layer... at the moment that is assets

        let searched = []
        search !== '' && Object.keys(treeData).forEach((key) => {
            let item = treeData[key]
            if (item.label.key.toLowerCase().indexOf(search.toLowerCase()) > -1) {
                searched.indexOf(item.value) === -1 && searched.push(item.value)
            }
            return null;
        })
        //searched.filter((item, i, self) => item && self.indexOf(item) === i);
        setSearchValue(search)
        setSearchedNodes(searched)
    }
    
    const onModelChange = (value) => {
        let newModel = models.find((model) => model.name === value)
        newModel && setModel(newModel)
    }

    const onLayerChange = (value) => {
        let newLayer = layers.find((layer) => layer.layerName === value)
        newLayer && setLayer(newLayer)
    }

    const onlySearched = () => {

        let filteredTreeData = searchValue === '' ? treeData : treeData.filter((item) => {
            return (searchedNodes.indexOf(item.value) > -1 || item.label.key.toLowerCase().includes(searchValue.toLowerCase()))
        }) 
        return filteredTreeData
    }

    return (
        <span style={{display: 'flex', flexDirection: 'column', height: 'calc(100% -  2rem)'}}>
            <span style={{padding: '1rem 1rem 0rem 1rem', display: 'flex', flexDirection: 'column', width: '100%', marginTop: '1rem', gap: '1rem'}}>
                    <SelectPicker
                        loading={loading}
                        searchable={false}
                        cleanable={false}
                        value={model ? model.name : null}
                        onChange={onModelChange}
                        data={ models && models.map((model, i) => {
                            return { 
                                label: model.name, 
                                value: model.name
                            }   
                        })}
                        style={{
                            color: '#807f96',
                            flexGrow: 1,
                        }}
                    />
                    <SelectPicker
                        loading={loading}
                        value={layer ? layer.layerName: null}
                        onChange={onLayerChange} 
                        searchable={false}
                        cleanable={false}
                        data={ layers && layers.map((layer) => {
                            return {
                                label: layer.layerName,
                                value: layer.layerName
                            }
                        })}
                    />
                    <Input
                        placeholder="Search"
                        onChange={onSearchChange}
                    />
            </span>
            <span style={{overflow: 'auto', backgroundColor: 'lightgray', height: '100%', padding: '2rem 1rem 2rem 1rem'}}>
                { loading && <Loader/> }
                { treeData && !loading && 
                    <Tree
                        expandedItemValues={searchedNodes}
                        draggable
                        data={onlySearched()}
                        showIndentLine
                        onDragStart={onDragStart}
                        style={{
                            height: '100%',
                            maxHeight: '100%'
                        }}
                    />
                }
            </span>
            <span style={{display: 'flex', flexDirection: 'row', width: '100%', height: '2rem', backgroundColor: {}, gap: '1rem', padding: '0 1rem', justifyContent: 'flex-end', alignItems: 'center'}}>
                <IconButton onClick={addChart} icon={<SplitIcon color='white'/>} size="xs" color='violet' appearance="primary"/>
            </span>
        </span>
    )
}

export default TOC

