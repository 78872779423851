import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import _ from "lodash";
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import withQuery from './withQuery';
import withTenantName from './withTenantName';

const LinkWithTenantNameWithQuery = withQuery(withTenantName(Link));

const styles = theme => ({
    root: {
        position: "absolute",
        height: "100vh",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
});

const NotFound = (props) => {

    const {classes, theme, menuContext, defaultDashboard, newSideMenu} = props;
    const {collapsedDrawerWidth} = menuContext;

    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

    return (
        <Paper 
            elevation={2} 
            style={{ width: '40vw', marginLeft: drawerWidth + 'px'}} 
            className={classes.root}>

            <Typography variant='h5' style={{color: theme.getColor('primary.Primary'), textAlign: 'center', verticalAlign: 'middle', marginTop: '64px', marginLeft: '50px', marginRight: '50px'}}>
                <span> {`Sorry! Something went wrong while fetching your data.`} </span>
            </Typography>

            <Typography variant='h5' style={{color: theme.getColor('primary.Primary'), textAlign: 'center', verticalAlign: 'middle', marginTop: '32px', marginLeft: '50px', marginRight: '50px'}}>
                <span> {`Please use the side menu or click`}
                    <LinkWithTenantNameWithQuery key={Math.random()} to={`/{tenantName}/dashboards/performance/${defaultDashboard.id}`} style={{color: 'gray'}}>
                        <Typography variant='h5'> here. </Typography>
                    </LinkWithTenantNameWithQuery>
                </span>
            </Typography>
        </Paper>
    )
}
export default withStyles(styles)(NotFound);