import React from "react";
import _ from 'lodash';
import { withStyles, CircularProgress } from '@material-ui/core';
import ShowChart from "@material-ui/icons/ShowChart";
import BarChart from '@material-ui/icons/BarChart';
import Alert from '@material-ui/icons/ErrorTwoTone';
import Missing from '@material-ui/icons/Block';
import DottedLine from '@material-ui/icons/Timeline';
import DashedLine from '@material-ui/icons/MoreHoriz';

const LegendContent = ({ series, toggleSeries, hiddenSeriesList }) => {
    let icon = Missing;
    if(series.isLoading) {
        icon = CircularProgress;
    } else if(series.ChartType === 'bar') {
        icon = BarChart;
    } else if(series.ChartType === 'line') {
        icon = ShowChart;
    } else if(series.ChartType === 'area') {
        icon = ShowChart;
    } else if (series.ChartType === 'alert') {
        icon =  Alert;
    } else if (series.ChartType === 'dottedLine') {
        icon = DottedLine;
    } else if (series.ChartType === 'dashedLine') {
        icon = DashedLine;
    }

    const StyledSVG = withStyles({
        colorPrimary: {
            color: series.Color
        }
    })(icon);

    return (
        <span key={Math.random()} onClick={() => toggleSeries(series.Name)} style={{marginRight:'20px', cursor: 'pointer', whiteSpace: 'nowrap'}}>
            {
                series.Path
                ? <svg fill={hiddenSeriesList.indexOf(series.Name) == -1 ? series.Color : 'darkgrey'} width="24" height="24" style={{verticalAlign: 'middle'}}>
                    <path d={series.Path}/>
                  </svg>
                : <StyledSVG fill={series.Color} size={24} width="24" height="24" style={{verticalAlign: 'middle', color: (series.ChartType === 'loading' || hiddenSeriesList.indexOf(series.Name) != -1) ? 'darkgray' : series.Color}}/>
            }
            <span style={{verticalAlign: 'middle'}}>{series.Asset ? series.Name + ' (' + series.Asset + ')' : series.Name}</span>
        </span>
    );
}

export default LegendContent;