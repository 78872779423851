import React, { useEffect, forwardRef } from 'react';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let {x, y, label} = props;
   
    //Format label into <tspan> elements
    function createLabel(label) {
        var toReturn = [];

        var labelParts = splitter(label);
        for (var i = 0; i < labelParts.length; i++)
        {
            var label = <tspan x={x} y={y + (i*20)} dy={'1em'}> {labelParts[i]} </tspan>;
            toReturn.push(label);
        }
        return toReturn;
    }

    //Helper function for splitting Y-axis labels onto two lines based on length
    function splitter(s = '') {
        if (s.split(' ').length <= 5) return [s];

        var middle = Math.floor(s.length / 2);
        var before = s.lastIndexOf(' ', middle);
        var after = s.indexOf(' ', middle + 1);
    
        if (before == -1 || (after != -1 && middle - before >= after - middle)) {
            middle = after;
        } else {
            middle = before;
        }
    
        var s1 = s.substr(0, middle);
        var s2 = s.substr(middle + 1);

        return [s1, s2];
    }

    return (
        <text
            transform={"rotate(-90)"}
            style={{ textAnchor: "middle", fontFamily: "Roboto", fontSize: "1rem", opacity: "0.6" }}>
            {createLabel(label)}
        </text>
    )
})
