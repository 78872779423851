export default {
    "layout" : {
        "text-optional" : false,
        "text-line-height" : 1.2,
        "text-size" : 10,
        "text-font" : [
            "Roboto Bold"
        ],      
        "text-offset" : [ 
            0, 
            0.15
        ],
        "text-padding" : 0,
        "text-ignore-placement": true,
        "text-anchor" : "center",
        "text-field" : "{alert_count}",
    },
    "paint" : {
        "text-opacity" : [ 
            "step", 
            [ 
                "zoom"
            ], 
            0, 
            12, 
            1
        ],
        "text-color" : "black"
    }
}