import React, { createRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import withLoading from '../../withLoading';
import { withStyles, Typography, Link } from '@material-ui/core';
import _ from 'lodash';
import { ReactComponent as EmptyChartSVG } from "../../../assets/images/emptyChart.svg";
import MainChart from './MainChart';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';
import ApiHelper from '../../../api/ApiHelper';
import { getGenericRequestData } from '../../../api/ApiWorker';
import * as d3Array from 'd3-array';
import { differenceInCalendarWeeks } from 'date-fns';

const styles = theme => ({
    chartContainer: {
        height: '100%',
        width: '100%',
        position: "absolute",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    subtitle: {
        position: 'absolute',
        top: '2.2rem',
        left: '1.7rem',
        fontSize: '1.2rem',
        fontFamily: 'Roboto',
    },
    highlightedAssetName: {
        position: "absolute",
        top: "2.5rem",
        left: "4rem",
        fontFamily: "Roboto",
    },
    text: {
        position: "absolute",
        fontFamily: "Roboto",
        fontSize: "2rem",
        top: "50%",
        left: "50%",
        zIndex: "1",
        transform: "translate(-50%,-50%)"
    },
    progress: {
        top: "35%",
        left: "35%",
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    emptyChart: {
        position: 'absolute',
        top: "15%",
        left: "5%",
        height: "80%",
        width: "90%",
    },
    mainChart: {
        position: 'absolute',
        top: '6.5rem',
        left: '1.5rem',
        bottom: '0',
        right: '1.5rem',
        backgroundColor: 'lightblue'
    },
    loadingDiv: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute'
    }
});

const LoadingDiv = withLoading('div');

const PieChart = forwardRef((props, ref) => {
    const { 
        title,
        width,
        height,
        cardRef,
        model,
        dateContext,
        selectedDashboard,
        api,
        userContext,
        errorContext,
        extraData,
        selectedItem,
        containerRef,
        classes
    } = props;

    const clipPathId = useState('clip' + Math.round((Math.random() * 1000000)));
    const mainChartRef = createRef();
    const loadWithoutSelectedItem = extraData.find(a => a.name == "LoadWithoutSelectedItem")?.value;
    const endpointRequiresProcessing = extraData.find(a => a.name == "EndpointRequiresProcessing")?.value;
    const groupingAttribute = extraData.find(a => a.name == "GroupingAttribute")?.value;
    const limitValues = extraData.find(a => a.name == "LimitValues")?.value.map(v => v.toLowerCase());
    const defaultSelectedItem = extraData.find(a => a.name == "DefaultSelectedItem");
    
    const margin = {
        top: 40,
        right: 60,
        bottom: 32,
        left: 60
    };
    
    useImperativeHandle(ref, () => ({
        handlePrintClick() {
            const aspectRatio = width / height;
            const newWidth = 850;
            const newHeight = newWidth / aspectRatio;
    
            html2canvas(cardRef.current, { scale: 2 })
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/PNG');
                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt'
                    });
                    pdf.addImage(imgData, 'PNG', 0, newHeight / 5, newWidth, newHeight);
                    pdf.save("PieChart.pdf");
                });
        }
    }));

    const getAssetName = () => {
        const selectedItemFmt = getFormattedSelectedItem();
        return selectedItemFmt?.name;
    }

    const getDates = () => {
        let { startDate, realEndDate, validDates } = dateContext;
        const dateRangeLimit = selectedDashboard?.dateRangeLimit;

        //If validDates exists, set the startDate to either 52 weeks or dateContext.startDate, whichever one is the earliest.
        //If validDates does NOT exist, show data for the past 12 weeks
        const tz = sessionStorage.getItem(TENANT_TIMEZONE);
        realEndDate = moment.tz(realEndDate, tz);
        if (dateRangeLimit) {
            startDate = _.isEmpty(validDates)
                ? moment.tz(realEndDate, tz).subtract(dateRangeLimit, 'week')
                : moment.tz(realEndDate, tz).subtract(Math.min(dateRangeLimit, differenceInCalendarWeeks(new Date(realEndDate), new Date(validDates[0])) + 1), 'week');
                
        } else {
            startDate = moment.tz(startDate, tz);
            realEndDate = moment.tz(realEndDate, tz);
        }
        return { startDate: startDate, endDate: realEndDate };
    }

    const getData = async (apiKey, url, errorContext, title) => {
        try {
            const result = await getGenericRequestData(url);

            if (result.error && Object.keys(result.error).length > 0) {
                errorContext.updateErrors(result.error, apiKey, title);
            }

            return result;
        } catch (ex) {
            console.log(ex);
            throw ex;
        }
    }

    const getUrl = apiKey => {
        const tenant = userContext.getTenant();

        const apiObj = api[apiKey];

        const { startDate, endDate } = getDates();

        const selectedItemFmt = getFormattedSelectedItem();

        const replacements = {
            assetId: encodeURIComponent(selectedItemFmt?.id ?? "null"),
            assetName: encodeURIComponent(selectedItemFmt?.name ?? "null"),
            tenantName: tenant.tenantName,
            startDate: startDate.format(),
            endDate: endDate.format(),
            ...apiObj.parameters
        };

        let { urlPattern } = apiObj;

        if (model) {
            const { selectedFeature, selectedModel, baseModel } = model;
            replacements.modelId = selectedModel?.id;
            replacements.layerName = selectedFeature?.layerName;
            replacements.modelName = selectedModel?.name;
            const modelNames = [];
            if (selectedModel?.name) modelNames.push(selectedModel?.name);
            if (baseModel?.name) modelNames.push(baseModel?.name);
            replacements.modelNames = modelNames.join(',');

            const useSimulatedModel = apiObj.extraData?.find(a => a.name == "UseSimulatedModel");
            if (useSimulatedModel) {
                urlPattern = ApiHelper.fillWithSelectedModel(urlPattern, replacements, model);
            } else {
                urlPattern = ApiHelper.fillWithBaseModel(urlPattern, replacements, model);
            }
        }

        let url = new URL(urlPattern, ApiHelper.getUrlPath());

        if (selectedItemFmt?.properties) {
            url = ApiHelper.fillFromObj(url, selectedItemFmt?.properties);
        }

        return new URL(url, ApiHelper.getUrlPath());
    }

    const fetchData = async (apiKey, url) => {
        let formattedTitle = title.split(" ").join("_");

        try {
            return await getData(apiKey, url, errorContext, formattedTitle);
        } catch (e) {
            console.log('An error occurred retrieving chart data');
            throw e;
        }
    }

    const getGroupingValue = (value, groupingAttributePath) => {
        let toReturn = value;
        for (const groupingAttributeProperty of groupingAttributePath) {
            toReturn = toReturn[groupingAttributeProperty];
        }
        return toReturn;
    }

    const formatData = rawData => {
        if (!endpointRequiresProcessing) return rawData;
        
        const groupingAttributePath = groupingAttribute?.split('.');

        if (!groupingAttributePath) {
            console.error("Pie Chart GroupingAttribute property is invalid")
            return "";
        }

        const grouped = d3Array.group(rawData, d => getGroupingValue(d, groupingAttributePath));

        const result = [];

        for (const [label, values] of grouped) {
            if (_.isEmpty(limitValues) || limitValues?.indexOf(label.toLowerCase()) > -1) {
                result.push({ label, value: values.length });
            }
        }

        return result;
    }

    const queryIsEnabled = () => loadWithoutSelectedItem || !_.isEmpty(getFormattedSelectedItem());

    const generateQuery = () => {
        const apiKey = Object.keys(api)[0];
        const url = getUrl(apiKey);
        const queryKey = url.toString();
        return {
            queryKey,
            queryFn: async () => await fetchData(apiKey, url),
            options: { 
                select: formatData,
                enabled: queryIsEnabled()
            }
        }
    }

    const getFormattedSelectedItem = () => {
        if (model) {
            let toReturn = model.selectedItem;
            if (_.isEmpty(toReturn)) {
                toReturn = {};
                if(!_.isEmpty(defaultSelectedItem?.value)) {
                    defaultSelectedItem?.value.forEach(o => toReturn[o.name] = o.value);
                }
            }

            if (toReturn.properties != null) {
                const selectedAsset = toReturn;

                const displayField = model.selectedFeature.displayField;
                toReturn.name = selectedAsset.properties[displayField];

                const idField = model.selectedFeature.idField;
                toReturn.id = selectedAsset.properties[idField];
            }
            return toReturn;
        }
        return selectedItem;
    }

    const { queryKey, queryFn, options } = generateQuery();
    const {
        isIdle,
        isLoading,
        isSuccess,
        data,
        isError,
        refetch
    } = useQuery(queryKey, queryFn, options);

    const EmptyChartContent = () => {
        let message = 'No Data Found';
        if (isIdle) message = 'Data Not Available';
        if (isLoading) message = '';
        return (
            <>
                <Typography style={{ width: '100%', textAlign: 'center' }} className={classes.text}>{message}</Typography>
                <EmptyChartSVG className={classes.emptyChart} />
            </>
        )
    };

    const MainChartContent = () => (
        <div id={title} className={classes.chartContainer}>
            <MainChart
                className={classes.mainChart}
                ref={mainChartRef}
                margin={margin}
                clipPathId={clipPathId}
                data={data}
                height={height - (margin.top + margin.bottom)}
                {...props}
            />
        </div>
    );

    const ErrorContent = ()  => (
        <div style={{ color: 'red', fontSize: '15px', lineHeight: '15px', padding: '8px', display: 'flex' }}>
            <Typography className={classes.text} style={{ border: 'solid 1px black', backgroundColor: 'white', color: 'black', fontSize: '15px', lineHeight: '15px', padding: '16px' }}>
                An error occurred.
                <Link style={{ cursor: 'pointer' }} onClick={() => refetch && refetch()}>
                    Try again?
                </Link>
            </Typography>
            <EmptyChartSVG className={classes.emptyChart} />
        </div>
    );
    
    const CardContent = ({ children }) => (
        <LoadingDiv forwardedRef={containerRef} className={classes.loadingDiv} isLoading={isLoading}>
            <Typography variant="h6" className={classes.title}>
                <span>{title}</span>
            </Typography>
            <Typography variant='h6' className={classes.subtitle}>
                <span>{getAssetName()}</span>
            </Typography>
            {children}
        </LoadingDiv>
    );

    let content = <EmptyChartContent className={classes.emptyChart}></EmptyChartContent>;
    
    if(isSuccess && _.isArray(data) && !_.isEmpty(data)) {
        content = <MainChartContent></MainChartContent>;
    } else if (isError) {
        content = <ErrorContent></ErrorContent>
    }
    
    return (
        <CardContent>
            {content}
        </CardContent>
    );
});

export default withStyles(styles)(PieChart);