import React, { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

const withTenantName = (WrappedComponent) => {

    return (props) => {
        var { to } = props;
        const userContext = useContext(UserContext);
        to = to.replace('{tenantName}', userContext.getTenant().tenantName);

        return <WrappedComponent {...props} to={to} />
    }
}

export default withTenantName;