import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RsuiteTable from '../flexTable/RsuiteTable';
import { Popover, Whisper } from 'rsuite';
import FilterListIcon from '@material-ui/icons/FilterList';
import {DateContext} from '../../contexts/DateContext';
import { UserContext } from '../../contexts/UserContext';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import ApiHelper from '../../api/ApiHelper';
import { getHeaders } from '../../api/ApiWorker';
import { TENANT_TIMEZONE } from "../../constants/TimezoneConstants";
import moment from "moment";

const styles = theme => ({

});

const RainfallEventSelector = (props) => {

    const { selectedDashboard } = props

    const dateContext = React.useContext(DateContext);
    const userContext = React.useContext(UserContext)
    const triggerRef = React.useRef();
    const open = () => triggerRef.current.open();
    const close = () => triggerRef.current.close();

    const [loading, setLoading] = React.useState(false)
    const [activeAriFilter, setActiveAriFilter] = React.useState(null)
    const [ariFilters, setAriFilters] = React.useState([])
    const [tableDataRows, setTableDataRows] = React.useState([])
    const [tableDataColumns, setTableDataColumns] = React.useState([])

    React.useEffect(() => {

        selectedDashboard && selectedDashboard.extraData && selectedDashboard.extraData.TimePeriodOptions && selectedDashboard.extraData.TimePeriodOptions.RainfallEvent && selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.Api && selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.TableFields && getRainfallData()
        
    }, [activeAriFilter])

    React.useEffect(() => {

        if (selectedDashboard && selectedDashboard.extraData && selectedDashboard.extraData.TimePeriodOptions && selectedDashboard.extraData.TimePeriodOptions.RainfallEvent && selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.Api) {
            
            let filters = selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.Api.AriFilterOptions
            let active = ''
            filters.forEach((filter) => {
                if (filter.DefaultSelected === true) active = filter.Label
            })
            setAriFilters(filters)
            setActiveAriFilter(active)
        }

    }, [selectedDashboard])

    const getRainfallData = async () => {

        if (!activeAriFilter) return

        setLoading(true)
        
        let columns = selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.TableFields

        let pattern = selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.Api.RainfallEvents.UrlPattern
        let ariFilters = selectedDashboard.extraData.TimePeriodOptions.RainfallEvent.Api.AriFilterOptions
        let url = new URL(pattern, ApiHelper.getUrlPath());

        let tenantName = userContext.getTenant().tenantName
        let startDate = dateContext.startDate
        let endDate = dateContext.endDate

        let filterObject = ariFilters.find((filter) => { return filter.Label === activeAriFilter })

        let urlParams = {
            startDate,
            endDate,
            tenantName,
            ariMin: filterObject ? filterObject.MinAri : null,
            ariMax: filterObject ? filterObject.MaxAri : null,
        }
        url = ApiHelper.fillFromObj(url, urlParams);

        let res = await fetch(url, {
            method: 'GET',
            credentials: 'same-origin',
            headers: new Headers(getHeaders())
        })

        let rows = await res.json()

        // formatting dates
        columns.forEach((column) => {

            rows.forEach((row) => {
                if (column.DateFormat) {

                    let fieldName = column.FieldName
                    let fieldNamePath = fieldName.split('.')

                    let curObject = row[fieldNamePath[0]]
                    
                    for (let i = 1; i < fieldNamePath.length; i++) {
                        curObject = curObject[fieldNamePath[i]]
                    }
                    
                    row[fieldNamePath[fieldNamePath.length - 1]] = moment.tz(curObject, sessionStorage.getItem(TENANT_TIMEZONE)).format(column.DateFormat);
                }
            })
        })
       
        
        setTableDataColumns(columns)
        setTableDataRows(rows)
        setLoading(false)

    };

    const handleRadioChange = (e) => {
        setActiveAriFilter(e.target.value);
    };

    const title = (
        <span style={{ display: 'flex', flexDirection: 'row'}}>

            <span style={{ display: 'flex', flexDirection: 'column', flexGrow: 1,}}>
                <span style={{ fontSize: '16px'}}>Rainfall Events</span>
                <span style={{ color: 'gray'}}>ARI</span>
            </span>

            { ariFilters.length > 0 && 
                <FormControl component="fieldset" style={{ margin: '0 16px 0 48px'}}>
                    <RadioGroup
                        row
                        value={activeAriFilter}
                        onChange={handleRadioChange}
                    >
                        { ariFilters.map(filter => (
                            <FormControlLabel value={filter.Label} control={<Radio disabled={loading} size='small' />} label={<span style={{ fontSize: '12px', fontWeight: 'bold'}}>{filter.Label}</span>} />
                        ))}
                    </RadioGroup>
                </FormControl>
            }

        </span>
    )

    const speaker = (
        <Popover title={title} style={{ cursor: 'pointer'}}>
            <RsuiteTable 
                height={200}
                tableDataRows={tableDataRows}
                tableDataColumns={tableDataColumns}
                getColor={() => 'white'}
                extraData={[]}
                selectable={true}
                onSelect={(startDate, endDate) => {
                    close()
                    applyUpdates(startDate, endDate)
                }}
                compact={true}
                loading={loading}
            />
        </Popover>
    );

    const applyUpdates = (startDate, endDate) => {

        let timescale = 'custom'
        var endDate = new Date(endDate);
        var startDate = new Date(startDate);
        var numDays = Math.round((endDate - startDate)/(1000*60*60*24)) + 1;
        var prevEndDate = getPastDays(1,startDate);
        var prevStartDate = getPastDays(numDays+1, prevEndDate);
        var realEndDate = getPastDays(0,endDate);
        dateContext.updateDates(
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0],
            prevStartDate.toISOString().split('T')[0],
            prevEndDate.toISOString().split('T')[0],
            realEndDate.toISOString().split('T')[0],
            numDays,
            timescale
        );
    }

    const getPastDays = (days, date) => {
        var newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() - days + 1);
        return newDate;
    }

    return (
        <span>
            <Whisper ref={triggerRef} placement="bottomStart" trigger="click" controlId="control-id-click" speaker={speaker}>
                <FilterListIcon style={{ cursor: 'pointer', color: 'rgb(0, 161, 170)' }}/>
            </Whisper>
        </span>
    );

}

export default withStyles(styles)(RainfallEventSelector);