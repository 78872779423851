import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Fab, Button, Divider } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _ from "lodash";
import TemplateListFeaturePanel from './TemplateListFeaturePanel';
import TemplateDropDown from './TemplateDropDown';
import Grid from '@material-ui/core/Grid';
import TemplateMap from '../map/TemplateMap';
import ClearSelectionIcon from '@material-ui/icons/CropFree';


function TabContainer(props) {
    return (
        <Typography component="div" style={{height:'100%'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    rootList: {
        marginLeft: "73px",
        position: "fixed",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
        backgroundColor: "#EBEEF6",
        zIndex: 5000,
    },
    tabRoot: {
        minWidth: 50,
    },
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 8px)",
        height: "50px",
        margin: "4px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    fabRoot: {
        position: 'absolute',
        left: '14vw',
        top: '85vh',
        background: '#FFFFFF',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '200px'
    },
    fabExtendedIcon: {
        marginRight: theme.spacing(1)
    }
});

class NewFeaturePanel extends React.Component {
    constructor() {
        super();
        this.state = {
            tabValue: null,
            tabType: null
        }
    }

    handleTabChange = (event, value) => {
        const {configuration} = this.props;
        let {tabs} = configuration;

        let newTab = tabs.filter(x => x.enabled).find(x => x.order == value);

        this.setState({
            tabValue: value,
            tabType: newTab.type
        });
    }

    handleLayerChange = (layerName) => {
    }

    handleHighlightChange = (highlightName) => {
        const {modelContext} = this.props;
        const {statusTypes} = modelContext.state.value;
        var newStatus = !_.isEmpty(statusTypes) ? statusTypes.find(x => x.displayName == highlightName) : null;

        modelContext.updateValues({selectedStatus: newStatus, statusResults: null});
    }

    setSelectedItem = (feature) => {
        const {modelContext} = this.props;
        const {selectedItem} = modelContext.state.value;

        // Set selectedItem when modelContext.selectedItem is empty
        // Else, de-select if items are the same, or set new
        if (selectedItem == null) { 
            modelContext.updateValues({selectedItem: feature})
        } else {
            var newSelectedItem = (feature.properties.id == selectedItem.properties.id) ? null : feature;
            modelContext.updateValues({selectedItem: newSelectedItem});
        }
    }

    setSelectedItemFromName = (assetName) => {
        const {modelContext} = this.props;

        modelContext.updateHighlightedAssetFromName(assetName);
    }

    getMapOptions = () => {
        const { dashboards } = this.props;
        const eventDashboard = dashboards.find(x => x.dashboardName == 'Event');
        const mapOptions = eventDashboard.dashboardCards.find(x => x.type == 'Map').options;
        return mapOptions;
    }

    handleClearSelection = () => {
        const {modelContext} = this.props;

        modelContext.updateHighlightedAssetFromName(null);
    }

    getStatusResultsListInfo = () => {
        const {modelContext} = this.props;
        const {assets, statusResults, selectedStatus} = modelContext.state.value;
        
        let assetResults = [];

        if (_.isEmpty(statusResults)) return assetResults;

        let assetNames = Object.keys(statusResults).filter(x => x != 'All');

        assetNames.forEach( (assetName) => {
            let statusResult = statusResults[assetName];
            let matchingHighlight = selectedStatus.classes.find(x => x.value == statusResult.class)
            let toAdd = {
                name: assetName,
                value: statusResult.value,
                color: matchingHighlight.color
            };
            assetResults.push(toAdd);
        });

        return assetResults;
    }

    render() {
        const {tabValue, tabType} = this.state;
        const {classes, menuContext, dateContext, modelContext, themeContext, panelWidth, configuration, selectedDashboard} = this.props;
        const {baseModel, selectedModel, statusTypes, statusResults} = modelContext.state.value;
        const currentModel = selectedDashboard.baseModelExclusive ? baseModel : selectedModel;
        let {layers} = currentModel;

        let {tabs} = configuration;
        
        tabs = tabs.filter(x => x.enabled);
        let selectedTabType = (tabType ? tabType : tabs[0].type);

        layers = layers.filter(layer => layer.isSelectableAssetType);

        if (!_.isEmpty(selectedDashboard.limitLayers))
        {
            layers = layers.filter(layer => selectedDashboard.limitLayers.includes(layer.layerName));
        }

        const modelLayers = layers.map(layer => {
            let obj = {};
            obj.displayName = layer.layerName;
            return obj;
        });

        const statusLayers = !_.isEmpty(modelContext.selectedStatus) && modelContext.selectedStatus.classes.map((statusClass, idx) => {
            let layer = {
                layerName: statusClass.name,
                iconColor: themeContext.getColor(statusClass.color)
            };
            return layer;
        });

        const { collapsedDrawerWidth } = menuContext;
        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

        return (
            <Paper square elevation = {0} square style={{marginLeft: drawerWidth + 'px'}} classes={{
                root: classNames(classes.rootList),
            }}
            style={{width: panelWidth, marginLeft: drawerWidth + 'px'}}>
                <Grid container direction='row' alignItems='center' style={{marginBottom: '16px', marginLeft: '10%', width: 'auto'}}>
                    <Grid item xs>
                        <TemplateDropDown title={'View as'} menuItems={modelLayers} handleChange={this.handleLayerChange}/>
                    </Grid>
                    <Grid item xs>
                        <TemplateDropDown title={'Highlight by'} menuItems={_.isEmpty(statusTypes) ? [] : statusTypes.filter(x => x.displayName != 'None')} handleChange={this.handleHighlightChange}/>
                    </Grid>
                </Grid>

                {
                    tabs.length > 0 &&
                    <Tabs style={{ marginLeft: '5%'}} indicatorColor='primary' textColor='primary' value={tabValue ? tabValue : tabs[0].order} onChange={this.handleTabChange}>
                        {tabs.map(tab=>(
                            <Tab key={Math.random()} label={tab.type} value={tab.order} classes={{root: classes.tabRoot}} />
                        ))}
                    </Tabs>
                }

                {/*Render Map*/}
                {
                    selectedTabType == 'Map' && 
                        <TabContainer style={{ position: "relative", height: "100%", width: "100%" }}>
                        <Divider />
                        <TemplateMap 
                            layers={statusLayers}
                            mapOptions={this.getMapOptions()}
                            setSelectedItem={this.setSelectedItem}
                            selectedFeature={modelContext.selectedFeature}
                            modelContext={modelContext}
                            dateContext={dateContext}
                            themeContext={themeContext}
                            />                   
                      </TabContainer>  
                }

                {/*Render List*/}
                {
                    selectedTabType == 'List' && 
                    <TabContainer>
                        <Grid container direction='row' justify='space-between' alignItems='center'>
                            <Grid item xs style={{marginLeft: '32px'}}>
                                <Button onClick={() => {}} style={{color: 'gray', textTransform: 'none'}}> Sewershed </Button> {/* Current layer name */}
                            </Grid>
                            <Grid item xs style={{marginLeft: '32px'}}>
                                <Button onClick={() => {}} style={{color: 'gray', textTransform: 'none'}}> Week over Week Change </Button> {/* Current highlight name */}
                            </Grid>
                        </Grid>
                        <TemplateListFeaturePanel 
                            isLoading={_.isEmpty(statusResults)}
                            model={modelContext}
                            assets={this.getStatusResultsListInfo()}
                            selectedFeature={modelContext.selectedFeature}
                            selectedItem={modelContext.selectedItem}
                            setSelectedItem={this.setSelectedItemFromName}
                        />
                    </TabContainer>
                }

                {/* Fab component for clearing selectedItem from modelContext */}
                {
                    !_.isEmpty(modelContext.selectedItem) && 
                    <Fab onClick={this.handleClearSelection} style={{color: themeContext.getColor('primary.Primary')}} variant='extended' className={classes.fabRoot}>
                        <ClearSelectionIcon style={{color: themeContext.getColor('primary.Primary')}} className={classes.fabExtendedIcon} />
                         Clear Selection
                    </Fab>
                }
                
            </Paper>
        );
    }
}

NewFeaturePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewFeaturePanel);