import React, { useEffect, forwardRef, useRef } from 'react';
import _ from 'lodash';
import * as d3 from 'd3';

import {
    createValueScale,
    createBandedTimeScale,
    createBand,
    getDomainedBounds
} from '../../../charts/chartComponents/util';

import {
    ClipPath,
    YGridLines,
    XAxisGroup,
    YAxisLabel,
    YAxisGroup,
    MultiSeriesBar,
    Tooltip
} from '../../../charts/chartComponents';
import moment from 'moment-timezone';

export default forwardRef((props, primaryChartRef) => {
    const tooltipRef = useRef(null);

    const clipPathId = 'clip' + Math.round((Math.random() * 10000));

    let hiddenSeriesList = [];

    let { height, width, data, margin, dateContext, domain, colors, getColor, extraData } = props;

    const customTimeFormatObj = extraData.find(e => e.name == "MomentTimeFormat");
    const customTimeFormat = customTimeFormatObj ? customTimeFormatObj.value : null;

    const leftAxisLabel = (extraData.find(a => a.name == "LeftAxisLabel") || {}).value;
    // left y axis label placement
    const yOffset = 55;
    const xOffset = -(height - margin.top - margin.bottom) / 2;

    let scales = getScales(domain);
    
    function getScales(domain) {
        let startDate = new Date(dateContext.startDate.split("-").join("/"));
        let endDate = new Date(dateContext.endDate.split("-").join("/"));

        var customTimeScale = extraData.find(e => e.name == "CustomTimeScale");
        var dataDrivenTimeScale = extraData.find(e => e.name == "DataDrivenTimeScale");
        if(customTimeScale) {
            startDate = customTimeScale.value[0] == "today" ? new Date() : new Date(Date.parse(customTimeScale.value[0]))
            endDate = customTimeScale.value[1] == "today" ? new Date() : new Date(Date.parse(customTimeScale.value[1]))
        } else if (dataDrivenTimeScale && dataDrivenTimeScale.value == "true") {
            try {
                startDate = data[0].Data[0].Date;
                endDate = data[0].Data[data[0].Data.length - 1].Date;
            } catch {

            }
        }
        var customTicks = data[0].Data.map(d => d.Date);
        const x0Scale = createBandedTimeScale(startDate, endDate, margin, width, customTicks);
        // custom invert function
        const x0Domain = x0Scale.domain();
        const x0Range = x0Scale.range();
        x0Scale.invert = d3.scaleQuantize().domain(x0Range).range(x0Domain);

        let x1Domain = domain.map(d => d.tooltipLabel).filter(ttl => hiddenSeriesList.indexOf(ttl) === -1);
        const x1Scale = createBand(x1Domain, x0Scale);

        const [, maxLeft] = getDomainedBounds(data, hiddenSeriesList);

        const customYMin = extraData.find(x => x.name == 'CustomYMin');
        const customYMax = extraData.find(x => x.name == 'CustomYMax');

        const yMin = customYMin ? customYMin.value : 0;
        const yMax = customYMax ? customYMax.value : (maxLeft * 1.05);
        
        const yScale = createValueScale(yMin, yMax, margin, height);
        return {
            yScale,
            x0Scale,
            x1Scale
        };
    }

    const generateTooltipInfo = (chartObj, x) => {
        const datum = chartObj.Data.find(el => new Date(el.Date).getTime() === x.getTime())

        if(typeof datum == 'undefined' || !datum) return null;

        return Object.keys(datum)
            .filter(key => hiddenSeriesList.indexOf(key) == -1 && key !== 'Date')
            .map(key => {
                return `<li><span style="color: ${getColor(colors[key])}">${key}: ${datum[key]}</span></li>`
            });
    }

    function getTooltipData (svgRef) {
        let mouseLocation = d3.mouse(svgRef);
        let x0 = scales.x0Scale.invert(mouseLocation[0]);
    
        const momentDate = moment.tz(x0, sessionStorage.getItem(TENANT_TIMEZONE));
        var formattedValue =  customTimeFormat ? momentDate.format(customTimeFormat) : momentDate.format();
        let html = `<span>${formattedValue}</span><ul style="list-style-type:none;padding:2px; margin:2px">`;
        
        data.forEach(chartObj => {
            var info = generateTooltipInfo(chartObj, x0);
            if(info) {
                info.forEach(tip => {
                    html = html + tip;
                });
            }
        });
        html = html + "</ul>"; 

        const xValue = scales.x0Scale(x0);
        
        return { html, xValue };
    }

    function redrawChildren() {
        const { redrawFns } = primaryChartRef.current;
        redrawFns && redrawFns.forEach(fn => {
            fn(scales, hiddenSeriesList);
        });
    }

    // called by the parent component to update scales/bounds 
    // and redraw the appropriate child components.
    // These changes are handled through d3 to avoid awkward react redraws
    function redraw(providedHiddenSeriesList) {        
        if(providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }
        scales = getScales(domain);
        redrawChildren();   
    }

    function init() {
        primaryChartRef.current.redraw = redraw;
    }

    useEffect(init, [primaryChartRef])   

    const setRef = node => {        
        if(node) {
            primaryChartRef.current = node;
            primaryChartRef.current.redrawFns = [];
        }
    }

    const series = data.sort((a, b) => {
        if(a.Order > b.Order) return 1;
        if(a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = Math.random();
        return (<MultiSeriesBar key={key} domain={domain} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s}></MultiSeriesBar>);
    });
    var customTicks = data[0].Data.map(d => d.Date);
    return (
        <>                          
            <div ref={tooltipRef} className="tooltip" style={{position: "absolute"}} />  
            <svg width={props.width} height={props.height} >
                <g ref={setRef}  width={props.width - margin.left} height={props.height - margin.top} transform={`translate(${margin.left}, ${margin.top})`} >
                    <ClipPath clipPathId={clipPathId} {...props}></ClipPath>
                    <YGridLines scales={scales} {...props} ref={primaryChartRef} scaleName={'yScale'}></YGridLines>
                    <XAxisGroup useBrowserTz={true} scales={scales} {...props} ref={primaryChartRef} customTimeFormat={customTimeFormat} scaleName={"x0Scale"} customTicks={customTicks}></XAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"yScale"} handSide={"left"}></YAxisGroup>
                    <YAxisLabel {...props} ref={primaryChartRef} y={-yOffset} x={xOffset} label={leftAxisLabel} handSide={"left"}></YAxisLabel>
                    {series}
                    <Tooltip getTooltipData={getTooltipData} clipPathId={clipPathId} scales={scales} yOffset={yOffset} {...props} ref={primaryChartRef} tooltipRef={tooltipRef} domain={domain}></Tooltip>
                </g>
            </svg>
        </>
    );
})
