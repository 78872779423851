import React from 'react';
import InteractiveMap from './InteractiveMap';
import { ModelContext } from '../../contexts/ModelContext';
import GeolocatControls from './GeolocateControl';
import NavigationControls from './NavigationControls';
import _ from "lodash";
import MapContextProvider, { MapContext } from '../../contexts/MapContext';
import LayerLegendTool from './tools/LayerLegendTool';
import "../../css/DashboardMap.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import StylesButton from "../cardcontainer/StylesButton";
import alertLayerStyleOptions from './alertLayerStyleOptions';
import { IconButton } from '@material-ui/core';
import { Layers } from '@material-ui/icons';
import { Print } from '@material-ui/icons';
import jsPDF from 'jspdf';

class OperationsMap extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showSystem: false,
            showModelSystem: false,
            layers: {},
            viewType: "Map", //Default view
            shouldRedirect: false
        }
        
        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.sources = {};
        this.layers = {};
        this.modelLayers = null;
        this.selectedFeature = null;
        this.mapLoaded = false;
        this.assets = null;
    }

    toggleViewType = (viewType) => {
        this.setState({ viewType: viewType });
    }

    handlePrintClick() {
        const aspectRatio = this.props.width / this.props.height;
        const width = 850;
        const height = width / aspectRatio;

        const imgData = this.map.map.getCanvas().toDataURL();
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt'
        });
        pdf.addImage(imgData, 'PNG', 0, height / 5, width, height);
        pdf.save('Map.pdf');
    }

    toggleModelSystem = () => {
        Object.keys(this.layers).filter(layerId => layerId !== this.props.model.selectedFeature.id).forEach(layerGroupKey => {
            const layerGroup = this.layers[layerGroupKey];
            if (this.state.showModelSystem) {
                this.map.hideExternalLayer(layerGroup);
            } else {
                this.map.showExternalLayer(layerGroup);
            }
        });
        this.setState({
            showModelSystem: !this.state.showModelSystem
        });
    }

    toggleSystem = () => {
        this.map.map.getStyle().layers.forEach(layer => {
            if (layer["source"] == "system") {
                this.map.map.setLayoutProperty(layer.id, 'visibility', this.state.showSystem ? 'none' : 'visible');
            }
        });
        this.setState({
            showSystem: !this.state.showSystem
        });
    }

    updateSource = (map, source, data) => {
        let mapSource = map.getSource(source);
        if (typeof mapSource === "undefined" || mapSource == null) {
            map.addSource(source, {
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        } else {
            mapSource.setData({
                "name": source,
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });

        }
    }

    updateModelLayers = async () => {
        if(!this.map || !this.map.map) return;

        this.map.map.crossSourceCollisions = false;

        this.mapLoaded = true;
        let { layers, selectedFeature } = this.props.model;
        for(const layer of layers) {
            let layerStyles = null;

            if(layer.layer.options && layer.layer.options.styles) {
                layerStyles = layer.layer.options.styles;
            }

            const layerId = this.map.addExternalLayerToMap({
                "type": "FeatureCollection",
                "generateId": true,
                "features": []
            }, layerStyles, { hide: layer.layer.id !== selectedFeature.id });

            this.layers[layer.layer.id] = layerId;

            const data = await layer.data;

            this.map && this.map.updateExternalLayerSource(layerId, {
                "type": "FeatureCollection",
                "generateId": true,
                "features": _.isEmpty(data) ? [] : data.map(a => {
                    a.properties.tempId = a.id;
                    return a;
                })
            });
        }

        this.setState({layers: this.layers});
    }

    hideLayers = (layerId) => {
        if (this.layers && this.layers[layerId]) {
            this.map.hideExternalLayer(this.layers[layerId]);
        }
    }


    showLayers = (layerId) => {
        if (this.layers && this.layers[layerId]) {
            this.map.showExternalLayer(this.layers[layerId]);
        }
    }

    removeLayer = (layer) => {
        return new Promise((resolve, reject) => {
            const map = this.map;

            if (this.layers[layer.id]) {
                map.removeExternalLayer(this.layers[layer.id]);
                this.layers[layer.id] = undefined;
            }

            resolve();
        });
    }

    removeLayers = (layers) => {
        return new Promise((resolve, reject) => {
            layers.forEach(layer => {
                try {
                    this.removeLayer(layer)
                } catch { }
            });
            resolve();
        });
    }

    componentDidMount() {
        if (!_.isUndefined(this.props) && !_.isUndefined(this.props.model) && !_.isUndefined(this.props.model.layers)) {
            this.map.map.on("load", this.updateModelLayers);
        }
    }
    
    componentWillUnmount() {
        if(this.map && this.map.map) {
            this.map.map.off("load", this.updateModelLayers);
        }
    }

    updateSelectedItem(selected) {
        const currentSelectedFeature = this.props.model.selectedFeature;
        const featureName = selected.properties[currentSelectedFeature.idField] && selected.properties[currentSelectedFeature.idField].trim();
        var selectedEvent = this.props.eventList.find(a => a.asset.trim() == featureName);

        if(!selectedEvent) return;

        this.props.model.updateHighlightedAssetFromName(featureName)
        this.props.routeContext.updateValues({assetName: featureName});
        this.props.setSelectedEvent(selectedEvent, true);
        this.setState({ selectedEvent, shouldRedirect: true });
    }

    addAlertData = () => {
        const comp = this;
        
        if(!this.map || !this.map.map || !comp.props.eventList || !comp.props.eventList.length) return;

        const currentSelectedFeature = this.props.model.selectedFeature;

        if(this.props.assetFilter && this.props.assetFilter.trim() != '') {
            let visibleAssetsIds = this.props.model.assets.filter(a => {
                try {
                    return a.properties[currentSelectedFeature.idField].toLowerCase().indexOf(comp.props.assetFilter.trim().toLowerCase()) != -1;
                } catch {
                    return false;
                }
            }).map(a => a.id)

            this.map.map.setFilter(this.layers[currentSelectedFeature.id] + '.0', ['in', 'tempId', ...visibleAssetsIds]);
        } else {
            this.map.map.setFilter(this.layers[currentSelectedFeature.id] + '.0');
        }

        let features = this.map.map.querySourceFeatures(this.layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...this.props.model.assets.map(a => a.id)]});

        features = features.filter(a => {
            try {
                return a.properties[currentSelectedFeature.idField].toLowerCase().indexOf(this.props.assetFilter.trim().toLowerCase()) != -1;
            } catch {
                return false;
            }
        })

        features.forEach(feature => {
            const featureName = feature.properties[currentSelectedFeature.idField] && feature.properties[currentSelectedFeature.idField].trim();
            const alertDefinitions = this.props.alertTypes;
            let alerts = comp.props.eventList.filter(a => a.asset.trim() == featureName);

            if(comp.props.alertFilter != 'All Events') {
                alerts = alerts.filter(a => a.eventType == comp.props.alertFilter)
            }

            var severity = -1;
            var color = 'rgba(0, 0, 0, 0.2)';

            for(const alertDefinition of alertDefinitions) {
                var hasAlert = !!alerts.find(a => a.eventType == alertDefinition.displayName);

                if(hasAlert && alertDefinition.severity > severity) {
                    severity = alertDefinition.severity;
                    color = this.props.themeContext.getColor(alertDefinition.displayColor);
                }
            }

            comp.map.map.setFeatureState({ source: comp.layers[currentSelectedFeature.id], id: feature.id }, { "color": color });
            
            feature.properties.alert_count = alerts.length >= 9 ? '9+' 
                : alerts.length > 0 ? '' + alerts.length 
                : '';
        });

        if(this.alertCountLayerId)  {
            this.map.removeExternalLayer(this.alertCountLayerId);
            this.alertCountLayerId = null;
        }
        // add layer for alert count labels
        this.alertCountLayerId = this.map.addExternalLayerToMap({
            "type": "FeatureCollection",
            "generateId": true,
            "features": features
        }, [{
            "type" : "symbol",
            "layout": alertLayerStyleOptions.layout,
            "paint": alertLayerStyleOptions.paint
        }], { });
        
        this.map.map.getStyle().layers.forEach(layer => {
            if (layer["source"].indexOf(this.layers[currentSelectedFeature.id]) > -1) {
                if(layer.type == 'line') {
                    this.map.map.setPaintProperty(layer.id, "line-color", 'rgba(0, 0, 0, 0.5)');
                } else {
                    this.map.map.setPaintProperty(layer.id, "icon-halo-color", 'rgba(0, 0, 0, 0.5)');
                    this.map.map.setPaintProperty(layer.id, "icon-halo-width", 6);
                }
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.shouldRedirect) return;

        if (this.props.model.layers != prevProps.model.layers) {
            const layersToDelete = prevProps.model.layers;
            const comp = this;
            if (this.mapLoaded) {
                this.removeLayers(layersToDelete).then(() => comp.updateModelLayers.call(comp));
            } else {
                this.map.map.once("load",  () => comp.updateModelLayers.call(comp));
            }
        }

        const prevSelectedFeature = prevProps.selectedFeature;
        const currentSelectedFeature = this.props.selectedFeature;

        if (currentSelectedFeature != prevSelectedFeature) {
            if (prevSelectedFeature) {
                let features = this.props.model.assets; //.map.querySourceFeatures(this.layers[prevSelectedFeature.id]);
                features.forEach((feature, idx, features) => {
                    this.map.map.setFeatureState({ source: this.layers[prevSelectedFeature.id], id: feature.id }, { "color": undefined });
                });
                this.hideLayers(prevSelectedFeature.id);
            }
            this.showLayers(currentSelectedFeature.id);
        }

        if (this.props.model.assets != null && 
                !_.isEmpty(this.props.model.assets) &&
                this.props.eventList != null &&
                this.layers != null && !_.isEmpty(this.layers)) {

            if(this.map.map.loaded()) {
                this.addAlertData();
            } else {
                const comp = this;
                this.map.map.once('idle', () => comp.addAlertData.call(comp));
            }
            
        }
    }

    render() {
        const {userContext, date, history} = this.props;

        if (this.state.shouldRedirect) {
            const selectedEvent = this.state.selectedEvent;
            let basePath=`/${userContext.getTenant().tenantName}/dashboards/operations/Event/`.concat(selectedEvent.asset).concat('/')
                                                        .concat(selectedEvent.eventType).concat('/')
                                                        .concat(selectedEvent.startDate);
            let startDate = date.timescale == 'custom' && date.startDate ? '&startDate=' + date.startDate : '';
            let endDate = date.timescale == 'custom' && date.endDate ? '&endDate=' + date.endDate : '';

            history.push(basePath + '?' + 'timescale=' + date.timescale + startDate + endDate);
            return null;
        }

        return (

            <MapContextProvider>
                <MapContext.Consumer>
                    {(mapContext) => {
                        const mapViewBox = mapContext.interactiveMap ? [mapContext.interactiveMap.clientWidth, mapContext.interactiveMap.clientHeight] : [0, 0];
                        return (
                            <>

                                <ThemeContext.Consumer>
                                    {({ theme, getColor, updateTheme }) => {
                                        this.getColor = getColor;
                                    }}
                                </ThemeContext.Consumer>

                                <ModelContext.Consumer>
                                    {(model) => {
                                        const self = this;
                                        function updateSelected(x) {
                                            self.updateSelectedItem(x);
                                        }
                                        return (
                                            <div style={{ position: "relative", height: "calc(100vh - 225px)" }}>

                                                <div style=
                                                {{
                                                    id: 'Print Button',
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '5px',
                                                    zIndex: 6000,
                                                    width: '40px',
                                                    height: '40px',
                                                    boxShadow: '0px 0px 0px 2px rgba(0,0,0,.1)',
                                                    backgroundColor: 'white',
                                                    borderRadius: '16px'
                                                }}>
                                                    <IconButton
                                                        aria-label='Print Button'
                                                        style={{padding: '8px', color: this.getColor('primary.Primary')}}
                                                        onClick={this.handlePrintClick}>
                                                        <Print />
                                                    </IconButton>
                                                </div>

                                                <InteractiveMap {...this.props} options={this.props.mapOptions} viewType={this.state.viewType}
                                                    ref={(element) => {
                                                        this.map = element;
                                                        mapContext.updateMap(element);
                                                    }}
                                                    zoomOnSelect={14}
                                                    mapContext={mapContext}
                                                    layers={this.state.layers}
                                                    selectableLayers={(_.isUndefined(this.state.layers) || _.isNull(this.state.layers)) ? [] : Object.keys(this.state.layers).reduce((layerIds, layerId, idx) => {
                                                        if (layerId === this.props.model.selectedFeature.id) {
                                                            return layerIds.concat(this.state.layers[layerId]);
                                                        }
                                                        return layerIds;
                                                    }, [])}
                                                    updateSelectedItem={updateSelected} >
                                                    <GeolocatControls></GeolocatControls>
                                                    <NavigationControls></NavigationControls>
                                                </InteractiveMap>

                                                <div style= 
                                                    {{
                                                        id: "Layers Button",
                                                        position: 'absolute', 
                                                        left: "5px", 
                                                        top: "5px", 
                                                        zIndex: 6000, 
                                                        width: "40px", 
                                                        height: "40px",
                                                        backgroundColor: 'white',
                                                        boxShadow: "0px 0px 0px 2px rgba(0,0,0,.1)",
                                                        borderRadius: "16px", }}>
                                                        <IconButton aria-label = "Layer Legend" style={{padding: '8px', color: this.getColor('primary.Primary')}}  onClick={() => { mapContext.toggleLegend() }}>
                                                            <Layers />
                                                        </IconButton>
                                                    </div>

                                                <div style={{
                                                    position: 'absolute',
                                                    left: "5px",
                                                    bottom: "5px",
                                                    zIndex: 6000,
                                                    width: "200px",
                                                    height: "33px",
                                                }}>
                                                    <StylesButton viewType={this.state.viewType} toggleViewType={this.toggleViewType} />
                                                </div>
                                                {mapContext.showLegend && //Layers Table When Clicked
                                                    <div style=
                                                        {{
                                                            position: 'absolute',
                                                            left: "5px",
                                                            top: "5px",
                                                            bottom: "5px",
                                                            minWidth: "250px",
                                                            zIndex: 6000,
                                                            backgroundColor: 'white',
                                                            borderRadius: "16px",
                                                        }}>
                                                        <LayerLegendTool
                                                            layers={this.props.layers}
                                                            legendTitle={'Alerts'}
                                                            legendSubtitle={'Alert Types'}
                                                            toggleSystem={() => { this.toggleSystem() }}
                                                            showSystem={this.state.showSystem}
                                                            toggleModel={() => { this.toggleModelSystem() }}
                                                            showModelSystem={this.state.showModelSystem}
                                                            mapOptions={this.props.mapOptions}
                                                            model={model}
                                                            mapContext={mapContext}></LayerLegendTool>
                                                    </div>}

                                            </div>
                                        );
                                    }
                                    }
                                </ModelContext.Consumer>
                            </>
                        )
                    }}
                </MapContext.Consumer>
            </MapContextProvider>

        )
    }
}

export default OperationsMap;