import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Typography, CardContent} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';


const styles = theme => ({
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    },
    key: {
        fontSize: 14
    },
});

const AssetInformationTable = (props) => {
    const {selectedItem, title, classes, extraData} = props;
    const useTenantTzObj = extraData.find(e => e.name == "UseTenantTz");
    const useTenantTz = useTenantTzObj ? useTenantTzObj.value : false;

    const createDataFields = (selectedItem) => {
        let dataFields = [];

        props.tableFields.forEach(x => {
            let key = x.fieldLabel;
            let value = selectedItem[x.fieldName];
            if (x.isDate) {
                var tz = useTenantTz ? sessionStorage.getItem(TENANT_TIMEZONE) : moment.tz.guess();
                value = moment.tz(value, tz).fromNow();
            }
            dataFields.push(
                <Typography className={classes.key} color="textSecondary" gutterBottom>
                    <span style={{color: 'black'}}> {`${key}: `} </span> 
                    {value}
                </Typography>
            );
        })
        return dataFields;
    }

    const Content = !_.isEmpty(selectedItem)
    ?
    <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', right: '1.5rem', bottom: '1.5rem', width: "calc(100% - 4rem)", height: "calc(100% - 4rem)"}}>
        {createDataFields(selectedItem)}
    </div>
    :
    <Typography className={classes.text}> Select an asset to show more </Typography>;

    return (
        <CardContent>
            <Typography variant='h6' style={{position: 'absolute', top: '.5rem', left: '1.5rem', fontSize: '20px', fontFamily: 'Roboto'}}>
                <span>{title}</span>
            </Typography>
            {Content}
        </CardContent>
    )
}

export default withStyles(styles)(AssetInformationTable);
