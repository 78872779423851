import _ from "lodash";
import alertLayerStyleOptions from './alertLayerStyleOptions';

export default {
    CMOM: (assets, selectedStatus, layers, getColor, map, currentSelectedFeature, model, assetFilter, maintenanceContext) => {
        if (assets != null && !_.isEmpty(assets) &&
            selectedStatus != null && !_.isEmpty(selectedStatus) &&
            selectedStatus.classes != null && !_.isEmpty(selectedStatus.classes) &&
            layers != null && !_.isEmpty(layers)) {

            selectedStatus.classes.sort((a,b) => a.value - b.value).map((statusClass) => {
                const classAssets = assets.filter(asset => asset.properties["class"] === statusClass.value);
                const features = map.querySourceFeatures(layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...classAssets.map(a => a.id)]});
                const color = getColor(statusClass.color);
                features.forEach(feature => {
                    map.setFeatureState({source: layers[currentSelectedFeature.id], id: feature.id}, {"color": color});
                });

                let cmomLayer = model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));

                let maintenanceAssets = [];
                let visibleAssets = classAssets.filter(a => {
                    try {
                        return a.properties[currentSelectedFeature.displayField].toLowerCase().indexOf(assetFilter.trim().toLowerCase()) != -1;
                    } catch {
                        return false;
                    }
                });

                visibleAssets.forEach(x => {
                    if (!_.isEmpty(x.properties[currentSelectedFeature.maintenanceCollectionFieldName])) {
                        maintenanceAssets = maintenanceAssets.concat(x.properties[currentSelectedFeature.maintenanceCollectionFieldName]);
                    }
                });

                maintenanceAssets = maintenanceAssets.map(x => x.Feature.properties[cmomLayer.layer.displayField]);

                const collectionFeatures = map.querySourceFeatures(layers[currentSelectedFeature.layerName], {filter: ['in', cmomLayer.layer.displayField, ...maintenanceAssets]});
                collectionFeatures.forEach(feature => {
                    map.setFeatureState({source: layers[currentSelectedFeature.layerName], id: feature.id}, {'color': color});
                });

            });

            const classAssets = assets.filter(asset => asset.properties["class"] === -1);
            const features = map.querySourceFeatures(layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...classAssets.map(a => a.id)]});
            const color = getColor('threshold');
            features.forEach(feature => {
                map.setFeatureState({source: layers[currentSelectedFeature.id], id: feature.id}, { "color": color });
            });

            let cmomLayer = model.layers.find(x => x.layer.tags && x.layer.tags.includes('CMOMSource'));

            let maintenanceAssets = [];
            classAssets.forEach(x => {
                if (!_.isEmpty(x.properties[currentSelectedFeature.maintenanceCollectionFieldName])) {
                    maintenanceAssets = maintenanceAssets.concat(x.properties[currentSelectedFeature.maintenanceCollectionFieldName]);
                }
            });

            maintenanceAssets = maintenanceAssets.map(x => x.Feature.properties[cmomLayer.layer.displayField]);

            const collectionFeatures = map.querySourceFeatures(layers[currentSelectedFeature.layerName], {filter: ['in', cmomLayer.layer.displayField, ...maintenanceAssets]});
            collectionFeatures.forEach(feature => {
                map.setFeatureState({source: layers[currentSelectedFeature.layerName], id: feature.id}, {'color': color});
            });
        }
    },
    status: (assets, selectedStatus, layers, getColor, map, currentSelectedFeature, model) => {
        if(assets != null && !_.isEmpty(assets) &&
            selectedStatus != null && !_.isEmpty(selectedStatus) &&
            selectedStatus.classes != null && !_.isEmpty(selectedStatus.classes) &&
            layers != null && !_.isEmpty(layers)) {

            selectedStatus.classes.map((statusClass) => {
                const classAssets = assets.filter(asset => asset.properties["class"] === statusClass.value);

                const features = map.querySourceFeatures(layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...classAssets.map(a => a.id)]});
                const color = getColor(statusClass.color);
                features.forEach(feature => {
                    map.setFeatureState({source: layers[currentSelectedFeature.id], id: feature.id}, { "color": color });
                });
            });

            const classAssets = assets.filter(asset => asset.properties["class"] === -1);
            const features = map.querySourceFeatures(layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...classAssets.map(a => a.id)]});
            const color = getColor('threshold');
            features.forEach(feature => {
                map.setFeatureState({source: layers[currentSelectedFeature.id], id: feature.id}, { "color": color });
            });
        }
    },
    alert: (assets, selectedStatus, layers, getColor, map, currentSelectedFeature, model, assetFilter, comp, alertDefinitions) => {
        const eventList = comp && comp.props.alertContext && comp.props.alertContext.state.events;

        if(!map || !eventList || !eventList.length) return;

        if(comp.props.assetFilter && comp.props.assetFilter.trim() != '') {
            let visibleAssetsIds = assets.filter(a => {
                try {
                    return a.properties[currentSelectedFeature.idField].toLowerCase().indexOf(comp.props.assetFilter.trim().toLowerCase()) != -1;
                } catch {
                    return false;
                }
            }).map(a => a.id)

            map.setFilter(layers[currentSelectedFeature.id] + '.0', ['in', 'tempId', ...visibleAssetsIds]);
        } else {
            map.setFilter(layers[currentSelectedFeature.id] + '.0');
        }

        let features = map.querySourceFeatures(layers[currentSelectedFeature.id], {filter: ["in", "tempId", ...assets.map(a => a.id)]});

        features = features.filter(a => {
            try {
                return !comp.props.assetFilter || a.properties[currentSelectedFeature.idField].toLowerCase().indexOf(comp.props.assetFilter.trim().toLowerCase()) != -1;
            } catch {
                return false;
            }
        })

        features.forEach(feature => {
            const featureName = feature.properties[currentSelectedFeature.idField] && feature.properties[currentSelectedFeature.idField].trim();
            let alerts = eventList.filter(a => a.asset.trim() == featureName);

            if(comp.props.alertFilter && comp.props.alertFilter != 'All Events') {
                alerts = alerts.filter(a => a.eventType == comp.props.alertFilter)
            }

            var severity = Infinity;
            var color = 'rgba(0, 0, 0, 0.2)';

            for(const alertDefinition of alertDefinitions) {
                var hasAlert = !!alerts.find(a => a.eventType == alertDefinition.displayName);

                if(hasAlert && alertDefinition.severity < severity) {
                    severity = alertDefinition.severity;
                    color = getColor(alertDefinition.displayColor);
                }
            }

            comp.map.map.setFeatureState({ source: layers[currentSelectedFeature.id], id: feature.id }, { "color": color });
            
            feature.properties.alert_count = alerts.length >= 9 ? '9+' 
                : alerts.length > 0 ? '' + alerts.length 
                : '';
        });

        // add layer for alert count labels
        if(!comp.alertCountLayerId)  {
            comp.alertCountLayerId = comp.map.addExternalLayerToMap({
                "type": "FeatureCollection",
                "generateId": true,
                "features": features
            }, [{
                "type" : "symbol",
                "layout": alertLayerStyleOptions.layout,
                "paint": alertLayerStyleOptions.paint
            }], { });
        } else {
            comp.map.updateExternalLayerSource(comp.alertCountLayerId, {
                "type": "FeatureCollection",
                "generateId": true,
                "features": features
            });
        }

        
        map.getStyle().layers.forEach(layer => {
            if (layer["source"].indexOf(layers[currentSelectedFeature.id]) > -1) {
                map.setPaintProperty(layer.id, "icon-halo-color", 'rgba(0, 0, 0, 0.5)');
                map.setPaintProperty(layer.id, "icon-halo-width", 6)
            }
        });
    }
}