// Table component.

import React,{useState,useRef} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SortedIcon from '@material-ui/icons/TrendingFlat';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        overflow: "auto"
    },
    dataContainer: {
        padding: "0 0 0 1.5rem"
    },
    headerRow: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "rgba(191, 191, 191)",
        borderBottom: "2px solid rgba(0, 0, 0, .12)",
        padding: "0 17px 0 1.5rem"
    },    
    dataRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid rgba(0, 0, 0, .12)"
    },
    headerCell: {
        color: "rgba(0,0,0)", 
        height: "31px",
        margin: "8px 12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: '100%',
        whiteSpace: 'nowrap'
    },
    dataCell: {
        width: '100%',
        height: "31px",
        margin: "8px 12px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        whiteSpace: 'nowrap',
    }
});

const CustomTable = (props) => {

    const { classes, columns, sortKey, sortDirection, sortableDisabled, data } = props;
    let minWidthTable = 0;

    for(let col of columns) { 
        minWidthTable += col.width + 24;  //to get the total width of the columns including the padding
    }
      
    const getSortIcon = (icon) => {
        if(icon === 'asc') return (<SortedIcon style={{ marginTop: '1px', height: '18px', width: '18px', float: 'left', transform: 'rotate(-90deg)'}} />);
        return (<SortedIcon style={{ marginTop: '1px', height: '18px', width: '18px', float: 'left', transform: 'rotate(90deg)'}} />);
    }

    const getHeaders = () => {
        return [columns.map((col,i) => (
            <div key={i} onClick={() => !sortableDisabled && props.sort(col)} aria-disabled={sortableDisabled}
                style={{ cursor: !sortableDisabled ? 'pointer' : '', width: col.width, minWidth: col.width }} 
                className={classes.headerCell}>
                <div>
                    <Typography variant='body1' ref={useRef('header')} style={{ float: 'left', fontSize: '14px' }}>
                        <span>
                            {col.header}
                        </span>
                    </Typography>
                    <span>{sortKey === col.name && getSortIcon(sortDirection)}</span>
                </div>
            </div>
        ))];
    }

    const getColumns = (rowData) => {
        return columns.map((col,i) => {
            return (
                <span key={i} style={{ fontSize: '12px', width: col.width, minWidth: col.width }} className={classes.dataCell}>
                    {rowData[col.fieldName]} {col.units ? col.units : ''}
                </span>
            );
        });
    }

    const getRows = () => {   
        return data.map((row,i) => {
            return (
                <div key={i} className={classes.dataRow} style={{ width: 'calc(' + props.width + '- 1.5rem )' }}>
                    {getColumns(row)}
                </div>
            );
        });
    }

    return ( 
        <div className={classes.tableContainer} style={{ height: props.height, width: props.width }}>
            <div className={classes.headerRow} style={{ position:'sticky', top:0, width: 'calc(' + props.width + '- 1.5rem - 17px )', minWidth: minWidthTable }}>
                {getHeaders()}
            </div>
            <div className={classes.dataContainer} style={{ width: 'calc(' + props.width + '- 1.5rem )', minWidth: minWidthTable }}>
                {getRows()}
            </div>
        </div>
    );
};

CustomTable.propTypes = {
    classes : PropTypes.object.isRequired
}
export default withStyles(styles)(CustomTable);