import React from 'react';

export const MapContext = React.createContext(null);

class MapContextProvider extends React.Component {
    
    state = {
        showNetworkTracing: false,
        showLegend: false,
    };
    _interactiveMap = null;

    updateValue = (name, value) => {
        var tempVal = this.state;
        tempVal[name] = value;
        this.setState(tempVal);
        this.forceUpdate()
    };

    toggleTracing = () => {
        this.updateValue("showNetworkTracing", !this.state.showNetworkTracing);
    };

    toggleLegend = () => {
        this.updateValue("showLegend", !this.state.showLegend);
    };

    updateMap = (mapElement) => {
        if(mapElement !== this._interactiveMap) {
            this._interactiveMap = mapElement;
        }
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <MapContext.Provider value={{
                state: this.state,
                toggleTracing: this.toggleTracing,
                toggleLegend: this.toggleLegend,
                showNetworkTracing: this.state.showNetworkTracing,
                showLegend: this.state.showLegend,
                updateValue: this.updateValue,
                interactiveMap: this._interactiveMap,
                updateMap: this.updateMap,
            }}>
                {this.props.children}
            </MapContext.Provider>
        )
    }
}

export default MapContextProvider;