import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { height, width, margin, extraData, tooltipRef } = props;

    const asDonut = extraData.find(a => a.name == "AsDonut")?.value;
    const hideValues = extraData.find(a => a.name == "HideValues")?.value;
    const hidePercentages = extraData.find(a => a.name == "HidePercentages")?.value;
    const hideSmallValueLabels = extraData.find(a => a.name == "HideSmallValueLabels")?.value;
    // does nothing if hideSmallValueLabels is false
    const smallValueThreshold = extraData.find(a => a.name == "SmallValueLabelThreshold")?.value ?? 0.05;
    
    const radius = Math.round(Math.min(width - (margin.left + margin.right), height - (margin.top + margin.bottom)) / 2);
    // defining the arc at which the labels will reside
    const labelArc = d3.arc()
        .innerRadius(radius * 0.55)
        .outerRadius(radius * 0.55);

    const arc = d3.arc()
        .innerRadius(asDonut ? radius * 0.45 : 1)
        .outerRadius(radius * 0.98);

    const hoveredArc = d3.arc()
        .innerRadius(asDonut ? radius * 0.43 : 1)
        .outerRadius(radius);
        
    const pie = d3.pie().value(d => d.value);
    const key = d => d.label;
    const asClass = str => str ? str.replaceAll(' ', '-') : "";

    const gId = 'inner-value-labels-group';

    function draw() {
        let { data } = props;
        const primaryChart = d3.select(primaryChartRef.current);
        const g = primaryChart.select("#" + gId);

        const valueSum = data.reduce((sum, d) => sum += d.value, 0);

        const getRatio = d => d.data.value / valueSum;
        const getPercentage = d => Math.round(getRatio(d) * 100)
        const failsThreshold = d => hideSmallValueLabels && getRatio(d) <= smallValueThreshold;
        const shouldHideValue = d => hideValues || failsThreshold(d);
        const shouldHidePercentage = d => hidePercentages || failsThreshold(d);
        const getValueText = d => shouldHideValue(d) ? "" : d.data.value;
        const getPercentageText = d => {
            return shouldHidePercentage(d) ? "" : getPercentage(d) + '%';
        };
        
        var labelGroup = g.selectAll("rect.label-container")
            .data(pie(data), key)
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("class", "label-container");

        labelGroup.enter()
            .insert("rect")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("class", "label-container");

        /* value labels */
        var valueLabel = labelGroup.enter()
            .insert("text")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("class", "value-label")
            .text(getValueText)
            .style("text-anchor", "middle")
            .style("cursor", "default");
    
        var percentageLabel = labelGroup.enter()
            .insert("text")
            .attr("transform", d => `translate(${labelArc.centroid(d)})`)
            .attr("class", "percentage-label")
            .attr("dy", "1em")
            .text(getPercentageText)
            .style("text-anchor", "middle")
            .style("cursor", "default");

        var tooltip = d3.select(tooltipRef.current)
            .attr("class", "pie-chart-tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-color", "#ccc")
            .style("border-width", "1px")
            .style("padding", "10px")
            .style("position", "absolute")
            .style("z-index", 1001)
            .style("opacity", 0);

        valueLabel
            .on("mouseover", d => { 
                tooltip.style("display", null).style("opacity", 1); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', hoveredArc)
            })
            .on("mouseout", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mouseleave", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mousemove", function(d) {
                tooltip.style("display", null); 
                tooltip.style("opacity", 1);

                let mouseLocation = d3.mouse(primaryChartRef.current.parentElement);
                tooltip.style('left', Math.abs(mouseLocation[0] + 10) + 'px')
                    .style('top',  Math.abs(mouseLocation[1]+ 10) + 'px')
                    
                const html = d.data.label + ': ' + d.data.value + ' (' + Math.round((d.data.value / valueSum)*100) + '%' + ')';
                tooltip.html(html);
            });

        percentageLabel
            .on("mouseover", d => { 
                tooltip.style("display", null).style("opacity", 1); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', hoveredArc)
            })
            .on("mouseout", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mouseleave", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = primaryChart.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mousemove", function(d) {
                tooltip.style("display", null); 
                tooltip.style("opacity", 1);

                let mouseLocation = d3.mouse(primaryChartRef.current.parentElement);
                tooltip.style('left', Math.abs(mouseLocation[0] + 10) + 'px')
                    .style('top',  Math.abs(mouseLocation[1]+ 10) + 'px')
                    
                const html = d.data.label + ': ' + d.data.value + ' (' + Math.round((d.data.value / valueSum)*100) + '%' + ')';
                tooltip.html(html);
            });

        labelGroup.exit().remove();
    }

    function init() {
        draw();
    }

    useEffect(init, [primaryChartRef]);

    return (
        <g id={gId}></g>
    )
})
