import React from 'react'
import { Button, Modal } from 'rsuite'

const SimpleModal = (props) => {

    const { open, setOpen, description, onConfirm, title} = props
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        
        <Modal
            open={open}
            onClose={handleClose}
            backdrop={true}
        >

            <Modal.Header>
                <Modal.Title> {title} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: 600, margin: '1rem 0rem 0rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}> {description} </span>
                    <span style={{ height: '.4rem', backgroundColor: '#7373b9', margin: '2rem 3rem 1rem 3rem'}}/> 
                </span>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={() => { onConfirm(); handleClose()}} appearance="primary">
                    Ok
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>

      </Modal>
    )
}

export default SimpleModal


