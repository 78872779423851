import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { series, scales, dataProp, clipPathId, isDashed } = props;
    
    if(!dataProp) dataProp = 'Data';

    const seriesName = series.Name.split(' ').join('-').split('.').join('-');
   
    function drawLineSeries(newScales, hiddenSeriesList) {
        scales = newScales;
        draw(series[dataProp], hiddenSeriesList);
    }

    function draw(seriesData, hiddenSeriesList = []) {
        const scale = series.Axis == 'left' ? scales.leftScale : series.Axis == 'right' ? scales.rightScale : scales.boolScale; 

        if(!scale) return;

        const line = d3.line()
            .defined(d => d.value !== null && d.value !== '' && !isNaN(d.value))
            .x(d => {
                var value = d.date.valueOf();
                var scaledValue = scales.timeScale(value);
                return scaledValue;
            })
            .y(d => scale(d.value))
            .curve(d3.curveMonotoneX);

        const primaryChart = d3.select(primaryChartRef.current);

        const g = primaryChart.select('#' + seriesName);    

        const visibility = hiddenSeriesList.indexOf(series.Name) == -1 ? 'visible' : 'hidden';
        
        const path = g.selectAll('path.' + seriesName)
            .data([seriesData])
            .attr("class", seriesName)
            .attr("fill", "none")
            .attr("clip-path", "url(#" + clipPathId + ")")
            .attr("stroke", series.Color)
            .attr("data-legend", series.Name)
            .attr("stroke-width", "2px")
            .attr("d", line)
            .attr("visibility", visibility)
            .style("stroke-dasharray", isDashed ? ("2, 2") : ("1", "0"));

        path.enter()
            .append("path") 
            .attr("class", seriesName)
            .attr("fill", "none")
            .attr("clip-path", "url(#" + clipPathId + ")")
            .attr("stroke", series.Color)
            .attr("data-legend", series.Name)
            .attr("stroke-width", "2px")
            .attr("d", line)
            .attr("visibility", visibility)
            .style("stroke-dasharray", isDashed ? ("2, 2") : ("1", "0"));

        path.exit().remove();
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawLineSeries);
   
        draw(series[dataProp]);
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(series[dataProp]), [scales]);

    return (
        <g id={seriesName}></g>
    )
})
