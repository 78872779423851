import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import { UserContext } from "../../contexts/UserContext";
import { ThemeContext } from '../../contexts/ThemeContext';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useContext } from 'react';

const styles = theme => ({
    addRowButton: {
        position: "absolute",
        right: "190px",
        top: "0",
        zIndex: "6001" // The fullscreen modal is 6000
    },
    paper: {
        position: 'fixed',
        left: "12.5%",
        top: "10%",
        width: document.documentElement.clientWidth * 0.75,
        height: document.documentElement.clientHeight * 0.80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

const ToggleCsoAutomatedPublishingCheckbox = (props) => {

    const userContext = useContext(UserContext);

    const { csoAutomatedPublishing, isDataAdmin } = userContext

    if (!isDataAdmin || !csoAutomatedPublishing) return (<></>);

    const { classes } = props;
    return (
        <div className={classes.addRowButton} data-html2canvas-ignore="true">
            <IconButton title='automated publishing' onClick={() => props.handleClick(!csoAutomatedPublishing.enabled)} >
                <ThemeContext.Consumer>
                    {({ theme, getColor, updateTheme }) => (
                        <>
                            {csoAutomatedPublishing.enabled && <CheckBoxIcon style={{ color: getColor("primary.Primary") }} />}
                            {!csoAutomatedPublishing.enabled && <CheckBoxOutlineBlankIcon style={{ color: getColor("primary.Primary") }} />}
                        </>
                    )}
                </ThemeContext.Consumer>

            </IconButton>
        </div>
    )
}

ToggleCsoAutomatedPublishingCheckbox.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ToggleCsoAutomatedPublishingCheckbox);;