import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { ModelContext } from '../../contexts/ModelContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import CardContainer from './CardContainer';


const styles = theme => ({
    fullScreenButton : {
        position: "absolute",
        right: "5px",
        top: "5px",
        zIndex: "6001", // The fullscreen modal is 6000
        width: "40px",
        height: "40px",
        backgroundColor: 'white',
        borderRadius: "16px",
    },
    paper: {
        position: 'fixed',
        left: "12.5%",
        top: "10%",
        width: document.documentElement.clientWidth * 0.75,
        height: document.documentElement.clientHeight * 0.80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

class FullScreenButton extends React.Component
{

    constructor(props) 
    {
        super(props);
        this.state = { open: false };
    }
    
    static contextType = ModelContext;

    render () 
    {
        const { classes } = this.props;

        if (this.props.cardType === "Map")
        {
            return (
                <div className={classes.fullScreenButton} data-html2canvas-ignore="true">
                    <IconButton onClick={this.props.handleClick} style={{padding: '8px', boxShadow: "0px 0px 0px 2px rgba(0,0,0,.1)"}}>
                        <ThemeContext.Consumer>
                            {({theme, getColor, updateTheme}) => 
                            (
                                <Icon style={{color: getColor("primary.Primary")}}>fullscreen</Icon>
                            )}
                        </ThemeContext.Consumer>
                    </IconButton>
                </div>
                )
        }
        else
        {
            return (
                <div className={classes.fullScreenButton} data-html2canvas-ignore="true">
                    <IconButton onClick={this.props.handleClick} title='full screen' style={{padding: '8px'}}>
                        <ThemeContext.Consumer>
                            {({theme, getColor, updateTheme}) => 
                            (
                                <Icon style={{color: getColor("primary.Primary")}}>fullscreen</Icon>
                            )}
                        </ThemeContext.Consumer>
                    </IconButton>
                </div>
                )
        }
    }
}

FullScreenButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

// We need an intermediary variable for handling the recursive nesting.
const SimpleModalWrapped = withStyles(styles)(FullScreenButton);

export default SimpleModalWrapped;