import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from 'react-router-dom';
import App from "./App";


ReactDOM.render(
    <BrowserRouter style={{height: '100%'}} basename='/pipecast/dashboard'>
        <App  style={{height: '100%'}} />
    </BrowserRouter>, 
    document.getElementById("root")
);

