import React, {useState, useEffect, useContext} from 'react';
import _ from "lodash";
import EmailPreferences from './EmailPreferences';
import { withStyles } from '@material-ui/styles';
import { updateEmailSubscriber } from '../../api/ApiWorker';
import { SnackbarContext } from "../../contexts/SnackbarContext";

const styles = theme => ({
});

const UserPreferences = (props) => {
    // Individual user settings (only occurrence for now)
    const [occurrence, setOccurrence] = useState();
    const [isLoading, setLoading] = useState(false);
    const snackbarContext = useContext(SnackbarContext);

    // Settings Change
    useEffect( () => {
      try {
        // Occurrence is the email preference, which is the only setting for now.
        if (!occurrence) return; 
        const settings = occurrence;
        setLoading(true);
        updateEmailSubscriber(JSON.stringify(settings)).then( () => {
          snackbarContext.addMessage("Email preferences saved.", "success");
          setLoading(false);
        });
      }
      catch (e) {
        snackbarContext.addMessage("An error occurred. Email preference not saved.", "error");
        console.debug(e);
      }
    }, [occurrence]);

    return ( 
        <>
            <EmailPreferences isLoading={isLoading} setOccurrence={setOccurrence} />
        </>
    )
}
export default withStyles(styles)(UserPreferences);