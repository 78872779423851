import React, {useState, useEffect, useContext} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {useLocation} from 'react-router-dom';
import {DateContext} from '../../contexts/DateContext';
import {ModelContext} from '../../contexts/ModelContext';
import {ErrorContext} from '../../contexts/ErrorContext';
import {RouteContext} from '../../contexts/RouteContext';
import _ from 'lodash';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        height: "100%",
        margin: "0 8px 6px 0"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const FeatureTypeSelector = (props) => {

    const [query, setQuery] = useState(new URLSearchParams(useLocation().search));

    const dateContext = useContext(DateContext);
    const modelContext = useContext(ModelContext);
    const errorContext = useContext(ErrorContext);
    const routeContext = useContext(RouteContext);

    const handleChange = event => {
        const feature = event.target.value;
        modelContext.updateValues({
            selectedFeature: feature,
            selectedItem: null,
            highlightedAsset: null
        });
        errorContext.updateValues({'errors': []});
        routeContext.updateValues({
            layerName : feature.layerName,
            assetName : null
        });
    }

    const createSelectItems = (selectedModel) => {
        const {selectedDashboard} = props;
        let items = [];    

        if (selectedModel != null && typeof selectedModel != 'undefined' && selectedDashboard) 
        {  
            selectedModel.layers.forEach(function(layer) {
                switch (!!selectedDashboard.limitLayers)
                {
                    case true:
                        if (layer.isSelectableAssetType && selectedDashboard.limitLayers && selectedDashboard.limitLayers.includes(layer.layerName)) {
                            items.push(<MenuItem key={layer.id} value={layer}> {layer.layerName} </MenuItem>)
                        }
                        break;
                    default:
                        if (layer.isSelectableAssetType) {
                            items.push(<MenuItem key={layer.id} value={layer}> {layer.layerName} </MenuItem>)
                        }
                        break;
                }
            });
        }   
        return items;
    }

    useEffect( () => {
        if (!modelContext.selectedFeature || _.isEmpty(modelContext.selectedFeature)) return;

        routeContext.updateValues({
            layerName: modelContext.selectedFeature.layerName
        });
        
        const {tenant, pageType, layoutType, viewType, layerName, assetName} = routeContext.state;

        if (layoutType == 'performance')
        {
            let basePath = `/${tenant}/${pageType}/${layoutType}/${viewType}/${layerName}${assetName ? `/${assetName}` : ''}`;

            let timescale = 'timescale=' + dateContext.timescale;
            let startDate = dateContext.timescale == 'custom' && dateContext.startDate ? '&startDate=' + dateContext.startDate : '';
            let endDate = dateContext.timescale == 'custom' && dateContext.endDate ? '&endDate=' + dateContext.endDate : '';
            
            if(basePath + '?' + timescale + startDate + endDate != props.history.location.pathname.concat(props.history.location.search) && props.history.action != 'POP') {
                props.history.push(basePath + "?" + timescale + startDate + endDate);
            }
        }

    },[modelContext.selectedFeature]);

    useEffect( () => {
        if(modelContext.selectedModel && !_.isEmpty(modelContext.selectedModel.layers) && props.match.params.layerName && props.history.action == "POP")
        {
            let feature = modelContext.selectedModel.layers.find(layer => layer.layerName == props.match.params.layerName);

            if(feature && feature != modelContext.selectedFeature) {
                modelContext.updateValues({
                    selectedFeature: feature,
                    selectedItem: null,
                    highlightedAsset: null
                });
                errorContext.updateValues({'errors': []});
                routeContext.updateValues({
                    layerName : feature.layerName,
                    assetName : null
                });
            }
        }
    },[props.match.params.layerName]);

    return (
        <FormControl className={props.classes.formControl}>
            <InputLabel>View By</InputLabel>
            <Select
                value = {modelContext.selectedFeature}
                onChange={handleChange}
                name="feature"
                displayEmpty
                className={props.classes.selectEmpty}>
                {createSelectItems(modelContext.selectedModel)}
            </Select>
        </FormControl> 
    );
}

export default withStyles(styles)(FeatureTypeSelector);