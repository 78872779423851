import React from "react";
import _ from 'lodash';
import GroupedLegendContent from './GroupedLegendContent';
import LegendContent from './LegendContent';

class ChartLegend extends React.Component {

    constructor(props) {
        super(props);
        
        this.state= { 
            hiddenSeriesList: [] 
        };
    }
    
    toggleSeries = name => {
        let { hiddenSeriesList } = this.state;
        hiddenSeriesList = [...hiddenSeriesList];
        
        if(hiddenSeriesList.indexOf(name) == -1) {
            hiddenSeriesList.push(name);
        } else {
            hiddenSeriesList = hiddenSeriesList.filter(n => n != name);
        }

        this.setState({ hiddenSeriesList });
    }

    componentDidUpdate(prevProps, prevState) {
        if(!_.isEqual(this.state.hiddenSeriesList, prevState.hiddenSeriesList)) {
            this.props.onChange(this.state.hiddenSeriesList);
        }
    }

    render() {
        const { data, containerStyle } = this.props;

        const content = data && data.map(series => !!this.props.grouped ? 
            <GroupedLegendContent key={Math.random()} series={series} toggleSeries={this.toggleSeries} hiddenSeriesList={this.state.hiddenSeriesList}></GroupedLegendContent>
                : <LegendContent key={Math.random()} series={series} toggleSeries={this.toggleSeries} hiddenSeriesList={this.state.hiddenSeriesList}></LegendContent>
        );

        return (
            <div style={ containerStyle }>
                {content}
            </div>
        );
    }
}

export default ChartLegend;