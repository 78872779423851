import React from 'react';
import { withStyles, ListItemSecondaryAction, Checkbox, IconButton } from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { Warning, ExpandLess, ExpandMore } from '@material-ui/icons';
import LayerLegendItem from './LayerLegendItem';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
    spacer: {
        width: `${theme.spacing(1)}px`,
        height: `${theme.spacing(1)}px`,
    },
    card: {
        maxHeight: 350
    },
    elementList: {
        width: '100%',
        maxWidth: 360,
    },
});

class LayerLegendGroupItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isChecked: props.group.isSelected,
            dense: true
        }
    }

    handleToggle = (ev) => {
        ev.stopPropagation();
        let { group } = this.props;
        if(typeof group.selectionAction !== 'undefined') {
            group.selectionAction();
        }
        this.setState({
            isChecked: !this.state.isChecked
        });
    }

    render() {
        let { index, group, mapContext, model, classes } = this.props;
        let { isOpen } = this.state;
        return(
        <>
            <ListItem
                key={group.groupName + "li" + index} 
                style={{width: '100%'}}>
                <Checkbox 
                    onChange={this.handleToggle}
                    checked={this.state.isChecked} 
                    tabIndex={-1}/>
                <ListItemText primary={`${group.groupName}`} >
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton
                        onClick={() => { this.setState({isOpen: !isOpen}) }}>
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                        
                </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={isOpen} style={{ width: '100%', textAlign: 'justify', paddingLeft: '20px' }} unmountOnExit>
                <Divider variant="fullWidth" />
                <List className={classes.elementList} dense={this.state.dense}  disablePadding={true}>
                    {group.layers.map((layer, idx) => {
                        return (<LayerLegendItem key={layer.layerName + idx} layer={layer} index={idx} model={model} mapContext={mapContext} group={group} ></LayerLegendItem>)
                    })}
                </List>
            </Collapse>
        </>);
    }
}

export default withStyles(styles)(LayerLegendGroupItem)