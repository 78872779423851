import React from 'react'
import { Button, Modal, Notification } from 'rsuite'
import { ExplorerContext } from '../../../contexts/ExplorerContext'
import { DateContext } from '../../../contexts/DateContext'

const ViewsModal = (props) => {

    const { open, setOpen, chart, setSeriesObjects, selectedView } = props
    const explorerContext = React.useContext(ExplorerContext)
    const dateContext = React.useContext(DateContext)
    
    const { getConfig, getSeriesData, openNotification} = explorerContext
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let views = JSON.parse(localStorage.getItem('views'))

    const onConfirm = async () => {
        if (selectedView === null) { openNotification('error', 'load'); return }
        // reload data with new date range and set as seriesData
        let view = views[selectedView]
        if (view === null ) { openNotification('error', 'load'); return }
        let newSeriesObjects = []
        chart.current.showLoading('loading...')
        for (let attribute in view.Api) {
            let info = view.Api[attribute]
            info.Parameters['startDate'] = dateContext.startDate
            info.Parameters['endDate'] = dateContext.endDate
            let seriesData = await getSeriesData(info)
            let seriesConfig = getConfig(info, seriesData)
            newSeriesObjects.push(seriesConfig)
        }
        chart.current.hideLoading()
        setSeriesObjects(newSeriesObjects)
        openNotification('success', 'load')
        handleClose()
    }

    const onDelete = () => {
        if (selectedView) { openNotification('error', 'delete'); return }
        delete views[selectedView]
        localStorage.setItem('views', views)
        openNotification('success', 'delete')
        handleClose()
    }

    return (
        
        <Modal
            open={open}
            onClose={handleClose}
            backdrop={true}
        >

            <Modal.Header>
                <Modal.Title> Load/delete config </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    { localStorage.getItem('views') && 
                        <span style={{ margin: '1rem 0rem 2rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                            <span style={{fontWeight: 800, fontSize: '2rem', color: 'dimgray'}}> { selectedView } </span>
                        </span> 
                    }
                    { !localStorage.getItem('views') && <Notification closable type='warning' placement='topEnd' children='you have no saved views' /> }
                    { localStorage.getItem('views') && <span style={{fontWeight: 600}}> are you sure you would like to load this view? </span> }
                    <span style={{ height: '.4rem', backgroundColor: '#7373b9', margin: '2rem 3rem 1rem 3rem'}}/> 
                </span>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onConfirm} appearance="primary">
                    Confirm
                </Button>
                <Button onClick={onDelete} appearance="subtle">
                    Delete
                </Button>
            </Modal.Footer>

      </Modal>
    )
}

export default ViewsModal


