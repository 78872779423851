import React, { useContext } from 'react';
import { MenuContext } from '../../../contexts/MenuContext';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import _ from "lodash";
import SearchBar from '../../featurepanel/SearchBar';
import { Divider, Link } from '@material-ui/core';
import ListFeaturePanel from './ListFeaturePanel';

function TabContainer(props) {
    return (
        <Typography component="div" style={{height:'100%'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    rootList: {
        marginLeft: "73px",
        position: "fixed",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
    tabRoot: {
        minWidth: 50,
    },
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 8px)",
        height: "50px",
        margin: "4px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(1)
    },
    listItem: {
      cursor: 'pointer',
      '&:hover': {
        border: '2px solid black',
      }
    },
});

const ModelFreeFeaturePanel = (props) => {
    const {selectedItem, classes, isLoading, assets, assetsError, getData, setFilter, listItemRenderer} = props;

    const menuContext = useContext(MenuContext);

    const {collapsedDrawerWidth} = menuContext;
    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

    return (
        <Paper square elevation={2} style={{marginLeft: drawerWidth + 'px'}} classes={{
                root: classNames(classes.rootList),
            }}
            style={{width: props.panelWidth, marginLeft: drawerWidth + "px"}}>

            <SearchBar placeholderText={'Find a station'} setFilter={setFilter}/>

            <Divider className={classes.divider} />
            {
                _.isEmpty(assetsError) && 
                <ListFeaturePanel
                    selectedItem={selectedItem}
                    isLoading={isLoading}
                    assets={assets}
                    listItemRenderer={listItemRenderer}
                    itemSize={70}
                    />
            }
            {
                !_.isEmpty(assetsError) && 
                <div style={{ color: 'red', fontSize: '15px', lineHeight: '15px', padding: '8px', display: 'flex'}}>
                    {assetsError}
                    <Typography style={{ fontSize: '15px', lineHeight: '15px', paddingLeft: '8px', cursor: 'pointer'}}>
                        <Link onClick={getData}>
                            Try again?
                        </Link>
                    </Typography>
                </div>
            }
        </Paper>
    )
}

ModelFreeFeaturePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModelFreeFeaturePanel);