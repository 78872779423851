import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { height, width, margin, clipPathId, tooltipRef, extraData, getTooltipData, yMin, yMax, yOffset } = props;

    // margin is the distance between the side of the chart and the side of the card container
    const innerHeight = height - margin.top - margin.bottom;
    const innerWidth = width - margin.left - margin.right;

    function updateTooltip(html, xValue, y1 = 0, y2 = 0) {
        const container = document.getElementById(clipPathId);

        if(!container) return;

        /* On Firefox getBoundingClientRect cannot get height, width, right, or bottom when the
         * element used (container) is a SVG (or in this case a clipPath). We calculate the
         * innerHeight and innerWidth above in order to  be able to do the math necessary.
         *
         * boundingRect.height == innerHeight
         * boundingRect.width == innerWidth
         * boundingRect.bottom == boundingRect.top - innerHeight
         * boundingRect.right == boundingRect.left + innerWidth
         * 
         * Additionally, boundingRect is read-only since getBoundingClientRect() returns a read-only,
         * so anywhere you need height, width, bottom, or right, use the above equivalent equations.
         * Doing that will allow the code to work properly on either Chrome or Firefox.
         */
        const boundingRect = container.getBoundingClientRect();

        const primaryChart = d3.select(primaryChartRef.current);
        
        const focus = primaryChart.select("#focus")
            .style("display", null);
    
        focus.select(".x")
            .attr("x1", xValue)
            .attr("x2", xValue)
            .attr("y1", y1)
            .attr("y2", y2);

        const pageX = d3.event.pageX; // x location of cursor on page
        const pageY = d3.event.pageY; // y location of cursor on page

        const tooltipContainer = d3.select(tooltipRef.current);

        tooltipContainer.html(html);

        const tooltipBoundingBox = tooltipContainer.node().getBoundingClientRect();

        const cursorX = (pageX - boundingRect.x) + margin.left; // distance from the cursor location to the left edge of the card

        const willOverflow = pageX + (yOffset/2) + tooltipBoundingBox.width > boundingRect.left + innerWidth;

        const alignRight = cursorX + (yOffset/2) + 'px';

        const alignLeft =  cursorX - (yOffset/2) - tooltipBoundingBox.width + 'px';

        let left = willOverflow ? alignLeft : alignRight;

        tooltipContainer
            .style("left", left)
            .style("top", ((pageY - boundingRect.y)) + "px")
    }

    function draw() {
        if(!primaryChartRef.current.redrawFns) {
            primaryChartRef.current.redrawFns = [];
        }
        
        const primaryChart = d3.select(primaryChartRef.current);
    
        const focus = primaryChart.select("#focus")
            .style("display", "none");
        
        focus.select("#focus-line")
            .attr("class", "x")
            .attr("clip-path", "url(#" + clipPathId + ")")
            .style("stroke", "grey")
            .style("stroke-dasharray", "3,3")
            .style("opacity", 0.5)
            .attr("y1", yMin || 0)
            .attr("y2", yMax || 0);
    
        const tooltip = d3.select(tooltipRef.current)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-color", "#ccc")
            .style("border-width", "1px")
            .style("padding", "10px")
            .style("position", "absolute")
            .style("z-index", 1001)
            .style("opacity", 0);
    
        let hideLegend = extraData.find(x => x.name === 'HideLegend');
        hideLegend = !!hideLegend && hideLegend.value;
        
        primaryChart.select("#capture-rect")
            .attr("width", innerWidth)
            .attr("height", innerHeight)
            .style("fill", "none")
            .style("pointer-events", "all")
            .on("mouseover", () => { focus.style("display", null); tooltip.style("display", null).style("opacity", 1); })
            .on("mouseout", () => { focus.style("display", "none"); tooltip.style("display", "none").style("opacity", 0); })
            .on("mouseleave", () => { focus.style("display", "none"); tooltip.style("display", "none").style("opacity", 0); })
            .on("mousemove", function() {
                focus.style("display", null); 
                tooltip.style("display", null); 
                tooltip.style("opacity", 1);
                var getTooltipDataFn = primaryChartRef?.current?.getTooltipData || getTooltipData;
                const { html, xValue, y1, y2 } = getTooltipDataFn(this);
                updateTooltip(html, xValue, y1, y2);
            });

        return () => {
            primaryChart
                .on("mouseover", null)
                .on("mouseout", null)
                .on("mouseleave", null)
                .on("mousemove", null)
        }
    }

    function init() {
        primaryChartRef.current.redrawFns.push(draw);
        return draw();
    }

    useEffect(init, [primaryChartRef, tooltipRef]);

    return (
        <g>
            <g id="focus">
                <line id="focus-line"></line>
            </g>
            <rect id="capture-rect"></rect>
        </g>
    )
})
