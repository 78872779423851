import React, { useContext } from 'react'
import _ from 'lodash';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';
import { Table, Whisper, Popover, Checkbox, Button } from 'rsuite';
import InfoIcon from '@material-ui/icons/Info';
import CheckIcon from '@material-ui/icons/Check';
import "rsuite/dist/rsuite.min.css";
import { ThemeContext } from '../../contexts/ThemeContext';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { alpha } from '@material-ui/core';
import { UserContext } from '../../contexts/UserContext';

const { Column, Cell, HeaderCell } = Table

const RsuiteTable = (props) => {

    const {extraData, getColor, width, height, tableDataRows, tableDataColumns, selectable, onSelect, compact, loading, checked, setChecked, isReportParent, isReportChild, overrideFields, handleEditClick, handleDeleteClick, handleParentCheckedSaved, handleChildCheck} = props;

    const userContext = useContext(UserContext)
    
    let resizable = false
    let wordWrapHeaders = false
    let headerIconLocation = 'right'
    let rowHeight = compact ? 30 : 46
    let defaultSortColumn = null
    let defaultSortType = null

    extraData.forEach((obj) => {
        if (obj.name === 'TableOptions') {
            obj.value.forEach((option) => {
                if (option.name === 'HeaderWrappingEnabled') wordWrapHeaders = option.value
                if (option.name === 'HeaderResizingEnabled') resizable = option.value
                if (option.name === 'HeaderIconLocation') headerIconLocation = option.value
                if (option.name === 'DefaultSortColumn') defaultSortColumn = option.value
                if (option.name === 'DefaultSortDirection') defaultSortType = option.value
            })
        }
    })

    const [sortColumn, setSortColumn] = React.useState(defaultSortColumn);
    const [sortType, setSortType] = React.useState(defaultSortType || 'asc');
    const [isSorting, setIsSorting] = React.useState(false);
    const [showSaveCheckedButton, setShowSaveCheckedButton] = React.useState(false)


    const getDateColumnWidth = (column) => {
        const minWidth = 100;
        let dateFormat = column.dateFormat ? column.dateFormat : column.DateFormat
        let dbWidth = column.width ? column.width : 0
        
        let capitals = bigLettersCount(dateFormat)
        let lowercaseMagicSpacing = 5.5
        let upperCaseMagicSpacing = 7
        let formatWidth = 20 + (dateFormat.length - capitals) * lowercaseMagicSpacing + capitals * upperCaseMagicSpacing
        
        let headerWidth = getHeaderWidth(column)
        return Math.max(minWidth, headerWidth, formatWidth, dbWidth);
    }

    const getNumericColumnWidth = (column) => {
        const minWidth = 60;
        let dbWidth = column.width ? column.width : 0
        let headerWidth = getHeaderWidth(column)
        return Math.max(minWidth, headerWidth, dbWidth);
    }

    const getTextColumnWidth = (column) => {
        const minWidth = 100;
        let dbWidth = column.width ? column.width : 0
        let headerWidth = getHeaderWidth(column)
        
        return Math.max(minWidth, headerWidth, dbWidth);
    }

    // for header word wrapping, find the best split for n lines
    const getHeaderLines = (column) => {

        let header = column.fieldLabel ? column.fieldLabel : column.FieldLabel

        let target = "";
        let n = 3
        let arr =  header.split(" ");
        let wordsInLine = 0;
        let wordsPerLine = Math.ceil(arr.length / n)
        for (var i = 0, len = arr.length; i < len; i++) {
            var cur = arr[i];
            if(wordsInLine + 1 > wordsPerLine) {
                target += '\n' + cur;
                wordsInLine = 1;
            } else {
                if (target.length > 0) {
                    target += " ";
                }
                target += cur;
                wordsInLine += 1;
            }
        }

        return target.split('\n');
    }

    // get the rough width of an rsuite table generic font header
    const getHeaderWidth = (column) => {

        let header = column.fieldLabel ? column.fieldLabel : column.FieldLabel
        let lowercaseMagicSpacing = 6.5
        let upperCaseMagicSpacing = 9.5

        if (wordWrapHeaders) {
            let headerLines = getHeaderLines(column)
            let headerLineWidths = []

            for (let i in headerLines) {
                let str = headerLines[i]
                let capitals = bigLettersCount(str)
                headerLineWidths[i] = (str.length - capitals) * lowercaseMagicSpacing + capitals * upperCaseMagicSpacing
            }

            return Math.max(...headerLineWidths) + 80 + (headerIconLocation === 'right' ? 20 : 0)
        } else {
            let capitals = bigLettersCount(header)
            return (header.length - capitals) * lowercaseMagicSpacing + capitals * upperCaseMagicSpacing + 80 + (headerIconLocation === 'right' ? 20 : 0)
        }

    }

    const bigLettersCount = (str) => {
        let result = 0;
        for (let i = 0; i < str.length; i += 1) {
            if ((str.charCodeAt(i) > 64 && str.charCodeAt(i) < 91) || (isNaN(str.charAt(i)) === false)) {
                result += 1;
            }
        }
        return result
    }
    
    const handleSortColumn = (sortColumn, sortType) => {
        setIsSorting(true);
        setTimeout(() => {
            setSortColumn(sortColumn);
            setSortType(sortType);
            setIsSorting(false);
        }, 500);
    };

    const filterData = (data) => {

        let sortColumnObject

        if (sortColumn) sortColumnObject = tableDataColumns.find((col) => col.fieldName === sortColumn || col.FieldName === sortColumn)

        if (sortColumnObject) {

            return data.sort((a, b) => {

                let sortColumnPath = sortColumnObject.sortFieldName ?? sortColumnObject.SortFieldName ?? sortColumn
                
                let path = sortColumnPath.split('.')
                let x = a[path[0]]
                let y = b[path[0]]
                
                for (let i = 1; i < path.length; i++) {
                    x = x[path[i]]
                    y = y[path[i]]
                }

                if (sortColumnObject.dateFormat || sortColumnObject.isDate) {
                    x = moment.tz(x, sessionStorage.getItem(TENANT_TIMEZONE)).unix() * 1000
                    y = moment.tz(y, sessionStorage.getItem(TENANT_TIMEZONE)).unix() * 1000
                }

                if (sortColumnObject.numeric)
                {
                    if (isNaN(x)) return 1;
                    if (isNaN(y)) return -1;
                }

                if (x > y) return sortType === 'asc' ? 1 : -1
                if (x < y) return sortType === 'asc' ? -1 : 1

                if (x > y) return sortType !== 'desc' ? -1 : 1
                if (x < y) return sortType !== 'desc' ? 1 : -1

                return 0
            });
        }

        return data;
    }

    const onRowClick = (row) => { if (selectable && onSelect) onSelect(row.startDate, row.endDate) }

    const handleParentCheck = (key, isChecked) => {

        let nextChecked = [...checked]

        if (isChecked) {
            nextChecked.push(key)
        } else {
            let index = nextChecked.indexOf(key)
            nextChecked.splice(index, 1)
        }

        setChecked(nextChecked)
        setShowSaveCheckedButton(true)
    };

    // ui calculations
    let defaultWidth = 0

    tableDataColumns.forEach((column) => {

        if (column.isDate || column.IsDate) {
            defaultWidth += getDateColumnWidth(column)
        } else if (column.numeric) {
            defaultWidth += getNumericColumnWidth(column)
        } else {
            defaultWidth += getTextColumnWidth(column)
        }
    })

    let rightStyle = { display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontSize: '14px', fontWeight: '500', color: 'black' }
    let bottomStyle = { display: 'flex', flexDirection: 'column', justifyContent: 'center', fontSize: '14px', fontWeight: '500', color: 'black' }
    let headerStyle = headerIconLocation === 'bottom' ? bottomStyle : rightStyle

    return (
        <Table
            height={height}
            width={width ? width : defaultWidth}
            data={filterData(tableDataRows)}
            sortColumn={sortColumn}
            sortType={sortType}
            onRowClick={onRowClick}
            onSortColumn={handleSortColumn}
            cellBordered
            virtualized
            loading={isSorting || loading}
            headerHeight={(wordWrapHeaders ? 80 : 40) + (headerIconLocation === 'bottom' ? 20 : 0)}
            rowHeight={rowHeight}
        >
            { checked && userContext.isDataAdmin() &&

                <Column sortable={false} key={'key'} width={80} align="left" fixed>
                        
                    <HeaderCell style={headerStyle}>


                        <span style={{display: 'flex', flexDirection: 'column'}}>
                            <span>{ isReportParent ? 'Include?' : 'Publish?'}</span>
                            { showSaveCheckedButton && isReportParent &&
                                <button 
                                    onClick={() => {
                                        handleParentCheckedSaved(checked)
                                        setShowSaveCheckedButton(false)
                                    }}
                                >
                                    save
                                </button> }
                        </span>
                        
                          
                        
                    </HeaderCell>
                    <CheckCell dataKey="key" onChange={(key, isChecked) => isReportParent ? handleParentCheck(key, isChecked) : handleChildCheck(key, isChecked)} checked={checked} />
                        
                </Column>
            }

            { isReportChild &&

                <Column sortable={false} key={'isPublished'} width={100} align="left" fixed>
                        
                    <HeaderCell style={headerStyle}>
                        <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <span>Previously</span>
                            <span>Published</span>
                        </span>
                    </HeaderCell>
                    <IconCell dataKey="isPublished" trueIcon={<CheckIcon />} falseIcon={null}/>
                        
                </Column>
            }
            { isReportChild &&

                <Column sortable={false} key={'updatedSincePublish'} width={100} align="left" fixed>
                        
                    <HeaderCell style={headerStyle}>
                        <span style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <span>Updated Since</span>
                            <span>Published</span>
                        </span>
                    </HeaderCell>
                    <IconCell dataKey="updatedSincePublish" trueIcon={<CheckIcon />} falseIcon={null} />
                        
                </Column>
            }

            { tableDataColumns.map((column, i) => {

                let backgroundColor = null
                let isPopup = false
                let popupNoHeader = false;
                let popupFields = []
                let colorFieldName = ''
                let colorFieldPrefix = null;

                column.extraData && column.extraData.forEach((obj) => {
                    if (obj.name === 'PopUpDisplay') isPopup = obj.value
                    if (obj.name === 'PopUpDisableHeader') popupNoHeader = obj.value
                    if (obj.name === 'PopUpDisplayFields') popupFields = obj.value
                    if (obj.name === 'BackgroundColor') backgroundColor = getColor(obj.value)
                    if (obj.name === 'ColorFieldName') colorFieldName = obj.value
                    if (obj.name === 'ColorFieldPrefix') colorFieldPrefix = obj.value;
                })

                let width

                if (column.isDate || column.IsDate) {
                    width = getDateColumnWidth(column)
                } else if (column.numeric) {
                    width = getNumericColumnWidth(column)
                } else {
                    width = getTextColumnWidth(column)
                }

                let fieldName = column.fieldName ? column.fieldName : column.FieldName
                let fieldLabel = column.fieldLabel ? column.fieldLabel : column.FieldLabel


                // customizing cell
                let cell

                if (isPopup) {
                    cell = <PopupCell fields={popupFields} isReportChild={isReportChild} getColor={getColor} colorFieldName={colorFieldName} dataKey={fieldName} colorFieldPrefix={colorFieldPrefix} />
                } else {
                    cell = <CustomCell column={column} isReportChild={isReportChild} overrideFields={overrideFields} getColor={getColor} colorFieldName={colorFieldName} definedBackgroundColor={backgroundColor} dataKey={fieldName} colorFieldPrefix={colorFieldPrefix} style={{ padding: '0px 8px 0px 8px', fontSize: '12px', fontWeight: '200', color: 'black', lineHeight: rowHeight + 'px' }} /> 
                } 

                return (
                    <Column sortable={isPopup && popupNoHeader ? false : true} key={i} width={isPopup && popupNoHeader? 50 : width} resizable={resizable} align="center">
                        
                        <HeaderCell style={headerStyle}>
                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                { (!isPopup || !popupNoHeader) && wordWrapHeaders && getHeaderLines(column).map((line, i) => <span key={i}> {line} </span>) }
                                { (!isPopup || !popupNoHeader) && !wordWrapHeaders && <span> {fieldLabel} </span> }
                            </span>
                        </HeaderCell>
                        
                        { cell }

                    </Column>
                )
            })}

            { isReportChild && userContext.isDataAdmin() && <Column fixed='right' width={50}>

                <HeaderCell style={headerStyle}>
                    
                </HeaderCell>
                
                <EditCell handleClick={handleEditClick}/>

            </Column> }


            { isReportChild && userContext.isDataAdmin() && <Column fixed='right' width={50}>

                <HeaderCell style={headerStyle}>
                    
                </HeaderCell>
                
                <DeleteCell handleClick={handleDeleteClick}/>

            </Column> }

        </Table>
    )
}

export default RsuiteTable


// cell that shows data on popover
const PopupCell = ({ rowData, dataKey, fields, colorFieldName, getColor, isReportChild, colorFieldPrefix, ...props }) => {

    
    const speaker = (
        <Popover >
            { fields.map((field, i) => {

                let properties = {}

                { field.forEach((property) => { properties[property.name] = property.value }) }

                let fieldNamePath = properties['FieldName'].split('.')

                let displayValue = rowData[fieldNamePath[0]]
                for (let i = 1; i < fieldNamePath.length; i++) {
                    displayValue = displayValue[fieldNamePath[i]]
                }

                if (properties['IsDate'] && properties['IsDate'] === true && properties['DateFormat']) {
                    displayValue = moment.tz(displayValue, sessionStorage.getItem(TENANT_TIMEZONE)).format(properties['DateFormat'])
                }

                return (
                    <p key={i} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'flex-start', gap: '.5rem'}}>
                        <b>{properties['FieldLabel']}</b> 
                        <span style={{ width: '200px', display: 'inline-block', textOverflow: 'ellipsis', wordWrap: 'break-word', overflow: 'hidden', maxHeight: '7.2em', lineHeight: '1.8em' }}    > 
                            {displayValue || 'N/A'}
                        </span>
                    </p>
                )
            })}
        </Popover>
    );

    let iconColor = '#00a1aa';

    if (colorFieldName) {
        let colorFieldPath = colorFieldName.split('.')
    
        iconColor = rowData[colorFieldPath[0]]
        for (let i = 1; i < colorFieldPath.length; i++) {
            iconColor = iconColor[colorFieldPath[i]]
        }
    }

    if (colorFieldPrefix) {
        iconColor = colorFieldPrefix + '.' + colorFieldValue;
    }

    return (
        <Cell {...props} dataKey={dataKey} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Whisper placement="left" speaker={speaker}>
                { ((isReportChild && rowData[dataKey] !== null && rowData[dataKey].trim().length !== 0) || !isReportChild) ?
                    <InfoIcon style={{ color: getColor(iconColor), fontSize:'18px'}} />
                    :
                    <></>
                }
            </Whisper>
        </Cell>
    );
}

const CustomCell = (props) => {

    const { rowData, dataKey, colorFieldName, definedBackgroundColor, getColor, colorFieldPrefix, overrideFields, isReportChild, column} = props

    let colorFieldValue = null;
    if (colorFieldName) {
        let colorFieldPath = colorFieldName.split('.')
    
        colorFieldValue = rowData[colorFieldPath[0]]
        for (let i = 1; i < colorFieldPath.length; i++) {
            colorFieldValue = colorFieldValue[colorFieldPath[i]]
        }
    }

    if (colorFieldPrefix) {
        colorFieldValue = colorFieldPrefix + '.' + colorFieldValue;
    }

    let displayDataKey = dataKey

    if (isReportChild && overrideFields[dataKey] && rowData[overrideFields[dataKey]] !== null) {
        displayDataKey = overrideFields[dataKey]
    }

    let value = rowData[dataKey]

    if (column.dateFormat) {
        value = moment.tz(value, sessionStorage.getItem(TENANT_TIMEZONE)).format(column.dateFormat)
    }

    return (
        <Table.Cell 
            {...props} 
            dataKey={dataKey} 
            style={{
                ...(props.style), 
                backgroundColor: colorFieldValue 
                    ? getColor(colorFieldValue) 
                    : definedBackgroundColor ? getColor(definedBackgroundColor) : 'null' 
                }}
        >
            {rowData[dataKey]}
        </Table.Cell>
    );
}

const CheckCell = React.memo(({ onChange, checked, dataKey, ...props }) => { 

    return (
        <Table.Cell {...props} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>
                <Checkbox
                    value={props.rowData[dataKey]}
                    onChange={onChange}
                    checked={ checked.includes(props.rowData[dataKey]) }
                />
            </span>
        </Table.Cell>
    )
})

const IconCell = React.memo(({ onChange, trueIcon, falseIcon, dataKey, ...props }) => { 

    return (
        <Table.Cell {...props} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {props.rowData[dataKey] && trueIcon}
            {!props.rowData[dataKey] && falseIcon}
        </Table.Cell>
    )
})

const EditCell = React.memo(({ handleClick, ...props }) => {

    const themeContext = React.useContext(ThemeContext)

    return (
        <Table.Cell {...props} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 0}} >
            <EditIcon style={{color: themeContext.getColor('primary.Primary'), cursor: 'pointer'}} size='xs' onClick={() => handleClick(props.rowData)}/>      
        </Table.Cell>
    ); 
})

const DeleteCell = React.memo(({ handleClick, ...props }) => {

  const themeContext = React.useContext(ThemeContext)
  let show = true
  if ( props.rowData['contributingModeledEventIds'].length !== 0 || props.rowData['contributingEventIds'].length !== 0 || props.rowData['isPublished'] === true) show = false

  return (
      <Table.Cell {...props} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: 0}} >
          <DeleteIcon style={{color: alpha(themeContext.getColor('primary.Primary'), show ? 1 : .5), cursor: show ? 'pointer' : 'default', }} size='xs' onClick={ show ? () => handleClick(props.rowData) : null}/>
      </Table.Cell>
  ); 
})