/* Component for Reporting Dashboard- CSO Discharge Dashboard*/

import React, { useEffect,useContext,useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _, { get } from 'lodash';
import {IconButton} from 'rsuite'
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import { MenuContext } from '../../contexts/MenuContext';
import { RouteContext } from '../../contexts/RouteContext';
import { UserContext } from '../../contexts/UserContext';
import { DateContext } from '../../contexts/DateContext';
import { ExplorerContext, ExplorerProvider } from '../../contexts/ExplorerContext';
import TOC from './TOC'
import Chart from './Chart'
import SplitIcon from '@rsuite/icons/Split'
import "rsuite/dist/rsuite.min.css";
import TopMenu from '../topmenu/TopMenu'


const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

const DataExplorerDashboard = (props) => { 
    const { classes, model, match, history, loadDashboard, dashboards, selectedDashboard } = props;
    
    const menuContext = useContext(MenuContext);
    const routeContext = useContext(RouteContext);
    const userContext = useContext(UserContext);
    const dateContext = useContext(DateContext);
    const explorerContext = useContext(ExplorerContext)

    const { charts } = explorerContext
    const { collapsedDrawerWidth } = menuContext;
    const {tenant} = userContext

    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;
    const frameOffset = "calc(" + drawerWidth + "px)";
    const frameWidth = "calc(100vw - " + drawerWidth + "px)";

    useEffect(() => {

        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'DataExplorer',
            viewType: match.params.viewType,
            layerName: match.params.layerName,
            assetName: match.params.assetName
        })
        return() => {
            model.updateValues({
                selectedItem: null,
                highlightedAsset: null,
                selectedStatus: null
            })
            dateContext.updateValues({
                validDates: [],
                validDatesError: null
            })
        }
    }, [])

    useEffect(() => {
        if (!_.isEqual(routeContext.state.tenant, match.params.tenantName)) {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            if (!_.isEmpty(newTenant)) {
                userContext.switchTenant(newTenant.id);
                routeContext.updateValues({
                    tenant: newTenant.tenantName
                });
            }
            else {
                history.push('/unknown');
            }
        }
    }, [routeContext.state.tenant, match.params.tenantName])

    useEffect(() => {
        
        if (dashboards.length > 0 && selectedDashboard == null)
        {
            let newDashboard = dashboards.find(x=> x.dashboardName == match.params.viewType);
            if (newDashboard) {
                loadDashboard(newDashboard.dashboardCards, newDashboard);
            }
            else {
                history.push('/unknown');
            }
        }

    }, [selectedDashboard])

    return (
        <span className={classes.root}>
            <TopMenu
                selectedDashboard={selectedDashboard}
                displayFeatureSelector={true}
                model={model}
                menuContext={menuContext}
                match={match}
                location={location}
                history={history}
            />
            <span style={{ display: 'flex', flexDirection: 'row', marginLeft: frameOffset, marginTop: '64px', width: frameWidth}}>
                <span style={{height: 'calc(100vh - 64px)', minWidth: '22rem', backgroundColor: '#d1d1d1', overflow: 'auto', borderRight: '8px solid dimgray',}}>
                    <TOC />
                </span>
                <span style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flexGrow: 1, overflow: 'auto'}}>
                    {charts.map((chart, i) => 
                        <Chart id={chart.id} key={chart.id} index={i} frameOffset={frameOffset}/>
                    )}
                </span>
            </span>
        </span>
    )
}

DataExplorerDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const DataExplorerComponent = withStyles(styles, { withTheme: true })(DataExplorerDashboard);
function useDesktopLayout(props) {
    const matches = useMediaQuery('(min-width:1367px)');
    return (
        <ExplorerProvider>
            <DataExplorerComponent {...props} isDesktop={matches}>
            </DataExplorerComponent>
        </ExplorerProvider>
    )
}

export default useDesktopLayout;