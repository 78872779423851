import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid, Typography, Tooltip } from '@material-ui/core';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import ImportContactsTwoToneIcon from '@material-ui/icons/ImportContactsTwoTone';
import IconButton from '@material-ui/core/IconButton';
import PipeCastIcon from '../../img/pipeCAST logo.svg';
import WeeklySelector from "./WeeklySelector";
import moment from 'moment-timezone';
import _ from 'lodash';
import { TENANT_TIMEZONE } from "../../constants/TimezoneConstants";

const styles = theme => ({
    appBar: {
        position: "fixed",
        zIndex: theme.zIndex.drawer - 1,
        boxShadow: "none",
        backgroundColor: "#FFFFFF" //#D8E9F4
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    title: {
        position: 'static',
        height: 28,
        left: 0,
        top: 0,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 20,
        lineHeight: 25, 
        display: 'flex',
        alignItems: 'center',
        letterSpacing: 0.5,
        color: 'rgba(0, 0, 0, 0.87)',
        flex: 'none',
        order: 0,
        alignSelf: 'center',
        flexGrow: 0,
        margin: '5px 0px'
    },
    lastUpdated: {
        position: 'static',
        left: 0,
        top: 0,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        alignItems: 'center',
        letterSpacing: '.25px'
    }
});
class NewTopMenu extends React.Component {

    constructor (props) {
        super(props);
        this.state= {
            customSelectionIsDisabled : false
        }
    }

    showCustomDateRange = () => {
        this.setState( {
            customSelectionIsDisabled : false
        })
    }

    hideCustomDateRange = () => {
        this.setState( {
            customSelectionIsDisabled : true
        })
    }

    render () {
        const { classes, menuContext, selectedDashboard, configuration, themeContext, dateContext, userContext, modelContext} = this.props;
        var open = menuContext.getLockOpen();
        var formattedDate = (!_.isEmpty(dateContext.validDates)) ? moment.tz(dateContext.validDates[dateContext.validDates.length-1], sessionStorage.getItem(TENANT_TIMEZONE)).format('MMMM Do YYYY') : null;
        var dateMessage = formattedDate ? 'Last updated: ' + formattedDate : '';
        var drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : 0;
        return (
            <AppBar className={classNames(classes.appBar)} 
                style={{left:drawerWidth, width: "calc(100% - " + drawerWidth + "px)"}}>
                <Toolbar style={{paddingLeft: '12px'}}>
                    <Grid container direction='row' spacing={1} alignItems='center'>
                        
                        {   
                            !menuContext.getLockOpen() &&
                                <Grid item>
                                    <IconButton onClick={menuContext.floatOpenDrawer} style={{color: 'white'}}>
                                        <img src={PipeCastIcon} style={{width: 100, height: 30}}/>
                                    </IconButton>
                                </Grid>
                        }
                        
                        <Grid item container direction='row' xs justify='space-between' alignItems='center'>
                            <Grid item container direction='column' xs style={{marginLeft: '0px'}}>
                                <Grid item xs>
                                    <Typography component="h2" variant="title" classes={{title: classes.title}} style={{marginTop: '-10px'}} >
                                        {selectedDashboard ? selectedDashboard.dashboardName : null}
                                    </Typography>
                                </Grid>
                                <Grid item container direction='row' justify='flex-start' alignItems='center' style={{marginTop: '-15px', height: '20px', color: 'rgba(0,0,0,0.6)'}}>
                                    <Typography variant='body1' classes={{title: classes.lastUpdated}}>
                                        {dateMessage}
                                    </Typography>
                                    <Tooltip title={configuration.description} aria-label={configuration.description} arrow placement={'bottom'}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '20px',
                                            lineHeight: 'normal'
                                        }}>
                                            <InfoTwoToneIcon style={{color: themeContext.getColor('primary.Primary'), fontSize: '80%'}} />
                                            <p style={{color: themeContext.getColor('primary.Primary'), fontSize: '80%'}}>&nbsp;More Info</p>
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={configuration.sources} aria-label={configuration.sources} arrow placement={'bottom'}>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '20px',
                                            lineHeight: 'normal'
                                        }}>
                                            <ImportContactsTwoToneIcon style={{color: themeContext.getColor('primary.Primary'), fontSize: '80%'}} />
                                            <p style={{color: themeContext.getColor('primary.Primary'), fontSize: '80%'}}>&nbsp;Sources</p>
                                        </div>
                                    </Tooltip>
                                </Grid> 
                            </Grid>
                            {
                                configuration.calendarType == "Weekly" &&
                                <Grid item xs>
                                    <WeeklySelector currentDashboard={selectedDashboard} modelContext={modelContext} userContext={userContext} dateContext={dateContext} themeContext={themeContext} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        );
    }
}

NewTopMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewTopMenu);