import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

export default forwardRef((props, primaryChartRef) => {
    let { height, width, margin, scales, scaleName, customTimeFormat, customTicks, isNumeric, useBrowserTz } = props;
    if(!customTimeFormat) customTimeFormat = 'M/D';
   
    function drawXAxisGroup(newScales) {
        scales = newScales;
        draw();
    }

    function draw() {
        const primaryChart = d3.select(primaryChartRef.current);
                   
        const xAxisGroup = primaryChart.select('.x-axis-group');

        const scale = scales[scaleName];

        const hasDomain = scale.domain && scale.domain() && scale.domain().length > 2;

        let tickValues = [
            scale.invert(0), 
            scale.invert((width - margin.left - margin.right) / 4.0), 
            scale.invert((width - margin.left - margin.right) / 2.0), 
            scale.invert(3 * (width - margin.left - margin.right) / 4.0),
            scale.invert(width - margin.left - margin.right)
        ];

        if(customTicks && customTicks.length) {
            tickValues = customTicks;
        } else if (hasDomain) {
            tickValues = scale.domain().filter((d, i) => {
                const isFirst = i === 0;
                const isLast = i === scale.domain().length - 1;
                
                const domainCount = scale.domain().length;
                const isMiddle = !(i % Math.floor(domainCount / 3));
    
                return isFirst || isLast || isMiddle;
            });
        }
        
        const formatTick = d => useBrowserTz ? moment(d).format(customTimeFormat) : moment.tz(d, sessionStorage.getItem(TENANT_TIMEZONE)).format(customTimeFormat);
        let xAxis = d3.axisBottom(scale)
                .tickFormat(formatTick)
                .tickValues(tickValues);

        if(isNumeric) {
            tickValues = tickValues.map(d => isNaN(d) ? 0 : d);
            xAxis = d3.axisBottom(scale)
                .tickFormat(d3.format("d"))
                .tickValues(tickValues);
        }

        xAxis(xAxisGroup);
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawXAxisGroup);
        draw();
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(), [scales]);

    return (
        <g className="x-axis-group xAxisGroup" 
            transform={"translate(0, " + (height - margin.top - margin.bottom) + ")"} 
            style={{ font: "1rem Roboto", strokeOpacity: "0.38", shapeRendering: "crispEdges", color: "rgba(0,0,0,0.6)" }}>
        </g>
    );
})
