import React, { useState, useContext, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { MenuContext } from '../../contexts/MenuContext';
import { Card, Tab, Tabs, Typography } from '@material-ui/core';
import ModalBackground from '../ModalBackground';
import CloseButton from '../CloseButton';
import AccountInfo from './AccountInfo';
import HelpAndFeedback from './HelpAndFeedback';
import UserPreferences from './UserPreferences';
import _ from 'lodash';

const styles = theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  title: {
    position: 'relative',
    top: '15px',
    left: '15px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '.25px',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  tabs: {
    flexGrow: 1
  },
  card: {
    position: "fixed",
    overflowY: 'auto',
    top: "25%",
    left: "25%",
    height: "50vh",
    width: "50vw",
    zIndex: 6001 //Pass zIndex 6000 to modal background, using zIndex 6001 ensures the card stacks on top of the background
  }

});

const SettingsPanel = (props) => {
  const { classes } = props;

  const menuContext = useContext(MenuContext);

  const [open, setOpen] = useState(menuContext.state.settingsPanelOpen);
  const [value, setValue] = useState(0);

  const handleClick = () => {
    menuContext.toggleSettingsPanel();
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  }

  useEffect( () => {
    setOpen(menuContext.state.settingsPanelOpen);
  }, [menuContext.state.settingsPanelOpen])

  return (
    <div className={classes.root}>
      {
        open &&
        <>
          <Card className={classes.card} elevation={24}>
            <Typography className={classes.title}>
              Your Account
            </Typography>
            <CloseButton handleClick={handleClick} />
            <Tabs className={classes.tabs} value={value} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
              <Tab label="Account Settings"/>
              <Tab label="Notifications" />
              <Tab label="Help & Feedback"/>
            </Tabs>
            {
              value == 0 &&
              <AccountInfo />
            }
            {
              value == 1 && 
              <UserPreferences />
            }
            {
              value == 2 &&  
              <HelpAndFeedback />
            }
          </Card>
          <ModalBackground zIndex={6000} onClick={() => { }} />
        </>
      }
    </div>
  )
}

export default withStyles(styles)(SettingsPanel);

