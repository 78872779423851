import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: "0 0 6px 0"
    },
    textField: {
        width: '140px'
    },
});

class DateSelector extends React.Component {

    handleChange = (event) => {
        var updatedDate = new Date(event.target.value);
        if(typeof(this.props.onChange) !== "undefined") {
            this.props.onChange(updatedDate, event);
        }
    }

    render() {
        const { classes, error, label, isDisabled, selectedDate } = this.props;

        return (
            <form className={classes.container} noValidate>
                <TextField
                    error = {error}
                    id="date"
                    label={label}
                    type="date"
                    onChange = {this.handleChange}
                    className={classes.textField}
                    disabled ={isDisabled}
                    value = {selectedDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </form>
        );
    }
}

export default withStyles(styles)(DateSelector);