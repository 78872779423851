import React from 'react';
import _ from 'lodash';

export const RouteContext = React.createContext(null);

// Route: /{tenant}/{pageType}/{layoutType}/{viewType}/{layerName}/{assetName}
const initialRoute = {
    tenant: null, // tenant name
    pageType: null, // dashboards, admin
    layoutType: null, // performance/operations, settings
    viewType: null, // overview/event
    layerName: null, // Regulators, Pipes, Manholes, etc.
    assetName: null, // asset
};

class RouteContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialRoute
        };
    }

    updateValues = async toUpdate => {
        const nextState = this.state;
        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && nextState[key] != value) {
                nextState[key] = value;
            }
        }

        this.setState(nextState);
    }

    render() {
        return (
            <RouteContext.Provider value={{
                state: this.state,
                updateValues: this.updateValues,
            }}>
                {this.props.children}
            </RouteContext.Provider>
        )
    }
}

export default RouteContextProvider;