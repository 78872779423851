import React, {useEffect, forwardRef, useRef, useState } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import simplify from '../../../util/simplify-date';
import { 
    getBrushedBounds,
    createTimeScale,
    createValueScale
} from '../chartComponents/util';
import { differenceInCalendarWeeks } from 'date-fns';
import {
    ClipPath,
    YGridLines,
    XAxisGroup,
    YAxisGroup,
    YAxisLabel,
    LineSeries,
    SingleSeriesBar,
    CurveStep,
    AlertMarker,
    CleaningMarker,
    AreaSeries,
    InspectionMarker,
    Tooltip
} from '../chartComponents';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

export default forwardRef((props, primaryChartRef) => {
    const tooltipRef = useRef(null);

    let { hiddenSeriesList, height, width, data, margin, dateContext, extraData, clipPathId, selectedDashboard } = props;

    const leftAxisLabel = (extraData.find(a => a.name == "LeftAxisLabel") || {}).value;
    const rightAxisLabel = (extraData.find(a => a.name == "RightAxisLabel") || {}).value;

    // left y axis label placement
    const yOffset = 75;
    const xOffset = -(height - margin.top - margin.bottom ) / 2;


    var tz = sessionStorage.getItem(TENANT_TIMEZONE);
    let initialStartDate = moment.tz(dateContext.startDate, tz);
    let initialEndDate = moment.tz(dateContext.realEndDate, tz);
    let { validDates} = dateContext;
    const dateRangeLimit = selectedDashboard?.dateRangeLimit;

    //If validDates exists, set the startDate to either 52 weeks or dateContext.startDate, whichever one is the earliest.
    //If validDates does NOT exist, show data for the past 12 weeks

    if (dateRangeLimit) {
        initialStartDate = !_.isEmpty(validDates) 
            ? moment.tz(endDate, tz).subtract(Math.min(dateRangeLimit, differenceInCalendarWeeks(new Date(endDate), new Date(validDates[0])) + 1), 'week')
            : moment.tz(endDate, tz).subtract(12, 'week');
    }
    let [startDate, setStartDate] = useState(initialStartDate);
    let [endDate, setEndDate] = useState(initialEndDate);

    useEffect(() => {
        redraw(null, moment.tz(dateContext.startDate, tz), moment.tz(dateContext.realEndDate, tz));
    }, [dateContext])

    let scales = getScales();

    setFormattedData(false);

    function getScales() {
        let [minLeft, maxLeft, minRight, maxRight] = getBrushedBounds(data, startDate, endDate, 'Data', hiddenSeriesList || []);
        const leftBuffer = (maxLeft - minLeft) * 0.1;
        const rightBuffer = (maxRight - minRight) * 0.1;

        const timeScale = createTimeScale(startDate, endDate, margin, width);
        const leftScale = createValueScale(minLeft, maxLeft + leftBuffer, margin, height);
        const rightScale = createValueScale(minRight, maxRight + rightBuffer, margin, height);
        const boolScale = createValueScale( 0, 1, margin, height);

        return {
            timeScale,
            leftScale,
            rightScale,
            boolScale,
            minLeft: leftScale(minLeft),
            minRight: rightScale(minRight),
            minBool: boolScale(0)
        };
    }

    const generateTooltipInfo = (chartObj, x, mouseLocation) => {
        const style = `color: ${chartObj.Color}`;
        try {
            var chartData = chartObj.Data;
            var formattedValue;
            if (chartObj.ChartType === 'alert') {
                const allowance = 0.0015 * width;
                let x0 = scales.timeScale.invert(mouseLocation[0] - allowance);  
                var tz = sessionStorage.getItem(TENANT_TIMEZONE);
                x0 = moment.tz(x0, tz);
                let x1 = scales.timeScale.invert(mouseLocation[0] + allowance);  
                x1 = moment.tz(x1, tz);
                var chartObjData = chartData.filter(a => moment.tz(a.date, tz) <= x1 && moment.tz(a.date, tz) >= x0);
                if (chartObjData.length === 0) {
                    formattedValue = '-'
                } else {
                    formattedValue = 'Yes'
                }
            } else {
                if (chartObj.ChartType === 'bar') {
                    x.set({seconds: 0, milliseconds: 0})
                } else {
                    x.set({seconds: 1})
                }

                const bisectDate = d3.bisector(function (d) { return d.date; }).left;
                let i = bisectDate(chartData, x);

                // bisectDate(data, x) finds the array index at which x should be inserted to maintain order,
                // so, if i == 0 or i >= data.length, there is no data for x date
                if (i == 0 || i >= chartData.length) return `<li><span style="${style}">${chartObj.Name}: - </span></li>`;
    
                if (chartObj.ChartType === 'line' || chartObj.ChartType === 'dashedLine') {
                    i--;
                }

                const d = chartData[i];
                
                formattedValue = chartObj.Axis != 'none' ? d.value.toFixed(4) : d.value !== 0 ? 'Yes' : 'No';
            }

            return `<li><span style="${style}">${chartObj.Name}: ${formattedValue}</span></li>`
        } catch (err) {
            return `<li><span style="${style}">${chartObj.Name}: - </span></li>`
        }
    }

    function getTooltipData(svgRef) {
        let mouseLocation = d3.mouse(svgRef);
        let x0 = scales.timeScale.invert(mouseLocation[0]);
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        x0 = moment.tz(x0, tz);

        let html = `<span>${x0.format('ddd MMM D YYYY h[:]mm a z')}</span><ul style="list-style-type:none;padding:2px; margin:2px">`;
        data.filter(d => hiddenSeriesList.indexOf(d.Name) == -1).forEach(chartObj => {
            html = html + generateTooltipInfo(chartObj, x0, mouseLocation)
        });
        html = html + "</ul>";

        const y1 = scales.boolScale(1);
        const y2 = scales.boolScale(0);
        const xValue = scales.timeScale(x0.valueOf());

        return { html, xValue, y1, y2 };
    }

    function setSimpleData() {
        setFormattedData(true);
        redraw();
    }

    function setFullData(providedHiddenSeriesList) {
        if(providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }
        setFormattedData(false);
        redraw();
    }

    function setFilterDates(newStartDate, newEndDate) {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setFormattedData(false, newStartDate, newEndDate);
        redraw();
    }

    function setFormattedData(brushing, start = null, end = null) {
        if (data) {
            data.map(async series => {
                if(series.ChartType == 'line' || series.ChartType == 'curveStep' || series.ChartType == 'area' || series.ChartType === 'dashedLine') {
                    if (brushing) {
                        series.formattedData = series.brushData;
                    }
                    else {
                        if (start && end) {
                            series.formattedData = series.Data.filter(x => (start <= x.date && end >= x.date));
                        } else {
                            series.formattedData = series.Data;
                        }
                    }
                } else {
                    //Non-Linear values
                    series.formattedData = series._formattedData;
                }
            });
        }
    }

    function redrawChildren() {
        const { redrawFns } = primaryChartRef.current;
        redrawFns && redrawFns.forEach(fn => {
            fn(scales, hiddenSeriesList);
        });
    }

    // called by either the parent component or brush bar component
    // to update scales/bounds and redraw the appropriate child components
    // these changes are handled through d3 to avoid awkward react redraws
    function redraw(providedHiddenSeriesList, brushStartDate, brushEndDate) {
        if(providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }

        if(brushStartDate && brushEndDate) {
            setStartDate(brushStartDate);
            setEndDate(brushEndDate);
        }

        scales = getScales();

        redrawChildren();
    }

    const setRef = node => {
        if(node) {
            var origRedraws = primaryChartRef.current?.redrawFns || [];
            primaryChartRef.current = node;
            primaryChartRef.current.redrawFns = origRedraws;
            
            primaryChartRef.current.getTooltipData = getTooltipData;
            primaryChartRef.current.setSimpleData = setSimpleData;
            primaryChartRef.current.setFullData = setFullData;
            primaryChartRef.current.redraw = redraw;
            primaryChartRef.current.setFilterDates = setFilterDates;
        }
    }

    const innerWidth = width - margin.left - margin.right;

    const series = data ? data.sort((a, b) => {
        if(a.Order > b.Order) return 1;
        if(a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = 'key_' + Math.random();
        if (hiddenSeriesList.indexOf(s.Name) > -1) return (<></>);
        if(s.ChartType == 'bar') {
            return (<SingleSeriesBar key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></SingleSeriesBar>);
        } else if (s.ChartType == 'line' || s.ChartType == 'dashedLine') {
            return (<LineSeries isDashed={s.ChartType == 'dashedLine'} key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></LineSeries>);
        } else if (s.ChartType == 'curveStep') { 
            return (<CurveStep clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></CurveStep>);
        } else if (s.ChartType == 'alert') {
            return (<AlertMarker clipPathId={clipPathId} scales={scales} key={s.Name} ref={primaryChartRef} series={s} {...props}></AlertMarker>);
        } else if (s.ChartType == 'area') {
            return (<AreaSeries clipPathId={clipPathId} scales={scales} key={s.Name} ref={primaryChartRef} series={s} dataProp="formattedData" {...props} ></AreaSeries>);
        } else {
            return (<></>);
        }
    }) : (<></>);
    
    return (
        <>                          
            <div ref={tooltipRef} className="tooltip" style={{position: "absolute"}} />  
            <svg width={props.width} height={props.height} >
                <g ref={setRef}  width={props.width - margin.left} height={props.height - margin.top} transform={`translate(${margin.left}, ${margin.top})`} >
                    <ClipPath clipPathId={clipPathId} {...props}></ClipPath>
                    <YGridLines scales={scales} {...props} ref={primaryChartRef} scaleName={'leftScale'}></YGridLines>
                    <XAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"timeScale"}></XAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"leftScale"} handSide={"left"}></YAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"rightScale"} handSide={"right"} transform={`translate(${innerWidth},0)`}></YAxisGroup>
                    <YAxisLabel {...props} ref={primaryChartRef} y={-yOffset} x={xOffset} label={leftAxisLabel} handSide={"left"}></YAxisLabel>
                    <YAxisLabel {...props} ref={primaryChartRef} y={innerWidth + yOffset} x={xOffset} label={rightAxisLabel} handSide={"right"}></YAxisLabel>
                    {series}
                    <Tooltip clipPathId={clipPathId} scales={scales} yMin={scales.boolScale(0)} yMax={scales.boolScale(1)} yOffset={yOffset} {...props} ref={primaryChartRef} tooltipRef={tooltipRef} data={data} getTooltipData={getTooltipData}></Tooltip>
                </g>
            </svg>
        </>
    )
})
