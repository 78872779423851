import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import {
    getDataHelper,
    formatData     
} from './dataUtil';
import ApiHelper from '../../../api/ApiHelper';
import { ReactComponent as EmptyChart } from "../../../assets/images/emptyChart.svg";
import MainChart from './MainChart';
import BrushBar from './BrushBar';
import ChartLegend from '../chartComponents/ChartLegend';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment-timezone';
import UseQueries from '../../caching/UseQueries';
import { differenceInCalendarWeeks } from 'date-fns';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

const styles = () => ({
    chartContainer: {
        height: '100%',
        width: '100%',
        position: "absolute"
    },
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    subtitle: {
        position: 'absolute',
        top: '2.2rem',
        left: '1.7rem',
        fontSize: '1.2rem',
        fontFamily: 'Roboto',
    },
    highlightedAssetName: {
        position: "absolute",
        top: "2.5rem",
        left: "4rem",
        fontFamily: "Roboto",
    },
    text: {
        position: "absolute",
        fontFamily: "Roboto",
        fontSize: "2rem",
        top: "50%",
        left: "50%",
        zIndex: "1",
        transform: "translate(-50%,-50%)"
    },
    progress: {
        top: "35%",
        left: "35%",
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    emptyChart: {
        position: 'absolute',
        top: "15%",
        left: "5%",
        height: "80%",
        width: "90%",
    },
    brushBar: {
        position: 'absolute',
        top: '2.5rem',
        left: '1.5rem',
        height: '3rem',
        right: '1.5rem',
        backgroundColor: 'lightgreen'
    },
    mainChart: {
        position: 'absolute',
        top: '6.5rem',
        left: '1.5rem',
        bottom: '0',
        right: '1.5rem',
        backgroundColor: 'lightblue'
    }
});

const legendContainerStyle = {
    display: "inline-block",
    position: "absolute",
    left: "5%",
    bottom: '8px',
    width: "92%",
    maxHeight: "88px",
    zIndex: 1000
};

class TimeSeriesChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = { clipPathId: 'clip' + Math.round((Math.random() * 1000000)), brushBarClipPathId: 'bbclip' + Math.round((Math.random() * 1000000)) }
        this.hiddenSeriesList = [];
        this.brushBarRef = React.createRef();
        this.mainChartRef = React.createRef();

        const { extraData } = props;

        let showBrushBarObj = extraData.find(a => a.name == "ShowBrushBar")
        this.showBrushBar = typeof showBrushBarObj !== "undefined" ? showBrushBarObj.value : true;

        this.margin = {
            top: 70,
            right: 120,
            bottom: this.showBrushBar ? 150 : 88,
            left: 80
        };
    }

    getSelectedItem() {
        let { model, extraData, selectedItem } = this.props;
        
        if(model) {
            selectedItem = model.selectedItem;
            const defaultSelectedItem = extraData.find(a => a.name == "DefaultSelectedItem");
            if (_.isEmpty(selectedItem) && !_.isEmpty(defaultSelectedItem?.value)) {
                selectedItem = {};
                defaultSelectedItem?.value.forEach(o => selectedItem[o.name] = o.value);

                let assetName = selectedItem.Name;
                if (selectedItem.properties != null) {
                    const selectedAsset = selectedItem;
                    const displayField = model.selectedFeature.displayField;
                    assetName = selectedAsset.properties[displayField];
                }

                selectedItem.Name = assetName;
            }
        }

        return selectedItem;
    }

    getDates() {
        let { dateContext, selectedDashboard } = this.props;
        let { startDate, realEndDate, validDates } = dateContext;
        const dateRangeLimit = selectedDashboard?.dateRangeLimit;

        //If validDates exists, set the startDate to either 52 weeks or dateContext.startDate, whichever one is the earliest.
        //If validDates does NOT exist, show data for the past 12 weeks
        const tz = sessionStorage.getItem(TENANT_TIMEZONE);
        realEndDate = moment.tz(realEndDate, tz);
        if (dateRangeLimit) {
            startDate = !_.isEmpty(validDates)
                ? moment.tz(realEndDate, tz).subtract(Math.min(dateRangeLimit, differenceInCalendarWeeks(new Date(realEndDate), new Date(validDates[0])) + 1), 'week')
                : moment.tz(realEndDate, tz).subtract(dateRangeLimit, 'week');
        } else {
            startDate = moment.tz(startDate, tz);
            realEndDate = moment.tz(realEndDate, tz);
        }
        return { startDate: startDate, endDate: realEndDate };
    }

    async fetchData(apiKey) {
        let { title, colors, api, extraData, errorContext } = this.props;
        title = title.split(" ").join("_");
        const url = this.getUrl(apiKey);
        const response = await getDataHelper(api, apiKey, colors, extraData, url, title, errorContext)
        return response;
    }

    shouldComponentUpdate(nextProps) {
        const { model, dateContext, width, height, selectedItem } = this.props;

        const dataNeedsUpdate = !_.isEqual(dateContext, nextProps.dateContext) ||
            (model && !_.isEqual(model.selectedFeature, nextProps.model.selectedFeature)) ||
            (model && !_.isEqual(model.selectedItem, nextProps.model.selectedItem)) ||
            (!model && !_.isEqual(selectedItem, nextProps.model.selectedItem));

        const dimensionsUpdated = (height != nextProps.height) || (width != nextProps.width);

        return dataNeedsUpdate || dimensionsUpdated;
    }

    componentDidUpdate(nextProps) {
        
        const { model, dateContext, selectedItem } = this.props;
        const dataNeedsUpdate = !_.isEqual(dateContext, nextProps.dateContext) ||
            (model && !_.isEqual(model.selectedFeature, nextProps.model.selectedFeature)) ||
            (model && !_.isEqual(model.selectedItem, nextProps.model.selectedItem)) ||
            (!model && !_.isEqual(selectedItem, nextProps.selectedItem));

        if (dataNeedsUpdate) {
            this.brushBarRef = React.createRef();
            this.mainChartRef = React.createRef();
            this.hiddenSeriesList = [];
        }
    }

    handleDownloadClick() {
        console.log('method not set')
    }

    handlePrintClick() {
        const { width, height, cardRef } = this.props;

        const aspectRatio = width / height;
        const newWidth = 850;
        const newHeight = newWidth / aspectRatio;

        html2canvas(cardRef.current, { scale: 2 })
            .then(canvas => {
                const imgData = canvas.toDataURL('image/PNG');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt'
                });
                pdf.addImage(imgData, 'PNG', 0, newHeight / 5, newWidth, newHeight);
                pdf.save("Timeseries.pdf");
            });
    }

    createCSV(csvData) {
        let csvContent = "Date,";
        let dates = [];

        for (const datum of csvData.filter(c => c.Data)) {
            dates = dates.concat(datum.Data.map(d => d.date));
            csvContent += datum.Name + ","
        }

        dates = [...new Set(dates.map(d => d.toString()))];

        dates.sort(function (a, b) {
            return new Date(a) - new Date(b);
        });

        const maps = [];
        for (const datum of csvData.filter(c => c.Data)) {
            const map = new Map(datum.Data.map( i => [i.date.toString(), i.value]));
            maps.push({ name: datum.Name, map });
        }

        csvContent += "\r\n";
        for (const date of dates) {
            var tz = sessionStorage.getItem(TENANT_TIMEZONE);
            let row = moment.tz(date, tz).format() + ",";

            for (const datum of csvData.filter(c => c.Data)) {
                let match = maps.find(x => x.name == datum.Name);

                let value = match.map.get(date);

                value = (value != null) ? value : '';

                row += value + ","
            }
            csvContent += row + "\r\n";
        }

        const assetName = this.getAssetName();

        var blob = new Blob([csvContent]);
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.setAttribute("download", "timeseries_" + this.props.title + "_" + assetName + ".csv");
        document.body.appendChild(a);
        a.click();

        // const encodedUri = encodeURI(csvContent);

        // const link = document.createElement("a");
        // link.setAttribute("href", encodedUri);

        // link.setAttribute("download", "timeseries_" + this.props.title + "_" + asset + ".csv");
        // document.body.appendChild(link);
        // link.click();
    }

    replaceUrlParam(urlPattern, param, value) {
        return urlPattern.indexOf(param) > -1 ?
            urlPattern.split(param).join(value) :
            urlPattern;
    }

    // ************************************
    // updates triggered by changes to hidden series list are
    // triggered by a state change in ChartLegend propagated
    // through the chart via redraw functions which are handled 
    // by d3 exclusively to avoid triggering awkward react redraws
    // of the chart components
    //
    // this is the callback function called when the ChartLegend's
    // Hidden Series List changes. It propagates changes downward through
    // the child refs
    // ************************************
    redrawChildren = hiddenSeriesList => {
        this.hiddenSeriesList = hiddenSeriesList;
        const redraw = this.mainChartRef.current.redraw;
        redraw(hiddenSeriesList);

        const brushRedraw = this.brushBarRef ? this.brushBarRef.current.redraw : null;
        brushRedraw && brushRedraw(hiddenSeriesList);
    }

    getAssetId() {
        const { model } = this.props;
        const selectedItem = this.getSelectedItem();

        if(model) {
            let propertyName = model.selectedFeature.IdField;
            let assetId = selectedItem && selectedItem.properties ? selectedItem.properties[propertyName] : selectedItem.Id;
            return assetId;
        } else {
            return selectedItem.id;
        }
    }

    getAssetName() {
        const { model } = this.props;
        const selectedItem = this.getSelectedItem();
        if(model) {
            let propertyName = model.selectedFeature.displayField;
            let assetId = selectedItem && selectedItem.properties ? selectedItem.properties[propertyName] : selectedItem.Name;
            return assetId;
        } else {
            return selectedItem.name;
        }
    }

    getUrl(apiKey) {
        const { model, api, userContext } = this.props;

        const tenant = userContext.getTenant();

        const apiObj = api[apiKey];

        const { startDate, endDate } = this.getDates();

        const replacements = {
            assetId: encodeURIComponent(this.getAssetId()),
            assetName: encodeURIComponent(this.getAssetName()),
            tenantName: tenant.tenantName,
            startDate: startDate.format(),
            endDate: endDate.format(),
            ...apiObj.parameters
        };

        let { urlPattern } = apiObj;

        if(model) {
            const { selectedFeature, selectedModel } = model;
            replacements.modelId = selectedModel?.id;
            replacements.layerName = selectedFeature?.layerName;
            replacements.modelName = selectedModel?.name;
            
            if (apiObj?.options?.isModeled || apiKey == 'Simulated') {
                urlPattern = ApiHelper.fillWithSelectedModel(urlPattern, replacements, model);
            } else {
                urlPattern = ApiHelper.fillWithBaseModel(urlPattern, replacements, model);
            }
        }

        let url = new URL(urlPattern, ApiHelper.getUrlPath());

        const { properties } = this.getSelectedItem();
        if (properties) {
            url = ApiHelper.fillFromObj(url, properties);
        }

        return new URL(url, ApiHelper.getUrlPath());
    }

    generateQueries() {
        const { extraData, getColor } = this.props;
        const loadWithoutSelectedItem = extraData.find(a => a.name == "LoadWithoutSelectedItem")?.value;
        const order = extraData.find(x => x.name == "Order").value
            .sort(function (a, b) {
                return a.value > b.value;
            });

        const selectedItem = this.getSelectedItem();
        const queries = loadWithoutSelectedItem || !_.isEmpty(selectedItem) ? order.map(pair => {
            return {
                queryKey: this.getUrl(pair.name),
                queryFn: async () => await this.fetchData(pair.name),
                select: data => formatData(data, getColor),
                enabled: this.isQueryEnabled(pair.name)
            }
        }) : [];
        return queries;
    }

    isQueryEnabled(queryName) {
        const { api } = this.props;
        const queryApi = api[queryName];
        if (queryApi?.options?.isForecast) {
            const { endDate } = this.getDates();
            const diff = moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)).valueOf() - endDate.valueOf();
            const TWO_DAYS_IN_MS = 172800000;
            return diff <= TWO_DAYS_IN_MS;
        }
        return true;
    }

    formatQueryResult(qr, i) {
        const { extraData } = this.props;
        const order = extraData.find(x => x.name == "Order").value
            .sort(function (a, b) {
                return a.value > b.value;
            });

        var name = order[i].name;
        if (!_.isEmpty(qr.data)) {
            return qr.data;
        } else if (qr.isLoading) {
            return { 'Name': name, isLoading: true, 'ChartType': 'Loading' };
        } else {
            return { 'Name': name, 'ChartType': 'NA' };
        }
    }

    render() {
        const { classes, title, extraData, snackbarContext, model } = this.props;
        const loadWithoutSelectedItem = extraData.find(a => a.name == "LoadWithoutSelectedItem")?.value;
        const disableSelectedItemSubtitle = extraData.find(a => a.name == "DisableSelectedItemSubtitle")?.value;
        const selectedItem = this.getSelectedItem();

        const chartContent = loadWithoutSelectedItem || !_.isEmpty(selectedItem) ?
            (
                <UseQueries queries={this.generateQueries()}>
                    {(queryResults) => {
                        const formattedData = queryResults.map(this.formatQueryResult.bind(this));

                        this.handleDownloadClick = () => {
                            this.createCSV(formattedData);
                        }

                        try { 
                            const redraw = this.mainChartRef.current.redraw;
                            redraw(); 
                        } catch { }

                        if (snackbarContext && queryResults.find(m => m.data?.IncludesSubstitutions)) {
                            snackbarContext.addMessage(`A substitute asset was used for this ${model.selectedFeature.layerName} in this time period.`);
                        }
                        
                        return (
                            <div id={title} className={classes.chartContainer}>
                                {this.showBrushBar && <BrushBar ref={this.brushBarRef}
                                    dates={this.getDates()}
                                    clipPathId={this.state.brushBarClipPathId}
                                    mainChartRef={this.mainChartRef}
                                    className={classes.brushBar}
                                    data={formattedData}
                                    {...this.props} ></BrushBar>}

                                <MainChart ref={this.mainChartRef}
                                    hiddenSeriesList={this.hiddenSeriesList}
                                    clipPathId={this.state.clipPathId}
                                    margin={this.margin}
                                    className={classes.mainChart}
                                    data={formattedData}
                                    {...this.props}></MainChart>

                                <ChartLegend
                                    key={Math.random()}
                                    containerStyle={legendContainerStyle}
                                    data={formattedData}
                                    onChange={this.redrawChildren} />
                            </div>
                        )
                    }}
                </UseQueries>
            ) : (
                <>
                    <Typography className={classes.text}>Data Not Available</Typography>
                    <EmptyChart className={classes.emptyChart} />
                </>
            );

        return (
            <div
                style={{ top: 0, bottom: 0, left: 0, right: 0, position: 'absolute' }}>
                <Typography variant="h6" className={this.props.classes.title}>
                    <span>{title}</span>
                </Typography>
                <Typography variant='h6' className={this.props.classes.subtitle}>
                    <span>{!disableSelectedItemSubtitle && this.getAssetName()}</span>
                </Typography>
                {chartContent}
            </div>
        );
    }
}


export default withStyles(styles)(TimeSeriesChart);