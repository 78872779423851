import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import TileTest from "./tileTest";
import TimeSeriesChart from "./charts/TimeSeriesChart";
import GenericTimeSeriesChart from './modelFree/charts/TimeSeriesChart';
import LineChart from './modelFree/charts/LineChart';
import BarChart from "./charts/BarChart"
import PieChart from "./charts/PieChart"
import GenericBarChart from './modelFree/charts/BarChart';
import CleaningTable from './charts/CleaningTable';
import InspectionTable from './charts/InspectionTable';
import DataTable from './modelFree/charts/DataTable';
import ObservationKpi from "./kpi/ObservationKpi";
import ComparisonKpi from "./kpi/ComparisonKpi";
import AssetInformationTable from "./charts/AssetInformationTable";
import WBEKpi from './kpi/WBEKpi';
import CardContainer from "./cardcontainer/CardContainer";
import TableCard from "./charts/TableCard";
import DashboardMap from "./map/DashboardMap";
import SanitarySewerMap from "./map/SanitarySewerMap";
import PrecipiCASTMap from './PrecipiCAST/PrecipiCASTMap';
import CMOMMap from './map/CMOMMap';
import { ThemeContext } from "../contexts/ThemeContext";
import { ModelContext } from "../contexts/ModelContext";
import { DateContext } from '../contexts/DateContext';
import { UserContext } from '../contexts/UserContext';
import { ErrorContext } from '../contexts/ErrorContext';
import _ from 'lodash';
import RsuiteDataTable from "./modelFree/charts/RsuiteDataTable";
import RsuiteReportDataTable from "./modelFree/charts/RsuiteReportDataTable";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashboardFrame extends React.Component {

    static defaultProps = {
        className: "layout",
        cols: { lg: 12, sm: 4 },
        breakpoints: { lg: 980, sm: 0 },
    };

    constructor(props) {
        super(props);
        this.siblingRefs = React.createRef({});
        this.state = {
            items: [],
            currentBreakpoint: "lg",
            layouts: {},
            rowHeight: 115
        };
        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    createElement(selectedDashboard, menuContext, routeContext, maintenanceContext, element, model, themeContext, mapType, selectedItem, setSelectedItem, dashboardAssets, filteredDashboardAssets, snackbarContext) {
        let { theme, getColor } = themeContext;
        var componentMap = {
            'ObservationKpi': ObservationKpi,
            'WBEKpi' : WBEKpi,
            'ComparisonKpi' : ComparisonKpi,
            'Map' : DashboardMap,
            'SanitarySewerMap' : SanitarySewerMap,
            'CMOMMap' : CMOMMap,
            'Bar' : BarChart,
            'GenericBarChart': GenericBarChart,
            'TimeSeries' : TimeSeriesChart,
            'GenericTimeSeries' : GenericTimeSeriesChart,
            'LineChart' : LineChart,
            'TileTest' : TileTest,
            'Table' : TableCard,
            'CleaningTable' : CleaningTable,
            'InspectionTable' : InspectionTable,
            'ConfiguredTable' : DataTable,
            'RSuiteTable' : RsuiteDataTable,
            'RSuiteReportTable' : RsuiteReportDataTable,
            'AssetInformationTable' : AssetInformationTable,
            'PrecipiCASTMap' : PrecipiCASTMap,
            'Pie': PieChart
        }

        const myRef = React.createRef();
        const title = element.title;
        let card = componentMap[element.subType]

        const cardProps = {
            'title': element.title,
            'description': element.description,
            'type': element.type,
            'trendingUp' : element.trendingUp ? element.trendingUp : null,
            'trendingDown' : element.trendingDown ? element.trendingDown : null,
            'isExpandable': element.isExpandable,
            'isDownloadable': element.isDownloadable,
            'isNonsortable': element.isNonsortable,
            'customYMin': element.customYMin ? element.customYMin: null,
            'customYMax': element.customYMax ? element.customYMax: null,
            'api': element.api,
            'tableFields': element.tableFields,
            'colors': element.colors,
            'cardRef': myRef,
            'id': element.id,
            'extraData': element.extraData ? element.extraData : [],
            'options': element.options,
            'siblingRefs': this.siblingRefs
        };
        return (
            <div ref={myRef} key={title} data-grid={element} style={{ marginBottom: '10px' }}>
                <UserContext.Consumer>
                    {(user) => (
                    <DateContext.Consumer>
                        {(date) => (
                            <ErrorContext.Consumer>
                                {(error) => (
                                    <CardContainer card={card}
                                        cardProps={{ selectedDashboard: selectedDashboard, theme: theme, model: { ...model }, userContext: user, maintenanceContext: maintenanceContext, dateContext: date, errorContext: error, itemType: mapType, menuContext: menuContext, routeContext: routeContext, alertContext: { ...alert }, getColor: getColor, customYScale: element.customYScale, selectedItem, setSelectedItem, dashboardAssets, filteredDashboardAssets, snackbarContext: snackbarContext, ...cardProps }}
                                        cardRef={myRef}
                                        cardType={element.type}
                                        cardSubType={element.subType} 
                                        siblingRefs={this.siblingRefs}
                                    >
                                    </CardContainer>
                                )}
                            </ErrorContext.Consumer>
                        )}
                    </DateContext.Consumer>
                    )}
                </UserContext.Consumer>
            </div>
        );
    }

    onLayoutChange(layout, layouts) {
        this.setState({ layouts });
    }

    onAddItem(jsonItems) {
        //Create Temp array of items to be added
        var temp = [];

        jsonItems.forEach(item => {
            temp.push({
                c: item,
                title: item.title,
                description: item.description,
                x: item.x,
                y: item.y, // puts it at the bottom
                w: item.width,
                h: item.height,
                id: item.id,
                isDraggable: item.isDraggable,
                subType: item.subType,
                type: item.type,
                isExpandable: item.isExpandable,
                isDownloadable: item.isDownloadable,
                isNonsortable: item.isNonsortable,
                trendingUp: item.trendingUp,
                trendingDown : item.trendingDown,
                customYMin: item.customYMin,
                customYMax: item.customYMax,
                api: item.api,
                tableFields: item.tableFields,
                colors: item.colors,
                extraData: item.extraData,
                options: item.options,
            })
        })

        this.setState({
            // Add a new item. It must have a unique key!
            items: this.state.items.concat(temp),
        }, () => window.dispatchEvent(new Event('resize')))
    }

    onBreakpointChange = breakpoint => {
        this.setState({
            currentBreakpoint: breakpoint
        });
    };

    componentDidMount() {
        if (this.props.importLayout != null && !_.isEmpty(this.props.importLayout)) {
            let jsonObject = this.props.importLayout;
            if (Object.keys(jsonObject).length === 0 && jsonObject.constructor === Object) {
                return;
            }
            this.setState({
                items: []
            }, () => {
                this.onAddItem(jsonObject);
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let jsonObject = this.props.importLayout
        if (Object.keys(jsonObject).length === 0 && jsonObject.constructor === Object) {
            return;
        }

        //This selects the correct initial layout. 
        //Other layouts will still adjust if the screen size changes
        if (prevProps.importLayout !== this.props.importLayout) {
            this.setState({
                items: []
            }, () => {
                this.onAddItem(jsonObject);
            })
        }

        window.dispatchEvent(new Event('resize'))
    }

    render() {
        const {rowHeight, layouts, items} = this.state;
        const {frameWidth, frameOffset, mapType, selectedDashboard, menuContext, routeContext, maintenanceContext, selectedItem, setSelectedItem, dashboardAssets, filteredDashboardAssets, snackbarContext} = this.props;

        return (
            <ModelContext.Consumer>
                {model => (
                    <ThemeContext.Consumer>
                        {({ theme, getColor, updateTheme }) => (
                            <div style={{
                                height: "calc(100vh - 64px)",
                                width: frameWidth,
                                display: 'flex',
                                top: "64px",
                                left: frameOffset,
                                overflowY: "auto",
                                overflowX: "hidden",
                                position: "relative"
                            }}>
                                <ResponsiveReactGridLayout style={{height: '100%', width: frameWidth}}
                                    width={1}
                                    rowHeight={rowHeight}
                                    layouts={layouts}
                                    onLayoutChange={(layout, layouts) =>
                                        this.onLayoutChange(layout, layouts)
                                    }
                                    onBreakpointChange={this.onBreakpointChange}
                                    {...this.props}>
                                    {items.map(el => this.createElement(selectedDashboard, menuContext, routeContext, maintenanceContext, el, model, { theme, getColor }, mapType, selectedItem, setSelectedItem, dashboardAssets, filteredDashboardAssets, snackbarContext))}
                                </ResponsiveReactGridLayout>
                            </div>
                        )}
                    </ThemeContext.Consumer>
                )}
            </ModelContext.Consumer>


        );
    }
}

export default DashboardFrame;