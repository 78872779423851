import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import setBrush from './setBrush';
import {
    getBrushedBounds,
    createTimeScale,
    createValueScale
} from '../../../../charts/chartComponents/util';
import {
    ClipPath,
    XAxisGroup,
    LineSeries,
    AreaSeries,
    SingleSeriesBar,
    CurveStep,
    AlertMarker
} from '../../../../charts/chartComponents';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../../../constants/TimezoneConstants';

export default forwardRef((props, brushBarRef) => {

    let { width, height, data, mainChartRef, brushBarStartDate, historicalDataStartDate, extraData } = props;

    const margin = {
        top: height - 120,                //Original: 18.5% (0.815) Test: 0.815
        right: 35,   //Original: 9%
        bottom: 78,              //Original: 10% (0.90) Test: 0.80
        left: 80     //Original: 9%
    };

    const clipPathId = 'clip' + Math.round((Math.random() * 10000));

    const customTimeFormatObj = extraData.find(e => e.name == "MomentTimeFormat");
    const customTimeFormat = customTimeFormatObj ? customTimeFormatObj.value : null;

    const customTicks = extraData.find(e => e.name == "CustomTicks");

    const dataDrivenTimeScaleObj = extraData.find(e => e.name == "DataDrivenTimeScale");
    const dataDrivenTimeScale = dataDrivenTimeScaleObj ? dataDrivenTimeScaleObj.value : false;

    let startDate = moment.tz(historicalDataStartDate, sessionStorage.getItem(TENANT_TIMEZONE));
    let endDate = startDate.add(1, 'day');

    var customTimeScale = extraData.find(e => e.name == "CustomTimeScale");
    if(customTimeScale) {
        startDate = customTimeScale.value[0] == "today" ? moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)) : moment.tz(customTimeScale.value[0], sessionStorage.getItem(TENANT_TIMEZONE));
        endDate = customTimeScale.value[1] == "today" ? moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)) : moment.tz(customTimeScale.value[1], sessionStorage.getItem(TENANT_TIMEZONE));
        brushBarStartDate = startDate;
    } else if (dataDrivenTimeScale) {
        if(data.find(d => d.Data)) {
            for(var series of data.filter(d => d.Data)) {
                try {
                    if(!startDate || startDate > series.Data[0].date) {
                        startDate = moment.tz(series.Data[0].date, sessionStorage.getItem(TENANT_TIMEZONE))
                        brushBarStartDate = startDate;
                    }
                } catch(ex) {
                    var a = ex;
                    debugger;
                    // noop
                }
            }
        }
    }
    let scales = getScales();

    function getScales() {
        let [minLeft, maxLeft, minRight, maxRight] = getBrushedBounds(data, startDate, endDate, 'Data', []);

        const leftBuffer = (maxLeft - minLeft) * 0.1;
        const rightBuffer = (maxRight - minRight) * 0.1;

        const timeScale = createTimeScale(startDate, endDate, margin, width);
        const leftScale = createValueScale(minLeft, maxLeft + leftBuffer, margin, height);
        const rightScale = createValueScale(minRight, maxRight + rightBuffer, margin, height);
        const boolScale = createValueScale(0, 1, margin, height);

        return {
            timeScale,
            leftScale,
            rightScale,
            boolScale,
            minLeft: leftScale(minLeft),
            minRight: rightScale(minRight),
            minBool: boolScale(0)
        };
    }

    function redraw(hiddenSeriesList = []) {
        scales = getScales();

        const { redrawFns } = brushBarRef.current;

        redrawFns && redrawFns.forEach(fn => {
            fn(scales, hiddenSeriesList);
        });
    }

    function init() {
        brushBarRef.current.redraw = redraw;

        const brushBar = d3.select(brushBarRef.current);

        const { timeScale } = scales;

        let [lastStart, lastEnd] = timeScale.range();
        lastStart = timeScale.invert(lastStart);
        lastEnd = timeScale.invert(lastEnd);

        const callbacks = {
            onBrushStart: () => {
                const setSimpleData = mainChartRef.current.setSimpleData;
                setSimpleData();
            },
            onBrush: (startPos, endPos) => {
                const mainRedraw = mainChartRef.current.redraw;

                const startDate = timeScale.invert(startPos);
                const endDate = timeScale.invert(endPos);

                const startChange = Math.abs(+lastStart - +startDate)
                const endChange = Math.abs(+lastEnd - +endDate)
                const totalChange = startChange + endChange;

                const currentRange = +endDate - +startDate;

                if (totalChange < currentRange / 50 || currentRange == 0) return;

                lastStart = startDate;
                lastEnd = endDate;

                mainRedraw(null, startDate, endDate);
            },
            onBrushEnd: (startPos, endPos) => {
                const mainRedraw = mainChartRef.current.redraw;

                if (startPos == endPos) {
                    const maxExtent = [(width - margin.left - margin.right), (height - margin.top - margin.bottom)];
                    mainRedraw(null, lastStart, lastEnd);
                    return setBrush(brushBar, timeScale, maxExtent, lastStart, lastEnd, callbacks);
                }

                const startDate = timeScale.invert(startPos);
                const endDate = timeScale.invert(endPos);

                mainRedraw(null, startDate, endDate);

                //Using this to pass start/end dates for counting datapoints within selection
                //for simplification check
                const setFilterDates = mainChartRef.current.setFilterDates;
                setFilterDates(startDate, endDate);

                // const setFullData = mainChartRef.current.setFullData;
                // setFullData();
            }
        }
        const maxExtent = [(width - margin.left - margin.right), (height - margin.top - margin.bottom)];

        //Set initial brush bar dates
        setBrush(brushBar, timeScale, maxExtent, brushBarStartDate, endDate, callbacks);
    }

    useEffect(init, [brushBarRef]);

    const setRef = node => {
        if (node) {
            brushBarRef.current = node;
            brushBarRef.current.redrawFns = [];
        }
    }

    const seriesList = data.sort((a, b) => {
        if (a.Order > b.Order) return 1;
        if (a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = Math.random();
        if (s.ChartType == 'bar') {
            return (<SingleSeriesBar key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></SingleSeriesBar>);
        } else if (s.ChartType == 'line') {
            return (<LineSeries key={key} scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></LineSeries>);
        } else if (s.ChartType == 'curveStep') {
            return (<CurveStep key={key} scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></CurveStep>);
        } else if (s.ChartType == 'alert') {
            return (<AlertMarker key={key} scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s}></AlertMarker>);
        } else if (s.ChartType == 'area') {
            return (<AreaSeries key={key} scales={scales} key={s.Name} areaHeight={(height - margin.top - margin.bottom)} {...props} ref={brushBarRef} series={s} dataProp="brushData"></AreaSeries>);
        } else {
            return (<></>);
        }
    });

    return (
        <svg width={width} height={height - margin.top} style={{ position: 'absolute', top: margin.top }} >
            <g ref={setRef} width={width - margin.left} transform={`translate(${margin.left}, 0)`}>
                <ClipPath clipPathId={clipPathId} margin={margin} {...props}></ClipPath>
                <XAxisGroup useBrowserTz={true} scales={scales} margin={margin} {...props} ref={brushBarRef} scaleName={"timeScale"} customTimeFormat={customTimeFormat} customTicks={customTicks}></XAxisGroup>
                {seriesList}
                <g className="brush"></g>
            </g>
        </svg>
    );
});