import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Warning from '@material-ui/icons/ErrorTwoTone';
import FeatureAttributeTable from './FeatureAttributeTable';
import CMOMAttributeTable from './CMOMAttributeTable';
import VirtualList from 'react-tiny-virtual-list';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { ThemeContext } from "../../contexts/ThemeContext";
import withLoading from "../withLoading";
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';

const VirtualListWithLoading = withLoading(VirtualList);

const styles = theme => ({
	list: {
		overflow: "auto",
		width: "100%",
		height: "calc(100vh - 250px)",
	},
	listItem: {
		cursor: 'pointer',
		'&:hover': {
			border: '2px solid black'
		}
	}
});

class ListFeaturePanel extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			anchor: 'left',
			selectedItem: null,
			clientHeight: 1,
			clientWidth: 1
		};
	}

	handleChange = event => {
		this.setState({
			anchor: event.target.value,
		});
	};

	handleListItemClick = (event, item) => {
		const selectedItem = this.props.model.state.value.selectedItem;

		const {model, routeContext, errorContext, date, history, match} = this.props;
		const {tenant, pageType, layoutType, viewType, layerName} = routeContext.state;

		const timescale = 'timescale=' + date.timescale;
		const startDate = date.timescale == 'custom' && date.startDate ? '&startDate=' + date.startDate : '';
		const endDate = date.timescale == 'custom' && date.endDate ? '&endDate=' + date.endDate : '';

		errorContext.updateValues({errors: []});

		if (item && selectedItem && item.id === selectedItem.id){
			const basePath = `/${tenant}/${pageType}/${layoutType}/${viewType}/${layerName}`;
			if(basePath + '?' + timescale + startDate + endDate != history.location.pathname.concat(history.location.search)) {
				history.push(basePath + '?' + timescale + startDate + endDate);
			}
			
			this.props.model.updateHighlightedAsset(null);
			routeContext.updateValues({
				assetName: null
			});
		} else {
			const displayName = item && item.properties[model.selectedFeature.displayField];
			const basePath = `/${tenant}/${pageType}/${layoutType}/${viewType}/${layerName}${displayName ? `/${encodeURIComponent(displayName)}` : ''}`;
			if(basePath + '?' + timescale + startDate + endDate != history.location.pathname.concat(history.location.search) && layerName == match.params.layerName) {
				history.push(basePath + '?' + timescale + startDate + endDate);
			}

			this.props.model.updateHighlightedAsset(item);
			routeContext.updateValues({
				assetName: displayName
			});
		}
	};

	handleAttributesClick = (event, item) => {
		event.stopPropagation();
		this.props.errorContext.updateValues({errors: []});
		this.props.model.updateHighlightedAsset(item, true);
	};

	componentDidMount() {
		this.setState({
			clientHeight: this.refs.listContainer.clientHeight,
			clientWidth: this.refs.listContainer.clientWidth
		});
	}

	componentDidUpdate(prevProps, prevState){
		const nextState = {};

		const { listContainer } = this.refs;
		const { clientHeight, clientWidth } = this.state;
		const { selectedFeature, selectedItem, match, history, assets, routeContext } = this.props;
		const { highlightedAsset } = this.props.model.state.value;

		if (assets.length > 0 && selectedFeature && _.isEmpty(selectedItem) && match.params.assetName)
		{
			const matchingAsset = assets.find(x => x.properties[selectedFeature.displayField] == decodeURIComponent(match.params.assetName));
			this.handleListItemClick(null, matchingAsset);
		}

		if (listContainer.clientHeight != clientHeight || listContainer.clientWidth != clientWidth) {
			nextState.clientHeight = listContainer.clientHeight
			nextState.clientWidth = listContainer.clientWidth            
        }

		if (selectedFeature && selectedFeature !== prevProps.selectedFeature) {
			this.props.model.updateValue('attributesShown', false);
		}

		Object.keys(nextState).length && this.setState(nextState);

		if(!_.isEmpty(assets) && highlightedAsset != match.params.assetName && highlightedAsset != decodeURIComponent(match.params.assetName) && selectedFeature.layerName == match.params.layerName && history.action == "POP") {
			if(match.params.assetName) {
				// has asset to go back to
				let selectedItem = this.props.model.state.value.assets.find(item => item.properties[selectedFeature.displayField] == decodeURIComponent(match.params.assetName));

				this.props.model.updateHighlightedAsset(selectedItem);
				routeContext.updateValues({
					assetName: selectedItem.properties[selectedFeature.displayField]
				});
			} else {
				// no asset to go back to
				this.props.model.updateHighlightedAsset(null);
				routeContext.updateValues({
					assetName: null
				});
			}
		}
    }

	listItemRenderer = ({index, style}) => {
		const { classes } = this.props;
		const { assets, selectedFeature } = this.props;
		const { selectedStatus, selectedItem } = this.props.model;
		const item = assets[index];

		if(!item) return null;		

		const selectedStatusIsValid = selectedStatus && selectedStatus.classes;
		const itemClassIsValid = item.properties.class && item.properties.statusClassType;

		const classItem = selectedStatusIsValid && itemClassIsValid ? 
			selectedStatus.classes.find(o => o.value === item.properties.class) : 
			null;

		const statusDisplayIsValid = selectedStatusIsValid && 
			itemClassIsValid &&
			selectedStatus.displayName != null && 
			selectedStatus.displayName != 'None';

		let itemDisplay = item.properties[selectedFeature.displayField];
		itemDisplay = itemDisplay && itemDisplay.trim();
		itemDisplay = itemDisplay == '' ? null : itemDisplay;

		const canDisplay = !!item.id && !!itemDisplay;

		return (
			<ListItem key={Math.random()} button={canDisplay} 
					className={canDisplay ? classes.listItem : ''}
					selected={selectedItem && selectedItem.id == item.id}  
					key={"asset_list" + index} 
					style={style}
					onClick={canDisplay ? event => this.handleListItemClick(event, item) : () => {}}>
				
				<ListItemTooltip classItem={classItem}></ListItemTooltip>

				<Tooltip title={itemDisplay}>
					<ListItemText
						disableTypography
						style={{
							wordBreak: 'break-all',
							overflow: 'hidden',
							fontSize: (itemDisplay && itemDisplay.length >= 20) ? '.75rem' : '1rem',
							marginLeft: statusDisplayIsValid ? '15px' : '40px',
							color: 'black'
						}}
						primary={itemDisplay || 'Display Not Found'} />
				</Tooltip>

				<ViewItemDetailsButton item={item} 
					handleAttributesClick={this.handleAttributesClick}>
				</ViewItemDetailsButton>

			</ListItem> 
		);
	}

	getSelectedIndex(item) {
		for (let i= 0; i < this.props.assets.length; i++) {
			if (item && this.props.assets[i].id == item.id)
				return i;
		}
		return null;
	}

	closePanel =() => {
		this.props.model.updateValue('attributesShown', false);
	}

	render() {
		const { classes, model, showPerformanceAttributes, maintenanceContext, assets, statusResults, selectedFeature, selectedItem, isLoading } = this.props;
		const { clientHeight, clientWidth } = this.state;
		const { attributesShown } = this.props.model.state.value;
		const selectedFeatureIndex = this.getSelectedIndex(this.props.model.selectedItem);
		return (
			<>				
				{
					attributesShown && showPerformanceAttributes &&
						<FeatureAttributeTable featureData={assets[selectedFeatureIndex]} 
							close={this.closePanel} 
							modelContext={model}/>
				}
				{
					attributesShown && !showPerformanceAttributes &&
						<CMOMAttributeTable
							maintenanceContext={maintenanceContext} 
							featureData={assets[selectedFeatureIndex]} 
							close={this.closePanel} />
				}
				<div className={classes.list} ref="listContainer">
					<VirtualListWithLoading isLoading={isLoading}
						width={clientWidth} 
						height={clientHeight} 
						itemCount={assets.length} 
						itemSize={50} 
						assets={this.props.assets}
						statusResults={this.props.statusResults}
						renderItem={this.listItemRenderer} 
						statusResults={statusResults}
						selectedfeature={selectedFeature}
						selecteditem={selectedItem}
						scrollToIndex={selectedFeatureIndex}
						scrollToAlignment={"auto"} />
				</div>
			</>
		);		
	}
}

ListFeaturePanel.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListFeaturePanel);

const ListItemTooltip = ({ classItem }) => {
	if(classItem) {
		return (
			<ThemeContext.Consumer>
				{({theme, getColor, updateTheme}) => (
					<Tooltip title={classItem.name}><Warning style={{fill: getColor(classItem.color)}}/></Tooltip>
				)}
			</ThemeContext.Consumer>
		);
	}

	return (<></>);
}

const ViewItemDetailsButton = ({ item, handleAttributesClick }) => (
	<IconButton onClick={event => handleAttributesClick(event, item)}>
		<ThemeContext.Consumer>
			{({theme, getColor, updateTheme}) => (
				<Icon style={{color: getColor("primary.Primary"), zIndex:1000}}>list_alt</Icon>
			)}
		</ThemeContext.Consumer>
	</IconButton>
)