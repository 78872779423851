import React from 'react';
import PipecastLoader from './PipecastLoader';
import {ModelContext} from "../contexts/ModelContext";
import { ThemeContext } from "../contexts/ThemeContext";
import { MenuContext } from '../contexts/MenuContext';
import { DateContext } from '../contexts/DateContext';
import { Switch, Redirect, Route } from 'react-router-dom';
import { RouteContext } from '../contexts/RouteContext';
import { ErrorContext } from '../contexts/ErrorContext';
import { SnackbarContext } from '../contexts/SnackbarContext';

class Pipecast extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.tenant !== this.props.tenant);
    }

    render() {
        return(
            <RouteContext.Consumer>
                {routeContext => (
                    <DateContext.Consumer>
                        {date => (
                            <MenuContext.Consumer>
                                {menuContext => (
                                    <SnackbarContext.Consumer>
                                        {snackbarContext => (
                                            <ModelContext.Consumer>
                                                {model => (
                                                    <ErrorContext.Consumer>
                                                        {error => (
                                                            <ThemeContext.Consumer>
                                                                { theme => (
                                                                    <PipecastLoader theme={theme} date={date} model={model} themeContext={theme} userContext={this.props.userContext} menuContext={menuContext} routeContext={routeContext} errorContext={error} snackbarContext={snackbarContext}/>
                                                                )}
                                                            </ThemeContext.Consumer>
                                                        )}
                                                    </ErrorContext.Consumer>
                                                )}
                                            </ModelContext.Consumer>
                                        )}
                                    </SnackbarContext.Consumer>
                                )}
                            </MenuContext.Consumer>
                        )}
                    </DateContext.Consumer>
                )}
            </RouteContext.Consumer>);
    }
}

export default Pipecast;

