import React, { useEffect } from 'react'
import { ExplorerContext } from '../../contexts/ExplorerContext'
import { DateContext } from '../../contexts/DateContext'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import ChartEdit from './ChartEdit'
import _ from 'lodash'
import ReferenceLineModal from './modals/ReferenceLineModal'
import EditSeriesModal from './modals/EditSeriesModal'
import randomColor from 'randomcolor'

Highcharts.addEvent(Highcharts.Chart, 'exportData', function(e) {
    let dryPeriodLowIndex = -1
    e.dataRows[0].forEach((attribute, i) => { if (attribute.includes('dry period (low)')) dryPeriodLowIndex = i })
    dryPeriodLowIndex !== -1 && e.dataRows.forEach(function(el) {
        el.splice(dryPeriodLowIndex, 1);
    });
});

require('highcharts/modules/stock')(Highcharts); 
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);  
require('highcharts/modules/offline-exporting')(Highcharts); 
require('highcharts/modules/export-data')(Highcharts); 
require('highcharts/modules/data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/pattern-fill')(Highcharts);
require('highcharts/modules/boost')(Highcharts);

const Chart = (props) => {

    const { id, index, frameOffset } = props

    const dateContext = React.useContext(DateContext)
    const explorerContext = React.useContext(ExplorerContext)


    const { getApiInfo, getSeriesData, findSeriesIndex, getConfig, updateData, getDashStyle, getSuffix, getChartType, getYAxis, getSeriesName, getAttributes, getSizingStyle, openNotification } = explorerContext

    let chart = React.useRef({})
    let seriesToEdit = React.useRef({})
    let referenceMetric = React.useRef('')
    let properties = React.useRef({ x: 0, y: 0, width: 0, height: 0})
    let uniqueYAxes = React.useRef([])
    let legend = React.useRef({
        height: 0,
        width: 0
    })

    const [seriesObjects, setSeriesObjects] = React.useState([])
    const [referenceLines, setReferenceLines] = React.useState({})
    const [axesOffsets, setAxesOffsets] = React.useState([0, 50, 0, 50])
    const [editSeriesModalOpen, setEditSeriesModalOpen] = React.useState(false)
    const [referenceLineModalOpen, setReferenceLineModalOpen] = React.useState(false)
    const [usedColors, setUsedColors] = React.useState([])
    const [isModal, setIsModal] = React.useState(false)

    useEffect(() => {

        const onChange = async () => {
            chart.current.showLoading('loading...')
            let newSeriesObjects = await updateData(seriesObjects, dateContext.startDate, dateContext.endDate)
            chart.current.hideLoading()
            setSeriesObjects(newSeriesObjects)
        }

        onChange()
        
    }, [dateContext.startDate, dateContext.endDate])

    const onDrop = async (e) => {

        chart.current.showLoading('loading...')

        let newSeriesObjects = _.cloneDeep(seriesObjects)
        let data = e.dataTransfer.getData('foo')

        if (data === "" || data === null) {
            console.log('invalid drag and drop')
            chart.current.hideLoading()
            openNotification('error', 'chart')
            return
        }

        data = JSON.parse(data)

        let attributes = data.attribute !== 'all' ? [data.attribute] : getAttributes(data.layer)

        for (let i in attributes) {
            let asset = data.asset
            let attribute = attributes[i]
            let model = attribute.includes('simulated') ? data.model : null

            if (findSeriesIndex(seriesObjects, asset, attribute, model) !== -1) continue
            let ApiInfo = getApiInfo(data.asset, data.layer, attribute, model, dateContext.startDate, dateContext.endDate)
            let resData = await getSeriesData(ApiInfo)
            let config = getConfig(ApiInfo, resData)
            let seriesColor = randomColor({luminosity: 'light'})
            while (usedColors.includes(randomColor)) {
                seriesColor = randomColor({luminosity: 'light'})
            }
            config.options.color = seriesColor
            let newUsedColors = _.cloneDeep(usedColors)
            newUsedColors.push(seriesColor)
            setUsedColors(newUsedColors)
            if (resData) {
                resData && Object.keys(resData).length > 0 && newSeriesObjects.push(config)
                resData && Object.keys(resData).length === 0 && openNotification('error', 'chart')
            } else {
                openNotification('error', 'chart')
            }
            
        }
        setSeriesObjects(newSeriesObjects) 
        chart.current.hideLoading()
    }

    const formatDataForHighcharts = (currentData, attribute) => {
        // format into [[date, value], ...] 
        let data = currentData.map((obj, i) => {
            let ts = obj.date.unix() * 1000
            let val = obj.value
            return attribute === 'dry period' ? [ts, 0, val] : [ts, val]
        })
        return data
    }

    const toHighchartsFormat = (seriesObjects) => {

        let data = []
        // add datasets to graph by iterating through seriesData state variable

        seriesObjects.forEach((series) => {

            if (!series.data) return

            let highchartsSeriesObject = {}
            let yAxis = getYAxis(series.attribute)
            let name = getSeriesName(series)
            let type = getChartType(series.attribute)
            let chartData = formatDataForHighcharts(series.data, series.attribute)
            if (!uniqueYAxes.current.includes(yAxis)) uniqueYAxes.current.push(yAxis)
            let suffix = getSuffix(series.attribute)
            let color = series.options.color
            let dashStyle = getDashStyle(series.attribute)
            let visible = series.options.visible !== null ? series.options.visible : true
            
            highchartsSeriesObject = {
                name: name,
                data: chartData,
                type: type,
                yAxis: yAxis,
                color: color,
                fillColor: {
                    pattern: {
                        color: color
                    }
                },
                tooltip: {
                    valueSuffix: suffix
                },
                enableMouseTracking: type !== 'arearange' ? true : false,
                dashStyle: dashStyle,
                lineWidth: series.options.thickness || 1,
                pointWidth: series.options.thickness || 1,
                visible: visible
            }
            highchartsSeriesObject.data && data.push(highchartsSeriesObject)
        })

        Object.keys(referenceLines).forEach((name) => { // reference lines

            let line = referenceLines[name]

            data.push({
                name: 'ref : ' + name,
                type: 'line',
                yAxis: getYAxis('observed ' + line.metric),
                xAxis: 1,
                tooltip: {
                    enabled: true,
                    valueSuffix: getSuffix('observed ' + line.metric)
                },
                symbol: {
                    enabled: false
                },
                lineWidth: line.thickness,
                data: [
                    [0, line.value],
                    [1, line.value],
                ],
                enableMouseTracking: true,
            })
        })

        // if no data, add this so that lines and stuff loads
        if (data.length === 0) { data.push({ type: 'line', data: [], name: 'no data', showInLegend: true, enableMouseTracking: false, }) }

        return data
    }

    const configureChart = (data) => {
        return {
            chart: {
                margin: [20, axesOffsets[1], legend.height, axesOffsets[3]],
                zoomType: 'x',
                events: {

                    load() {
                        this.xAxis[0].setExtremes()
                        this.xAxis[1].setExtremes()
                        chart.current = this
                    },

                    redraw() {
                        let newOffsets = _.cloneDeep(axesOffsets)
                        if (axesOffsets[3] !== this.axisOffset[3] && this.axisOffset[3] !== 0) {
                            newOffsets[3] = this.axisOffset[3]
                            setAxesOffsets(newOffsets)
                        }
                        if (axesOffsets[1] !== this.axisOffset[1] && this.axisOffset[1] !== 0) {
                            newOffsets[1] = this.axisOffset[1]
                            setAxesOffsets(newOffsets)
                        }
                        legend.current.width = this.legend.legendWidth
                        legend.current.height = this.legend.legendHeight
                        properties.current = {
                            x: 0,
                            y: 0, 
                            width: this.chartWidth,
                            height: this.chartHeight,
                        }
                    }

                },
            },
            boost: {
                enabled: true,
                seriesThreshold: 5,
            },
            plotOptions: {
                series: {
                    dataGrouping: {
                        enabled: false
                    },
                    boostThreshold: 4000,
                    events: {
                        legendItemClick: function (e) {

                            e.preventDefault() // forget what this does

                            if (this.name.includes('ref')) { // reference line, just delete on click
                                let components = this.name.split(' ')
                                let copy = _.cloneDeep(referenceLines)
                                delete copy[components[2]]
                                setReferenceLines(copy)
                                return
                            }

                            if (this.name.includes('no data')) { // no data indicator, do nothing on click
                                return
                            }

                            seriesToEdit.current = this
                            setEditSeriesModalOpen(true)
                            
                        }
                    },
                },
                column: {
                    clip: false
                },
                arearange: {
                    fillColor: {
                        pattern: {
                            path: {
                                d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                                strokeWidth: 3
                            },
                            width: 10,
                            height: 10,
                            opacity: 0.3,
                        }
                    },
                    lineWidth: 0,
                    enableMouseTracking: false,
                    step: 'left'
                },
            },
            xAxis: [
                { // 0
                    type: 'datetime',
                    crosshair: true,
            
                },
                {
                    type: 'linear',
                    labels: {
                        enabled: false
                    },
                    visible: false,
                } // for reference lines, 1
            ],
            yAxis: [
                { // empty y axis
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    }
                },
                uniqueYAxes.current.indexOf(1) > -1 && { // dry period
                    height: '100%',
                    max: 1,
                    min: 0,
                    labels: {
                        enabled: false
                    },
                    title: {
                        text: null
                    }
                },
                uniqueYAxes.current.indexOf(2) > -1 && { // rainfall
                    top: '0%',
                    title: {
                        text: 'rainfall (in)',
                        style: {
                            color: '#2e7ac7'
                        }
                    },
                    labels: {
                        format: '{value} in',
                        style: {
                            color: '#2e7ac7'
                        }
                    },
                    offset: 50,
                    min: 0,
                    crosshair: true,
                }, 
                uniqueYAxes.current.indexOf(3) > -1 && { // depth
                    title: {
                        text: 'depth (ft)',
                        style: {
                            color: 'dimgrey'
                        }
                    },
                    labels: {
                        format: '{value} ft',
                        style: {
                            color: 'dimgrey'
                        }
                    },
                    min: 0,
                    opposite: false,
                    crosshair: true,
                },
                uniqueYAxes.current.indexOf(5) > -1 && { // flow
                    title: {
                        text: 'flow (mgd)',
                        style: {
                            color: '#48a166'
                        }
                    },
                    labels: {
                        format: '{value} mgd',
                        style: {
                            color: '#48a166'
                        }
                    },
                    min: 0,
                    opposite: false,
                    crosshair: true,
                }
            ],
            loading: {
                labelStyle: {
                    fontSize: '1.4rem'
                }
            },
            legend: {
                enabled: true
            },
            scrollbar: { // keep this here, it auto adjusts the scrollbar when zoom maybe
                enabled: true
            },
            exporting: {
                enabled: false,
                filename: 'chart'
            },
            tooltip: {
                split: false,
                shared: true,
                valueDecimals: 4,
            },
            rangeSelector: { // default set to true
                enabled: false
            },
            series: data
        }
    }

    let style = getSizingStyle(index)
    style.display = 'flex'
    style.flexDirection = 'column'
    
    return (
        <React.Fragment>
            <span style={style}>
                <div 
                    style={isModal ? {position: 'fixed', left: frameOffset, top: '64px', height: 'calc(100% - 64px - 8rem)', width: `calc(100% - ${frameOffset} - 8rem)`, margin: '4rem 4rem', background: 'rgba(0, 0, 0, 0.7)', zIndex: 100, boxShadow: '0 7px 30px -10px rgba(150,170,180,0.5)',} : {position: 'relative', width: '100%', height: '100%', margin: '0rem'}} id={`wrapper-${id}`} 
                    onDragEnter={ (e) => { e.preventDefault() }} 
                    onDragOver={ (e) => { e.preventDefault() }} 
                    onDragLeave={ (e) => { e.preventDefault() }} 
                    onDrop={onDrop} 
                >
                    <ChartEdit chartID={id} chart={chart} isModal={isModal} setIsModal={setIsModal} seriesObjects={seriesObjects} setSeriesObjects={setSeriesObjects} setReferenceLines={setReferenceLines} properties={properties.current} />
                    <HighchartsReact
                        id={id} 
                        highcharts={Highcharts} 
                        constructorType={'stockChart'} 
                        options={configureChart(toHighchartsFormat(seriesObjects))} 
                        containerProps={{ style: { height: "calc(100% - 4rem)", } }} 
                    />
                </div>
            </span>
            <EditSeriesModal open={editSeriesModalOpen} setOpen={setEditSeriesModalOpen} referenceMetric={referenceMetric} setReferenceLineModalOpen={setReferenceLineModalOpen} seriesObj={seriesToEdit} seriesObjects={seriesObjects} setSeriesObjects={setSeriesObjects}/>
            <ReferenceLineModal open={referenceLineModalOpen} setOpen={setReferenceLineModalOpen} referenceMetric={referenceMetric} setReferenceLines={setReferenceLines} referenceLines={referenceLines} />
        </React.Fragment>
    )
}

export default Chart