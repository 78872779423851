import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { ThemeContext } from '../../contexts/ThemeContext';

const styles = theme => ({
    withDownload : {
        position: 'absolute',
        right: '91px',
        top: '0px',
        zIndex: '6001'
    },
    withoutDownload : {
        position: "absolute",
        right: "48px",
        top: "0px",
        zIndex: "6001" 
    }
});

const PrintButton = (props) => {
    return (        
        <div className={props.isDownloadable ? props.classes.withDownload: props.classes.withoutDownload} data-html2canvas-ignore="true">
            <IconButton onClick={props.handleClick} title='print' >
                <ThemeContext.Consumer>
                    {({ getColor }) => (
                        <Icon style ={{color: getColor("primary.Primary")}}>print</Icon>
                    )}
                </ThemeContext.Consumer>
            </IconButton>
        </div>
    )
}

PrintButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrintButton);;