import React, {useRef, useEffect, useState, createElement} from "react";
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card"
import classNames from 'classnames';
import FullScreenButton from "./FullScreenButton";
import DownloadButton from "./DownloadButton";
import ExitFullScreenButton from "./ExitFullScreenButton";
import ModalBackground from "../ModalBackground";
import PrintButton from "./PrintButton";
import InfoHover from "./InfoHover";
import _ from 'lodash';
import PreviewReportButton from "./PreviewReportButton";
import AddRowButton from "./AddRowButton";
import ToggleCsoAutomatedPublishingCheckbox from "./ToggleCsoAutomatedPublishingCheckbox";

const styles = theme => ({
    cardContents : {
        height: "100%",
        width: "100%",
    },
    modalMode : {
        position: "fixed",
        top: "5%",
        left: "5%",
        height: "90vh",
        width: "90vw",
        zIndex : "6001"
    }
});

const CardContainer = (props) => {    

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [isPrinting, setIsPrinting] = useState(false);
    const [previousStyle, setPreviousStyle] = useState({});

    const childRef = useRef({});
    const divElement = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect( () => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevProps = usePrevious(props);

    const updateDimensions = (drawerWidth, prevDrawerWidth) => {
        let h = isFullscreen ? divElement.current.clientHeight * .9 : divElement.current.clientHeight;
        let w = isFullscreen ? divElement.current.clientWidth * .9 : divElement.current.clientWidth;

        if (!_.isEqual(h, height) || !_.isEqual(w, width) || !_.isEqual(drawerWidth, prevDrawerWidth)) {
            setHeight(h);
            setWidth(w);
        }
    }

    useEffect( () => {
        window.addEventListener('resize', updateDimensions)
    }, []);

    useEffect( () => () => {
        window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect( () => {
        let currentDrawerWidth;
        let prevDrawerWidth;

        try {
            currentDrawerWidth = props.cardProps.menuContext.state.drawerWidth;
            prevDrawerWidth = prevProps.cardProps.menuContext.state.drawerWidth;
        } catch (e) {
            currentDrawerWidth = null;
            prevDrawerWidth = null;
        }

        updateDimensions(currentDrawerWidth, prevDrawerWidth);
    });

    useEffect( () => {
        const {cardRef} = props;
        if (isFullscreen && cardRef && cardRef.current && cardRef.current.style) {
            cardRef.current.style.transform = 'none';
            cardRef.current.style.position = "fixed";
            cardRef.current.style.left = "0%";
            cardRef.current.style.top = "0%";
            cardRef.current.style.height = "100vh";
            cardRef.current.style.width = "100vw";
            cardRef.current.style.zIndex = "6001";
        }
    }, [props.cardRef]);

    const generateCardContents = () => {
        const contentProps = props.cardProps;
        contentProps.isFullscreen = isFullscreen;
        contentProps.height = height;
        contentProps.width = width;

        let title = contentProps.title

        if (props.siblingRefs) {

            let queryKey

            if (contentProps.extraData) {
                
                contentProps.extraData.forEach((obj) => {
                    if (obj.name === 'TableOptions') {
                        obj.value.forEach((option) => {
                            if (option.name === 'QueryKey') { queryKey = option.value }
                        })
                    }
                })

            }
            if (queryKey) {
                if (!props.siblingRefs.current) props.siblingRefs.current = { [queryKey]: childRef}
                else props.siblingRefs.current[queryKey] = childRef
            }
        }
        
        const cardContents = createElement(
            props.card,
            {
                ...contentProps,
                cardType: props.cardType,
                cardSubType: props.cardSubType,
                dateContext: contentProps.dateContext,
                ref: childRef,
                innerRef: node => {childRef.current = node;}
            }
        );
        return cardContents;
    }

    const changeToModal = () => {
        const prevStyle = {
            transform: props.cardRef.current.style.transform,
            position: props.cardRef.current.style.position,
            left: props.cardRef.current.style.left,
            top: props.cardRef.current.style.top,
            height: props.cardRef.current.style.height,
            width: props.cardRef.current.style.width,
            zIndex: props.cardRef.current.style.zIndex,
        }

        props.cardRef.current.style.transform = "none";
        props.cardRef.current.style.position = "fixed";
        props.cardRef.current.style.left = "0%";
        props.cardRef.current.style.top = "0%";
        props.cardRef.current.style.height = "100vh";
        props.cardRef.current.style.width = "100vw";
        props.cardRef.current.style.zIndex = "6001";

        setIsFullscreen(true);
        setPreviousStyle(prevStyle);
    }

    const changeToCard = () => {
        props.cardRef.current.style.transform = previousStyle.transform;
        props.cardRef.current.style.position = previousStyle.position;
        props.cardRef.current.style.left = previousStyle.left;
        props.cardRef.current.style.top = previousStyle.top;
        props.cardRef.current.style.height = previousStyle.height;
        props.cardRef.current.style.width = previousStyle.width;
        props.cardRef.current.style.zIndex = previousStyle.zIndex;

        setIsFullscreen(false);
    }

    const handleFullscreenClick = () => {
        if (isFullscreen) {
            changeToCard();
        } else {
            changeToModal();
        }
    }

    const handleDownloadClick = () => {
        childRef.current.handleDownloadClick();
    }

    const handlePrintClick = () => {
        setIsPrinting(true);
        childRef.current.handlePrintClick();
        setIsPrinting(false);
    }

    const handlePreviewClick = () => {
        childRef.current.handlePreviewClick();
    }

    const handleAddRowClick = () => {
        setIsPrinting(true);
        childRef.current.handleAddRowClick();
        setIsPrinting(false);
    }

    const handleToggleCsoDischargeAutomatedPublishing = (enabled) => {
        childRef.current.handleToggleCsoDischargeAutomatedPublishing(enabled);
    }

    return (
        <div className={props.classes.cardContents} ref={divElement}>
            <Card className={classNames(!isFullscreen && props.classes.cardContents, isFullscreen && props.classes.modalMode)}>
                { props.cardProps.isExpandable && !isFullscreen && !isPrinting && <FullScreenButton cardType={props.cardType} handleClick={() => handleFullscreenClick()}/>}
                { props.cardProps.isExpandable && isFullscreen && !isPrinting && <ExitFullScreenButton cardType={props.cardType} handleClick = {() => handleFullscreenClick()}/>}
                { props.cardProps.isDownloadable && !isPrinting && <DownloadButton handleClick={() => handleDownloadClick()}/>}
                { props.cardProps.isExpandable && !isPrinting && props.cardProps.type != 'Table' && props.cardProps.type != 'Map' && <PrintButton handleClick={() => handlePrintClick()} isDownloadable={props.cardProps.isDownloadable} />}
                { props.cardProps.description != null && !isPrinting && <InfoHover description={props.cardProps.description} isDownloadable={props.cardProps.isDownloadable} />}
                { props.cardProps.title === 'Outfall Discharge Data' && <PreviewReportButton handleClick={() => handlePreviewClick()} />}
                { props.cardProps.title === 'Outfall Discharge Data' && <AddRowButton handleClick={() => handleAddRowClick()} />}
                { props.cardProps.title === 'Outfall Discharge Data' && <ToggleCsoAutomatedPublishingCheckbox handleClick={handleToggleCsoDischargeAutomatedPublishing} />}
                {generateCardContents()}
            </Card>
            {isFullscreen && <ModalBackground onClick={() => changeToCard()} />}
        </div>
    )
}

// We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(CardContainer);