import React from 'react';
import { Typography, Paper, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import LayerLegendItem from './LayerLegendItem';
import _ from 'lodash';
import LayerLegendGroupItem from './LayerLegendGroupItem';
import { List, Divider } from '@material-ui/core';
import { ThemeContext } from '../../../contexts/ThemeContext';

const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        overflow: 'hidden', 
        height: `calc(100% - ${theme.spacing(2)}px)`,
        maxHeight: `100%`,
        maxWidth: '300px'
    },
    header: {
        position: 'relative',
        top: 0,
        width: '100%',
        height: 48
    },
    title: {
        textAlign: "center",
        fontFamily: "Roboto",
        color: "rgba(0,0,0,1.0)",
        fontSize: "1rem",
    },
    subtitle: {
        textAlign: "left",
        fontFamily: "Roboto",
        color: "rgba(0,0,0,1.0)",
        fontSize: "1rem",
    },
    container: {
        height: `calc(100% - 48px)`,
        overflowX: 'hidden',
        overflowY: 'auto'
    },

});

class LayerLegendTool extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modelGroup: {
                groupName: "Model",
                selectionAction: props.toggleModel,
                isSelected: props.showModelSystem,
                layers: this.getLayerLegend(this.props.model.selectedModel.layers)
            },
            systemGroup: {
                groupName: "System",
                selectionAction: props.toggleSystem,
                isSelected: props.showSystem,
                layers: this.getSystemLegend(props.mapOptions)
            }
        };
    }

    hide = () => {
        this.setState({});

        this.props.mapContext.toggleLegend();
    }

    getSystemLegend = (options) => {
        if(typeof options === "undefined" || options == null) {
            return [];
        } else if(typeof options.legend === 'undefined' || options.legend == null) {
            return [];
        } else {
            return options.legend;
        }
    }

    getLayerLegend = (layers) => {
        let returnValue = layers.map(layer => {
            if(typeof layer.options === 'undefined' || layer.options == null) {
                return [];
            } else if (typeof layer.options.legend === 'undefined' || layer.options.legend == null) {
                return [];
            } else {
                return layer.options.legend
            }
        });
        return _.flattenDeep(returnValue);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.model.selectedModel.layers !== this.props.model.selectedModel.layers) {
            let modelGroup = this.state.modelGroup;
            modelGroup.layers = this.getLayerLegend(this.props.model.selectedModel.layers);
            this.setState({modelGroup: modelGroup});
        }
    }

    componentWillUnmount() {

    }

    render() {
        let { classes, mapContext, model, layers, legendTitle, legendSubtitle, hideModelSystemGroups } = this.props;
        let layerGroups = [this.state.modelGroup, this.state.systemGroup ];
        return(
            <Paper elevation={2} className={classes.root}>
                <div className={classes.header}>
                    <Typography variant="overline" className={classes.title}>
                        {legendTitle}
                    </Typography>
                    <IconButton style={{ position: 'absolute', right: '0px', top: '0px'}} onClick={() => this.hide()}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </div>

                <div className={classes.container} >
                    {!_.isEmpty(layers) && (
                        <Grid container spacing={0}>
                            <Grid item>
                                <Typography variant="title" className={classes.subtitle} style={{paddingLeft: '20px'}}>
                                    {legendSubtitle}
                                </Typography>
                                <List style={{width: '100%', textAlign: 'justify', paddingLeft: '20px'}} dense={true} disablePadding={true} >
                                    {layers.map((layer, idx) => {
                                        return (<LayerLegendItem key={Math.random()} layer={layer} index={idx} model={model} mapContext={mapContext}></LayerLegendItem>)
                                    })}
                                </List>
                            </Grid>
                        </Grid>)}
                    {!hideModelSystemGroups &&
                        <List style={{width: '100%'}} dense={true} >
                            {layerGroups.map((layerGroup, idx) => 
                                (<>
                                    <Divider variant="fullWidth" key={layerGroup.groupName + "divider" + idx} />
                                    <LayerLegendGroupItem key={layerGroup.groupName + idx} index={idx} layer={null} mapContext={this.props.mapContext} group={layerGroup} ></LayerLegendGroupItem>
                                </>))}
                        </List>
                    }

                </div>
                    
            </Paper>
        )
    }
}

export default withStyles(styles)(LayerLegendTool);