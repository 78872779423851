import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import _ from "lodash";
import {ModelContext} from "./../../contexts/ModelContext";
import ListFeaturePanel from "./ListFeaturePanel";
import SearchBar from "./SearchBar";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

function TabContainer(props) {
    return (
        <Typography component="div" style={{height:'100%'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    rootList: {
        marginLeft: "73px",
        position: "fixed",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
    rootTable: {
        marginLeft: "73px",
        position: "fixed",
        height: "calc(100vh - 250px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
    tabRoot: {
        minWidth: 50,
    },
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 8px)",
        height: "50px",
        margin: "4px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class CMOMFeatureContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            assetFilter: ''
        }
    }

    static contextType = ModelContext;

    handleChange = (event, value) => {
        this.setState({ value: value });
    };

    createSelectItems(statusTypes) {
        if (statusTypes) {  
            let items = []; 
            statusTypes = statusTypes.filter(x => (x.displayName == 'None') || (!_.isEmpty(x.tags) && x.tags.includes('CMOM') ) );   
            statusTypes.forEach(function(status) {
                items.push(<MenuItem key={status.id} value={status}>{status.displayName}</MenuItem>); 
            });
            //Model context defaults to first status type irrespective of tag (since it stores status types for Performance + CMOM Dashboards),
            //So update the selected status to the first CMOM asset status
            if (!statusTypes.includes(this.context.state.value.selectedStatus)) {
                this.context.updateValues({selectedStatus: statusTypes[0]});
            };
            return items;
        }
    }  

    handleChangeHighlight = event => {
        const status = event.target.value;
        //Update status, clear selected item when switching highlights (status types)
        this.context.updateValues({
            selectedStatus: status
        });
    };

    setFilter = input => {
        this.setState({
            assetFilter: input
        });
        this.context.updateValue('assetFilter', input);
    }

    applyListFilter = (assets) => {
        const {selectedFeature} = this.context.state.value;
        const {assetFilter} = this.state;
        
        let filteredList = [];

        if (!_.isEmpty(assets)) {
            filteredList = assets.filter(a => {
                if(!selectedFeature || !selectedFeature.displayField) return false;
                if(!a.properties || !a.properties[selectedFeature.displayField]) return false;
                return a.properties[selectedFeature.displayField].toLowerCase().includes(assetFilter.toLowerCase());
            });
        }
        
        return filteredList;
    }

    

    render() {
        const { classes, userContext, menuContext, maintenanceContext, routeContext, date} = this.props;
        const { 
            assets, 
            tableData,
            summaryTableData, 
            selectedStatus, 
            statusTypes, 
            statusResults,
            summaryTableHeaders, 
            assetsError, 
            tableDataError, 
            selectedFeature, 
            selectedModel, 
            selectedItem,
            attributesShown
        } = this.context.state.value;

        let { value } = this.state;

        // OVERRIDE STATE
        if(attributesShown) {
            value = 0;
        }

        const { collapsedDrawerWidth } = menuContext;
        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

        return (
            <Paper square elevation = {2} style={{marginLeft: drawerWidth + 'px'}} classes={{
                root: classNames(value === 0 && classes.rootList, value === 1 && classes.rootTable),
            }}
            style={{width: this.state.value == 1 ? "calc(100% - " + drawerWidth + "px)" : this.props.panelWidth, marginLeft: drawerWidth + "px"}}>
                
                <SearchBar placeholderText={'Find an asset'} setFilter={this.setFilter} />

                <Tabs value={value} onChange={this.handleChange}>
                    <Tab label="List" classes={{ root: classes.tabRoot }}/>
                </Tabs>

                <Divider />
                <FormControl className={classes.formControl} disabled={!statusTypes || statusTypes.length <= 0}>
                    <InputLabel>Highlight</InputLabel>
                    <Select 
                        value = {selectedStatus || {}}
                        name="feature"
                        onChange={this.handleChangeHighlight}
                        displayEmpty
                        className={classes.selectEmpty}>
                        {this.createSelectItems(statusTypes)} 
                    </Select>
                </FormControl>
                <Divider/>

                {(!assetsError && value === 0) && 
                    <TabContainer>
                        <ListFeaturePanel 
                            maintenanceContext={maintenanceContext}
                            showPerformanceAttributes={false}
                            isLoading={_.isEmpty(assets)}
                            statusResults={statusResults}
                            model={this.context}
                            date={date}
                            assets={this.applyListFilter(assets)}
                            selectedItem={selectedItem || {}} 
                            selectedFeature={selectedFeature || {}}
                            statusResults={statusResults}
                            summaryData={summaryTableData}
                            match={this.props.match}
                            history={this.props.history}
                            location={this.props.location}
                            errorContext={this.props.errorContext}
                            routeContext={routeContext}/>
                    </TabContainer>
                }
                
            </Paper>
        );
    }

    sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; 
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
}

CMOMFeatureContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CMOMFeatureContainer);