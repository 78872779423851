import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { series, scales, dataProp, clipPathId } = props;

    if(!dataProp) dataProp = 'Data';

    const seriesName = series.Name.split(' ').join('-').split('.').join('-');;

    function drawBarSeries(newScales, hiddenSeriesList) {
        scales = newScales;

        draw(series[dataProp], hiddenSeriesList);
    }

    drawBarSeries.isSeries = true;

    function draw(seriesData, hiddenSeriesList = []) {
        const primaryChart = d3.select(primaryChartRef.current);
        
        const scale = series.Axis == 'left' ? scales.leftScale : series.Axis == 'right' ? scales.rightScale : scales.boolScale;
        const minValue = series.Axis == 'left' ? scales.minLeft : series.Axis == 'right' ? scales.minRight : scales.minBool;

        function getWidth(d) {
            const x0 = scales.timeScale(d.start)
            const x1 = scales.timeScale(d.end);
            return x1 - x0 < 0.5 ? 0.5 : x1-x0;
        }
    
        const g = primaryChart.select("#" + seriesName);        

        const visibility = hiddenSeriesList.indexOf(series.Name) == -1 ? 'visible' : 'hidden';
    
        const rect = g.selectAll("rect")
            .data(seriesData)
            .attr("class", seriesName)
            .attr("fill", series.Color)
            .attr("clip-path", "url(#" + clipPathId + ")")
            .style("opacity", .9)
            .attr("x", data => scales.timeScale(data.start))
            .attr("y", data => scale(data.value) > 0 ? scale(data.value) : 0)
            .attr("height", data => {
                const res = minValue - scale(data.value)
                return res > 0 ? res : 0;   
            })
            .attr("width", getWidth)
            .attr("visibility", visibility);


        rect.enter()
            .append("rect")
            .attr("class", seriesName)
            .attr("fill", series.Color)
            .attr("clip-path", "url(#" + clipPathId + ")")
            .style("opacity", .9)
            .attr("x", data => scales.timeScale(data.start))
            .attr("y", data => scale(data.value) > 0 ? scale(data.value) : 0)
            .attr("height", data => {
                const res = minValue - scale(data.value)
                return res > 0 ? res : 0;   
            })
            .attr("width", getWidth)
            .attr("visibility", visibility);

        rect.exit().remove();
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawBarSeries);        
   
        draw(series[dataProp]);
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(series[dataProp]), [scales]);

    return (
        <g id={seriesName}></g>
    )
})
