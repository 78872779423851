import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: theme.spacing(50),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: '70vh',
        width: '50vw'
    },
    closeImg: {
        cursor:'pointer', 
        float:'right', 
        marginTop: '-20px', 
        marginRight: '-15px',
        width: '48px',
        height: '48px',
    },
    errorMsgs: {
        overflow: 'auto',
        maxHeight: 'calc(100vh - 322px)',
        width: '100%',
    }
});

class ErrorModal extends React.Component {

    createErrorList () {
        return this.props.errors.map((card) => {
            return Object.keys(card.errors).map(function(key, index) {
                var iteratorKey = key + card.metric + card.cardType;
                return (
                    <Typography variant="body1" key={iteratorKey}>
                        <span style={{fontWeight:"bold"}}>{key} detected in the "{card.cardType}" metric for "{card.metric}" at:</span> {card.errors[key].join()}.
                    </Typography>
                );
            });
        });
    }

    render() {
        const { classes } = this.props;
        const ErrorList = this.createErrorList();

        return (
            <div>
                <Modal aria-labelledby="simple-modal-title"
                        open={this.props.open}
                        onClose={this.props.handleClose}>
                    <div className={classes.paper}>
                        <Typography variant="h6" id="modal-title">
                            Potential Data Quality Issues
                            <IconButton aria-label="Close"
                                        color="inherit"
                                        className={classes.closeImg}
                                        onClick={this.props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Typography>
                        <div className={classes.errorMsgs}>
                            {ErrorList}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ErrorModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ErrorModal);