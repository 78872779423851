import React from 'react';
import CMOMFeaturePanel from "./featurepanel/CMOMFeaturePanel";
import DashboardFrame from "./DashboardFrame";
import TopMenu from "./topmenu/TopMenu";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import MaintenanceContextProvider from '../contexts/MaintenanceContext';
import {MaintenanceContext} from '../contexts/MaintenanceContext';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

class CMOMDashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            layout: []
        }
    }

    componentDidMount() {
        const { userContext, routeContext, model, match, selectedDashboard, loadDashboard } = this.props;

        const tenant = userContext.getTenant();
        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'performance',
            viewType: selectedDashboard.dashboardName,
            assetName: match.params.assetName
        });
        model.updateValues({
            selectedFeature: null
        });

        loadDashboard(selectedDashboard.dashboardCards, selectedDashboard); 
    }

    componentWillUnmount() {
        this.props.model.updateValues({
            selectedItem: null,
            highlightedAsset: null,
            selectedStatus: null
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedDashboard:dashboard, match, history, model, routeContext, userContext} = this.props;
        const {baseModel, selectedModel} = model.state.value;

        const currentModel = dashboard && dashboard.baseModelExclusive ? baseModel : selectedModel;

        if (prevState.layout !== this.state.layout)
        {
            this.setState({layout: this.props.layout});
        }

        if (currentModel && currentModel.layers.length > 0 && (!model.selectedFeature || _.isEmpty(model.selectedFeature)))
        {
            var matchLayer = currentModel.layers.find(m => m.layerName == match.params.layerName);
            let layerName = matchLayer ? matchLayer.layerName : model.selectedFeature.layerName;

            //If switching to CMOM dashboard where the current feature is not supposed to be shown on the CMOM Dashboard
            if (dashboard && dashboard.limitLayers && !dashboard.limitLayers.includes(layerName)) {
                let defaultLayerName = dashboard.limitLayers[0];
                const defaultLayer = currentModel.layers.find(x => x.layerName == defaultLayerName);
                if (defaultLayer) {
                    layerName = defaultLayer.layerName;
                }
            }

            const layer = layerName ? (currentModel.layers.find(x => x.layerName == layerName) || currentModel.layers[0]) : currentModel.layers[0];
            model.updateValues({
                selectedFeature: layer
            });
            routeContext.updateValues({
                layerName: layer.layerName
            });
        }

        if (routeContext.state.tenant && match.params.tenantName && !_.isEqual(routeContext.state.tenant, match.params.tenantName))
        {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            if (!_.isEmpty(newTenant)) {
                userContext.switchTenant(newTenant.id);
                routeContext.updateValues({
                    tenant: newTenant.tenantName
                });
            } else {
                history.push('/unknown');
            }
        }
    }

    render() {
        const { classes, model, match, location, history, menuContext, date, userContext, routeContext, dashboards, selectedDashboard, mapType, ...rest } = this.props;

        const { collapsedDrawerWidth } = menuContext;
        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

        var panelWidth = "15vw";
        var frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)"; 
        var frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";

        return (
            <MaintenanceContextProvider users={userContext.users} tenant={userContext.tenant} date={date} model={model} match={match} userContext={userContext} currentDashboard={selectedDashboard}>
                <MaintenanceContext.Consumer>
                    {(maintenanceContext) => (
                        <div className={classes.root}>
                        <TopMenu
                            selectedDashboard={selectedDashboard}
                            displayFeatureSelector={true}
                            model={model}
                            menuContext={this.props.menuContext}
                            match={match}
                            location={location}
                            history={history}>
                        </TopMenu>
                        <CMOMFeaturePanel 
                            userContext={userContext}
                            context={this.context}
                            date={date}
                            menuContext={this.props.menuContext}
                            errorContext={this.props.errorContext}
                            routeContext={routeContext}
                            panelWidth={panelWidth}
                            match={match}
                            history={history}
                            location={location}
                            maintenanceContext={maintenanceContext}/>
                        <DashboardFrame 
                            menuContext={this.props.menuContext}
                            routeContext={this.props.routeContext}
                            maintenanceContext={maintenanceContext}
                            frameOffset={frameOffset}
                            frameWidth={frameWidth}
                            mapType={mapType}
                            importLayout={this.props.layout}/> 
                    </div>
                    )}
                </MaintenanceContext.Consumer>
            </MaintenanceContextProvider>
        );
    }
}

CMOMDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const CMOMDashboardComponent = withStyles(styles, { withTheme: true })(CMOMDashboard);
function useDesktopLayout(props) {
    const matches = useMediaQuery('(min-width:1367px)');
    return (<CMOMDashboardComponent{...props} isDesktop={matches}></CMOMDashboardComponent>);
}

export default useDesktopLayout;