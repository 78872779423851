import simplify from '../../../../util/simplify-date';
import ApiHelper from '../../../../api/ApiHelper';
import { getHeaders } from '../../../../api/ApiWorker';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../../constants/TimezoneConstants';

function formatBarData(data) {
    const results = [];
    let start;

    if(data[0].value) {
        start = data[0].date;
    }

    for(let i = 1; i < data.length; i++) {
        const prevValue = data[i - 1].value;
        const currentValue = data[i].value;
        const nextValue = data[i + 1] ? data[i + 1].value : null;

        if(currentValue == prevValue && nextValue != null) continue;

        if(!start) {
            if(currentValue) {
                if(currentValue == nextValue) {
                    start = data[i - 1].date;
                } else {
                    results.push({
                        value: data[i].value,
                        start: data[i - 1].date,
                        end: data[i].date,
                        date: data[i].date
                    });
                }
            }
        } else {
            results.push({
                value: prevValue,
                start, 
                end: data[i - 1].date,
                date: data[i - 1].date
            });
            start = null;
        }
    }

    return results;
}

export function formatData(rawData, getColor) {
    const toReturn = Object.assign({}, rawData);            
    
    if(toReturn.ChartType == 'line' || toReturn.ChartType == 'curveStep' || toReturn.ChartType == 'area' || toReturn.ChartType == 'dashedLine') {
        const values = rawData.Data.map(f => f.value);
        const tolerance = (values.reduce((total, value) => total + value, 0) / values.length) * 0.75;
        toReturn.brushData = simplify(rawData.Data, tolerance, false);
    } else if(toReturn.ChartType == 'bar') {
        toReturn.brushData = formatBarData(rawData.Data);
        toReturn._formattedData = formatBarData(rawData.Data);
    } else if(toReturn.ChartType == "alert") {
        //TODO no alerts in brush bar for now
        // update: currently alerts do not need formatting
    }
    
    toReturn.Color = (toReturn.Color != undefined) ? getColor(toReturn.Color) : "#000000"

    return toReturn;
}

function getUrl(dataType, urlPattern, userContext, replacements, maintenanceContext) {
    const tenant = userContext.getTenant();

    const { assetId } = replacements;

    urlPattern = urlPattern.split("[tenantName]").join(tenant.tenantName);
    urlPattern = urlPattern.split("[assetId]").join(assetId);

    let url = new URL(urlPattern);

    url = ApiHelper.fillFromObj(url, replacements);

    return new URL(url);
}

export function getData(apiObj, replacements, abortSignal, userContext, errorContext, title) {
    // return new Promise(resolve => {
    //     resolve(mockData[apiObj.title]);
    // });
    return new Promise(resolve => {
        const url = getUrl(apiObj.title, apiObj.urlPattern, userContext, replacements);

        let response = fetch(url, {
            method: 'get',
            credentials: 'same-origin',
            headers: getHeaders()
        });

        response.then(x => {
            return x.json();
        }).then(results => {
            if(results.error && Object.keys(results.error).length > 0) {
                errorContext.updateErrors(results.error,apiObj.title,title);
            }
    
            const data = results;
    
            if(data == null) return resolve(null);
            let toReturn = Object.keys(data).map(key => {
                return {
                    date: moment.tz(key, sessionStorage.getItem(TENANT_TIMEZONE)),
                    value: data[key]
                };
            })
            .sort((a, b) => {
                return a.date - b.date;
            });
    
            return resolve(toReturn);
        }).catch(err => {
            return resolve(null);
        })
    });
}


function inputDataIsValid(api, dateContext) {
    return !!api;
}

export function getDataHelper(api, apiKey, colors, extraData, userContext, selectedItem, abortController, title, errorContext) {
    if(!inputDataIsValid(api)) {
        console.error('Input data invalid; Returning empty array');
        return [];
    }

    const replacements = {
        assetId: selectedItem ? selectedItem.id : ''
    };

    return new Promise(resolve => {
        const apiObj = {
            title: apiKey,
            ...api[apiKey]
        }
        const { ...parameters } = apiObj.parameters;

        try {
            getData(apiObj, {...replacements, ...parameters}, abortController.signal, userContext, errorContext, title).then(data => {
                if(!data) return resolve([null, null]);

                const extraDataFormatted = {};

                for(const key of Object.keys(extraData)) {
                    if(!_.isArray(extraData[key])) { 
                        if(_.isArray(extraData[key].value)) {
                            extraDataFormatted[extraData[key].name] =  extraData[key].value.find(v => v.name == apiObj.title) ? extraData[key].value.find(v => v.name == apiObj.title).value : null;
                        } else {
                            extraDataFormatted[extraData[key].name] = extraData[key].value;
                        }
                    } else {
                        extraData[key].forEach(value => {
                            if(_.isArray(value)) {
                                const matchingValue =  value.find(v => v.name == apiObj.title);
                                extraDataFormatted[matchingValue.name] = matchingValue.value;
                            } else {
                                extraDataFormatted[value.name] = value.value;
                            }
                        });
                    }
                }

                const result = {
                    Name: apiObj.title,
                    Url: "",
                    Data: data ? data : [],
                    Id: Math.random(),
                    Color: colors[apiObj.title],
                    ...extraDataFormatted
                };

                return resolve([result, null]);
            });
        } catch (e) {
            console.log('An error occurred retrieving chart data');
            return resolve([null, {
                errors: e,
                cardType: apiObj.cardType,
                apiCall: apiObj.title,
                metric: apiObj.metric
            }]);
        }
    });
}