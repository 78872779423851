import React, { forwardRef, useImperativeHandle } from 'react'
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Link, CardContent, Typography } from '@material-ui/core';
import withLoading from "../../withLoading";
import _ from 'lodash';
import { getHeaders, getTenantId } from '../../../api/ApiWorker';
import moment from 'moment-timezone';
import RsuiteTable from '../../flexTable/RsuiteTable';
import ApiHelper from '../../../api/ApiHelper';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';
import "rsuite/dist/rsuite.min.css";


const LoadingDiv = withLoading('div');

const styles = theme => ({
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    }
});

const RsuiteDataTable = forwardRef((props, ref) => {
    const {model, userContext, dateContext, title, classes, api, extraData, getColor, routeContext, selectedDashboard} = props;
    let { selectedItem } = props;

    // the model is not where you get the selected item on model-free dashboards
    if (_.isEmpty(selectedItem) && !_.isEmpty(model.selectedItem)) selectedItem = model.selectedItem;

    let [ loadWithoutSelectedItem ] = extraData;
    loadWithoutSelectedItem = loadWithoutSelectedItem?.value;

    const [isLoading, setIsLoading] = React.useState(false);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [tableDataRows, setTableDataRows] = React.useState(null);
    const [tableDataColumns, setTableDataColumns] = React.useState([]);
    const [tableDataError, setTableDataError] = React.useState(null);
    let getAssetNameFromRoute = false;

    extraData.forEach((obj) => {
        if (obj.name === 'TableOptions') {
            obj.value.forEach((option) => {
                if (option.name === 'GetAssetNameFromRoute') getAssetNameFromRoute = option.value
            })
        }
    })

    const thisElement = React.useRef({});

    useImperativeHandle(ref, () => ({
        handleDownloadClick() {
            var csvContent = "data:text/csv;charset=utf-8,";
            var dataKeys = tableDataColumns.map(e => { return e.fieldName});
            csvContent += tableDataColumns.map(e => e.fieldLabel).join(",") + "\r\n";
                tableDataRows.forEach( (data) => {
                    dataKeys.forEach( (key) => {
                        if (key === null) return
                            let path = key.split('.')
                            let value = data[path[0]]
                            for (let i = 1; i < path.length; i++) {
                                value = value[path[i]]
                            }

                            csvContent += value + ","
                    });
                csvContent += "\r\n";
            });


    
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = "table_" + props.title;
            filename += ".csv";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }
    }));

   
    //Unmount
    React.useEffect( () => () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
    }, []);

    React.useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
        if (loadWithoutSelectedItem || !_.isEmpty(selectedItem)) {
            getData();
        } else if (!loadWithoutSelectedItem && _.isEmpty(selectedItem)) {
            setIsLoading(false);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
        }
    }, [selectedItem, dateContext.startDate, dateContext.endDate, model.selectedModel])


    const getAssetName = parameters => {
        try {
            if (getAssetNameFromRoute && routeContext.state.assetName) return routeContext.state.assetName;
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'name';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getAssetId = parameters => {
        try {
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'id';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getUrlParams = () => {
        let key = Object.keys(api)[0];
        let tableDataObj = api[key];

        const parameters = tableDataObj?.parameters || {};
        parameters.tenantId = getTenantId();
        const tenant = userContext.getTenant();
        parameters.tenantName = tenant.tenantName;

        parameters.assetName = getAssetName(parameters);
        parameters.assetId = getAssetId(parameters);

        parameters.layerName = model?.selectedFeature?.layerName;
        parameters.modelName = model?.selectedModel?.name;
        parameters.startDate = moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        parameters.endDate = moment.tz(dateContext.realEndDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();

        return parameters;
    }

    const getData = async () => {
        try {
            setNoDataFound(false);
            setIsLoading(true);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);

            let key = Object.keys(api)[0];
            let tableDataObj = api[key];

            let urlPattern = tableDataObj.urlPattern;

            let url = new URL(urlPattern, ApiHelper.getUrlPath());

            const urlParams = getUrlParams();

            url = ApiHelper.fillFromObj(url, urlParams);

            thisElement.current.abortController && thisElement.current.abortController.abort();
            thisElement.current.abortController = new AbortController();
            
            const results = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
                signal: thisElement.current.abortController.signal
            });

            if (results.ok) {
                let data = await results.json();

                //Format columns 
                var fields = props.tableFields;
                for (let i = 0; i < fields.length; i++) 
                {
                    var field = fields[i];
                    field.name = field.fieldName;
                    field.header = field.fieldLabel;
                }

                //Format data
                var dateColumns = fields.filter(x => !!x.isDate);
                var numericColumns = fields.filter(x => !!x.numeric);
                var textColumns = fields.filter(x => !dateColumns.includes(x) && !numericColumns.includes(x))
                for (let i = 0; i < data.length; i++)
                {
                    let d = data[i]
                    d['key'] = i
                    dateColumns.forEach(x => {
                        d[x.name] = moment.tz(d[x.name], sessionStorage.getItem(TENANT_TIMEZONE)).format(x.dateFormat);
                    });

                    numericColumns.forEach(x => {
                        d[x.name] = Math.round(parseFloat(d[x.name]) * 100000) / 100000;
                    });

                }

                setTableDataRows(data);
                setTableDataColumns(fields);
                
                setNoDataFound(data.length == 0);
                setIsLoading(false);
            } else {
                throw new Error(results.statusText);
            }
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                setTableDataError('An error occurred while loading data.');
                setIsLoading(false);
            } 
        } 

    };

    const getCustomTable = () => {

        return (
            <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', right: '1.5rem', bottom: '1.5rem', width: "calc(100% - 4rem)", height: "calc(100% - 4rem)"}}>
                <AutoSizer>
                    
                    {({ width, height }) => (
                        
                        <RsuiteTable 
                            width={width}
                            height={height}
                            tableDataRows={tableDataRows}
                            tableDataColumns={tableDataColumns}
                            getColor={getColor}
                            extraData={extraData}
                            compact={true}
                            title={title}
                        />
                               
                    )}  
                    
                </AutoSizer>
            </div>
        )
    }

    const Content = (!_.isEmpty(tableDataError)) || noDataFound
    ?
        <Typography className={classes.text} style={{ paddingLeft: '8px'}}>
            {!_.isEmpty(tableDataError) ? tableDataError : "No Data Found"}
            <Link style={{ paddingLeft: '8px', cursor: 'pointer'}} onClick={() => getData()}>
                Try again?
            </Link>
        </Typography>
    : !_.isEmpty(tableDataRows) && !_.isEmpty(tableDataColumns)
        ? 
        getCustomTable()
        : 
            isLoading
            ? <> </>
            : <Typography className={classes.text}> Select an item to view data</Typography>;


    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={isLoading}>
            <CardContent>
                <Typography variant='h6' className={classes.title}>
                    <span>{title}</span>
                </Typography>
                {Content}
            </CardContent>
        </LoadingDiv>
    )
})

export default withStyles(styles)(RsuiteDataTable);
