import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoTwoToneIcon from '@material-ui/icons/InfoTwoTone';
import { ModelContext } from '../../contexts/ModelContext';
import { ThemeContext } from '../../contexts/ThemeContext';

const styles = theme => ({
    infoHoverTimeSeries: {
        position: "absolute",
        right: "140px",
        top: "12px",
        zIndex: "6001" // The fullscreen modal is 6000
    },
    infoHoverKPI: {
        position: "absolute",
        right: "5px",
        top: "5px"
    }
});

class InfoHover extends React.Component {

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    static contextType = ModelContext;

    render () {
        const { classes, description, isDownloadable } = this.props;
        return (
            <div className = {isDownloadable ? classes.infoHoverTimeSeries: classes.infoHoverKPI} data-html2canvas-ignore="true">
                <Tooltip title={description} aria-label={description} arrow placement={'bottom'} >
                    <div>
                        <ThemeContext.Consumer>
                            {({theme, getColor, updateTheme}) => (    
                                isDownloadable ?
                                    <InfoTwoToneIcon style={{color: getColor('primary.Primary'), fontSize: 'normal'}} /> :
                                    <InfoTwoToneIcon style={{color: getColor('primary.Primary'), fontSize: '85%'}} />
                            )}
                        </ThemeContext.Consumer>
                    </div>
                </Tooltip>
            </div>
        )
    }
}

InfoHover.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoHover);;