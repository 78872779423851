import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import Icon from '@material-ui/core/Icon';
import { ModelContext } from '../../contexts/ModelContext';
import { ThemeContext } from '../../contexts/ThemeContext';

const styles = theme => ({
    downloadButton : {
        position: "absolute",
        right: "48px",
        top: "0px",
        zIndex: "6001" // The fullscreen modal is 6000
    },
    paper: {
        position: 'fixed',
        left: "12.5%",
        top: "10%",
        width: document.documentElement.clientWidth * 0.75,
        height: document.documentElement.clientHeight * 0.80,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
    },
});

class DownloadButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    static contextType = ModelContext;

    render () {
        const { classes } = this.props;
        return (
            <div className = {classes.downloadButton} data-html2canvas-ignore="true">
                <IconButton title='download' onClick={this.props.handleClick} >
                    <ThemeContext.Consumer>
                        {({theme, getColor, updateTheme}) => (
                            <Icon style={{color: getColor("primary.Primary")}}>cloud_download</Icon>
                        )}
                    </ThemeContext.Consumer>
                    
                </IconButton>
            </div>
        )
    }
}

DownloadButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DownloadButton);;