import React, {useState, useContext, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useLocation} from 'react-router-dom';
import _ from 'lodash';
import {DateContext} from '../../contexts/DateContext';
import {ModelContext} from '../../contexts/ModelContext';

const useStyles = makeStyles(theme => ({
  root: {
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function EventFilterSelect(props) {
  const classes = useStyles();
  const [filter, setFilter] = React.useState('All Events');
  const [query, setQuery] = useState(new URLSearchParams(useLocation().search));

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const modelContext = useContext(ModelContext);
  const dateContext = useContext(DateContext);
  
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  React.useEffect(() => {
      //If eventType filter is provided through query string,
      if (query.has("eventType") && props.eventList && props.eventList.length >= 0 && !_.isEqual(query.get("eventType"), props.selectType) ) {
        let matchingAlert = props.alertTypes.find(a => a.displayName == query.get('eventType'));
        props.setFilter(matchingAlert.displayName);
    }
  }, [props.eventList]);

  React.useEffect(() => {
    let baseUrl = props.match.url; //Current matching route (defined in PipecastLoader)
    let timescale = 'timescale=' + dateContext.timescale;
    let startDate = dateContext.timescale == 'custom' && dateContext.startDate ? "&startDate=" + dateContext.startDate : "";
    let endDate = dateContext.timescale == 'custom' && dateContext.endDate ? "&endDate=" + dateContext.endDate : "";
    
    if(baseUrl + "?" + timescale + startDate + endDate != props.history.location.pathname.concat(props.history.location.search)) {
      props.history.push(baseUrl + "?" + timescale + startDate + endDate);
    }
  }, [props.selectType])

  function handleChange(event) {
      setFilter(event.target.value);
      props.setFilter(event.target.value);
  }

  function createMenuItems(props) {
      let items = [];

      items.push(<MenuItem key={Math.random()} value={'All Events'}> All Events </MenuItem>);

      if (props.alertTypes && props.alertTypes.length >= 0) {  
          props.alertTypes.forEach(function(alert) {
            items.push(
                  <MenuItem key={Math.random()} value={alert.displayName}>
                    {alert.displayName}
                  </MenuItem>); 
              }
          ); 
      }
      return items;
}

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl style ={{marginTop: '30px', marginLeft: '-50px'}} variant="outlined" className={classes.formControl}>
        <InputLabel ref={inputLabel} htmlFor="outlined-age-simple">
          Showing
        </InputLabel>
        <Select
          value={props.selectType}
          onChange={handleChange}
          input={<OutlinedInput labelWidth={labelWidth} />}
        >
          
          {createMenuItems(props)}

        </Select>
      </FormControl>
    </form>
  );

}