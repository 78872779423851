import React from 'react';
import withLoading from '../../../withLoading';
import { withStyles, Typography, Link } from '@material-ui/core';
import _ from 'lodash';
import {
    getData,
    formatBarData
} from './dataUtil';
import { ReactComponent as EmptyChart } from "../../../../assets/images/emptyChart.svg";
import MainChart from './MainChart';
import ChartLegend from '../../../charts/chartComponents/ChartLegend';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const styles = theme => ({
    chartContainer: {
        height: '100%',
        width: '100%',
        position: "absolute",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    highlightedAssetName: {
        position: "absolute",
        top: "2.5rem",
        left: "4rem",
        fontFamily: "Roboto",
    },
    text: {
        position: "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 1
    },
    progress: {
        top: "35%",
        left: "35%",
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    emptyChart: {
        position: 'absolute',
        top: "15%",
        left: "5%",
        height: "80%",
        width: "90%",
    },
    mainChart: {
        position: 'absolute',
        top: '6.5rem',
        left: '1.5rem',
        bottom: '0',
        right: '1.5rem',
        backgroundColor: 'lightblue'
    },
    loadingDiv: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'absolute'
    }
});

const LoadingDiv = withLoading('div');

class BarChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            formattedData: null,
            rawData: null,
            errors: null
        };
    }

    async fetchData() {
        let { api, extraData, userContext, dateContext, colors, getColor } = this.props;

        try {
            this.abortController && this.abortController.abort();
            this.abortController = new AbortController();

            const responses = await Promise.all(getData(api, this.props.selectedItem.id, extraData, this.abortController, userContext, dateContext, colors, getColor));

            const rawData = responses.map(a => a[0]).filter(a => !!a);
            const errors = responses.map(a => a[1]).filter(a => !!a);

            const formattedData = this.formatData(rawData);

            this.setState({ rawData, errors, formattedData });
        } catch (error) {
            this.setState({ errors: error ? [error] : [new Error('An Error Occurred')] })
        }
    }

    formatData(rawData) {
        this.margin = {
            top: 50,
            right: 60,
            bottom: 30,
            left: 60
        }

        if (!rawData) return;

        const formattedData = rawData.map(rd => {
            const toReturn = Object.assign({}, rd);
            toReturn._Data = rd.Data.map(d => Object.assign({}, d));
            toReturn._formattedData = formatBarData(rd.Data)
            return toReturn;
        });

        return formattedData;
    }

    shouldComponentUpdate(nextProps) {
        return !!nextProps.api;
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(this.props.selectedItem, prevProps.selectedItem)) {
            this.abortController && this.abortController.abort();
            return this.setState({ rawData: null, formattedData: null, errors: null });
        }

        if (this.state.errors) return;

        if (!this.state.rawData && this.props.selectedItem) {
            return this.fetchData();
        }
    }

    componentWillUnmount() {
        this.abortController && this.abortController.abort();
    }

    // ************************************
    // updates triggered by changes to hidden series list are
    // triggered by a state change in ChartLegend propagated
    // through the chart via redraw functions which are handled 
    // by d3 exclusively to avoid triggering awkward react redraws
    // of the chart components
    //
    // this is the callback function called when the ChartLegend's
    // Hidden Series List changes. It propagates changes downward through
    // the child refs
    // ************************************
    redrawChildren = hiddenSeriesList => {
        const redraw = this.mainChartRef.current.redraw;
        redraw(hiddenSeriesList);
    }

    getBottomPadding = (hideLegend) => {
        let paddingBottom = 8;

        if (hideLegend) return paddingBottom;

        const legendHeight = 40;

        if (legendHeight) {
            paddingBottom += legendHeight;
        }

        return paddingBottom;
    }

    handlePrintClick() {
        if (this.state.formattedData) {
            const { width, height, cardRef } = this.props;

            const aspectRatio = width / height;
            const newWidth = 850;
            const newHeight = newWidth / aspectRatio;

            html2canvas(cardRef.current, { scale: 2 })
                .then(canvas => {
                    const imgData = canvas.toDataURL('image/PNG');
                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt'
                    });
                    pdf.addImage(imgData, 'PNG', 0, newHeight / 5, newWidth, newHeight);
                    pdf.save("BarChart.pdf");
                });
        }
    }

    render() {
        const { containerRef, classes, title, extraData, colors } = this.props;

        const hideLegend = extraData && extraData.find(x => x.name === 'HideLegend').value;

        const { formattedData, errors } = this.state;

        const domain = formattedData && formattedData.length ? formattedData.map(d => {
            return { colorLabel: d.Name, tooltipLabel: d.Name };
        }) : [];

        this.mainChartRef = React.createRef();

        let chartContent = (
            <>
                <Typography className={classes.text}>Select an item to view data</Typography>
                <EmptyChart className={classes.emptyChart} />
            </>
        );

        if (errors && errors.length) {
            chartContent = (
                < >
                    <Typography className={classes.text}>
                        An error occurred.
                        <Link onClick={() => this.fetchData()}>
                            Try again?
                        </Link>
                    </Typography>
                    <EmptyChart className={classes.emptyChart} />
                </>
            )
        } else if (formattedData && formattedData.length) {
            chartContent = (
                <div id={title} className={classes.chartContainer} >

                    <MainChart ref={this.mainChartRef}
                        margin={this.margin}
                        className={classes.mainChart}
                        data={formattedData}
                        domain={domain}
                        {...this.props}
                        height={this.props.height - this.getBottomPadding(hideLegend)}></MainChart>

                    {!hideLegend && <ChartLegend
                        key={Math.random()}
                        containerStyle={{ zIndex: 1000 }}
                        data={formattedData}
                        grouped={true}
                        onChange={this.redrawChildren.bind(this)} />}
                </div>
            )
        }
        return (
            <LoadingDiv forwardedRef={containerRef} className={classes.loadingDiv} isLoading={(this.props.selectedItem && (!formattedData || !formattedData.length) && (!errors || !errors.length))}>
                <Typography variant="h6" className={this.props.classes.title}>
                    <span>{title}</span>
                </Typography>
                {chartContent}
            </LoadingDiv>
        );
    }
}

export default withStyles(styles)(BarChart);