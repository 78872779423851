import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Link, CardContent, Typography } from '@material-ui/core';
import withLoading from "../withLoading";
import _ from 'lodash';
import { getHeaders, getTenantId } from '../../api/ApiWorker';
import CustomTable from '../flexTable/CustomTable';
import moment from 'moment-timezone';
import ApiHelper from '../../api/ApiHelper';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading('div');

const styles = theme => ({
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    }
});

const TableCard = forwardRef((props, ref) => {
   
    const {model, dateContext, userContext, isNonsortable, title, classes, api, extraData} = props;
    let { selectedItem } = props;

    // the model is not where you get the selected item on model-free dashboards
    if (_.isEmpty(selectedItem) && !_.isEmpty(model.selectedItem)) selectedItem = model.selectedItem;

    let [ loadWithoutSelectedItem ] = extraData;
    loadWithoutSelectedItem = loadWithoutSelectedItem?.value;
    const [unFormatedData,setUnformatedData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableDataRows, setTableDataRows] = useState([]);
    const [tableDataColumns, setTableDataColumns] = useState([]);
    const [tableDataError, setTableDataError] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState('asc');

    const thisElement = useRef({});

    useImperativeHandle(ref, () => ({
        handleDownloadClick() {
            var csvContent = "data:text/csv;charset=utf-8,";
            var dataKeys = tableDataColumns.map(e => e.fieldName);
            csvContent += tableDataColumns.map(e => e.fieldLabel).join(",") + "\r\n";
            tableDataRows.forEach(function(data) {
                dataKeys.forEach(function(key) {
                    csvContent += data[key] + ","
                });
                csvContent += "\r\n";
            });
    
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = "table_" + props.title;
            filename += ".csv";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }
    }));

    //Component Umount
    useEffect(() => () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
    }, []);

    useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
        if (loadWithoutSelectedItem || !_.isEmpty(selectedItem)) {
            getData();
        } else if (!loadWithoutSelectedItem && _.isEmpty(selectedItem)) {
            setIsLoading(false);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
            setOrderBy(null);
            setOrderDirection('asc');
        }
        
    }, [selectedItem, dateContext.startDate, dateContext.endDate, model.selectedModel])

    const getAssetName = parameters => {
        try {
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'name';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getAssetId = parameters => {
        try {
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'id';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getUrlParams = () => {
        let key = Object.keys(api)[0];
        let tableDataObj = api[key];

        const parameters = tableDataObj?.parameters || {};
        parameters.tenantId = getTenantId();
        const tenant = userContext.getTenant();
        parameters.tenantName = tenant.tenantName;

        parameters.assetName = getAssetName(parameters);
        parameters.assetId = getAssetId(parameters);

        parameters.layerName = model?.selectedFeature?.layerName;
        parameters.modelName = model?.selectedModel?.name;
        parameters.startDate = moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        parameters.endDate = moment.tz(dateContext.endDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();

        return parameters;
    }

    const sort = (col) => {
        let newOrderDirection = orderDirection;
        if(orderDirection === 'desc') {
            newOrderDirection = 'asc';
        } else {
            newOrderDirection = 'desc';
        }
        
        var newTableDataRows = sortByKey(unFormatedData, col.fieldName, newOrderDirection); 

        setOrderBy(col.fieldName);
        setOrderDirection(newOrderDirection);
        setTableDataRows(formatData(newTableDataRows));
        setIsLoading(false);
    }

    const sortByKey = (data, orderBy, orderDirection) => {
        data = data.sort(function(a, b) {
            var x = b[orderBy]; 
            var y = a[orderBy];

            if (((typeof x) === 'object' && 
                (typeof y) === 'object') &&
                x.hasOwnProperty('value') && 
                x.hasOwnProperty('displayValue') && 
                y.hasOwnProperty('value') && 
                y.hasOwnProperty('displayValue')) {
                    x = x.value;
                    y = y.value;
            }

            if ((!x || ((typeof x) == 'string' && x.trim() == '')) && y)
                return 1
            else if ((!x || ((typeof x) == 'string' && x.trim() == '')) && (!y || ((typeof y) == 'string' && y.trim() == '')))
                return 0
            else if (x && (!y || ((typeof y) == 'string' && y.trim() == '')))
                return -1

            if (orderDirection === 'desc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            else {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });

        return data;
    }

    const getData = async () => {
        try {
            setIsLoading(true);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
            setOrderBy(null);
            setOrderDirection('asc');

            let key = Object.keys(api)[0];
            let tableDataObj = api[key];

            let urlPattern = tableDataObj.urlPattern;

            let url = new URL(urlPattern, ApiHelper.getUrlPath());

            const urlParams = getUrlParams();

            url = ApiHelper.fillFromObj(url, urlParams);

            thisElement.current.abortController && thisElement.current.abortController.abort();
            thisElement.current.abortController = new AbortController();
            
            const results = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
                signal: thisElement.current.abortController.signal
            });

            if (results.ok) {
                let resultJson = await results.json(); //result from api
                
                var columns = resultJson.fields; // table columns are defined in the database
                var data = resultJson.data; // table row data

                data.forEach(ele => {
                    if (ele.StartDateTime) {
                        ele.StartDateTime = moment.tz(ele.StartDateTime, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD HH:mm:ss z');
                    }
                    if (ele.EndDateTime) {
                        ele.EndDateTime = moment.tz(ele.EndDateTime, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD HH:mm:ss z');
                    }
                });

                if (columns && columns.length > 0) {
                    columns = sortByKey(columns, 'order', 'asc');
                    for (var i = 0; i < columns.length; i++) {
                        columns[i].name = columns[i].fieldName;
                        columns[i].header = columns[i].fieldLabel;
                    }
                    if (!orderBy) {
                        setOrderBy(columns[0].fieldName);
                        setOrderDirection('asc');
                    }
                }
                if (orderBy && orderDirection) {
                    data = sortByKey(data, orderBy, orderDirection);
                }

                setUnformatedData(data); // need this unformated data again for the sort function when its called
                setTableDataRows(formatData(data)); // formated data for inner object property values
                setTableDataColumns(columns);
                setIsLoading(false);
            } else {
                throw new Error(results.statusText);
            }
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                setTableDataError('An error occurred while loading data.');
                setIsLoading(false);
            } 
        } 
    };


    const formatData = (data) => {
        for (let i = 0; i < data.length; i++)
        {
            var event = data[i];
            for (const property in event) {
                if (typeof event[property] === 'object') {
                    event[property] = event[property]?.displayValue;
                }
            }
        }

        return data;
    }

    const getCustomTable = () => {        
        return (
            <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', right: '1.5rem', bottom: '1.5rem', width: 'calc(100% - 4rem)', height: 'calc(100% - 4rem)' }}>
                <AutoSizer>
                    {({ width, height }) => (
                        <CustomTable 
                            sortableDisabled={isNonsortable ? isNonsortable : false}
                            data={tableDataRows}
                            columns={tableDataColumns}
                            width={width}
                            height={height}
                            sortKey={orderBy}
                            sortDirection={orderDirection}
                            sort={sort}/>                 
                    )}
                </AutoSizer>
            </div>
        )
    }

    const Content = (!_.isEmpty(tableDataError))
        ?
        <div className={classes.text} style={{ color: 'red', fontSize: '15px', lineHeight: '15px', padding: '8px', display: 'flex'}}>
            {tableDataError}
            <Typography style={{ fontSize: '15px', lineHeight: '15px', paddingLeft: '8px', cursor: 'pointer'}}>
                <Link onClick={() => getData()}>
                    Try again?
                </Link>
            </Typography>
        </div>
        : !_.isEmpty(tableDataRows) && !_.isEmpty(tableDataColumns)
            ? 
            getCustomTable()
            : 
                isLoading
                ? <> </>
                : <Typography className={classes.text}> Select an item to view data</Typography>;

    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={isLoading}>
            <CardContent>
                <Typography variant='h6' className={classes.title}>
                    <span>{title}</span>
                </Typography>
                {Content}
            </CardContent>
        </LoadingDiv>
    )
})

export default withStyles(styles)(TableCard);