import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Link, CardContent, Typography } from '@material-ui/core';
import withLoading from "../../withLoading";
import _ from 'lodash';
import { getHeaders, getTenantId } from '../../../api/ApiWorker';
import CustomTable from '../../flexTable/CustomTable';
import moment from 'moment-timezone';
import ApiHelper from '../../../api/ApiHelper';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';
import { RouteContext } from '../../../contexts/RouteContext';

const LoadingDiv = withLoading('div');

const styles = theme => ({
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    }
});

const DataTable = forwardRef((props, ref) => {
    const {model, routeContext, userContext, dateContext, isNonsortable, title, classes, api, extraData} = props;
    let { selectedItem } = props;
    // the model is not where you get the selected item on model-free dashboards
    if (_.isEmpty(selectedItem) && !_.isEmpty(model.selectedItem)) selectedItem = model.selectedItem;

    let [ loadWithoutSelectedItem ] = extraData;
    loadWithoutSelectedItem = loadWithoutSelectedItem?.value;

    const [isLoading, setIsLoading] = useState(false);
    const [noDataFound, setNoDataFound] = useState(false);
    const [tableDataRows, setTableDataRows] = useState(null);
    const [tableDataColumns, setTableDataColumns] = useState([]);
    const [tableDataError, setTableDataError] = useState(null);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState('asc');

    const thisElement = useRef({});

    useImperativeHandle(ref, () => ({
        handleDownloadClick() {
            var csvContent = "data:text/csv;charset=utf-8,";
            var dataKeys = tableDataColumns.map(e => e.fieldName);
            csvContent += tableDataColumns.map(e => e.fieldLabel).join(",") + "\r\n";
            tableDataRows.forEach(function(data) {
                dataKeys.forEach(function(key) {
                    csvContent += data[key] + ","
                });
                csvContent += "\r\n";
            });
    
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = "table_" + props.title;
            filename += ".csv";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        }
    }));

   
    //Umount
    useEffect( () => () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
    }, []);

    useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
        if (loadWithoutSelectedItem || !_.isEmpty(selectedItem)) {
            getData();
        } else if (!loadWithoutSelectedItem && _.isEmpty(selectedItem)) {
            setIsLoading(false);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
            setOrderBy(null);
            setOrderDirection('asc');
        }
    }, [selectedItem, routeContext?.state?.assetName, dateContext.startDate, dateContext.endDate, model.selectedModel])

    const getAssetName = parameters => {
        try {
            if (routeContext.state.assetName) return routeContext.state.assetName;
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'name';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getAssetId = parameters => {
        try {
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'id';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getUrlParams = () => {
        let key = Object.keys(api)[0];
        let tableDataObj = api[key];

        const parameters = tableDataObj?.parameters || {};
        parameters.tenantId = getTenantId();
        const tenant = userContext.getTenant();
        parameters.tenantName = tenant.tenantName;

        parameters.assetName = getAssetName(parameters);
        parameters.assetId = getAssetId(parameters);

        parameters.layerName = model?.selectedFeature?.layerName;
        parameters.modelName = model?.selectedModel?.name;
        parameters.startDate = moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        parameters.endDate = moment.tz(dateContext.endDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();

        return parameters;
    }

    const getData = async () => {
        try {
            setNoDataFound(false);
            setIsLoading(true);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
            setOrderBy(null);
            setOrderDirection('asc');

            let key = Object.keys(api)[0];
            let tableDataObj = api[key];

            let urlPattern = tableDataObj.urlPattern;

            let url = new URL(urlPattern, ApiHelper.getUrlPath());

            const urlParams = getUrlParams();

            url = ApiHelper.fillFromObj(url, urlParams);

            thisElement.current.abortController && thisElement.current.abortController.abort();
            thisElement.current.abortController = new AbortController();
            
            const results = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
                signal: thisElement.current.abortController.signal
            });

            if (results.ok) {
                let data = await results.json();

                //Format columns 
                var fields = props.tableFields;
                for (let i = 0; i < fields.length; i++) 
                {
                    var field = fields[i];
                    field.name = field.fieldName;
                    field.header = field.fieldLabel;
                }

                //Format data
                var dateColumns = fields.filter(x => !!x.isDate);
                var numericColumns = fields.filter(x => !!x.numeric);
                var textColumns = fields.filter(x => !dateColumns.includes(x) && !numericColumns.includes(x))
                for (let i = 0; i < data.length; i++)
                {
                    let d = data[i];
                    dateColumns.forEach(x => {
                        d[x.name] = moment.tz(d[x.name], sessionStorage.getItem(TENANT_TIMEZONE)).format(x.dateFormat);
                        x.width = getDateColumnWidth(x);
                    });

                    numericColumns.forEach(x => {
                        d[x.name] = parseFloat(d[x.name]).toFixed(2);
                        x.width = getNumericColumnWidth(data, x.name, x);
                    });

                    textColumns.forEach(x => {
                        x.width = getTextColumnWidth(data, x.name, x);
                    });
                }

                setTableDataRows(data);
                setTableDataColumns(fields);
                
                setNoDataFound(data.length == 0);
                setIsLoading(false);
            } else {
                throw new Error(results.statusText);
            }
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                setTableDataError('An error occurred while loading data.');
                setIsLoading(false);
            } 
        } 
    };

    const handleColumnClick = (column) => {
        let newOrderDirection = orderDirection;
        if (orderDirection === 'desc') {
            newOrderDirection = 'asc';
        } else {
            newOrderDirection = 'desc';
        }

        var newTableDataRows = sortByColumn(column, newOrderDirection); 

        setOrderBy(column.name);
        setOrderDirection(newOrderDirection);
        setTableDataRows(newTableDataRows);
        setIsLoading(false);
    }

    const sortByColumn = (column, orderDirection) => {
        const orderBy = column.name;
        //Convert dates to sortable string with Moment, order and then reMoment again
        var newTableDataRows = tableDataRows.sort(function(a, b) {
            var x = b[orderBy]; 
            var y = a[orderBy];

            if (!!column.isDate) {
                switch (column.dateFormat)
                {
                    case 'MMMM':
                        x = moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)).month(x);
                        y = moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)).month(y);
                    break;
                    case 'dddd':
                        x = moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)).day(x).day() + 1;
                        y = moment.tz(sessionStorage.getItem(TENANT_TIMEZONE)).day(y).day() + 1;
                    default:
                }
            } else if (!!column.numeric) {
                x = Number(x);
                y = Number(y);
            }

            if (orderDirection === 'desc') {
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            }
            else {
                return ((x > y) ? -1 : ((x < y) ? 1 : 0));
            }
        });

        return newTableDataRows;
    }

    const getDateColumnWidth = (column) => {
        const minWidth = 100;
        const magicSpacing = 10;

        return Math.max(minWidth, column.dateFormat.length * magicSpacing);
    }

    const getNumericColumnWidth = (data, property, column) => {
        let max = 0;
        const minWidth = 100;
        const magicSpacing = 10;

        // for (var i = 0; i < data.length; i++) {
        //     try {
        //         let d = parseFloat(d[property]).toFixed(2);
        //         if (d !== undefined && d !== null) {
        //             if (JSON.stringify(d || 'null').length > max) {
        //                 max = JSON.stringify(d || 'null').length;
        //             }
        //         }
        //     } catch {}
        // }
        return Math.max(minWidth, column.header.length * magicSpacing);
    }

    const getTextColumnWidth = (data, property, column) => {
        let max = 0;
        const minWidth = 150;
        const magicSpacing = 20;

        // for (var i = 0; i < data.length; i++) {
        //     try {
        //         let d = d[property];
        //         if (d !== undefined && d !== null) {
        //             if (JSON.stringify(d || 'null').length > max) {
        //                 max = JSON.stringify(d || 'null').length;
        //             }
        //         }
        //     } catch {}
        // }
        return Math.max(minWidth, max * magicSpacing);
    }

    const getCustomTable = () => {        
        return (
            <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', right: '1.5rem', bottom: '1.5rem', width: "calc(100% - 4rem)", height: "calc(100% - 4rem)"}}>
                <AutoSizer>
                    {({ width, height }) => (
                        <CustomTable 
                            sortableDisabled = {isNonsortable ? isNonsortable : false}
                            data={tableDataRows}
                            columns={tableDataColumns}
                            width={width}
                            height={height}
                            sortKey={orderBy}
                            sortDirection={orderDirection}
                            sort={handleColumnClick}/>                   
                    )}
                </AutoSizer>
            </div>
        )
    }

    const Content = (!_.isEmpty(tableDataError)) || noDataFound
    ?
        <Typography className={classes.text} style={{ paddingLeft: '8px'}}>
            {!_.isEmpty(tableDataError) ? tableDataError : "No Data Found"}
            <Link style={{ paddingLeft: '8px', cursor: 'pointer'}} onClick={() => getData()}>
                Try again?
            </Link>
        </Typography>
    : !_.isEmpty(tableDataRows) && !_.isEmpty(tableDataColumns)
        ? 
        getCustomTable()
        : 
            isLoading
            ? <> </>
            : <Typography className={classes.text}> Select an item to view data</Typography>;

    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={isLoading}>
            <CardContent>
                <Typography variant='h6' className={classes.title}>
                    <span>{title}</span>
                </Typography>
                {Content}
            </CardContent>
        </LoadingDiv>
    )
})

export default withStyles(styles)(DataTable);
