import React, {useState, useContext, useEffect} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/icons/ErrorTwoTone';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Chart from '@material-ui/icons/InsertChartTwoTone';
import { ThemeContext } from '../../contexts/ThemeContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import withQuery from '../withQuery';
import withTenantName from '../withTenantName';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LinkWithTenantNameWithQuery = withQuery(withTenantName(Link));

export default function OMListItem(props) {

    const bigScreen = useMediaQuery("(min-width: 1367px)"); // > iPad Pro Landscape

	const {style, classes, match, item, selectedEvent } = props;
	
    const handleOverviewListItemClick = item => event => {
		props.setFilter && props.setFilter(item.asset);
		props.setSelectedEvent(item);
		var dashboard = props.dashboards.find(x => x.dashboardName == 'Event');
        if (typeof(dashboard) !== 'undefined' && dashboard !== null) {
            props.loadDashboard(dashboard.dashboardCards, dashboard);
		}
		
	};

	const handleAssetListItemClick = item => event => {
		props.setFilter && props.setFilter(item.asset);
		props.setSelectedEvent(item);
		// Update the Event Dashboard Cards to show stats for the given item
	}

	var tz = sessionStorage.getItem(TENANT_TIMEZONE);
	const momentFormat = 'YYYY-MM-DD hh:mm a z';
	//Monitor-size overview list item
	if (bigScreen && match.params.viewType == 'Overview')
	{
		return (
		<ThemeContext.Consumer>
			{({getColor}) => (
					<ListItem button={false}
							style={props.style}
							divider={true}
							>
						<Grid container direction='row' justify='space-around' alignItems='center'>

							<Alert style = {{marginRight: '5px', color: getColor(item.colorCode)}} />

							<Grid item xs>
								<ListItemText
									primary={(item.asset && item.asset.length > 8) ? item.asset.substring(0,5).concat('...') : item.asset || 'Not Found'} />
							</Grid>
							<Grid item xs={6} style = {{marginLeft: '-15px'}}>
								<ListItemText
									primary={item.eventType || 'Not Found'} />
							</Grid>
							<Grid item xs>
								<ListItemText
								primary={moment.tz(item.startDate, tz).fromNow() || 'Not Found'} />
							</Grid> 
							<LinkWithTenantNameWithQuery key={Math.random()} to={'/{tenantName}/dashboards/operations/Event/'.concat(item.asset).concat('/').concat(item.eventType).concat('/').concat(item.startDate)} style={{textDecoration: 'none'}}>
								<IconButton onClick={handleOverviewListItemClick(item)} style={{fontSize: '0rem'}}>
										<Chart style={{fontSize: '1.5rem', color: getColor('primary.Primary')}}/>
								</IconButton>
							</LinkWithTenantNameWithQuery>
						</Grid>	
					</ListItem> 
				)}
		</ThemeContext.Consumer>
		);
	}

	//Touch screen overview list item
	else if (!bigScreen && match.params.viewType == 'Overview')
	{
		return (
		<ThemeContext.Consumer>
				{({getColor}) => (
					<LinkWithTenantNameWithQuery key={Math.random()} to={'/{tenantName}/dashboards/operations/Event/'.concat(item.asset).concat('/').concat(item.eventType).concat('/').concat(item.startDate)} style={{textDecoration: 'none', color: 'black'}}>
						<ListItem button={true}
										className={classes.listItem} 
										onClick={handleOverviewListItemClick(item)}
										style={style}
										divider={true}
										>
							<Grid container direction='row' justify='space-around' alignItems='center'>

								<Alert style = {{marginRight: '5px', color: getColor(item.colorCode)}} />

								<Grid item xs={3}>
										<ListItemText
												primary={(item.asset && item.asset.length > 5) ? item.asset.substring(0,5).concat('...') : item.asset || 'Not Found'} />
								</Grid>
								<Grid item xs={5} style = {{marginLeft: '-15px'}}>
										<ListItemText
												primary={item.eventType || 'Not Found'} />
								</Grid>
								<Grid item xs>
										<ListItemText
										primary={moment.tz(item.startDate, tz).fromNow() || 'Not Found'} />
								</Grid> 
							</Grid>   
						</ListItem> 
					</LinkWithTenantNameWithQuery>
				)}
		</ThemeContext.Consumer>
		);
	}

	//Event list item for all devices
	if (match.params.viewType == 'Event')
	{
		return (
		<ThemeContext.Consumer>
			{({getColor}) => (
				<LinkWithTenantNameWithQuery key={Math.random()} to={'/{tenantName}/dashboards/operations/Event/'.concat(match.params.assetName).concat('/').concat(item.eventType).concat('/').concat(item.startDate)} style={{textDecoration: 'none', color: 'black'}}>
					<ListItem button={true} 
							className={classes.listItem} 
							onClick={handleAssetListItemClick(item)}
							selected={selectedEvent ? ((selectedEvent.eventType == item.eventType) && (selectedEvent.startDate == item.startDate)) : false} 
							style={style}
							divider={true}
							>
						<Grid container direction='row' justify='space-around' alignItems='center'>
			
							<Alert style={{marginRight: '5px', fill: getColor(item.colorCode)}} />

							<Grid item xs={7}>
								<ListItemText
									primary={item.eventType || 'Not Found'} />
							</Grid>
							<Grid item xs>
								<ListItemText
								primary={moment.tz(item.startDate, tz).fromNow() || 'Not Found'} />
							</Grid> 
						</Grid>	
					</ListItem> 
				</LinkWithTenantNameWithQuery>
			)}
		</ThemeContext.Consumer>
		);
	}
}