import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import _ from "lodash";
import {ModelContext} from "./../../contexts/ModelContext";
import OperationsListFeaturePanel from "./OperationsListFeaturePanel";
import { Button, ListSubheader } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import SystemSummaryBar from './SystemSummaryBar';
import AssetInfoBar from './AssetInfoBar';
import EventFilterBar from './EventFilterBar';
import EventFilterSelect from './EventFilterSelect';
import IconButton from '@material-ui/core/IconButton';
import OperationsMap from '../map/OperationsMap';
import UnsortedIcon from '@material-ui/icons/Menu';
import SortedIcon from '@material-ui/icons/TrendingFlat';
import moment from 'moment-timezone';
function TabContainer(props) {
    return (
        <Typography component="div" style={{height:'100%'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    rootList: {
        marginLeft: "73px",
        position: "fixed",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
    },
    rootTable: {
        marginLeft: "73px",
        position: "fixed",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
    },
    tabRoot: {
        minWidth: 75,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '.75px',
        textColor: 'rgba(0,0,0,0.6)',
        mixBlendMode: 'normal',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 8px)",
        height: "50px",
        margin: "4px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class OperationsFeatureContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            eventList: null,
            searchTerm: '',
            eventFilterName: '',
            selectType: 'All Events',
            filter: '',
            timeSort: '',
            alertSort:'',
            assetSort: '',
            shouldRedirect: null,
            assetNotFound: false,
            selectedEvent: null
        }
    }

    static contextType = ModelContext;

    setSelectedEvent = (event, shouldRedirect = null) => {
        //this.context.updateHighlightedAssetFromName(event.asset);
        if(shouldRedirect != null) {
            this.setState({
                selectedEvent: event,
                eventFilterName: event.asset,
                shouldRedirect
            });
        } else {
            this.setState({
                selectedEvent: event,
                eventFilterName: event.asset
            });
        }

        const {model, errorContext, alertContext, routeContext} = this.props;
        
        errorContext.updateValues({errors: []});
        alertContext.updateValues({selectedEvent: event});
        model.updateHighlightedAssetFromName(event.asset);
        routeContext.updateValues({assetName: event.asset});

        if (event.eventType == 'Event' || event.startDate == 'NaN') return;

        var dashboard = this.props.dashboards.find(x => x.dashboardName === 'Event');
        if (typeof(dashboard) !== 'undefined' && dashboard !== null) {
            this.props.loadDashboard(dashboard.dashboardCards, dashboard.dashboardName);
        }
    }

    handleChange = (event, value) => {
        this.setState({ value: value });
    };

    componentDidMount() {
        this.setState({
            shouldRedirect: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const assets = this.props.alertContext.state.events;
        const alertTypes = this.props.alertContext.state.alertTypes;

        const {match, tenant, dateContext} = this.props;
        const {viewType, assetName, eventType, eventTime} = match.params;    

        if (prevProps.tenant && tenant && !_.isEqual(tenant, prevProps.tenant)) {
            this.setState({value: 0});
        }

        //Update events
        if (assets && alertTypes && alertTypes.length > 0 && this.state.eventList != assets)
        {
            for(const asset of assets) {
                let matchingAlertType = alertTypes.find(function(alert) {
                    return alert.displayName == asset.eventType;
                });
                asset.priority = matchingAlertType ? matchingAlertType.severity : 0;
                asset.colorCode = matchingAlertType ? matchingAlertType.displayColor : '#000000';
            }
            this.setState({
                timeSort: 'Descending',
                eventList: assets.sort((a,b) => this.sortTimeDescending(a,b))
            });

            //If the event list for the given time range is empty, this updates the dashboard cards with placeholder values
            //and updates the timeseries chart accordingly
            if (assets.length === 0) {
                return this.setSelectedEvent({asset: match.params.assetName, eventType: 'Event', startDate: 'NaN'});
            }
        } 

        // Handle event selection when the event list changes while in event view (changing dates, etc.)
        if (!_.isEqual(this.state.eventList, prevState.eventList) && this.state.selectedEvent && this.state.eventList.length > 0 && viewType == 'Event') {
            if (!this.state.eventList.includes(this.state.selectedEvent)) {
                let matchingEvent = (this.state.eventList.filter(x => x.asset == this.state.selectedEvent.asset)[0]);
                if (matchingEvent) {
                    this.setSelectedEvent(matchingEvent)
                } 
                else {
                    this.setSelectedEvent({asset: match.params.assetName, eventType: 'Event', startDate: 'NaN'})
                } 
            }
        }

        // Slight changes to Matt's implementation to handle O&M map events
        // update to check if any part of route has changed rather than just asset name
        const eventUpdated = !_.isEqual(this.props.match.params, prevProps.match.params);

        if (eventUpdated && !_.isEmpty(this.state.eventList) && this.props.match.params.viewType == 'Event') {
            let matchingEvent = this.state.eventList.find(item => item.asset == this.props.match.params.assetName &&
                                                          item.eventType == match.params.eventType && moment.utc(item.startDate).format() == moment.utc(eventTime).format());
            if (!!matchingEvent) { 
                this.setSelectedEvent(matchingEvent)
            };
            this.setState({eventFilterName : this.props.match.params.assetName});

            if (!this.props.history.location.pathname.includes("?")) {
                let timescale = 'timescale=' + dateContext.timescale;
                let startDate = dateContext.timescale == 'custom' && dateContext.startDate ? '&startDate=' + dateContext.startDate : '';
                let endDate = dateContext.timescale == 'custom' && dateContext.endDate ? '&endDate=' + dateContext.endDate : '';
                
                if(this.props.location.pathname.concat('?' + timescale).concat(startDate).concat(endDate) != this.props.history.location.pathname.concat(this.props.history.location.search)) {
                    this.props.history.push(this.props.location.pathname.concat('?' + timescale).concat(startDate).concat(endDate));
                }
            }
        }

        else if (this.state.eventList != null && this.state.shouldRedirect == true) {     

            var event;

            if (!this.state.eventList || _.isEmpty(this.state.eventList)) { return; }

            event = this.state.eventList.find( item => {
                //Filter list conditions if the asset is found through the given URL (must match name, event, and time)
                return ((item.asset == assetName) && (item.eventType == eventType) && (moment.utc(item.startDate).format() == moment.utc(eventTime).format()) );
            });

            if (viewType == 'Event' && event == null && this.state.assetNotFound == false) {
                this.setState({
                    assetNotFound: true,
                    shouldRedirect: false
                });
            } else if (event != null) {
                this.setAssetSelectingFilter(event.asset);
                this.setState({
                    shouldRedirect: false,
                    selectedEvent: event
                });
                this.props.errorContext.updateValues({errors: []});
                this.props.alertContext.updateValues({selectedEvent: event});
                this.props.model.updateHighlightedAssetFromName(event.asset);
                this.props.routeContext.updateValues({assetName: event.asset});
            }
        }
    }

    //Set list to item.includes(filter) to show all matching results
    setAssetTypingFilter = filter => {
        this.setState({
            searchTerm: filter,
            eventFilterName: ''
        });
    } 

    //Set list to item == filter (asset name dropdown selection)
    setAssetSelectingFilter = filter => {
        this.setState({
            eventFilterName: filter
        });
    }

    //Reset to show all events
    reset = () => {
        this.setState({
            searchTerm: '',
            eventFilterName: '',
            shouldRedirect: false,
            assetNotFound: false,
            selectedEvent: null,
        }, () => {
            this.props.errorContext.updateValues({errors: []});
            this.props.alertContext.updateValues({selectedEvent: null});
            this.props.routeContext.updateValues({assetName: null});
            //this.props.model.updateHighlightedAssetFromName(null);
            var dashboard = this.props.dashboards.find(x => x.dashboardName === 'O&M');
            if (typeof(dashboard) !== 'undefined' && dashboard !== null) {
                this.props.loadDashboard(dashboard.dashboardCards, dashboard.dashboardName);
            }
        });
    }

    //Handler when selection through event type dropdown
    setEventFilter = filter => {
        this.setState({
            selectType: filter
        });
    }

    applyFilters = eventList => {
        const {searchTerm, eventFilterName, selectType, assetNotFound} = this.state;
        let returnList;

        if (assetNotFound) {
            return this.setState({
                assetNotFound: false
            }, () => {
                this.setSelectedEvent({asset: this.props.match.params.assetName, eventType: 'Not Found', startDate: 'NaN'})
            });
        }

        //Selected event name filter exists
        if (eventFilterName != '' && eventFilterName != null) {
            return eventList.filter(event => event.asset == eventFilterName);
        }

        //If search term DNE, check the event filter and sort accordingly
        if (searchTerm == '')
        {
            switch (selectType)
            {
                case 'All Events':
                    returnList = eventList;
                break;
                default: 
                    returnList = eventList.filter(event => event.eventType == selectType);
                break;
            }
            return returnList;
        }

        //Search term exists, filter with event type
        else if (searchTerm != '' && searchTerm != null) {
            switch (selectType)
            {
                case 'All Events':
                    returnList = eventList.filter(event => event.asset.toLowerCase().includes(searchTerm.toLowerCase()));
                break;
                default:
                    returnList = eventList.filter(event => event.asset.toLowerCase().includes(searchTerm.toLowerCase()) && event.eventType == selectType);
                break;
            }
            return returnList;
        }
    }

    sortAssets = () => {
        let sortedList = [];
        let currentList = this.state.eventList;
        let newSortType;

        switch (this.state.assetSort)
        {
            case 'Ascending':
                sortedList = currentList.sort((a,b) => this.sortAssetDescending(a,b));
                newSortType = 'Descending';
            break;
            default:
                sortedList = currentList.sort((a,b) => this.sortAssetAscending(a,b));
                newSortType = 'Ascending';
            break;
        }
        this.setState({
            eventList: sortedList,
            assetSort: newSortType,
            alertSort: 'Unsorted',
            timeSort: 'Unsorted'
        });
        this.forceUpdate();
    }

    sortAssetAscending = (a,b) => {
        if (a.asset < b.asset) {
            return -1;
        }
        if (a.asset > b.asset) {
            return 1;
        }
        return 0;
    }

    sortAssetDescending = (a,b) => {
        if (a.asset > b.asset) {
            return -1;
        }
        if (a.asset < b.asset) {
            return 1;
        }
        return 0;
    }

    sortAlerts = () => {
        let sortedList = [];
        let currentList = this.state.eventList;
        let newSortType;

        switch (this.state.alertSort)
        {
            case 'Ascending':
                sortedList = currentList.sort((a,b) => this.sortAlertDescending(a,b));
                newSortType = 'Descending';
                break;
            default:
                sortedList = currentList.sort((a,b) => this.sortAlertAscending(a,b));
                newSortType = 'Ascending';
                break;
        }
        this.setState({
            eventList: sortedList,
            assetSort: 'Unsorted',
            alertSort: newSortType,
            timeSort: 'Unsorted'});

        this.forceUpdate();
    }

    //Get priority from item.eventType, alertType.eventType.severity
    sortAlertAscending = (a,b) => {
        if (a.eventType < b.eventType) {
            return -1;
        }
        if (a.eventType > b.eventType) {
            return 1;
        }
        return 0;
    }

    //Get priority from item.eventType, alertType.eventType.severity
    sortAlertDescending = (a,b) => {
        if (a.eventType > b.eventType) {
            return -1;
        }
        if (a.eventType < b.eventType) {
            return 1;
        }
        return 0;
    }

    sortEventTimes = () => {
        let sortedList = [];
        let currentList = this.state.eventList;
        let newSortType;

        switch (this.state.timeSort)
        {
            case 'Descending':
                sortedList = currentList.sort(this.sortTimeAscending);
                newSortType = 'Ascending';
            break;
            default:
                sortedList = currentList.sort(this.sortTimeDescending);
                newSortType = 'Descending';
            break;
        }
        this.setState({
            eventList: sortedList,
            timeSort: newSortType,
            alertSort: 'Unsorted',
            assetSort: 'Unsorted'});
            
        this.forceUpdate();
    }

    sortTimeAscending = (a,b) => {
        var aDate = new Date(a.startDate).getTime();
        var bDate = new Date(b.startDate).getTime();
        if (aDate < bDate) return -1;
        if (aDate > bDate) return 1;
        return 0;
    }

    sortTimeDescending = (a,b) => {
        var aDate = new Date(a.startDate).getTime();
        var bDate = new Date(b.startDate).getTime();
        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        return 0;
    }

    //This function is used for the virtualized asset name dropdown in AssetInfoBar.js (underneath SystemSummary button)
    //Removes duplicate asset names (since only the name is needed to set filter)
    removeDuplicateNames = (array) => {
        if (!array) {return}
        let assets =  array.map(item => {
            return (item.asset);
        })
        var set = new Set(assets);
        return [...set].sort();
    }

    getAssetIcon = () => {
        var toReturn;
        switch (this.state.assetSort)
        {
            case 'Ascending':
                toReturn = <SortedIcon style={{transform: 'rotate(90deg)'}} />;
            break;
            case 'Descending':
                toReturn = <SortedIcon style={{transform: 'rotate(-90deg)'}} />;
            break;
            default: // Unsorted
                toReturn = null;
            break;
        }
        return toReturn;
    }

    getAlertIcon = () => {
        var toReturn;
        switch (this.state.alertSort)
        {
            case 'Ascending':
                toReturn = <SortedIcon style={{transform: 'rotate(90deg)'}} />;
                break;
            case 'Descending':
                toReturn = <SortedIcon style={{transform: 'rotate(-90deg)'}} />;
                break;
            default: //Unsorted
                toReturn = null
            break;
        }
        return toReturn;
    }

    getTimeIcon = () => {
        var toReturn;
        switch (this.state.timeSort)
        {
            case 'Ascending':
                toReturn = <SortedIcon style={{transform: 'rotate(-90deg)'}} />;
                break;
            case 'Descending':
                toReturn = <SortedIcon style={{transform: 'rotate(90deg)'}} />;
                break;
            default: //Unsorted
                toReturn = null;
            break;
        }
        return toReturn;
    }

    getMapOptions = () => {
        const { dashboards } = this.props;
        const eventDashboard = dashboards.find(x => x.dashboardName == 'Event');
        const mapOptions = eventDashboard.dashboardCards.find(x => x.type == 'Map').options;
        return mapOptions;
    }

    getAlertLayerLegend = () => {
        const {alertContext, themeContext} = this.props;
        const {alertTypes} = alertContext.state;
        const {selectType} = this.state;

        let layers;
        let alertLayers;

        //Filter alert layer legend depending on which alerts we are viewing
        switch (selectType)
        {
            case 'All Events':
                alertLayers = alertTypes;
            break;
            default:
                alertLayers = alertTypes.filter(x => x.displayName == selectType);
            break;
        }

        //Format alerts into format used for layer legend
        layers = alertLayers.map((alert, index) => {
            return {
                layerName: alert.displayName,
                iconColor: themeContext.getColor(alert.displayColor)
            };
        });

        //Remove duplicates from layer list (if there are multiple alerts with same severity color scheme)
        layers = _.uniqWith(layers, _.isEqual);

        return layers;
    }

    render() {
        const { classes, menuContext, routeContext, userContext, panelType, match, history, model, location} = this.props;

        if (this.props.panelTabs[this.state.value] == null) {
            this.setState({ value: 0});
        }

        const { 
            summaryTableData, 
            selectedStatus, 
            statusTypes, 
            summaryTableHeaders, 
            assetsError, 
            tableDataError, 
            selectedFeature, 
            selectedModel, 
            selectedItem,
            attributesShown
        } = this.context.state.value;

        let { value } = this.state;

        let assets = this.props.alertContext.state.events;

        // OVERRIDE STATE
        if(attributesShown) {
            value = 0;
        }

        const { collapsedDrawerWidth } = menuContext;
        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

        return (
            <Paper square elevation = {2} style={{ marginLeft: drawerWidth + 'px'}} classes={{
                root: classNames(value === 0 && classes.rootList, value === 1 && classes.rootTable),
            }}
            style={{ width: this.props.panelWidth, marginLeft: drawerWidth + "px"}}>
                {panelType == 'Overview' &&
                <Grid container direction = 'row' justify = 'flex-start' alignItems = 'center' style={{marginBottom: '16px'}}>
                    <Grid item xs={8}>
                        <EventFilterBar setFilter={this.setAssetTypingFilter}/>
                    </Grid>
                    <Grid item xs>
                        <EventFilterSelect eventList={this.state.eventList} match={match} history={history} alertTypes={this.props.alertContext.state.alertTypes} selectType={this.state.selectType} setFilter={this.setEventFilter} />
                    </Grid>  
                </Grid>
                }
                { panelType == 'Event' &&
                <div>
                    <div>
                        <SystemSummaryBar history={history} reset={this.reset}/> 
                    </div>
                        <AssetInfoBar 
                        selectedModel={selectedModel}
                        model={model}
                        selectedFeature={selectedFeature}
                        updateValues={this.context.updateValues}
                        setFilter={this.setAssetSelectingFilter}
                        assets={this.removeDuplicateNames(assets) || {}}
                        selectedItem={selectedItem || {}}
                        match={match}
                        location={location}
                        disabled={this.state.assetNotFound} />
                </div>

                }

                <Tabs style={{ marginLeft: 25}} indicatorColor='primary' textColor='primary' value={panelType == 'Overview' && value != null ? value : 0} onChange={this.handleChange}>
                    {this.props.panelTabs.map(tab=>(
                        <Tab key={Math.random()} label={tab} classes={{root: classes.tabRoot}} />
                    ))}
                </Tabs>

                <Divider />
                {/* Map/List view - Alerts */}
                {(!assetsError && value == 0 && panelType == 'Overview' && 
                    <TabContainer>
                        <Grid container direction='row' justify='space-around' alignItems='center'>
                            <Grid item xs={2} style={{marginLeft: '32px'}}>
                                <Button onClick={this.sortAssets} style={{color: 'gray', textTransform: 'none'}} endIcon={this.getAssetIcon()}> Asset </Button>
                            </Grid>
                            <Grid item xs={5} style={{marginLeft: '32px'}}>
                                <Button onClick={this.sortAlerts} style={{color: 'gray', textTransform: 'none'}} endIcon={this.getAlertIcon()}> Alert </Button>
                            </Grid>
                            <Grid item xs>
                                <Button onClick={this.sortEventTimes} style={{color: 'gray', textTransform: 'none'}} endIcon={this.getTimeIcon()}> Time </Button>
                            </Grid>
                        </Grid>
                    
                    <Divider />

                   {<OperationsListFeaturePanel 
                                isLoading={this.props.alertContext.state.isLoadingEvents} 
                                model={this.context}
                                assets={this.applyFilters(this.state.eventList) || []}
                                selectedItem={selectedItem || {}} 
                                selectedFeature={selectedFeature || {}}
                                match={match}
                                location={location}
                                userContext={userContext}
                                setFilter={this.setAssetSelectingFilter}
                                selectedEvent={this.state.selectedEvent}
                                setSelectedEvent={this.setSelectedEvent}
                                dashboards={this.props.dashboards}
                                loadDashboard={this.props.loadDashboard}/> }
                </TabContainer>)
                }

                {/* Map/List View - Map */}
                {!assetsError && value == 1 && panelType == 'Overview' && 
                    <TabContainer style={{ position: "relative", height: "100%", width: "100%" }}>
                      <OperationsMap 
                            layers={this.getAlertLayerLegend()}
                            mapOptions={this.getMapOptions()}
                            assetFilter={this.state.searchTerm} 
                            alertFilter={this.state.selectType}
                            setSelectedEvent={this.setSelectedEvent}
                            selectedFeature={this.context.selectedFeature}
                            routeContext={routeContext}
                            userContext={userContext}
                            model={this.context}
                            date={this.props.dateContext}
                            history={history}
                            eventList={this.state.eventList}
                            themeContext={this.props.themeContext}
                            alertTypes={this.props.alertContext.state.alertTypes} />
                    </TabContainer>
                }

                {/* Event View for Given Asset */}
                {!assetsError && value == 0 && panelType == 'Event' &&
                    <TabContainer>
                        <Grid container direction='row' justify = 'space-around' alignItems = 'center'>
                            <Grid item xs={7} style={{marginLeft: '32px'}}>
                                <Button onClick={this.sortAlerts} style={{color: 'gray', textTransform: 'none'}} endIcon={this.getAlertIcon()}> Alert </Button>
                            </Grid>
                            <Grid item xs>
                                <Button onClick={this.sortEventTimes} style={{color: 'gray', textTransform: 'none'}} endIcon={this.getTimeIcon()}> Time </Button>
                            </Grid>
                        </Grid>

                        <Divider />

                       { <OperationsListFeaturePanel isLoading={this.props.alertContext.state.isLoadingEvents} 
                                    model={this.context}
                                    assets={this.applyFilters(this.state.eventList) || []}
                                    selectedItem={selectedItem || {}} 
                                    selectedFeature={selectedFeature || {}}
                                    match={match}
                                    date={this.props.dateContext}
                                    userContext={userContext}
                                    location={location}
                                    history={history}
                                    selectedEvent={this.state.selectedEvent}
                                    setSelectedEvent={this.setSelectedEvent}
                                    loadDashboard={this.props.loadDashboard}
                                    dashboards={this.props.dashboards}/> }
                    </TabContainer>
                }
  
            </Paper>
        );
    }

    sortByKey(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; 
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
}

OperationsFeatureContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationsFeatureContainer);