import React from 'react';
import _ from "lodash";

export const DateContext = React.createContext(null);
const today = new Date().toISOString().split('T')[0];

// for easy testing
// const today = new Date('9/7/2017').toISOString().split('T')[0];

const stateValue = {
    startDate: '',
    endDate: today,
    realEndDate: new Date(Date.now() + 1),
    daysBetweenStartEnd: 0,
    prevRangeStartDate: '',
    prevRangeEndDate: '',
    errorDates: false,
    timescale: '',
    validDates: [],
    validDatesError: null
};

class DateContextProvider extends React.Component {
    state = {
        //WARNING
        //1. when you update value, you must include ALL props. It is not like updating state,
        //      where some can be left out
        //2. you NEED value as the state property or the components relying on the context won't re-render.
        //3. if you update the individual value properties back to back (updateStartDate then updateEndDate),
        //      it will usually batch update these for performance and only the last one will persist because
        //      we shouldn't be reading from this.state during a state update, as it isn't always reliable.
        value: stateValue
    }

    updateValues = async toUpdate => {
        const nextState = this.state.value;

        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && nextState[key] != value) {
                nextState[key] = value;
            }
        }

        this.setState(nextState);
    }

    updateDates = (startDate, endDate, prevStartDate, prevEndDate, realEndDate, numDays, timescale) => {
        const dates = {};
        dates.startDate = startDate;
        dates.endDate = endDate;
        dates.daysBetweenStartEnd = numDays;
        dates.prevRangeStartDate = prevStartDate;
        dates.prevRangeEndDate = prevEndDate;
        dates.realEndDate = realEndDate;
        dates.errorDates = (numDays <= 0);
        dates.timescale = timescale;

        this.updateValues(dates);
    }

    render() {
        return (
            <DateContext.Provider value={{
                startDate: this.state.value.startDate,
                endDate: this.state.value.endDate,
                realEndDate: this.state.value.realEndDate,
                daysBetweenStartEnd: this.state.value.daysBetweenStartEnd,
                prevRangeStartDate: this.state.prevRangeStartDate,
                prevRangeEndDate: this.state.prevRangeEndDate,
                errorDates: this.state.errorDates,
                timescale: this.state.timescale,
                validDates: this.state.validDates,
                validDatesError: this.state.validDatesError,

                updateValues: this.updateValues,
                updateDates: this.updateDates,
            }}>
                {this.props.children}
            </DateContext.Provider>
        )
    }
}

export default DateContextProvider;
