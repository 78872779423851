import React, { useState, useContext, useEffect } from "react";
import { withStyles } from '@material-ui/core/styles';
import { MenuContext } from '../../contexts/MenuContext';
import { UserContext } from '../../contexts/UserContext';
import { Card, Tab, Tabs, Typography, Link } from '@material-ui/core';
import ModalBackground from '../ModalBackground';
import CloseButton from '../CloseButton';
import DataSources from './DataSources';
import Endpoints from './Endpoints';
import withLoading from '../withLoading';
import { getExternalDataEndpoints, getSupportedDataSources } from "../../api/ApiWorker";
import _ from 'lodash';

const cardZIndex = 6001;
const styles = theme => ({
  card: {
    position: "fixed",
    top: "15%",
    left: "15%",
    height: "75vh",
    width: "75vw",
    zIndex: cardZIndex //Pass zIndex 6000 to modal background, using zIndex 6001 ensures the card stacks on top of the background
  },
  title: {
    position: 'relative',
    top: '15px',
    left: '15px',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '.25px',
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.87)',
    paddingBottom: '15px'
  },
  tabs: {
    flexGrow: 1,
    paddingBottom: '10px'
  },
  contentDiv: {
    overflowY: 'hidden',
    overflowX: "hidden",
    height: "84%"
  }
});

const LoadingDiv = withLoading('div');

const AdminPanel = (props) => {
  const { classes } = props;

  const userContext = useContext(UserContext);
  const menuContext = useContext(MenuContext);

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState({index: 0, defaultDataSource: null});
  const [error, setError] = useState(false);

  const [data, updateData] = useState({
    dataSources: [],
    endpoints: [],
    supportedDataSources: []
  });

  const getData = async () => {
    const tenant = userContext.getTenant();
    const supportedDataSourcesAbortController = new AbortController();
    const endpointAbortController = new AbortController();

    setError(false);
    setIsLoading(true);

    try {
      var supportedDataSources = await getSupportedDataSources(tenant.tenantName, supportedDataSourcesAbortController);
      var endpoints = await getExternalDataEndpoints(tenant.tenantName, endpointAbortController);

      setData({
        dataSources: tenant.externalDataApis,
        supportedDataSources: supportedDataSources,
        endpoints: endpoints
      });
    } catch (e) {
      setError('An error occurred while retrieving external data.');
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const setData = (newData) => {
    updateData(newData);
  }

  const onClose = () => {
    menuContext.toggleAdminPanel();
  }

  const getCurrentTab = () => {
    if (error) {
      return (
        <div style={{ color: 'red', fontSize: '15px', lineHeight: '15px', padding: '8px', display: 'flex'}}>
            {error}
            <Typography style={{ fontSize: '15px', lineHeight: '15px', paddingLeft: '8px', cursor: 'pointer'}}>
                <Link style={{cursor: 'pointer'}} onClick={() => {getData()}}>
                    Try again?
                </Link>
            </Typography>
        </div>
      )
    }
    if (isLoading) return <LoadingDiv isLoading={true} />;
    
    switch (currentTab.index)
    {
      case 0:
        return <DataSources setData={setData} dataSources={data.dataSources} endpoints={data.endpoints} supportedDataSources={data.supportedDataSources} setCurrentTab={setCurrentTab} defaultDataSource={currentTab.defaultDataSource} />;
      case 1:
        return <Endpoints setData={setData} dataSources={data.dataSources} endpoints={data.endpoints} supportedDataSources={data.supportedDataSources} setCurrentTab={setCurrentTab} defaultDataSource={currentTab.defaultDataSource} />;
      default:
        return <> </>;
    }
  }

  const handleTabChange = (event, newValue) => {
    setCurrentTab({
      index: newValue,
      defaultDataSource: null
    });
  }

  const Content =
  <>
    <Card className={classes.card} elevation={24}>
      <Typography className={classes.title}>
        Organization Settings
      </Typography>
      <CloseButton handleClick={onClose} />
      <Tabs className={classes.tabs} value={currentTab.index} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
        <Tab label="Data Sources"/>
        <Tab disabled={_.isEmpty(data.dataSources)} label="Endpoints"/>
      </Tabs>
      <div className={classes.contentDiv}>
          {getCurrentTab()}
      </div>
    </Card>
    <ModalBackground />
  </>

  return Content;
}

export default withStyles(styles)(AdminPanel);