import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { RouteContext } from '../../contexts/RouteContext';
import { ModelContext } from '../../contexts/ModelContext';
import { MenuContext } from '../../contexts/MenuContext';
import { DateContext } from '../../contexts/DateContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { withStyles } from '@material-ui/core/styles';
import NewTopMenu from '../topmenu/NewTopMenu';
import _ from 'lodash';
import ModelFreeFeaturePanel from '../modelFree/featurePanel/FeaturePanel';
import DashboardFrame from '../DashboardFrame';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getHeaders } from '../../api/ApiWorker';
import { Typography, ListItem, ListItemText, Grid } from '@material-ui/core';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

const PrecipiCASTDashboard = (props) => {
    const {dashboards, selectedDashboard, match, history, mapType, classes} = props;

    const userContext = useContext(UserContext);
    const routeContext = useContext(RouteContext);
    const modelContext = useContext(ModelContext);
    const menuContext = useContext(MenuContext);
    const dateContext = useContext(DateContext);
    const themeContext = useContext(ThemeContext);

    const [isLoading, setIsLoading] = useState(true);
    const [layout, setLayout] = useState([]);
    const [assets, setAssets] = useState([]);
    const [assetFilter, setAssetFilter] = useState('');
    const [assetsError, setAssetsError] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect( () => {
        const tenant = userContext.getTenant();
        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'PrecipiCAST',
            viewType: null,
            layerName: null,
            assetName: null
        });
        return function cleanup() {
            dateContext.updateValues({
                validDates: [],
                validDatesError: null
            })
        }
    }, []);

    useEffect( () => {
        setLayout(props.layout);
    }, [props.layout]);

    useEffect( () => {
        if (!_.isEqual(routeContext.state.tenant, match.params.tenantName)) {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            if (!_.isEmpty(newTenant)) {
                userContext.switchTenant(newTenant.id);
                routeContext.updateValues({
                    tenant: newTenant.tenantName
                });
            }
            else {
                history.push('/unknown');
            }
        }
    }, [routeContext.state.tenant, match.params.tenantName])

    useEffect( () => {
        if (dashboards.length > 0 && selectedDashboard == null) {
            let newDashboard = dashboards.find(x=> !_.isEmpty(x.tags) && x.tags.includes('PrecipiCAST'));
            if (newDashboard) {
                props.loadDashboard(newDashboard.dashboardCards, newDashboard);
            } else {
                history.push('/unknown');
            }
        }
    }, [dashboards]);

    useEffect( () => {
        if (!_.isEmpty(selectedDashboard)) {
            getData();
        }
    }, [selectedDashboard])

    const getData = async () => {
        try {
            setIsLoading(true);
            setAssets([]);
            setAssetsError(null);

            const assetsConfiguration = selectedDashboard.featurePanelConfiguration.api.Assets;
            const url = new URL(assetsConfiguration.urlPattern);

            const results = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders())
            });

            if (results.ok) {
                let result = await results.json();
                setAssets(result);

                dateContext.updateValues({
                    validDates: result.map(x => x.lastDownloadedTimestamp)
                })
                return;
            } 
            throw new Error(results.statusText);
        } catch (e) {
            console.error(e);
            setAssetsError('An error occurred while loading data.');
        } finally {
            setIsLoading(false);
        }
    }

    const applyFilter = (assetList) => {
        let filteredList = assetList.filter(x => x.name.toLowerCase().includes(assetFilter.toLowerCase()) || 
                                                 x.city.toLowerCase().includes(assetFilter.toLowerCase()) ||
                                                 x.state.toLowerCase().includes(assetFilter.toLowerCase()) );
        let sortedList = filteredList.sort( (a,b) => {
            function containsFilterIndex(input) {
                return input.toLowerCase().indexOf(assetFilter.toLowerCase());
            }
            return containsFilterIndex(b.state) - containsFilterIndex(a.state) || 
            containsFilterIndex(b.city) - containsFilterIndex(a.city) || 
            containsFilterIndex(b.name) || containsFilterIndex(a.name);
        });
        return sortedList;
    }

    const handleListItemClick = (item) => {
        if (_.isEqual(selectedItem, item)) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    }

    const listItemRenderer = ({ index, style }) => {
		const item = applyFilter(assets)[index];
		if (_.isEmpty(item)) return null;
		return (
            <ListItem 
                selected={_.isEqual(item.id, !_.isEmpty(selectedItem) ? selectedItem.id : false)}
                onClick={() => handleListItemClick(item)}
                className={classes.listItem}
                style={style}
                button={false}
                divider={true}>
                <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item xs>
                        <ListItemText primary={item.name} />
                        <Typography style={{color: 'gray', marginLeft: '16px'}} variant='body2'>
                           {`${item.city}, ${item.state}`}
                        </Typography>
                    </Grid>
                </Grid>	
            </ListItem> 
		);
	}


    if (_.isEmpty(selectedDashboard)) return null;

    const {collapsedDrawerWidth} = menuContext;
    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

    var panelWidth = "20vw";
    var frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)"; 
    var frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";

    return (
        <div className={classes.root}>
            <>
                <NewTopMenu
                    modelContext={modelContext}
                    userContext={userContext}
                    dateContext={dateContext}
                    themeContext={themeContext}
                    configuration={selectedDashboard.topMenuConfiguration}
                    menuContext={menuContext}
                    selectedDashboard={selectedDashboard}
                    />
                <ModelFreeFeaturePanel
                    panelWidth={panelWidth}
                    isLoading={isLoading}
                    assets={applyFilter(assets)}
                    assetsError={assetsError}
                    selectedItem={selectedItem}
                    getData={getData}
                    setFilter={setAssetFilter}
                    listItemRenderer={listItemRenderer}
                    />
                <DashboardFrame 
                    dashboardAssets={assets}
                    filteredDashboardAssets={applyFilter(assets)}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    selectedDashboard={selectedDashboard}
                    menuContext={menuContext}
                    routeContext={routeContext}
                    frameOffset={frameOffset}
                    frameWidth={frameWidth}
                    mapType={mapType}
                    importLayout={layout}
                    />
            </>
        </div>
    )
}

PrecipiCASTDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const PrecipiCASTDashboardComponent = withStyles(styles, { withTheme: true })(PrecipiCASTDashboard);
const useDesktopLayout = (props) => {
    const matches = useMediaQuery('(min-width:1367px)');
    return (<PrecipiCASTDashboardComponent{...props} isDesktop={matches}></PrecipiCASTDashboardComponent>);
}

export default useDesktopLayout;
