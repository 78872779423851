import React, { useContext } from 'react';

import ApiHelper from '../../api/ApiHelper';
import CardContent from '@material-ui/core/CardContent';
import { DateContext } from '../../contexts/DateContext';
import Grid from '@material-ui/core/Grid';
import { ModelContext } from "../../contexts/ModelContext";
import Typography from '@material-ui/core/Typography';
import { UserContext } from '../../contexts/UserContext';
import _ from 'lodash';
import { getGenericRequestData } from '../../api/ApiWorker';
import moment from 'moment-timezone';
import { useQueries } from 'react-query';
import withLoading from '../withLoading';
import { withStyles } from '@material-ui/core/styles';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading('div');

const styles = {
    cardTitle : {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "1.5rem",
        lineHeight: "20px",
        display: "block",
        alignItems: "center",
        textAlign: "center"
    },
    observedValue : {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "2rem",
        lineHeight: "50px"
    },
    prevObservedValue : {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "2rem",
        lineHeight: "50px"
    },
    errorMessage : {
        fontFamily : 'Roboto',
        fontStyle: 'normal',
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: '10px',
        display: 'block',
        alignItems: 'center',
        textAlign: 'center'
    },
    progress: {
        top: 25,
        left: 150,
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    }
};

const numbers = {
    oneThousand: 1000,
    oneMillion: 1000000,
    oneBillion: 1000000000,
    oneTrillion: 1000000000000
}

const WBEKpi = (props) => {
    const {classes, api, title, getColor, trendingUp, trendingDown} = props;

    const modelContext = useContext(ModelContext);
    const dateContext = useContext(DateContext);
    const userContext = useContext(UserContext);

    const apiKey = Object.keys(api)[0];
    const urlPattern = api[apiKey].urlPattern;
    const urlParams = api[apiKey].parameters;

    const getKpiUrl = (isCurrentRange = true) => {
        const {selectedItem, selectedFeature} = modelContext.state.value;

        let startDate = dateContext.startDate;
        let endDate = dateContext.realEndDate;

        if (!isCurrentRange) {
            startDate = dateContext.prevRangeStartDate;
            endDate = dateContext.prevRangeEndDate;
        }

        let pattern = urlPattern;
        var assetName = _.isEmpty(selectedItem) ? 'All' : selectedItem.properties[selectedFeature.displayField];
        pattern = pattern.split("[assetName]").join(assetName);
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        pattern = pattern.split("[startDate]").join(moment.tz(startDate, tz).format());
        pattern = pattern.split("[endDate]").join(moment.tz(endDate, tz).format());
        const tenant = userContext.getTenant();
        pattern = pattern.split("[tenantName]").join(tenant.tenantName);
        pattern = pattern.split("[modelName]").join(modelContext.selectedModel.name);

        let url = new URL(pattern, ApiHelper.getUrlPath());
        url = ApiHelper.fillFromObj(url, urlParams);

        return new URL(url, ApiHelper.getUrlPath());
    }

    const formatKpiValue = (value) => {
        if (value == null) return null;
        try {
            let kpiValue = JSON.parse(value);
            kpiValue = kpiValue.toString().includes('.') ? kpiValue.toFixed(2) : kpiValue;
            return kpiValue;
        } catch {
            return 0;
        }
    }

    const formatData = (results) => {
        if (_.isEmpty(results) || results.find(x => x.isLoading)) return;

        const kpiResult = results[0];
        const differenceResult = results[1];
        let kpiValue = formatKpiValue(kpiResult.data);
        let differenceValue = formatKpiValue(differenceResult.data);

        let diff = 0;
        let note = 'Unable to locate resource';
        let color = "#676767";

        //No data for current week
        if (kpiValue == null) {
            return (
                <Grid container direction='row' justify='center' alignItems='center' alignContent='center' spacing={1} style={{marginTop: 30}}>
                    <Typography variant='body1' className={classes.errorMessage} style={{color: color}}>
                        <span>{'Data Not Available'}</span>
                    </Typography>
                </Grid>
            )
        }
        //No data for previous week, just show current week data
        if (differenceValue == null) {
            note = 'N/A';
        } else {
            diff = (kpiValue - differenceValue) * 100 / kpiValue;
            diff = (diff.toString().includes(".") ? diff.toFixed(2) : diff);
    
            note = diff + "%";

            var num = parseFloat(diff);
    
            switch (true)
            {
                case (num > 0) :
                    color = getColor(trendingUp);
                    break;
                case (num < 0) : 
                    color = getColor(trendingDown);
                    break;
                default:
            }
    
            if (num > 0) {
                note = "+" + note;
            }
        }

        if (!urlParams.isPercent) {
            kpiValue = kpiValue.toString().split('.')[0];
            // Trillion
            if (kpiValue.length >= 13) {
                kpiValue = Math.round(10 * (kpiValue / numbers.oneTrillion)) / 10 + 'T';
            }
            else if (kpiValue.length >= 10 && kpiValue.length <= 12) {
                kpiValue = Math.round(10 * (kpiValue / numbers.oneBillion)) / 10 + 'B';
            }
            else if (kpiValue.length >= 7 && kpiValue.length <= 9) {
                kpiValue = Math.round(10 * (kpiValue / numbers.oneMillion)) / 10 + 'M';
            }
            else if (kpiValue.length >= 4 && kpiValue.length <= 6) {
                kpiValue = Math.round(10 * (kpiValue / numbers.oneThousand)) / 10 + 'K';
            }
        } else {
            kpiValue = kpiValue.toString() + '%';
        }

        return (
            <Grid container direction='row' justify='center' alignItems="center" alignContent='center' spacing={1} style={{marginTop: 10}}>
                <Grid item xs style={{flexGrow: .25}}>
                    { /* Observed Value */}
                    <Typography variant="h5" className={classes.observedValue}>
                        <span>{kpiValue}</span>
                    </Typography>
                </Grid>
                <Grid item xs style={{flexGrow: .25}}>
                    { /* Percentage Comparison */}
                    <Typography variant='h5' className={classes.prevObservedValue} style={{color: color}}>
                        <span>{note}</span>
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    const kpiUrl = getKpiUrl();
    const differenceUrl = getKpiUrl(false);

    const generatedQueries = [
        //KPI Data (Current timeframe)
        {
            queryKey: kpiUrl.toString(),
            queryFn: async () => await getGenericRequestData(kpiUrl)
        },
        //Difference Data (Previous timeframe)
        {
            queryKey: differenceUrl.toString(),
            queryFn: async () => await getGenericRequestData(differenceUrl)
        }
    ];

    const results = useQueries(generatedQueries);
    const Content = formatData(results);

    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={results.find(x => x.isLoading)}>
            <CardContent className={classes.root}>
                <Typography variant='body1' className={classes.cardTitle} style={{color: getColor('neutral')}}>
                    <span>{title}</span>
                </Typography>
                {Content}
            </CardContent>
        </LoadingDiv>
    );
}

export default withStyles(styles)(WBEKpi);