import React, {useContext} from 'react';
import { withStyles } from '@material-ui/styles';
import {Button, Grid, TextField} from '@material-ui/core';
import AccountIcon from '@material-ui/icons/AccountCircle';
import {UserContext} from '../../contexts/UserContext';
import _ from "lodash";


const styles = theme => ({
    textField: {
        width: '20vw',
        margin: '16px'
    },
    button: {
        cursor: 'pointer', 
        position: 'relative', 
        background: '#FFFFFF', 
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '200px', 
        margin: '16px',
        color: '#556EB5'
    }
});

const AccountInfo = (props) => {

    const {classes} = props;

    const userContext = useContext(UserContext);

    const {email, given_name, family_name, iss} = userContext.currentUser.tokenParsed;

    return ( 
        <Grid container direction='column' justify='center' alignItems='flex-start'>
            <Grid item>
                <TextField 
                className={classes.textField}
                label='Email'
                defaultValue={email}
                variant='outlined' 
                InputProps={{
                    readOnly: true,
                }}/>
            </Grid>
            <Grid item>
                <TextField 
                className={classes.textField}
                label='First Name'
                defaultValue={given_name}
                variant='outlined' 
                InputProps={{
                    readOnly: true,
                }}/>
            </Grid>
            <Grid item>
                <TextField 
                className={classes.textField}
                label='Last Name'
                defaultValue={family_name}
                variant='outlined' 
                InputProps={{
                    readOnly: true,
                }}/>
            </Grid>
            <Grid item>
                <Button 
                onClick={() => {window.open(`${iss}/account`);}}
                className={classes.button}
                variant='outlined'
                startIcon={<AccountIcon style={{color: '#556EB5'}} />}>
                    Manage Account
                </Button>
            </Grid>
        </Grid> 
    )
}
export default withStyles(styles)(AccountInfo);