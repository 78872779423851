import React, {useContext, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ModelContext } from "../../contexts/ModelContext";
import { DateContext } from '../../contexts/DateContext';
import { UserContext } from '../../contexts/UserContext';
import ApiHelper from '../../api/ApiHelper';
import moment from 'moment-timezone';
import { useQueries } from 'react-query';
import { getGenericRequestData } from '../../api/ApiWorker';
import _ from 'lodash';
import withLoading from '../withLoading';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading('div');

const styles = {
    root: {
        paddingRight: "1rem",
        paddingLeft: "1rem",
    },
    Title: {
        textAlign: "center",
        fontFamily: "Roboto",
        textTransform: "uppercase",
        color: "rgba(0,0,0,0.6)",
        fontSize: "1rem",
        lineHeight: "unset",
        display: "block"
    },
    Value: {
        fontFamily: "Roboto",
        display: "inline-block",
        marginRight: ".5rem",
        fontSize: "2rem",
        fontWeight: "500",
        marginTop: "-0.5rem"
    },
    Label: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "1.2rem",
    },
    Notes: {
        position: "absolute",
        left: "1rem",
        bottom: "1.2rem",
        fontFamily: "Roboto",
        fontSize: "1.2rem",
        lineHeight: "1rem",
        color: "rgba(0, 0, 0, 0.6)",
    },
    progress: {
        position: 'absolute',
        color: 'rgba(0,0,0,.2)'
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%",
    }
};

const ComparisonKpi = (props) => {
    const {classes, api, title} = props;

    const modelContext = useContext(ModelContext);
    const dateContext = useContext(DateContext);
    const userContext = useContext(UserContext);

    const observedApiKey = Object.keys(api)[0];
    const modeledApiKey = Object.keys(api)[1];
    
    const getUrl = (apiKey, isObserved = true) => {
        let urlPattern = api[apiKey].urlPattern;
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        urlPattern = urlPattern.split("[startDate]").join(moment.tz(dateContext.startDate, tz).format());
        urlPattern = urlPattern.split("[endDate]").join(moment.tz(dateContext.realEndDate, tz).format());
        const tenant = userContext.getTenant();
        urlPattern = urlPattern.split("[tenantName]").join(tenant.tenantName);

        const parameters = api[apiKey].parameters;

        const url = isObserved
        ? ApiHelper.fillWithBaseModel(urlPattern, parameters, modelContext.state.value)
        : ApiHelper.fillWithSelectedModel(urlPattern, parameters, modelContext.state.value);

        return new URL(url, ApiHelper.getUrlPath());
    }

    const formatData = (results) => {
        if (_.isEmpty(results) || results.find(x => x.isLoading)) return;

        const observedResult = results[0];
        const modeledResult = results[1];

        let observedValue = typeof observedResult.data === 'number' ? observedResult.data : 0;
        let modeledValue = typeof modeledResult.data === 'number' ? modeledResult.data : 0;
        let note = 'Unable to locate resource';

        if (observedResult.isSuccess && modeledResult.isSuccess) {
            let variance = 100 * (1 - modeledValue / (observedValue || 1));
            variance = Math.round(variance * 100) / 100;
            if (observedValue != 0) {
                note = variance.toFixed(1) + "%  variance";
                if (variance >= 0) {
                    note = "+" + note;
                }
            } else if (modeledValue == 0) {
                variance = 0;
                note = "+0% variance";
            } else {
                note = "Variance not applicable";
            }
            observedValue = Math.round(observedValue * 100) / 100;
            modeledValue = Math.round(modeledValue * 100) / 100;
        }

        return (
            <>
                <Typography variant="body2" className={classes.Value}>
                    <span>{observedValue}</span>
                </Typography>
                <Typography variant="body2" className={classes.Label}>
                    <span>{observedApiKey}</span>
                </Typography>
                <div />
                <Typography variant="body2" className={classes.Value}>
                    <span>{modeledValue}</span>
                </Typography>
                <Typography variant="body2" className={classes.Label}>
                    <span>{modeledApiKey} </span>
                </Typography>
                <Typography variant="body2" className={classes.Notes}>
                    <span>{note}</span>
                </Typography>
            </>
        )
    }

    const observedUrl = getUrl(observedApiKey);
    const modeledUrl = getUrl(modeledApiKey, false);

    const generatedQueries = [
        //Observed data
        {
            queryKey: observedUrl.toString(),
            queryFn: async () => await getGenericRequestData(observedUrl)
        },
        //Modeled data
        {
            queryKey: modeledUrl.toString(),
            queryFn: async () => await getGenericRequestData(modeledUrl)
        }
    ];

    const results = useQueries(generatedQueries);
    const Content = formatData(results);

    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={results.find(x => x.isLoading)}>
            <CardContent className={classes.root}>
                <Typography variant="overline" className={classes.Title}>
                    <span>{title}</span>
                </Typography>
                {Content}
            </CardContent>
        </LoadingDiv>
    );
}

export default withStyles(styles)(ComparisonKpi);