import React from "react";
import _ from 'lodash';
import { Typography } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';

const GroupedMultiSeriesLegendContent = ({ series, toggleSeries, hiddenSeriesList }) => {
    const domain = (typeof(series.Domain) === 'undefined') ? [] : series.Domain;

    let groups = Array.from(new Set(domain.map(a => a.legendGroup)));

    return (
        <table key={Math.random()} style={{
            display: 'table',
            fontFamily: 'Roboto',
            width: '100%',
            height: '100%',
            borderCollapse: 'collapse',
            borderSpacing: 0,
            borderStyle: 'none',
          }} >
            <tbody>
                {groups.map((group) => 
                    <tr key={Math.random()}>
                        <td key={Math.random()}>
                            <Typography variant="h6" style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                                <span>{group}</span>
                            </Typography>
                        </td>
                        {domain.filter(d => d.legendGroup === group).map(data => 
                            <td key={Math.random()}>
                                <BarChartIcon fill={data.color} width="24" height="24" style={{verticalAlign: 'middle', color: hiddenSeriesList.indexOf(data.tooltipLabel) == -1 ? data.color : 'darkgrey'}} />
                                <span onClick={() => {toggleSeries(data.tooltipLabel)}} style={{ cursor: 'pointer', verticalAlign: 'middle'}}>{data.legendLabel}</span>
                            </td>
                        )}
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default GroupedMultiSeriesLegendContent;