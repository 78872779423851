/* Component for Reporting Dashboard- CSO Discharge Dashboard*/

import React, { useEffect,useContext,useState } from 'react';
import FeaturePanel from "../featurepanel/FeaturePanel";
import TopMenu from "../topmenu/TopMenu";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import { MenuContext } from '../../contexts/MenuContext';
import { RouteContext } from '../../contexts/RouteContext';
import { UserContext } from '../../contexts/UserContext';
import { DateContext } from '../../contexts/DateContext';
import {ModelContext} from '../../contexts/ModelContext';
import DashboardFrame from '../DashboardFrame';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

const CSODischargeDashboard = (props) => { 
    const { classes, model, match, location, history, loadDashboards,loadDashboard, dashboards, selectedDashboard, mapType, layout } = props;
    const menuContext = useContext(MenuContext);
    const routeContext = useContext(RouteContext);
    const userContext = useContext(UserContext);
    const dateContext = useContext(DateContext);
    const modelContext = useContext(ModelContext);

    const { collapsedDrawerWidth } = menuContext;

    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;
    var panelWidth = "15vw";
    var frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)"; 
    var frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";
    
    if (selectedDashboard && !_.isEmpty(selectedDashboard.featurePanelConfiguration))
    {
        panelWidth = "35vw";
        frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)";
        frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";
    }

    if (selectedDashboard?.extraData?.DisableFeaturePanel) {
        frameOffset = "calc(" + drawerWidth + "px)";
        frameWidth = "calc(100vw - " + drawerWidth + "px)";
    }

    useEffect(() => {
        const {model, selectedDashboard} = props;
        const tenant = userContext.getTenant();
        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'reports',
            viewType: match.params.viewType,
            layerName: match.params.layerName,
            assetName: match.params.assetName
        })

        return() => {
            model.updateValues({
                selectedItem: null,
                highlightedAsset: null,
                selectedStatus: null
            })
            dateContext.updateValues({
                validDates: [],
                validDatesError: null
            })
        }
    }, [])

    useEffect(() => {
        modelContext.updateValues({
            selectedFeature: match.params.layerName,
        });
    }, [model.allModels])

    useEffect(() => {
        if (!_.isEqual(routeContext.state.tenant, match.params.tenantName)) {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            if (!_.isEmpty(newTenant)) {
                userContext.switchTenant(newTenant.id);
                routeContext.updateValues({
                    tenant: newTenant.tenantName
                });
            }
            else {
                history.push('/unknown');
            }
        }
    }, [routeContext.state.tenant, match.params.tenantName])

    useEffect(() => {
        
        if (dashboards.length > 0 && selectedDashboard == null)
        {
            let newDashboard = dashboards.find(x=> x.dashboardName == match.params.viewType);
            if (newDashboard) {
                loadDashboard(newDashboard.dashboardCards, newDashboard);
            }
            else {
                history.push('/unknown');
            }
        }

    },[selectedDashboard])

    return (
        <div className={classes.root}>           
            <TopMenu
                selectedDashboard={selectedDashboard}
                displayFeatureSelector={!selectedDashboard?.extraData.DisableFeaturePanel}
                model={model}
                menuContext={menuContext}
                match={match}
                location={location}
                history={history}/>
            { !selectedDashboard?.extraData.DisableFeaturePanel && <FeaturePanel 
                panelWidth={panelWidth}
                match={match}
                history={history}
                location={location}/> }
            <DashboardFrame 
                selectedDashboard={selectedDashboard}
                loadDashboards={loadDashboards}
                frameOffset={frameOffset}
                frameWidth={frameWidth}
                mapType={mapType}
                importLayout={layout}/>
        </div>
    )
}

CSODischargeDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const CSODischargeComponent = withStyles(styles, { withTheme: true })(CSODischargeDashboard);
function useDesktopLayout(props) {
    const matches = useMediaQuery('(min-width:1367px)');
    return (<CSODischargeComponent{...props} isDesktop={matches}></CSODischargeComponent>);
}

export default useDesktopLayout;