import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonGroup }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles ({
    groupedOutlined: {
        color: '#00a1aa',
        backgroundColor: "#FBFCFC",
        borderWidth: "1px",
        "&:hover": {
            backgroundColor: "#F0F3F4",
        },
    },
});

const StylesButton = (props) => {
    let classes = useStyles();
    return (
        <ButtonGroup classes = {{groupedOutlined: classes.groupedOutlined}} variant = "outlined" size = "small" aria-label = "Styles Button Group">
            <Button disabled={props.viewType == "Map"} onClick = {() => props.toggleViewType("Map")}> Map </Button>
            <Button disabled={props.viewType == "Satellite"} onClick = {() => props.toggleViewType("Satellite")}> Satellite </Button>
        </ButtonGroup>
    );
}

export default StylesButton
