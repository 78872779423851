import React from "react";
import PipeCast from '../../img/pipeCAST logo.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TimeScaleSelector from "./TimeScaleSelector";
import FeatureTypeSelector from "./FeatureTypeSelector";
import DateRangeSelectorComponent from "./DateRangeSelectorComponent";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RainfallEventSelector from "./RainfallEventSelector";


const styles = theme => ({
    appBar: {
        position: "fixed",
        zIndex: theme.zIndex.drawer - 1,
        boxShadow: "none",
        backgroundColor: "#EBEEF6" //#D8E9F4
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },

});

const TopMenu = (props) => {

    const { classes, menuContext, match, location, history, selectedDashboard, displayFeatureSelector} = props;
    var open = menuContext.getLockOpen();
    var drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : 0;

    const [customSelectionIsDisabled, setCustomSelectionIsDisabled] = React.useState(false)

    const showCustomDateRange = () => {
        setCustomSelectionIsDisabled(false)
    }

    const hideCustomDateRange = () => {
        setCustomSelectionIsDisabled(true)
    }

    return (
        <AppBar className={classNames(classes.appBar)} 
        style={{left:drawerWidth, width: "calc(100% - " + drawerWidth + "px)"}}>
            <Toolbar style={{paddingLeft: '12px'}}>
            <Grid container direction='row' spacing={1} alignItems='center'>
                    {   
                        !menuContext.getLockOpen() &&
                        <Grid item>
                            <IconButton onClick={menuContext.floatOpenDrawer} style={{color: 'white'}}>
                                <img src={PipeCast} style={{width: 100, height: 30}}/>
                            </IconButton>
                        </Grid>
                    }
                    <Grid item container direction='row' xs justify='space-between' alignItems='flex-end'>
                        <Grid item xs={'auto'}>
                            {displayFeatureSelector &&
                                <FeatureTypeSelector
                                    selectedDashboard={selectedDashboard}
                                    match={match}
                                    location={location}
                                    history={history}
                                />
                            } 
                        </Grid>
                        <Grid item xs={'auto'}>
                            <TimeScaleSelector 
                                showCustomDateRange={showCustomDateRange} 
                                hideCustomDateRange={hideCustomDateRange}
                                match={match}
                                history={history}
                                location={location}
                                {...props}
                            />
                        </Grid>
                        <Grid item xs={'auto'}>
                            <DateRangeSelectorComponent
                                isDisabled={customSelectionIsDisabled}
                            />
                        </Grid>
                        
                        <Grid item xs={'auto'} style={{ margin: '1px 1px 6px 16px'}}>
                            <RainfallEventSelector 
                                selectedDashboard={selectedDashboard}
                            />
                        </Grid>
                        
                        <Grid item xs>
                            <img src={PipeCast} style={{width: '250px', height: '60px', float:'right'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
    
}

TopMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TopMenu);