import React, { useEffect, forwardRef, useState } from 'react';
import * as d3 from 'd3';
import setBrush from './setBrush';
import {
    getBrushedBounds,
    createTimeScale,
    createValueScale
} from '../../chartComponents/util';
import {
    ClipPath,
    XAxisGroup,
    LineSeries,
    SingleSeriesBar,
    CurveStep,
    AlertMarker,
    AreaSeries
} from '../../chartComponents';
import moment from 'moment-timezone';
import { differenceInCalendarWeeks } from 'date-fns';
import { TENANT_TIMEZONE } from '../../../../constants/TimezoneConstants';

export default forwardRef((props, brushBarRef) => {

    let { width, height, data, mainChartRef, clipPathId, dateContext, selectedDashboard } = props;
    const margin = {
        top: height - 120,                //Original: 18.5% (0.815) Test: 0.815
        right: 120,   //Original: 9%
        bottom: 78,              //Original: 10% (0.90) Test: 0.80
        left:  80     //Original: 9%
    };
    
    var tz = sessionStorage.getItem(TENANT_TIMEZONE);
    let startDate = moment.tz(dateContext.startDate, tz);
    let endDate = moment.tz(dateContext.realEndDate, tz);
    let { validDates} = dateContext;
    const dateRangeLimit = selectedDashboard?.dateRangeLimit;

    //If validDates exists, set the startDate to either 52 weeks or dateContext.startDate, whichever one is the earliest.
    //If validDates does NOT exist, show data for the past 12 weeks

    if (dateRangeLimit) {
        startDate = !_.isEmpty(validDates) 
            ? moment.tz(endDate, tz).subtract(Math.min(dateRangeLimit, differenceInCalendarWeeks(new Date(endDate), new Date(validDates[0])) + 1), 'week')
            : moment.tz(endDate, tz).subtract(12, 'week');
    }

    let [hiddenSeriesList, setHiddenSeriesList] = useState([]);
    // let [scales, setScales] = useState(getScales(hiddenSeriesList));

    function getScales(providedHiddenSeriesList) {
        let [minLeft, maxLeft, minRight, maxRight] = getBrushedBounds(data, startDate, endDate, 'Data', providedHiddenSeriesList);

        const leftBuffer = (maxLeft - minLeft) * 0.1;
        const rightBuffer = (maxRight - minRight) * 0.1;

        const timeScale = createTimeScale(startDate, endDate, margin, width);
        const leftScale = createValueScale(minLeft, maxLeft + leftBuffer, margin, height);
        const rightScale = createValueScale(minRight, maxRight + rightBuffer, margin, height);
        const boolScale = createValueScale( 0, 1, margin, height);

        return {
            timeScale,
            leftScale,
            rightScale,
            boolScale,
            minLeft: leftScale(minLeft),
            minRight: rightScale(minRight),
            minBool: boolScale(0)
        };
    }

    function redraw(providedHiddenSeriesList) {
        if (providedHiddenSeriesList) setHiddenSeriesList(providedHiddenSeriesList)
        const { redrawFns } = brushBarRef.current;
            
        redrawFns && redrawFns.forEach(fn => {
                    fn(getScales(providedHiddenSeriesList), providedHiddenSeriesList);
                });
    }

    // useEffect(() => {
    //     setScales(getScales(hiddenSeriesList));
    // }, [hiddenSeriesList, data])

    useEffect(redraw, [hiddenSeriesList, data])

    function throttle(func, timeFrame) {
        var lastTime = 0;
        return function () {
            var now = Date.now();
            if (now - lastTime >= timeFrame) {
                func();
                lastTime = now;
            }
        };
      }

    function init() {
        brushBarRef.current.redraw = redraw;
        
        const brushBar = d3.select(brushBarRef.current);

        const { timeScale } = getScales(hiddenSeriesList);

        let [lastStart, lastEnd] = timeScale.range();
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        lastStart = moment.tz(timeScale.invert(lastStart), tz);
        lastEnd = moment.tz(timeScale.invert(lastEnd), tz);

        const callbacks = {
            onBrushStart: () => {
                const setSimpleData = mainChartRef.current.setSimpleData;
                setSimpleData();
            },
            onBrush: (startPos, endPos) => {
                const mainRedraw = mainChartRef.current.redraw;
                const start = moment.tz(timeScale.invert(startPos), tz);
                const end = moment.tz(timeScale.invert(endPos), tz);
                mainRedraw(hiddenSeriesList, start, end);
            },
            onBrushEnd: (startPos, endPos) => {   
                const mainRedraw = mainChartRef.current.redraw;

                if (startPos == endPos) {
                    const maxExtent = [(width - margin.left - margin.right), (height - margin.top - margin.bottom)];
                    mainRedraw(null, lastStart, lastEnd);
                    return setBrush(brushBar, timeScale, maxExtent, lastStart, lastEnd, callbacks);
                }
                tz = sessionStorage.getItem(TENANT_TIMEZONE);

                const start = moment.tz(timeScale.invert(startPos), tz);
                const end = moment.tz(timeScale.invert(endPos), tz);

                mainRedraw(hiddenSeriesList, start, end);
                
                //Using this to pass start/end dates for counting datapoints within selection
                //for simplification check
                const setFilterDates = mainChartRef.current.setFilterDates;
                setFilterDates(start, end);

                // const setFullData = mainChartRef.current.setFullData;
                // setFullData();
            }
        }
        const maxExtent = [(width - margin.left - margin.right), (height - margin.top - margin.bottom)];

        setBrush(brushBar, timeScale, maxExtent, startDate, endDate, callbacks)
    }

    useEffect(init, [brushBarRef]);
    useEffect(init, [dateContext.startDate, dateContext.realEndDate]);
    
    const setRef = node => {
        if(node) {
            var origRedraws = brushBarRef.current?.redrawFns || [];
            brushBarRef.current = node;
            brushBarRef.current.redrawFns = origRedraws;
        }
    }

    var scales = getScales(hiddenSeriesList);
    const series = data.sort((a, b) => {
        if(a.Order > b.Order) return 1;
        if(a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = Math.random();
        if(hiddenSeriesList.indexOf(s.Name) > -1) return (<></>);
        if(s.ChartType == 'bar') {
            return (<SingleSeriesBar key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></SingleSeriesBar>);
        } else if (s.ChartType == 'line' || s.ChartType == 'dashedLine') {
            return (<LineSeries key={key} isDotted={s.ChartType == 'dashedLine'} clipPathId={clipPathId}  scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></LineSeries>);
        } else if (s.ChartType == 'curveStep') { 
            return (<CurveStep clipPathId={clipPathId}  scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s} dataProp="brushData"></CurveStep>);
        } else if (s.ChartType == 'alert') {
            return (<AlertMarker clipPathId={clipPathId}  scales={scales} key={s.Name} {...props} ref={brushBarRef} series={s}></AlertMarker>);
        } else if (s.ChartType == 'area') {
            return (<AreaSeries clipPathId={clipPathId} scales={scales} key={s.Name} ref={brushBarRef} series={s} dataProp="brushData" {...props} ></AreaSeries>);
        }else {
            return (<></>);
        }
    });

    return (
        <svg width={width} height={height - margin.top} style={{ position: 'absolute', top: margin.top }} >
            <g ref={setRef} width={width - margin.left} transform={`translate(${margin.left}, 0)`}>
                <ClipPath clipPathId={clipPathId} margin={margin} {...props}></ClipPath>
                <XAxisGroup scales={scales} margin={margin} {...props} ref={brushBarRef} scaleName={"timeScale"}></XAxisGroup>                
                {series}
                <g className="brush"></g>
            </g>
        </svg>
    );
});