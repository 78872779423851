import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {ModelContext} from "../../contexts/ModelContext";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginRight: 24,
        marginLeft: 0,
        paddingTop: theme.spacing(3),
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '80%',
    },
    inputInput: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: theme.shape.borderRadius,
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        width: '80%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
});

class EventFilterBar extends React.Component {

    static contextType = ModelContext;

    constructor (props){
        super(props);
        this.state={
            input: ''
        }
    }

    componentDidMount() {
        this.props.setFilter('');
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
        this.props.setFilter(event.target.value);
    };

    render () {
        const { classes } = this.props;
        return (
            <div className={classes.search}>
                <TextField placeholder="Find an event (by regulator)"
                    onChange={this.handleChange('input')}
                    classes={{
                        root: classes.inputRoot,
                    }}
                    value={this.state.input}
                    variant="outlined"
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                    }}/>
            </div>
        )
    }
}

EventFilterBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventFilterBar);