import React from 'react';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const tileTest = {
    float: "left",
    display: "table",
    width: "100%",
    height: "100%",
}

const tileTestContent = {
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
    padding: "2px"
}

const TileTest = props => {
        return (
            <Card style = {tileTest}>
                <div style={tileTestContent}>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </Typography>
                </div>
            </Card>
        );
    
}

export default TileTest;