import React, { useEffect, forwardRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { width, margin, scales, clipPathId, scaleName } = props;

    function drawYGridLines(newScales) {
        scales = newScales;
        draw();
    }

    function draw() {
        const primaryChart = d3.select(primaryChartRef.current);
        
        primaryChart.select('.y-grid-lines')
            .call(d3.axisLeft(scales[scaleName])
            .ticks(7)
            .tickFormat("")
            .tickSize(-width + margin.left + margin.right)
        );   
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawYGridLines);
        draw();
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(), [scales]);

    return (
        <g className="y-grid-lines gridLines" id={clipPathId} style={{ stroke: "lightgray", strokeOpacity: "0.12", shapeRendering: "crispEdges" }}></g>
    )
})
