import React, {useState, useEffect, useContext} from 'react';
import { ModelContext } from '../../contexts/ModelContext';
import { DateContext } from '../../contexts/DateContext';
import { UserContext } from '../../contexts/UserContext';
import PropTypes from 'prop-types';
import {Paper, Button, Typography, Divider, Link} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StreetViewIcon from '@material-ui/icons/Streetview';
import _ from 'lodash';
import ApiHelper from '../../api/ApiHelper';
import { getGenericRequestData } from '../../api/ApiWorker';
import moment from 'moment-timezone';
import {useQuery} from 'react-query';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const styles = theme => ({
    root: {
        fontFamily: "Roboto",
        marginLeft: "0px",
        position: "absolute",
        top: 0,
        height: "calc(100vh - 64px)",
        minWidth: "300px",
        maxWidth : "300px",
        backgroundColor: "rgba(255, 255, 255, 1)",
        zIndex : 4000,
        overflow: "auto"
    },    
    featureName: {
        position: "relative",
        left: "5%",
        width: "95%"
    },
    featureTable : {
        width : "283px", //300 - 17 for the scrollbar
        minWidth : "283px",
        maxWidth : "283px",
        padding: "4px",
        tableLayout: "fixed"
    },
    obsVsSimTable : {
        width : "283px", //300 - 17 for the scrollbar
        minWidth : "283px",
        maxWidth : "283px",
        padding: "4px 4px 4px 12px",
    },
    attributeName : {
        position: "relative",
        fontSize: ".75rem",
        width: "50%",
        minWidth: "50%",
        wordBreak: "break-all",
        maxWidth: "50%",
    },
    attributeInfo : {
        position: "relative",
        fontSize: ".7rem",
        wordBreak: "break-all",
        width: "50%",
        minWidth: "50%",
        maxWidth: "50%",
    },
    obsVsSimTd : {
        position: "relative",
        fontSize: ".7rem",
        wordWrap: "word-break",
        width: "30%",
        minWidth: "30%",
        padding: "3px",
    },
    backButton : {

        color: "#526DCE",
    },
    streetViewButton : {
        fontSize: ".9rem",
        position: "absolute",
        top: "0rem",
        right: "0rem",
        color: "#526DCE"
    }
} )

const FeatureAttributeTable = (props) => {
    const {classes} = props;

    const modelContext = useContext(ModelContext);
    const dateContext = useContext(DateContext);
    const userContext = useContext(UserContext);

    const {featureData, close} = props;
    const {selectedModel, selectedFeature, highlightedAsset} = modelContext.state.value;
    const {startDate, realEndDate} = dateContext;
    const tenant = userContext.getTenant();

    const momentFormat = 'YYYY-MM-DD hh:mm a z';

    const getUrl = () => {
        const tz = sessionStorage.getItem(TENANT_TIMEZONE);
        return new URL(`api/v2/${tenant.tenantName}/${selectedModel.name}/assets/${selectedFeature.layerName}/statistictable/SummaryStats/${highlightedAsset}?&startDate=${moment.tz(startDate, tz).format()}&endDate=${moment.tz(realEndDate, tz).format()}`, ApiHelper.getUrlPath());
    }

    const getAttributeTd = () => {
        let renderedOutput = [];
        for(let feature in featureData?.properties) {
            const tz = sessionStorage.getItem(TENANT_TIMEZONE);
            let displayValue = featureData.properties[feature] == null ? "-" : featureData.properties[feature];
            let displayValueAsDate = moment.tz(displayValue, tz);
            displayValue = isNaN(displayValue) && displayValueAsDate.isValid() ? displayValueAsDate.format(momentFormat) : displayValue;
                                    
            renderedOutput.push(
                <tr key={'propTable-' + selectedFeature.displayField + '-' + feature}>
                    <td className={classes.attributeName}>{feature}</td>
                    <td className={classes.attributeInfo}>{displayValue}</td>
                </tr>
            )
        }
        return renderedOutput;
    }

    const getPerformanceTd = (result) => {
        let {data, fields} = result;
        if (!_.isEmpty(fields) && !_.isEmpty(fields)) {
            return fields.map(function(item, index){                
                const tz = sessionStorage.getItem(TENANT_TIMEZONE);
                let displayValue = data && data[item.fieldName]? data[item.fieldName] : '-' ;
                let displayValueAsDate = moment.tz(displayValue, tz);
                displayValue = isNaN(displayValue) && displayValueAsDate.isValid() ? displayValueAsDate.format(momentFormat) : displayValue;
                return (
                    <tr key={Math.random()}>
                        <td className={classes.attributeName}>{item.fieldLabel}</td>
                        <td className={classes.attributeInfo}>{displayValue}</td>
                    </tr>
                )
            });
        }
        return null;
    }

    const getObservedVsSimulatedTd = (result) => {
        let {data, fields} = result;
        if (!_.isEmpty(data) && !_.isEmpty(fields)) {
            var comparativeFields = selectedFeature.propertyTableFields.filter(obj => {
                return obj.columnName !== null
            });
            var rowLabels = [...new Set(comparativeFields.map(item => item.fieldLabel))];
            var columnLabels = [...new Set(comparativeFields.map(item => item.columnName))];

            return (
                <>
                    <tr key={Math.random()}>
                        <td className={classes.obsVsSimTd}></td>
                        {columnLabels.map(function(columnLabel, index){
                            return (
                                <td key={Math.random()} className={classes.obsVsSimTd}>{columnLabel}</td>
                            );
                        })}
                    </tr>
                    {rowLabels.map(function(rowLabel, index){
                        return (
                            <tr key={Math.random()}>
                                <td className={classes.obsVsSimTd}>{rowLabel}</td>
                                {columnLabels.map(function(columnLabel, index){
                                    var propertyStat = comparativeFields.find(obj => {
                                        return obj.columnName === columnLabel && obj.fieldLabel === rowLabel
                                    });
                                    const tz = sessionStorage.getItem(TENANT_TIMEZONE);
                                    let displayValue = data && propertyStat && data[propertyStat.fieldName] ? data[propertyStat.fieldName] : '-';
                                    let displayValueAsDate = moment.tz(displayValue, tz);
                                    displayValue = isNaN(displayValue) && displayValueAsDate.isValid() ? displayValueAsDate.format(momentFormat) : displayValue;
                                    return (
                                        <td key={Math.random()} className={classes.obsVsSimTd}>{displayValue}</td>
                                    );
                                })} 
                            </tr>
                        );
                    })}
                </>
            )
        }
       return null;
    }

    const openStreetViewWindow = () => {
        const coordinates = featureData.geometry.coordinates;
        const streetViewUrl = "http://www.google.com/maps?q&layer=c&cbll=" + coordinates[1]+ "," + coordinates[0];

        window.open(streetViewUrl, '_blank');
    }

    const url = getUrl();

    const result = useQuery(url.toString(), async () => await getGenericRequestData(url));

    const formatData = (result) => {
        let {data, fields} = result.data;

        fields = sortSummaryStatsKey(fields, 'order');

        for (let i = 0; i < fields.length; i++) {
            fields[i].order = i;
        }

        return {
            data,
            fields
        };
    }

    const sortSummaryStatsKey = (array, key) => {
        return array.sort(function (a, b) {
            if (a[key] > b[key]) return 1;
            if (a[key] < b[key]) return -1;
            return 0;
        });
    };

    const isLoading = (result.isLoading || result.isFetching || result.isRefetching);

    return (
        <Paper elevation={2} className={classes.root}>
            <Button className={classes.backButton} onClick={close}>
                <ChevronLeftIcon /> Back
            </Button>
            <Button className={classes.streetViewButton} onClick={openStreetViewWindow}>
                Street View
                <StreetViewIcon />
            </Button>
            <Typography className={classes.featureName} variant='h5'> {highlightedAsset} </Typography>
            <Divider />
            {
                isLoading && 
                <>
                    <span style={{left: '5%'}}> Loading summary data... </span>
                </>
            }
            {
                !isLoading && result.isSuccess && 
                <>
                     <table className={classes.featureTable}>
                     <tbody>
                         {getPerformanceTd(formatData(result))}
                     </tbody>
                     </table>
                     <Divider/>
                     <table className={classes.obsVsSimTable}>
                         <tbody>
                             {getObservedVsSimulatedTd(formatData(result))}
                         </tbody>
                     </table>
                 </>
            }
            {
                !isLoading && result.isError && 
                <div style={{ color: 'red', fontSize: '15px', lineHeight: '15px', padding: '8px', display: 'flex'}}>
                    {'An error occurred.'}
                    <Typography style={{ fontSize: '15px', lineHeight: '15px', paddingLeft: '8px', cursor: 'pointer'}}>
                        <Link onClick={() => result.refetch()}>
                            Try again?
                        </Link>
                    </Typography>
                </div>
            }
            <Divider/>
            <table className= {classes.featureTable}>
                <tbody>
                    {getAttributeTd()}
                </tbody>
            </table>
        </Paper>
    )
}

FeatureAttributeTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeatureAttributeTable);