import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

const path1d = 'M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm1 13h-2v-2h2v2zm0-4h-2V7h2v6z';

const defaultAlertPath = "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-1-5h2v2h-2zm0-8h2v6h-2z";

export default forwardRef((props, primaryChartRef) => {
    let { series, scales, dataProp, clipPathId, width } = props;

    if(!dataProp) dataProp = 'Data';

    const seriesName = series.Name.split(' ').join('-').split('.').join('-');;

    function drawAlertMarker(newScales, hiddenSeriesList) {
        scales = newScales;
        draw(series[dataProp], hiddenSeriesList);
    }

    drawAlertMarker.isSeries = true;

    function draw(seriesData, hiddenSeriesList = []) {
        const primaryChart = d3.select(primaryChartRef.current);
        
        const scale = scales.boolScale;

        const g = primaryChart.select("#" + seriesName);
    
        const visibility = hiddenSeriesList.indexOf(series.Name) == -1 ? 'visible' : 'hidden';

        try {
            const rect = g.selectAll("rect")
                .data(seriesData)
                .attr("class", seriesName)
                .attr("fill", series.Color)
                .attr("clip-path", "url(#" + clipPathId + ")")
                .style("opacity", .9)
                .attr("x", d => {
                    var value = d.date.valueOf();
                    var scaledValue = scales.timeScale(value);
                    return scaledValue;
                })
                .attr("y", d => 0.09*scale(0))
                .attr("height", d => 0.91*(scale(0) - scale(1)))
                .attr("width", '2px')
                .attr("visibility", visibility);

            rect.enter()
                .append("rect")
                .attr("class", seriesName)
                .attr("fill", series.Color)
                .attr("clip-path", "url(#" + clipPathId + ")")
                .style("opacity", .9)
                .attr("x", d => {
                    var value = d.date.valueOf();
                    var scaledValue = scales.timeScale(value);
                    return scaledValue;
                })
                .attr("y", data => 0.09*scale(0))
                .attr("height", data => 0.91*(scale(0) - scale(1)))
                .attr("width", '2px')
                .attr("visibility", visibility);

            rect.exit().remove();

            const path1 = g.selectAll('.path1')
                .data(seriesData)
                    .attr("class", 'path1')
                    .attr("clip-path", "url(#" + clipPathId + ")")
                    .attr('d', path1d)
                    .attr('transform', d => {
                        var value = d.date.valueOf();
                        var scaledValue = scales.timeScale(value);
                        scaledValue -= 11;
                        return 'translate(' + scaledValue + ',' + .05*(scale(0)) +')'
                    })
                    .attr('fill', series.Color)
                    .attr('opacity', 0.3)
                    .attr("visibility", visibility);

            path1.enter()
                .append('path')
                    .attr("class", 'path1')
                    .attr("clip-path", "url(#" + clipPathId + ")")
                    .attr('d', path1d)
                    .attr('transform', d => {
                        var value = d.date.valueOf();
                        var scaledValue = scales.timeScale(value);
                        scaledValue -= 11;
                        return 'translate(' + scaledValue + ',' + .05*(scale(0)) +')'
                    })
                    .attr('fill', series.Color)
                    .attr('opacity', 0.3)
                    .attr("visibility", visibility);

            path1.exit().remove();

            const iconPath = series.Path ? series.Path : defaultAlertPath;

            const path2 = g.selectAll(".path2")
                .data(seriesData)
                    .attr("class", "path2")
                    .attr("clip-path", "url(#" + clipPathId + ")")
                    .attr('d', iconPath)
                    .attr('fill', series.Color)
                    .attr('transform', d => {
                        var value = d.date.valueOf();
                        var scaledValue = scales.timeScale(value);
                        scaledValue -= 11;
                        return 'translate(' + scaledValue + ',' + .05*(scale(0)) +')'
                    })
                    .attr("visibility", visibility);

            path2.enter()
                .append('path')
                .attr("class", "path2")
                .attr("clip-path", "url(#" + clipPathId + ")")
                .attr('d', iconPath)
                .attr('fill', series.Color)
                .attr('transform', d => {
                    var value = d.date.valueOf();
                    var scaledValue = scales.timeScale(value);
                    scaledValue -= 11;
                    return 'translate(' + scaledValue + ',' + .05*(scale(0)) +')'
                })
                .attr("visibility", visibility);

            path2.exit().remove();
        }
        catch (e)
        {
            console.debug(e);
        }
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawAlertMarker);
   
        draw(series[dataProp]);
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(series[dataProp]), [scales]);

    return (
        <svg width={width} id={seriesName} />
    )
})
