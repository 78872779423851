import React from 'react';

const defaultTheme = {
    alert: null,
    bad: "#D53F13",
    extraData: null,
    good: "#2C5AC7",
    levelOfControlStatus: null,
    levelOfControlVariance: null,
    observed: {Observed: "#000", Light: "#ACA5F0", Dark: "#4C4A8C"},
    primary: {Primary: "#000", Light: "#879BFF", Dark: "#09439C"},
    rainfall: {Rainfall: "#2EB8AE"},
    secondary: {Secondary: "#000", Light: "#8DFCFF", Dark: "#0098A7"},
    simulated: {Simulated: "#000", Light: "#FFAC5D", Dark: "#A94E00"},
    depthTrend: {Increasing: "#D53F13", Decreasing: "#2C5AC7", NoTrend: "#9E9E9E"},
    threshold: "#C2C2C2",
    wetWeather: "#CBE7E5",
    variance: {
        Low: "#F3ACA2",
        MediumLow: "#E96ABD",
        MediumHigh: "#973490",
        High: "#5E1F88"
    },
    DepthWarning: {
        "AboveOverflow" : "#D53F13",
        "Within30" : "#FFC533",
        "Below70" : "#9E9E9E"
    },
}

export const ThemeContext = React.createContext({theme: defaultTheme});

class ThemeContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: defaultTheme
        };
    }

    updateTheme = (newTheme) => {
        this.setState({
            theme: newTheme
        });
    }

    getColor = (color) => {
        try {
            if(!color) return "#000";

            // regex test for hex colors
            let hexColorRegex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/;
            if(hexColorRegex.test(color)) return color;

            if (color.split('.').length == 1) return this.state.theme[color];  
            
            //In a complicated color string, we need to drill in layer by layer
            var splitColor = color.split('.');
            var jsonObjectColor = this.state.theme;
            for (let i = 0; i < splitColor.length; i++) {   
                jsonObjectColor = jsonObjectColor[splitColor[i]];
            }
            return jsonObjectColor;
        } catch (e) {
            console.debug(e);
        }
    }

    render () {
        return (
            <ThemeContext.Provider value={{
                theme: this.state.theme,
                updateTheme : this.updateTheme,
                getColor: this.getColor
            }}>
                {this.props.children}
            </ThemeContext.Provider>
        )
    }
}

export default ThemeContextProvider;