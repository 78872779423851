import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import TileTest from "./tileTest";
import TimeSeriesChart from "./charts/TimeSeriesChart";
import ObservationKpi from "./kpi/ObservationKpi";
import ComparisonKpi from "./kpi/ComparisonKpi";
import AlertKpi from "./kpi/AlertKpi";
import CardContainer from "./cardcontainer/CardContainer";
import TableCard from "./charts/TableCard"
import AlertTable from "./charts/AlertTable"
import BarChart from "./charts/BarChart";
import DashboardMap from "./map/DashboardMap";
import PieChart from "./charts/PieChart";
import { ThemeContext } from "../contexts/ThemeContext";
import { ModelContext } from "../contexts/ModelContext";
import { DateContext } from '../contexts/DateContext';
import { AlertContext } from "../contexts/AlertContext";
import { ErrorContext } from "../contexts/ErrorContext";
import { UserContext } from '../contexts/UserContext';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class OperationsDashboardFrame extends React.Component {

    static defaultProps = {
        className: "layout",
        cols: { lg: 4, sm: 4},
        breakpoints: {lg: 980, sm: 0},
    };

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            currentBreakpoint: "lg",
            layouts : {},
            rowHeight : 115
        };
        this.onAddItem = this.onAddItem.bind(this);
        this.onBreakpointChange = this.onBreakpointChange.bind(this);
        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    componentDidMount() {
        
    }

    createElement(element, model, themeContext, alert, routeContext, mapType) {
        let { theme, getColor } = themeContext;
        var componentMap = {
            'ObservationKpi': ObservationKpi,
            'ComparisonKpi' : ComparisonKpi,
            'AlertKpi': AlertKpi,
            'Map' : DashboardMap,
            'Bar' : BarChart,
            'TimeSeries' : TimeSeriesChart,
            'TileTest' : TileTest,
            'Table' : TableCard,
            'AlertTable': AlertTable,
            'Pie': PieChart
        }

        const myRef = React.createRef();
        const title = element.title;
        
        const cardProps = {
            'title': element.title,
            'isExpandable': element.isExpandable,
            'isDownloadable': element.isDownloadable,
            'api': element.api,
            'colors' : element.colors,
            'cardRef' : myRef,
            'id' : element.id,
            'extraData' : element.extraData ? element.extraData : [],
            'options' : element.options,
        };
        return (
            <div ref = {myRef} key={title} data-grid={element} key={element.id} style={{marginBottom: '10px'}}>
                <UserContext.Consumer>
                    {(user) => (
                    <DateContext.Consumer>
                        {(date) => (
                            <ErrorContext.Consumer>
                                {(error) => (
                                    <CardContainer card={componentMap[element.subType]} 
                                        cardProps={ {theme: theme, model: {...model}, history: this.props.history, userContext: user, routeContext: routeContext, dateContext: date, errorContext: error, alertContext: {...alert}, itemType: mapType, getColor: getColor, ...cardProps}} 
                                        cardRef = {myRef}  
                                        cardType={element.type} 
                                        cardSubType={element.subType} >
                                    </CardContainer>
                                )}
                            </ErrorContext.Consumer>
                        )}
                        </DateContext.Consumer>
                    )}
                </UserContext.Consumer>
            </div>
            
        );
    }
    
    onLayoutChange(layout, layouts) {
        this.setState({ layouts });
    }

    onAddItem(jsonItems) {
        //Create Temp array of items to be added
        var temp = [];

        jsonItems.forEach( item => {
            temp.push( {
                c: item,
                title: item.title,
                x: item.x,
                y: item.y, // puts it at the bottom
                w: item.width,
                h: item.height,
                id: item.id,
                isDraggable: item.isDraggable,
                subType: item.subType,
                type: item.type,
                isExpandable: item.isExpandable,
                isDownloadable: item.isDownloadable,
                api: item.api,
                colors: item.colors,
                extraData : item.extraData,
                options: item.options,
            })
        })
        
        this.setState({
            // Add a new item. It must have a unique key!
            items: this.state.items.concat(temp),
        }, () => window.dispatchEvent(new Event('resize')) )     
    }
    
    onBreakpointChange = breakpoint => {
        this.setState({
            currentBreakpoint: breakpoint
        });
    };

    componentDidUpdate(prevProps, prevState){
        let jsonObject = this.props.importLayout
        if (typeof(jsonObject) === "undefined" || jsonObject.length === 0 || Object.keys(jsonObject).length === 0 && jsonObject.constructor === Object){
            return;
        }

        //This selects the correct initial layout. 
        //Other layouts will still adjust if the screen size changes
        if (prevProps.importLayout !== this.props.importLayout)
        {
            this.setState({
                items: []
            }, () => {
                this.onAddItem(jsonObject);
            })
        }

        window.dispatchEvent(new Event('resize'));
        
    }

    render() {
        let {frameWidth, frameOffset, mapType} = this.props;
        return (
            <AlertContext.Consumer>
                {alert => (
                    <ModelContext.Consumer>
                        {model => (
                            <ThemeContext.Consumer>
                                {({theme, getColor, updateTheme}) => (
                                    <div style = {{height: "calc(100vh - 64px)",
                                        width: frameWidth,
                                        display: 'flex',
                                        top: "64px",
                                        left: frameOffset,
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        position: "relative"}}>
                                        <ResponsiveReactGridLayout 
                                            {...this.props}
                                            style={{height: '100%', width: frameWidth}}
                                            rowHeight = {this.state.rowHeight}
                                            layouts = {this.state.layouts}
                                            onBreakpointChange={this.onBreakpointChange}
                                            onLayoutChange={(layout, layouts) =>
                                                this.onLayoutChange(layout, layouts)
                                            }>
                                            {this.state.items.map(el => this.createElement(el, model, {theme, getColor}, alert, this.props.routeContext, mapType))}
                                        </ResponsiveReactGridLayout>
                                    </div>
                                )}
                            </ThemeContext.Consumer>
                        )}
                    </ModelContext.Consumer>
                )}
            </AlertContext.Consumer>
            
            
        );
    }
}

export default OperationsDashboardFrame;