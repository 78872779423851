import React, { useContext } from 'react';
import { DateContext } from '../contexts/DateContext';

const withQuery = (WrappedComponent) => {

    return (props) => {
        var { to } = props;
        const dateContext = useContext(DateContext);
        const timescale = dateContext.timescale ? dateContext.timescale : 'past7';
        const startDate = dateContext.startDate;
        const endDate = dateContext.endDate;
        const timescaleString = 'timescale=' + timescale;
        const startDateString = timescale == 'custom' && startDate ? '&startDate=' + startDate : '';
        const endDateString = timescale == 'custom' && endDate ? '&endDate=' + endDate : '';

        to = to.split('?')[0] + "?" + timescaleString + startDateString + endDateString;

        return <WrappedComponent {...props} to={to} />
    }
}

export default withQuery;