import React, { forwardRef, useImperativeHandle, useReducer} from 'react'
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import { Link, CardContent, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar, Portal, Chip } from '@material-ui/core';
import withLoading from "../../withLoading";
import _ from 'lodash';
import { addCSOOutfallItem, deleteCSOOutfallItem, getHeaders, getTenantId, updateCSOIncludedInReport, updateCSOOutfallItem, updateCSOOutfallPublishInfo, toggleCsoDischargeAutomatedPublishing } from '../../../api/ApiWorker';
import moment from 'moment-timezone';
import RsuiteTable from '../../flexTable/RsuiteTable';
import ApiHelper from '../../../api/ApiHelper';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';
import "rsuite/dist/rsuite.min.css";
import { Button } from 'rsuite';
import { ThemeContext } from '../../../contexts/ThemeContext';
import MuiAlert from '@material-ui/lab/Alert';

const LoadingDiv = withLoading('div');

const dateDisplayFormat = 'YYYY-MM-DDTHH:mm'

const styles = theme => ({
    title: {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    }
});

const RsuiteReportDataTable = forwardRef((props, ref) => {
    const {model, userContext, dateContext, title, classes, api, extraData, getColor, routeContext, selectedDashboard, selectedItem, siblingRefs} = props;
    
    const { loadCsoAutomatedPublishing } = userContext

    const tenant = userContext.getTenant();

    const overrideFields = {
        overflowVolumeMG: 'overrideValue',
        csoEventEndTimestamp: 'endTimeOverride',
        csoEventStartTimestamp: 'startTimeOverride',
    }


    // the model is not where you get the selected item on model-free dashboards
    if (_.isEmpty(selectedItem) && !_.isEmpty(model.selectedItem)) selectedItem = model.selectedItem;

    let [ loadWithoutSelectedItem ] = extraData;
    loadWithoutSelectedItem = loadWithoutSelectedItem?.value;

    const [isLoading, setIsLoading] = React.useState(false);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [tableDataRows, setTableDataRows] = React.useState(null);
    const [tableDataColumns, setTableDataColumns] = React.useState([]);
    const [tableDataError, setTableDataError] = React.useState(null);

    const itemBeingEdited = React.useRef(null)
    const rowBeingDeleted = React.useRef(null)
    
    const [checked, setChecked] = React.useState([])
    const [reportPreviewModalOpen, setReportPreviewModalOpen] = React.useState(false)
    const [confirmPublishModalOpen, setConfirmPublishModalOpen] = React.useState(false)
    const [modifyDataModalOpen, setModifyDataModalOpen] = React.useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
    const [savePublishedModalOpen, setSavePublishedModalOpen] = React.useState(false)
    const [htmlTable, setHtmlTable] = React.useState('')
    const [htmlTableLoading, setHtmlTableLoading] = React.useState(false)
    const [rowBeingChecked, setRowBeingChecked] = React.useState(null)

    const [alert, dispatchAlert] = useReducer((state, action) => {
        switch (action.type) {
          case 'alert':
            return {
              title: action.value.title,
              message: action.value.message,
              variant: action.value.variant,
              snackBarOpen: true,
            }
          case 'clear':
            return {
              title: '',
              message: '',
              variant: '',
              snackBarOpen: false,
            }
          case 'open':
            return {
              ...state,
              snackBarOpen: true
            }
          case 'close':
            return {
              ...state,
              snackBarOpen: false
            }
          default:
            return state;
        }
      }, {
        title: '',
        message: '',
        variant: '',
        snackBarOpen: false,
      });

    let getAssetNameFromRoute = false;
    let isReportChild = false
    let isReportParent = false
    let invalidationQueryKeys = []

    extraData.forEach((obj) => {
        if (obj.name === 'TableOptions') {
            obj.value.forEach((option) => {
                if (option.name === 'GetAssetNameFromRoute') getAssetNameFromRoute = option.value
                if (option.name === 'IsReportChild') { isReportChild = option.value; isReportParent = !option.value }
                if (option.name === 'IsReportParent') { isReportChild = !option.value; isReportParent = option.value }
                if (option.name === 'InvalidationQueryKeys') { invalidationQueryKeys = option.value }
            })
        }
    })

    const thisElement = React.useRef({});

    useImperativeHandle(ref, () => ({
        handleDownloadClick() {
            var csvContent = "data:text/csv;charset=utf-8,";
            var dataKeys = tableDataColumns.map(e => { return e.fieldName});
            csvContent += tableDataColumns.map(e => e.fieldLabel).join(",") + "\r\n";
                tableDataRows.forEach( (data) => {
                    dataKeys.forEach( (key) => {
                        if (key === null) return
                            let path = key.split('.')
                            let value = data[path[0]]
                            for (let i = 1; i < path.length; i++) {
                                value = value[path[i]]
                            }

                            csvContent += value + ","
                    });
                csvContent += "\r\n";
            });


    
            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            var filename = "table_" + props.title;
            filename += ".csv";
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
        },

        handlePreviewClick() {
            loadPreviewHtmlTable()
            setReportPreviewModalOpen(true)
        },

        handleAddRowClick() {
            itemBeingEdited.current = null
            setModifyDataModalOpen(true)
        },

        handleToggleCsoDischargeAutomatedPublishing(enabled) {
          let params = getUrlParams()
          toggleCsoDischargeAutomatedPublishing(params.tenantName, enabled)
            .then((res) => {
              if (res.status === 200) {
                //tenant.tenantName
                loadCsoAutomatedPublishing(params.tenantName)
              }
            })
            .catch((err) => {})
        },

        async getData() {
            try {
                setNoDataFound(false);
                setIsLoading(true);
                setTableDataRows([]);
                setTableDataColumns([]);
                setTableDataError(null);
    
                let key = Object.keys(api)[0];
                let tableDataObj = api[key];
    
                let urlPattern = tableDataObj.urlPattern;
    
                let url = new URL(urlPattern, ApiHelper.getUrlPath());
    
                const urlParams = getUrlParams();
    
                url = ApiHelper.fillFromObj(url, urlParams);
    
                thisElement.current.abortController && thisElement.current.abortController.abort();
                thisElement.current.abortController = new AbortController();
                
                const results = await fetch(url, {
                    method: 'get',
                    credentials: 'same-origin',
                    headers: new Headers(getHeaders()),
                    signal: thisElement.current.abortController.signal
                });
    
                if (results.ok) {
    
                    let data = await results.json();
    
                    let nextParentChecked = []
                    let nextChildChecked = []
    
                    //Format columns 
                    var fields = props.tableFields;
                    for (let i = 0; i < fields.length; i++) 
                    {
                        var field = fields[i];
                        field.name = field.fieldName;
                        field.header = field.fieldLabel;
                    }
    
                    //Format data
                    var dateColumns = fields.filter(x => !!x.isDate);
                    var numericColumns = fields.filter(x => !!x.numeric);
                    var textColumns = fields.filter(x => !dateColumns.includes(x) && !numericColumns.includes(x))
                    for (let i = 0; i < data.length; i++)
                    {
                        let d = data[i]
                        d['key'] = i
                        if (d['includedInOutfallReport'] === true) nextParentChecked.push(i)
                        if (d['includeInPublish'] === true) nextChildChecked.push(i)
                        dateColumns.forEach(x => {
                            d[x.name] = d[x.name] != null
                                ? moment.tz(d[x.name], sessionStorage.getItem(TENANT_TIMEZONE)).format(x.dateFormat)
                                : null;
                        });
    
                        numericColumns.forEach(x => {
                            d[x.name] = d[x.name] != null
                                ? Math.round(parseFloat(d[x.name]) * 100000) / 100000
                                : null;
                        });
    
                    }
    
                    setTableDataRows(data);
                    setTableDataColumns(fields);
    
                    setChecked(isReportParent ? nextParentChecked: nextChildChecked)
                    
                    setNoDataFound(data.length == 0);
                    setIsLoading(false);
                } else {
                    throw new Error(results.statusText);
                }
            } catch (e) {
                if (e.name !== 'AbortError') {
                    console.error(e);
                    setTableDataError('An error occurred while loading data.');
                    setIsLoading(false);
                } 
            } 
    
        }

    }));

    const getData = async () => {
        try {
            setNoDataFound(false);
            setIsLoading(true);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);

            let key = Object.keys(api)[0];
            let tableDataObj = api[key];

            let urlPattern = tableDataObj.urlPattern;

            let url = new URL(urlPattern, ApiHelper.getUrlPath());

            const urlParams = getUrlParams();

            url = ApiHelper.fillFromObj(url, urlParams);

            thisElement.current.abortController && thisElement.current.abortController.abort();
            thisElement.current.abortController = new AbortController();
            
            const results = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
                signal: thisElement.current.abortController.signal
            });

            if (results.ok) {

                let data = await results.json();

                let nextParentChecked = []
                let nextChildChecked = []

                //Format columns 
                var fields = props.tableFields;
                for (let i = 0; i < fields.length; i++) 
                {
                    var field = fields[i];
                    field.name = field.fieldName;
                    field.header = field.fieldLabel;
                }

                //Format data
                var dateColumns = fields.filter(x => !!x.isDate);
                var numericColumns = fields.filter(x => !!x.numeric);
                var textColumns = fields.filter(x => !dateColumns.includes(x) && !numericColumns.includes(x))
                for (let i = 0; i < data.length; i++)
                {
                    let d = data[i]
                    d['key'] = i
                    if (d['includedInOutfallReport'] === true) nextParentChecked.push(i)
                    if (d['includeInPublish'] === true) nextChildChecked.push(i)
                    dateColumns.forEach(x => {
                        d[x.name] = d[x.name] != null
                            ? moment.tz(d[x.name], sessionStorage.getItem(TENANT_TIMEZONE)).format(x.dateFormat)
                            : null;
                    });

                    numericColumns.forEach(x => {
                        d[x.name] = d[x.name] != null
                            ? Math.round(parseFloat(d[x.name]) * 100000) / 100000
                            : null;
                    });

                }

                setTableDataRows(data);
                setTableDataColumns(fields);

                setChecked(isReportParent ? nextParentChecked: nextChildChecked)
                
                setNoDataFound(data.length == 0);
                setIsLoading(false);
            } else {
                throw new Error(results.statusText);
            }
        } catch (e) {
            if (e.name !== 'AbortError') {
                console.error(e);
                setTableDataError('An error occurred while loading data.');
                setIsLoading(false);
            } 
        } 

    };

    const getAssetName = parameters => {
        try {
            if (getAssetNameFromRoute && routeContext.state.assetName) return routeContext.state.assetName;
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'name';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getAssetId = parameters => {
        try {
            let assetProperty = parameters.assetProperty || model?.selectedFeature?.idField;
            if (assetProperty && selectedItem?.properties && selectedItem?.properties[assetProperty])
            {
                const value = selectedItem?.properties[assetProperty];
                return encodeURIComponent(value);
            } else {
                assetProperty = parameters.assetProperty || 'id';
                const value = selectedItem[assetProperty];
                return encodeURIComponent(value);
            }
        } catch {
            return null;
        }
    }

    const getUrlParams = () => {
        let key = Object.keys(api)[0];
        let tableDataObj = api[key];

        const parameters = tableDataObj?.parameters || {};
        parameters.tenantId = getTenantId();
        const tenant = userContext.getTenant();
        parameters.tenantName = tenant.tenantName;

        parameters.assetName = getAssetName(parameters);
        parameters.assetId = getAssetId(parameters);

        parameters.layerName = model?.selectedFeature?.layerName;
        parameters.modelName = model?.selectedModel?.name;
        parameters.startDate = moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        parameters.endDate = moment.tz(dateContext.realEndDate, sessionStorage.getItem(TENANT_TIMEZONE)).format();

        return parameters;
    }

    
    const loadPreviewHtmlTable = async () => {
        
        setHtmlTableLoading(true)

        let urlParams = getUrlParams()
        let baseUrl = new URL(api.PrePublishOutfallDischargeReport.urlPattern, ApiHelper.getUrlPath())
        let params = {
            ...api.PrePublishOutfallDischargeReport.parameters,
            ...urlParams
        }
        let url = ApiHelper.fillFromObj(baseUrl, params)

        fetch(url, {
            method: 'get',
            credentials: 'same-origin',
            headers: new Headers(getHeaders()),
        })
            .then((res) => res.text())
            .then((tableString) => {
                console.log(tableString)
                setHtmlTable(tableString)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setHtmlTableLoading(false)
            })
    }

    const handleUpdateSubmit = (item) => {
        let params = getUrlParams()

        let update = {}

        Object.keys(item).forEach((key) => {
            let value = item[key]
            let column = tableDataColumns.find((col) => col.fieldName === key)
            if (column && column.isDate) {
                if (moment(value).isValid()) {
                    value = moment.tz(value, sessionStorage.getItem(TENANT_TIMEZONE)).format()
                } else {
                    value = ''
                }
            }
            update[key] = value
        })

        updateCSOOutfallItem(params.tenantName, params.modelName, update, item.id)
            .then(res => {
                if (res.ok) {
                    getData()
                } else {
                    dispatchAlert({
                        type: 'alert',
                        value: {
                            variant: 'error',
                            title: 'error',
                            message: 'something went wrong',
                        }
                    });
                }
            })
            .catch(err => {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'something went wrong',
                    }
                });
            })

        itemBeingEdited.current = null
        setModifyDataModalOpen(false)
    }

    const handleAddSubmit = (item) => {
        let params = getUrlParams()

        let itemAdded = {}

        Object.keys(item).forEach((key) => {
            let value = item[key]
            let column = tableDataColumns.find((col) => col.fieldName === key)
            if (column && column.isDate) {
                if (moment(value).isValid()) {
                    value = moment.tz(value, sessionStorage.getItem(TENANT_TIMEZONE)).format()
                } else {
                    value = ''
                }
            }
            itemAdded[key] = value
        })

        addCSOOutfallItem(params.tenantName, params.modelName, itemAdded)
            .then(res => {
                if (res.ok) {
                    getData()
                } else {
                    dispatchAlert({
                        type: 'alert',
                        value: {
                            variant: 'error',
                            title: 'error',
                            message: 'something went wrong',
                        }
                    });
                }
            })
            .catch(err => {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'something went wrong',
                    }
                });
            })

        setModifyDataModalOpen(false)
    }

    const handlePublishSubmit = (columns, rows) => {

        setIsLoading(true)
        let urlParams = getUrlParams()
        let baseUrl = new URL(api.PublishOutfallDischargeReport.urlPattern, ApiHelper.getUrlPath())
        let params = {
            ...api.PublishOutfallDischargeReport.parameters,
            ...urlParams
        }
        let url = ApiHelper.fillFromObj(baseUrl, params)

        fetch(url, {
          method: 'post',
          credentials: 'same-origin',
          headers: new Headers(getHeaders()),
        })
            .then(res => {
                setIsLoading(false)
                if (res.ok) getData()
            })
            .catch(err => {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'something went wrong',
                    }
                });
            })

    }

    const handleDeleteSubmit = (row) => {
        let params = getUrlParams()
        deleteCSOOutfallItem(params.tenantName, params.modelName, row.id)
            .then(res => {
                if (res.ok) {
                    getData()
                } else {
                    dispatchAlert({
                        type: 'alert',
                        value: {
                            variant: 'error',
                            title: 'error',
                            message: 'something went wrong',
                        }
                    });
                }
            })
            .catch(err => {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'something went wrong',
                    }
                });
            })
    }

    const handleParentCheckedSubmit = async (row) => {

        if (!checked) return 

        let params = getUrlParams()
        let updates = []
      
        tableDataRows.forEach((row) => {
            let included = checked.includes(row['key'])
            if (row['includedInOutfallReport'] !== included) {
                updates.push({
                    Included: included,
                    ObservedEventId: row['observedEventId'],
                    SimulatedEventIds: row['simulatedEventIds']
                })
            }
        })

        try {

            for (let i = 0; i < updates.length; i++) {
                let update = updates[i]
                let res = await updateCSOIncludedInReport(params.tenantName, params.modelName, update)
                if (res.ok) {
                    
                } else {
                    dispatchAlert({
                        type: 'alert',
                        value: {
                            variant: 'error',
                            title: 'error',
                            message: 'something went wrong updating an item',
                        }
                    });
                }
            }
        } catch (err) {
            dispatchAlert({
                type: 'alert',
                value: {
                    variant: 'error',
                    title: 'error',
                    message: 'something went wrong',
                }
            });
        } finally {

            invalidationQueryKeys.forEach((key) => {
                let siblingRef = siblingRefs.current[key]
                siblingRef && siblingRef.current && siblingRef.current.getData && siblingRef.current.getData()
            })
            getData()
        }
    }

    const handleChildCheckedSubmit = async (comment, item) => {

        if (!checked) return 

        let params = getUrlParams()
        let key = item['key']
        let isChecked = item['isChecked']

        let row = tableDataRows.find((item) => item.key === key)

        let toUpdate = {
            id: row['id'],
            publishComment: comment,
            includeInPublish: isChecked
        }
        await updateCSOOutfallPublishInfo(params.tenantName, params.modelName, toUpdate, row.id)
        .then((res) => {
            if (res.ok) {
                getData()
            } else {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'something went wrong',
                    }
                });
            }
        })
        .catch((err) => {
            dispatchAlert({
                type: 'alert',
                value: {
                    variant: 'error',
                    title: 'error',
                    message: 'something went wrong',
                }
            });
        })
        
    }

    const getCustomTable = () => {

        return (
            <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', right: '1.5rem', bottom: '1.5rem', width: "calc(100% - 4rem)", height: "calc(100% - 4rem)"}}>
                <AutoSizer>
                    
                    {({ width, height }) => (
                        <>
                            <RsuiteTable 
                                width={width}
                                height={height}
                                tableDataRows={tableDataRows || []}
                                tableDataColumns={tableDataColumns}
                                getColor={getColor}
                                extraData={extraData}
                                compact={true}
                                title={title}
                                checked={checked}
                                setChecked={setChecked}
                                isReportParent={isReportParent}
                                isReportChild={isReportChild}
                                overrideFields={overrideFields}
                                handleParentCheckedSaved={isReportParent ? handleParentCheckedSubmit : null}
                                handleChildCheck={isReportChild ? (key, isChecked) => { 
                                    if (!isChecked) {
                                        setRowBeingChecked({key, isChecked}); 
                                        setSavePublishedModalOpen(true)
                                    } else {
                                        handleChildCheckedSubmit('item added to report', {key, isChecked})
                                    }
                                } : null}
                                handleEditClick={(row) => { 
                                    itemBeingEdited.current = row; 
                                    setModifyDataModalOpen(true) 
                                }}
                                handleDeleteClick={(row) => {
                                    rowBeingDeleted.current = row
                                    setDeleteModalOpen(true)
                                }}
                            />
                                
                        </>
                               
                    )}  
                    
                </AutoSizer>
            </div>
        )
    }

    //Unmount
    React.useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
    }, []);

    React.useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
        if (!tableDataRows) return
        // make request to update table
    }, [checked]);

    React.useEffect( () => {
        thisElement.current.abortController && thisElement.current.abortController.abort();
        if (loadWithoutSelectedItem || !_.isEmpty(selectedItem)) {
            getData();
        } else if (!loadWithoutSelectedItem && _.isEmpty(selectedItem)) {
            setIsLoading(false);
            setTableDataRows([]);
            setTableDataColumns([]);
            setTableDataError(null);
        }
    }, [selectedItem, dateContext.startDate, dateContext.endDate, model.selectedModel])

    const Content = (!_.isEmpty(tableDataError)) || noDataFound
    ?
        <Typography className={classes.text} style={{ paddingLeft: '8px'}}>
            {!_.isEmpty(tableDataError) ? tableDataError : "No Data Found"}
            <Link style={{ paddingLeft: '8px', cursor: 'pointer'}} onClick={() => getData()}>
                Try again?
            </Link>
        </Typography>
    : !_.isEmpty(tableDataRows) && !_.isEmpty(tableDataColumns)
        ? 
        getCustomTable()
        : 
            isLoading
            ? <> </>
            : <Typography className={classes.text}> Select an item to view data</Typography>;

    return (
        <>
            <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={isLoading}>
                <CardContent>
                    <Typography variant='h6' className={classes.title}>
                        <span>{title}</span>
                    </Typography>
                    {Content}
                </CardContent>
            </LoadingDiv>
            <ModifyDataModal open={modifyDataModalOpen} dispatchAlert={dispatchAlert} handleUpdateSubmit={handleUpdateSubmit} handleAddSubmit={handleAddSubmit} onCancel={() => { itemBeingEdited.current = null; setModifyDataModalOpen(false) }} item={itemBeingEdited.current} tableDataColumns={tableDataColumns} overrideFields={overrideFields} />
            <DeleteModal open={deleteModalOpen} row={rowBeingDeleted.current} onConfirm={handleDeleteSubmit} onCancel={() => setDeleteModalOpen(false)}/>
            <CustomAlert alertVariant={alert.variant} 
                alertMessage={alert.message} 
                alertTitle={alert.title} 
                close={(value) => dispatchAlert({type: 'close'})} 
                open={alert.snackBarOpen} 
            />   
            <SavePublishedModal open={savePublishedModalOpen} rowBeingChecked={rowBeingChecked} onConfirm={handleChildCheckedSubmit} onCancel={() => {setSavePublishedModalOpen(false)}} dispatchAlert={dispatchAlert} />   
            <PublishPreviewModal open={reportPreviewModalOpen} htmlTable={htmlTable} htmlTableLoading={htmlTableLoading} onConfirm={() => { setConfirmPublishModalOpen(true) }} onCancel={() => setReportPreviewModalOpen(false)}/>
            <ConfirmPublishModal open={confirmPublishModalOpen} onConfirm={() => { handlePublishSubmit(); setReportPreviewModalOpen(false); setConfirmPublishModalOpen(false)}} onCancel={() => { setConfirmPublishModalOpen(false) }} />
        </>
    )
})

export default withStyles(styles)(RsuiteReportDataTable);

const CustomAlert = (props) => {

    const {open, alertVariant, close, alertMessage, alertTitle} = props
    return (
        <Portal >
            <Snackbar
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal : 'left' }}
                open={open}
                onClose={() => close()}
                title={alertTitle}
                style={{position: 'absolute'}}
            >
                <MuiAlert severity={alertVariant} sx={{ width: '200px'}} >{alertMessage}</MuiAlert>
            </Snackbar>
        </Portal>
    )
}

const ConfirmPublishModal = (props) => {

    const themeContext = React.useContext(ThemeContext)

    const { onConfirm, onCancel, open} = props

    const handleConfirm = (item) => {
        onConfirm()
    }

    const handleCancel = (props) => {
        onCancel()
    }

    return (
        <Dialog
            open={open}
            maxWidth='md'
        >
            <DialogTitle>
                {'Confirm'}
            </DialogTitle>

            <DialogContent style={{display: 'flex', width: '100%', height: '100%', padding: '1vmax 3vmax'}}>
                Are you sure you would like to publish?
            </DialogContent>

            <DialogActions style={{padding: '1vmax', display: 'flex', gap: '10px'}}>
                <Button onClick={handleConfirm} variant='primary' style={{backgroundColor: themeContext.getColor("primary.Primary"), color: '#EEEEEE'}}> Confirm </Button>
                <Button onClick={handleCancel}> Cancel </Button>
            </DialogActions>

        </Dialog>
    )
}

const PublishPreviewModal = (props) => {

    const themeContext = React.useContext(ThemeContext)

    const { htmlTable, htmlTableLoading, onConfirm, onCancel, open} = props

    const handleConfirm = (item) => {
        onConfirm()
    }

    const handleCancel = (props) => {
        onCancel()
    }

    return (
        <Dialog
            open={open}
            maxWidth='md'
        >
            <DialogTitle>
                {'Outfall Discharge Table Preview'}
            </DialogTitle>

            <span style={{display: 'flex', gap: '8px', padding: '0vmax 3vmax 1vmax 3vmax'}}>
                <Chip label='New' style={{backgroundColor: '#11dd11'}}></Chip>
                <Chip label='Updated' style={{backgroundColor: '#dddd11'}}></Chip>
            </span>
            

            <DialogContent style={{display: 'flex', width: '100%', flexDirection: 'column', height: '100%', padding: '0vmax 3vmax 1vmax 3vmax', gap: '1vmax'}}>
                <LoadingDiv style={{display: 'flex', height: '100%', width: '100%', minWidth: '400px', minHeight: '400px'}} isLoading={htmlTableLoading}>
                    <div dangerouslySetInnerHTML={{__html: htmlTable}}/>
                </LoadingDiv>
            </DialogContent>

            <DialogActions style={{padding: '1vmax', display: 'flex', gap: '10px'}}>
                <Button onClick={handleConfirm} variant='primary' style={{backgroundColor: themeContext.getColor("primary.Primary"), color: '#EEEEEE'}}> Publish </Button>
                <Button onClick={handleCancel}> Cancel </Button>
            </DialogActions>

        </Dialog>
    )
}

const DeleteModal = (props) => {

    const themeContext = React.useContext(ThemeContext)

    const {row, onConfirm, onCancel, open} = props

    const handleConfirm = () => { 
        onConfirm(row);
        onCancel();
    }

    const handleCancel = () => {
        onCancel()
    }

    return (
        <Dialog
            open={open}
        >

            <DialogTitle>
                {'Delete'}
            </DialogTitle>

            <DialogContent style={{display: 'flex', width: '100%', height: '100%', padding: '1vmax 3vmax'}}>
                Are you sure you want to delete this row?
            </DialogContent>

            <DialogActions style={{padding: '1vmax', display: 'flex', gap: '10px'}}>
                <Button onClick={handleConfirm} variant='primary' style={{backgroundColor: themeContext.getColor("primary.Primary"), color: '#EEEEEE'}}> Confirm </Button>
                <Button onClick={handleCancel}> Cancel </Button>
            </DialogActions>

        </Dialog>
    )
}


const SavePublishedModal = (props) => {

    const themeContext = React.useContext(ThemeContext)
  
    const {onConfirm, onCancel, open, dispatchAlert, rowBeingChecked} = props
  
    const [comment, setComment] = React.useState('')

    const handleConfirm = () => {
        if (comment.trim().length === 0) {
            dispatchAlert({
                type: 'alert',
                value: {
                    variant: 'error',
                    title: 'error',
                    message: 'publish comment is required',
                }
            });
            return
        }
        onConfirm(comment, rowBeingChecked)
        onCancel()
    }

    const handleCancel = () => {
        onCancel()
    }
  
    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
        >
  
            <DialogTitle>
                {'Make a comment'}
            </DialogTitle>

            <DialogContent style={{display: 'flex', width: '100%', height: '100%', padding: '1vmax 3vmax'}}>
                <TextField
                    style={{flexGrow: 1}}
                    size='small'
                    label={'Comment'}
                    value={comment}
                    onChange={(e) => {
                        let nextComment = comment
                        nextComment = e.target.value
                        setComment(nextComment)
                    }}
                    multiline
                    minRows={10}
                    maxRows={10}
                />
            </DialogContent>
  
            <DialogActions style={{padding: '1vmax', display: 'flex', gap: '10px'}}>
                <Button onClick={handleConfirm} variant='primary' style={{backgroundColor: themeContext.getColor("primary.Primary"), color: '#EEEEEE'}}> Confirm </Button>
                <Button onClick={handleCancel}> Cancel </Button>
            </DialogActions>
  
        </Dialog>
    )
  }

const ModifyDataModal = (props) => {

    const themeContext = React.useContext(ThemeContext)

    const {tableDataColumns, item, handleUpdateSubmit, handleAddSubmit, onCancel, open, dispatchAlert} = props

    const [overrideCommentUpdated, setOverrideCommentUpdated] = React.useState(false)
    const [modifiedItem, setModifiedItem] = React.useState({})
    const [newItem, setNewItem] = React.useState({})
    let type = item ? 'edit' : 'add'

    React.useEffect(() => {

        if (item) setModifiedItem(item)

    }, [item])

    React.useEffect(() => {

        if (!tableDataColumns) return
        let nextNewItem = {...newItem}

        tableDataColumns.forEach((col) => {
            nextNewItem[col.fieldName] = nextNewItem[col.fieldName] || ''
        })

        setNewItem(nextNewItem)

    }, [tableDataColumns])

    const handleConfirm = () => {
        
        if (type === 'edit') {
            if (overrideCommentUpdated === false) {
                dispatchAlert({
                    type: 'alert',
                    value: {
                        variant: 'error',
                        title: 'error',
                        message: 'a new override comment is required',
                    }
                });
                return
            } else {
                handleUpdateSubmit(modifiedItem)
                handleCancel()
            }
        } else {
            handleAddSubmit(newItem)
            handleCancel()
        }
    }

    const handleCancel = (props) => {
        onCancel()
        setNewItem({})
        setModifiedItem({})
        setOverrideCommentUpdated(false)
    }

    let commentColumns = []

    tableDataColumns.map((col) => {
        if (col.fieldName && col.fieldName.toLowerCase().includes('comment')) {
            commentColumns.push(col)
        }
    })

    return (
        <Dialog
            open={open}
            maxWidth='md'
            fullWidth
        >

            <DialogTitle>
                {item ? 'Edit Item' : 'Add Item'}
            </DialogTitle>

            <DialogContent style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', padding: '1vmax 3vmax', gap: '1vmax'}}>

                <span style={{display: 'flex', gap: '1vmax', flexWrap: 'wrap', flexDirection: 'column'}}>
                    
                    { tableDataColumns.map((col) => {

                        if (col.fieldName && (col.fieldName.toLowerCase().includes('comment'))) return null
                        if (type === 'add' && (col.fieldName.toLowerCase().includes('overid') || col.fieldName.toLowerCase().includes('overrid'))) return null
                        

                        let value
                        let disabled = false    

                        if (type === 'add') {
                            value = newItem[col.fieldName]
                            if (col.fieldName === 'reportingMethod') {
                                value = 'Manual'
                                newItem['reportingMethod'] = value
                                disabled = true
                            }
                        } else {
                            value = modifiedItem[col.fieldName]
                            disabled = true
                            col.extraData && col.extraData.forEach(obj => {
                                if (obj.name === 'Editable' && obj.value === true) disabled = false
                            })
                        }

                        if (col.isDate && value) {
                          value = moment.tz(new Date(value), sessionStorage.getItem(TENANT_TIMEZONE)).format(dateDisplayFormat);
                        }

                        

                        return (

                            <span style={{display: 'flex', flexDirection: 'column'}} key={col.fieldName}>

                                
                                { col.isDate === false && <TextField
                                    label={col.fieldLabel}
                                    fullWidth
                                    value={value}
                                    onChange={(e) => {
                                        let nextItem = type === 'edit' ? {...modifiedItem} : {...newItem}
                                        let value = e.target.value
                                        nextItem[col.fieldName] = value
                                        if (type === 'edit') {
                                            setModifiedItem(nextItem)
                                        } else {
                                            setNewItem(nextItem)
                                        }
                                    }}
                                    disabled={disabled}
                                /> }

                                { col.isDate === true && <TextField
                                    value={value}
                                    label={col.fieldLabel}
                                    type="datetime-local"
                                    disabled={disabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => {
                                        let nextItem = type === 'edit' ? {...modifiedItem} : {...newItem}
                                        let value = moment.tz(e.target.value, sessionStorage.getItem(TENANT_TIMEZONE)).format()
                                        nextItem[col.fieldName] = value
                                        if (type === 'edit') {
                                            setModifiedItem(nextItem)
                                        } else {
                                            setNewItem(nextItem)
                                        }
                                    }}
                                />}

                            </span>
                        )
                    }) }

                    { type === 'edit' && 

                        <span style={{display: 'flex', width: '100%', gap: '1vmax'}}>
                            { commentColumns.map((col) => {

                                if (col.fieldName && col.fieldName.toLowerCase().includes('publish')) return null
                                return (
                                    <TextField
                                        key={col.fieldName}
                                        style={{flexGrow: 1}}
                                        size='small'
                                        label={col.fieldLabel}
                                        value={modifiedItem[col.fieldName]}
                                        onChange={(e) => {
                                            setOverrideCommentUpdated(true)
                                            let nextModifiedItem = {...modifiedItem}
                                            nextModifiedItem[col.fieldName] = e.target.value
                                            setModifiedItem(nextModifiedItem)
                                        }}
                                        multiline
                                        minRows={10}
                                        maxRows={10}
                                        disabled={!col.fieldName || (col.fieldName && !col.fieldName.toLowerCase().includes('override'))}
                                    />
                                )
                            })}
                        </span>
                    }

                </span>

                

            </DialogContent>

            <DialogActions style={{padding: '1vmax', display: 'flex', gap: '10px'}}>
                <Button onClick={handleConfirm} variant='primary' style={{backgroundColor: themeContext.getColor("primary.Primary"), color: '#EEEEEE'}}> Confirm </Button>
                <Button onClick={handleCancel}> Cancel </Button>
            </DialogActions>

        </Dialog>
    )
}

