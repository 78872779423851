import React, {useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import simplify from '../../../../util/simplify-date';
import { 
    getBrushedBounds,
    createTimeScale,
    createValueScale
} from '../../../charts/chartComponents/util';

import {
    ClipPath,
    YGridLines,
    XAxisGroup,
    YAxisGroup,
    YAxisLabel,
    LineSeries,
    AreaSeries,
    SingleSeriesBar,
    CurveStep,
    AlertMarker,
    Tooltip
} from '../../../charts/chartComponents';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../../constants/TimezoneConstants';

export default forwardRef((props, primaryChartRef) => {
    const tooltipRef = useRef(null);

    const clipPathId = 'clip' + Math.round((Math.random() * 1000000));

    let hiddenSeriesList = [];

    let { height, width, data, margin, dateContext, extraData, brushBarStartDate } = props;
    
    const customTimeFormatObj = extraData.find(e => e.name == "MomentTimeFormat");
    const customTimeFormat = customTimeFormatObj ? customTimeFormatObj.value : null;

    const customTicksObj = extraData.find(e => e.name == "CustomTicks");
    const customTicks = customTicksObj ? customTicksObj.value.map(t => new Date(Date.parse(t))) : null;

    const dataDrivenTimeScaleObj = extraData.find(e => e.name == "DataDrivenTimeScale");
    const dataDrivenTimeScale = dataDrivenTimeScaleObj ? dataDrivenTimeScaleObj.value : false;


    const innerWidth = width - margin.left - margin.right;

    const leftAxisLabel = (extraData.find(a => a.name == "LeftAxisLabel") || {}).value;
    const rightAxisLabel = (extraData.find(a => a.name == "RightAxisLabel") || {}).value;

    // left y axis label placement
    const yOffset = 75;
    const xOffset = -(height - margin.top - margin.bottom ) / 2;

    let startDate = moment.tz(brushBarStartDate, sessionStorage.getItem(TENANT_TIMEZONE));
    let endDate = moment.tz(dateContext.endDate, sessionStorage.getItem(TENANT_TIMEZONE));

    var customTimeScale = extraData.find(e => e.name == "CustomTimeScale");
    if(customTimeScale) {
        startDate = customTimeScale.value[0] == "today" ? moment.tz(new Date(), sessionStorage.getItem(TENANT_TIMEZONE)) : moment.tz(customTimeScale.value[0], sessionStorage.getItem(TENANT_TIMEZONE));
        endDate = customTimeScale.value[1] == "today" ? moment.tz(new Date(), sessionStorage.getItem(TENANT_TIMEZONE)) : moment.tz(customTimeScale.value[1], sessionStorage.getItem(TENANT_TIMEZONE));
    } else if (dataDrivenTimeScale) {
        if(data.find(d => d.Data)) {
            for(var series of data.filter(d => d.Data)) {
                try {
                    if(!startDate || startDate > series.Data[0].date) {
                        startDate = moment.tz(series.Data[0].date, sessionStorage.getItem(TENANT_TIMEZONE))
                    }
                } catch(ex) {
                    var a = ex;
                    debugger;
                    // noop
                }
            }
        }
    }
    // new Date(props.dateContext.realEndDate.split("-").join("/"));
    // endDate.setTime(startDate.getTime() + 1000 * 60 * 60 * 24);
    let [minLeft, maxLeft, minRight, maxRight ] = getBrushedBounds(data, startDate, endDate, 'Data');
    const leftBuffer = (maxLeft - minLeft) * 0.1;
    const rightBuffer = (maxRight - minRight) * 0.1;
    let scales = getScales();

    setFormattedData(false);

    function getScales() {
        const timeScale = createTimeScale(startDate, endDate, margin, width);
        const leftScale = createValueScale(minLeft - leftBuffer, maxLeft + leftBuffer, margin, height);
        const rightScale = createValueScale(minRight - rightBuffer, maxRight + rightBuffer, margin, height);
        const boolScale = createValueScale( 0, 1, margin, height);

        return {
            timeScale,
            leftScale,
            rightScale,
            boolScale,
            minLeft: leftScale(minLeft),
            minRight: rightScale(minRight),
            minBool: boolScale(0)
        };
    }

    const generateTooltipInfo = (chartObj, x, mouseLocation) => {
        const style = `color: ${chartObj.Color}`;
        try {
            var data = chartObj.Data;
            var formattedValue;
            if (chartObj.ChartType === 'alert') {
                const allowance = 0.0015 * width;
                const x0 = scales.timeScale.invert(mouseLocation[0] - allowance);  
                const x1 = scales.timeScale.invert(mouseLocation[0] + allowance);  
                var chartObjData = data.filter(a => a.date <= x1 && a.date >= x0);
                if (chartObjData.length === 0) {
                    formattedValue = '-'
                } else {
                    formattedValue = 'Yes'
                }
            } else {
                if (chartObj.ChartType === 'bar') {
                    x.setSeconds(0);
                    x.setMilliseconds(0);
                } else {
                    x.setSeconds(1);
                }

                const bisectDate = d3.bisector(function (d) { return d.date; }).left;
                let i = bisectDate(data, x);
                
                if (chartObj.ChartType === 'line' || chartObj.ChartType == 'area') {
                    i--;
                }

                const d = data[i];
                
                formattedValue = chartObj.Axis != 'none' ? d.value.toFixed(4) : d.value !== 0 ? 'Yes' : 'No';
            }

            return `<li><span style="${style}">${chartObj.Name}: ${formattedValue}</span></li>`
        } catch (err) {
            return `<li><span style="${style}">${chartObj.Name}: - </span></li>`
        }
    }

    function getTooltipData(svgRef) {
        let mouseLocation = d3.mouse(svgRef);
        let x0 = scales.timeScale.invert(mouseLocation[0]);   

        const formattedValue = customTimeFormat ? moment.tz(x0, sessionStorage.getItem(TENANT_TIMEZONE)).format(customTimeFormat) : moment.tz(x0, sessionStorage.getItem(TENANT_TIMEZONE)).format();
        let html = `<span>${formattedValue}</span><ul style="list-style-type:none;padding:2px; margin:2px">`;
        data.filter(d => hiddenSeriesList.indexOf(d.Name) == -1).forEach(chartObj => {
            html = html + generateTooltipInfo(chartObj, x0, mouseLocation)
        });
        html = html + "</ul>";

        const y1 = scales.boolScale(1);
        const y2 = scales.boolScale(0);
        const xValue = scales.timeScale(x0);

        return { html, xValue, y1, y2 };
    }

    function setSimpleData() {
        setFormattedData(true);
        redraw();
    }

    function setFullData(providedHiddenSeriesList) {
        if(providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }
        setFormattedData(false);
        redraw();
    }

    function setFilterDates(startDate, endDate) {
        setFormattedData(false, startDate, endDate);
        redraw();
    }

    function setFormattedData(brushing, startDate = null, endDate = null) {
        if (data) {
            data.map(async series => {
                if(series.ChartType == 'line' || series.ChartType == 'curveStep' || series.ChartType == 'area' || series.ChartType == 'dashedLine') {
                    if (brushing) {
                        series.formattedData = series.brushData;
                    }
                    else {
                        series.formattedData = series.Data;
                    }
                } else {
                    //Non-Linear values
                    series.formattedData = series._formattedData;
                }
            });
        }
    }

    function redrawChildren() {
        const { redrawFns } = primaryChartRef.current;
        redrawFns && redrawFns.forEach(fn => {
            fn(scales, hiddenSeriesList);
        });
    }

    // called by either the parent component or brush bar component
    // to update scales/bounds and redraw the appropriate child components
    // these changes are handled through d3 to avoid awkward react redraws
    function redraw(providedHiddenSeriesList, brushStartDate, brushEndDate) {
        if(providedHiddenSeriesList) {
            hiddenSeriesList = providedHiddenSeriesList;
        }

        if(brushStartDate && brushEndDate) {
            startDate = brushStartDate;
            endDate = brushEndDate;
        }

        [minLeft, maxLeft, minRight, maxRight] = getBrushedBounds(data, startDate, endDate, 'Data');

        scales = getScales();

        redrawChildren();   
    }

    function init() {
        primaryChartRef.current.setSimpleData = setSimpleData;
        primaryChartRef.current.setFullData = setFullData;
        primaryChartRef.current.redraw = redraw;
        primaryChartRef.current.setFilterDates = setFilterDates;
    }

    useEffect(init, [primaryChartRef])   

    const setRef = node => {        
        if(node) {
            primaryChartRef.current = node;
            primaryChartRef.current.redrawFns = [];
        }
    }

    const seriesList = data ? data.sort((a, b) => {
        if(a.Order > b.Order) return 1;
        if(a.Order < b.Order) return -1;
        return 0;
    }).map(s => {
        const key = Math.random();
        if(s.ChartType == 'bar') {
            return (<SingleSeriesBar key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></SingleSeriesBar>);
        } else if (s.ChartType == 'line' || s.ChartType == 'dashedLine') {
            return (<LineSeries isDashed={s.ChartType == 'dashedLine'} key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></LineSeries>);
        } else if (s.ChartType == 'curveStep') { 
            return (<CurveStep key={key} clipPathId={clipPathId} scales={scales} key={s.Name} {...props} ref={primaryChartRef} series={s} dataProp="formattedData"></CurveStep>);
        } else if (s.ChartType == 'alert') {
            return (<AlertMarker key={key} clipPathId={clipPathId} innerWidth={innerWidth} scales={scales} key={s.Name} ref={primaryChartRef} series={s} {...props}></AlertMarker>);
        } else if (s.ChartType == 'area') {
            return (<AreaSeries key={key} clipPathId={clipPathId} innerWidth={innerWidth} areaHeight={props.height} scales={scales} key={s.Name} ref={primaryChartRef} series={s} dataProp="formattedData" {...props} ></AreaSeries>);
        } else {
            return (<></>);
        }
    }) : (<></>);

    return (
        <>                          
            <div ref={tooltipRef} className="tooltip" style={{position: "absolute"}} />  
            <svg width={props.width} height={props.height} >
                <g ref={setRef}  width={props.width - margin.left} height={props.height - margin.top} transform={`translate(${margin.left}, ${margin.top})`} >
                    <ClipPath clipPathId={clipPathId} {...props}></ClipPath>
                    <YGridLines scales={scales} {...props} ref={primaryChartRef} scaleName={'leftScale'}></YGridLines>
                    <XAxisGroup useBrowserTz={true} scales={scales} {...props} ref={primaryChartRef} scaleName={"timeScale"} customTimeFormat={customTimeFormat} customTicks={customTicks}></XAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"leftScale"} handSide={"left"}></YAxisGroup>
                    <YAxisGroup scales={scales} {...props} ref={primaryChartRef} scaleName={"rightScale"} handSide={"right"} transform={`translate(${innerWidth},0)`}></YAxisGroup>
                    <YAxisLabel {...props} ref={primaryChartRef} y={-yOffset} x={xOffset} label={leftAxisLabel} handSide={"left"}></YAxisLabel>
                    <YAxisLabel {...props} ref={primaryChartRef} y={innerWidth + yOffset} x={xOffset} label={rightAxisLabel} handSide={"right"}></YAxisLabel>
                    {seriesList}
                    <Tooltip data={seriesList} clipPathId={clipPathId} scales={scales} yMin={scales.boolScale(0)} yMax={scales.boolScale(1)} yOffset={yOffset} {...props} ref={primaryChartRef} tooltipRef={tooltipRef} getTooltipData={getTooltipData}></Tooltip>
                </g>
            </svg>
        </>
    )
})
