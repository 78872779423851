import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SortedIcon from '@material-ui/icons/TrendingFlat';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    tableContainer: {
        display: "flex",
        flexDirection: "column",
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    dataContainer: {
        overflowY: 'auto',
        overflowX: 'visible',
        padding: '0 0 0 1.5rem'
    },
    headerRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "2px solid rgba(0, 0, 0, .12)",
        padding: '0 17px 0 1.5rem'
    },    
    dataRow: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid rgba(0, 0, 0, .12)"
    },
    headerCell: {
        color: 'rgba(0, 0, 0, 0.6)', 
        height: "31px",
        padding: '8px 12px',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    dataCell: {
        height: "31px",
        padding: '8px 12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
});

class FlexTable extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            sortKey: null,
            sortDirection: 1
        };
    }

    sortDefault(sortKey, sortDirection) {
        return this.state.data.sort((a, b) => {
            if(a[sortKey] > b[sortKey]) return 1 * sortDirection;
            if(a[sortKey] < b[sortKey]) return -1 * sortDirection;
            return 0;
        });
    }

    sortDate(sortKey, sortDirection) {
        return this.state.data.sort((a, b) => {
            const aValue = new Date(a[sortKey]).getTime();
            const bValue = new Date(b[sortKey]).getTime();

            if(aValue > bValue) return 1 * sortDirection;
            if(aValue < bValue) return -1 * sortDirection;
            return 0;
        });
    }

    sort(col) {
        const sortKey = col.dataKey;
        let sortDirection = this.state.sortDirection;

        if(sortKey == this.state.sortKey) {
            sortDirection = sortDirection * -1;
        } else {
            sortDirection = 1;
        }

        const data = col.dataType == 'date' ? 
            this.sortDate(sortKey, sortDirection) : 
            this.sortDefault(sortKey, sortDirection);

        this.setState({
            sortKey,
            sortDirection,
            data
        });
    }
    
    getSortIcon(icon) {
        if(icon == 'asc') return (<SortedIcon style={{ marginTop: '1px', height: '18px', width: '18px', float: 'left', transform: 'rotate(-90deg)'}} />);
        return (<SortedIcon style={{ marginTop: '1px', height: '18px', width: '18px', float: 'left', transform: 'rotate(90deg)'}} />);
    }

    getHeaders() {
        const { columns, classes, setSelectedItem } = this.props;
        const { sortKey, sortDirection } = this.state;
        const sort = this.sort.bind(this);
        const getSortIcon = this.getSortIcon.bind(this);
        
        return [...columns.map(col => (
            <div key={Math.random()} onClick={() => sort(col)} 
                style={{ width: (1 / columns.length) * 100 - (1 / columns.length) * 10 + '%', minWidth: col.width }} 
                className={classes.headerCell}>
                    <div>
                        <Typography variant="body1" ref="header" style={{ float: 'left', fontSize: '14px' }}>
                            <span>
                                {col.header}
                            </span>
                        </Typography>
                        <span>{ sortKey == col.dataKey && getSortIcon(sortDirection > 0 ? 'asc' : 'desc')}</span>
                    </div>
            </div>
        )), (setSelectedItem &&
            <div key={Math.random()} className={classes.headerCell} style={{  width: '10%' }}>
            </div>
        )];
    }

    getColumns(rowData) {
        const { columns, classes } = this.props;
        return columns.map(col => (
            <span key={Math.random()} style={{ width: (1 / columns.length) * 100 - (1 / columns.length) * 10 + '%', minWidth: col.width}} className={classes.dataCell}>
                {rowData[col.dataKey]} {col.units ? col.units : ''}
            </span>
        ));
    }

    getRows() {
        const { classes, setSelectedItem } = this.props;
        const { data } = this.state;
        
        return data.map(row => {
            const isSelected = row == this.props.selectedItem;
            return (
                <div key={Math.random()} className={classes.dataRow} style={{ backgroundColor: isSelected ? 'rgba(0, 0, 0, .12)' : 'inherit', width: 'calc(' + this.props.width + '- 1.5rem' }}>
                    {this.getColumns(row)}
                    {
                        setSelectedItem &&
                        <div className={classes.dataCell} style={{  width: '10%' }}>
                        <IconButton onClick={() => setSelectedItem(row)} style={{ color: '#3f51b5', alignSelf: 'flex-end' }}>
                            <Icon>insert_chart_outlined</Icon>
                        </IconButton>
                    </div>
                    }
                </div>
            );
        });
    }

    componentDidMount() {
        this.props.data && this.setState({ sortKey: null, data: this.props.data });
    }

    componentDidUpdate() {
        this.props.data && this.props.data != this.state.data && this.setState({ sortKey: null, data: this.props.data });
    }

    render() {
        const { classes } = this.props;
        let minWidth = 0;

        for(const col of this.props.columns) {
            // add col with plus horiz padding
            minWidth += col.width + 24;
        }

        // add icon col width
        minWidth += 48;

        return ( 
            <div className={classes.tableContainer} style={{ height: this.props.height, width: this.props.width }}>
                <div className={classes.headerRow} style={{ width: 'calc(' + this.props.width + '- 1.5rem - 17px', minWidth: minWidth }}>
                    {this.getHeaders()}
                </div>
                <div className={classes.dataContainer} style={{ width: 'calc(' + this.props.width + '- 1.5rem', minWidth: minWidth + 37  }}>
                    {this.getRows()}
                </div>
            </div>
        );
    }
}

const FlexTableWithStyles = withStyles(styles)(FlexTable);
export default FlexTableWithStyles;