import React, { forwardRef } from 'react';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let {x, y, label} = props;
   
    //Format label into <tspan> elements
    function createLabel(label) {
        return <tspan x={x} y={y} dy={'1em'}> {label} </tspan>;
    }

    return (
        <text
            style={{ textAnchor: "middle", fontFamily: "Roboto", fontSize: "1rem", opacity: "0.6" }}>
            {createLabel(label)}
        </text>
    )
})
