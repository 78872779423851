import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import { 
    getDataHelper,
    formatData 
} from './dataUtil';
import { ReactComponent as EmptyChart } from "../../../../assets/images/emptyChart.svg";
import MainChart from './MainChart';
import ChartLegend from '../../../charts/chartComponents/ChartLegend';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const styles = () => ({
    chartContainer : {
        height: '100%',
        width: '100%',
        position: "absolute"
    }, 
    title : {
        position: "absolute",
        top: ".5rem",
        left: "1.5rem",
        fontSize: "1.5rem",
        fontFamily: "Roboto",
    },
    subtitle : {
        position: 'absolute',
        top: '2.2rem',
        left: '1.7rem',
        fontSize: '1.2rem',
        fontFamily: 'Roboto',
    },
    highlightedAssetName : {
        position: "absolute",
        top: "2.5rem",
        left: "4rem",
        fontFamily: "Roboto",
    },
    text: {
        position : "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 1
    },
    progress: {
        top: "35%",
        left: "35%",
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    },
    emptyChart : {
        position: 'absolute',
        top: "15%",
        left: "5%",
        height: "80%",
        width: "90%",
    },
    mainChart: {
        position: 'absolute',
        top: '6.5rem',
        left: '1.5rem',
        bottom: '0',
        right: '1.5rem',
        backgroundColor: 'lightblue'
    }
});

const legendContainerStyle = {
    display: "inline-block",
    position: "absolute",
    left: "5%",
    bottom: '8px',
    width: "92%",
    maxHeight: "88px",
    zIndex: 1000
};

class TimeSeriesChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rawData: null,
            mappedRawData: [],
            formattedData: null
        };
    }

    async fetchData() {
        let { selectedDashboard, title, colors, api, extraData, userContext, errorContext, getColor, selectedItem } = this.props;

        let order = extraData.find(x => x.name == "Order").value;
        order.sort(function (a, b) {
            return a.value > b.value;
        });

        title = title.split(" ").join("_");

        this.abortController && this.abortController.abort();
        this.abortController = new AbortController();

        var temp = []
        order.forEach(pair => {
            let apiKey = pair.name;
            temp.push({
                'Name': apiKey,
                'isLoading': true,
                'ChartType': 'loading'});
        });

        this.setState({
            formattedData: temp
        });

        order.forEach(pair => {
            let apiKey = pair.name;
            getDataHelper(api, apiKey, colors, extraData, userContext, selectedItem, this.abortController, title, errorContext).then(response => {
                const rawData = response[0];

                if(!_.isEmpty(rawData)) {
                    const formattedData = formatData(rawData, getColor);

                    var tempRawData = this.state.rawData;
                    if(tempRawData) {
                        tempRawData.push(rawData);
                    } else {
                        tempRawData = [rawData];
                    }

                    var tempMappedRawData = this.state.mappedRawData;
                    let map = new Map(rawData.Data.map( i => [i.key, i.value]));
                    tempMappedRawData.push({name: rawData.Name, map: map });

                    var tempFormattedData = this.state.formattedData;
                    if(tempFormattedData) {
                        let x = tempFormattedData.find(x => x.Name == formattedData.Name);
                        var index = tempFormattedData.indexOf(x);
                        tempFormattedData[index] = formattedData;
                    }

                    this.setState({
                        rawData: tempRawData,
                        mappedRawData: tempMappedRawData,
                        formattedData: tempFormattedData
                    });
                } else {
                    var tempFormattedData = this.state.formattedData;
                    if(tempFormattedData) {
                        let x = tempFormattedData.find(x => x.Name == apiKey);
                        var index = tempFormattedData.indexOf(x);
                        tempFormattedData[index] = {'Name': apiKey,
                                                    'ChartType': 'NA'};
                    }
                    
                    this.setState({
                        formattedData: tempFormattedData
                    });
                }
            });
        });
    }

    async componentDidMount() {
        if(this.props.selectedItem) await this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { width, height, selectedItem} = this.props;

        const dataNeedsUpdate = selectedItem !== nextProps.selectedItem ||
        //Strict comparison for checking if data has updated in state
        this.state !== nextState;

        const dimensionsUpdated = (height != nextProps.height) || (width != nextProps.width);
        const dataNeedsFetch = _.isEmpty(nextState.rawData) || _.isEmpty(nextState.formattedData);

        return dataNeedsUpdate || dimensionsUpdated || dataNeedsFetch; 
    }

    componentDidUpdate(prevProps, prevState) {
        const {selectedItem} = this.props;

        const selectedItemUpdated = !_.isEqual(selectedItem, prevProps.selectedItem);

        if (selectedItemUpdated) {
            this.setState({ rawData: null, mappedRawData: [], formattedData: null });
            if (selectedItem) {
                return this.fetchData();
            }
        }
    }

    componentWillUnmount() {
        this.abortController && this.abortController.abort();
    }

    handleDownloadClick() {
        if (this.state.rawData) {
            this.createCSV();
        }
    }

    handlePrintClick() {
        if (this.state.formattedData) {
            const {width, height, cardRef} = this.props;

            const aspectRatio = width / height;
            const newWidth = 850;
            const newHeight = newWidth / aspectRatio;

            html2canvas(cardRef.current, {scale: 2})
            .then(canvas => {
                const imgData = canvas.toDataURL('image/PNG');
                const pdf = new jsPDF({
                    orientation: 'landscape',
                    unit: 'pt'
                });
                pdf.addImage(imgData, 'PNG', 0, newHeight / 5, newWidth, newHeight);
                pdf.save("Timeseries.pdf");
            });
        }
    }

    createCSV() {
        let csvContent = "Key,";
        const csvData = this.state.rawData;
        let keys = [];

        for (const data of csvData) {
            keys = keys.concat(data.Data.map(d => d.key));
            csvContent += data.Name + ","
        }

        keys = [...new Set(keys)];

        keys.sort(function(a,b){
            return a - b;
          });

        csvContent += "\r\n";
        for (const key of keys) {
            let row = key + ",";

            for (const data of csvData) {
                let match = this.state.mappedRawData.find(x => x.name == data.Name);

                let value = match.map.get(key);

                value = (value != null) ? value : '';

                row += value + ","
            }
            csvContent += row + "\r\n";
        }

        let asset;
        try {
            const displayField = this.props.model.selectedFeature.displayField;
            asset = this.props.model.selectedItem.properties[displayField];
        } catch (e) {
            asset = ''
        }

        var blob = new Blob([csvContent]);
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.setAttribute("download", "timeseries_" + this.props.title + "_" + asset + ".csv");
        document.body.appendChild(a);
        a.click();

        // const encodedUri = encodeURI(csvContent);

        // const link = document.createElement("a");
        // link.setAttribute("href", encodedUri);

        // link.setAttribute("download", "timeseries_" + this.props.title + "_" + asset + ".csv");
        // document.body.appendChild(link);
        // link.click();
    }

    // ************************************
    // updates triggered by changes to hidden series list are
    // triggered by a state change in ChartLegend propagated
    // through the chart via redraw functions which are handled 
    // by d3 exclusively to avoid triggering awkward react redraws
    // of the chart components
    //
    // this is the callback function called when the ChartLegend's
    // Hidden Series List changes. It propagates changes downward through
    // the child refs
    // ************************************
    redrawChildren = hiddenSeriesList => {
        const redraw = this.mainChartRef.current.redraw;
        redraw(hiddenSeriesList);
    }

    render() {
        const { selectedDashboard, containerRef, classes, title, extraData, selectedItem } = this.props;
   
        this.margin = {
            top: 70,
            right: 35,
            bottom: 96,
            left: 80
        };

        this.mainChartRef = React.createRef();

        // if the chart data is ready to draw, create chart content,
        // else draw data not available placeholder


        const chartContent = selectedItem ?
            (
                <div id={title} className={classes.chartContainer}>

                    <MainChart ref={this.mainChartRef}
                        margin={this.margin}
                        className={classes.mainChart}
                        data={this.state.formattedData || []}
                        {...this.props}></MainChart>

                    <ChartLegend 
                        key={Math.random()}
                        containerStyle={legendContainerStyle}
                        data={this.state.formattedData || []}
                        onChange={this.redrawChildren.bind(this)} />
                </div>
            ) : (
                <>
                    <Typography className={classes.text}>Select an item to view data</Typography>
                    <EmptyChart className={classes.emptyChart} />
                </>
            );

        return (
            <div forwardedRef={containerRef}
                style={{ top: 0, bottom: 0, left: 0, right: 0, position: 'absolute' }}>
                <Typography variant="h6" className={this.props.classes.title}>
                    <span>{title}</span>
                </Typography>
                <Typography variant='h6' className={this.props.classes.subtitle}>
                    <span>{/* asset name here */}</span>
                </Typography>
                {chartContent}
            </div>
        );
    }
}


export default withStyles(styles)(TimeSeriesChart);