import React, {useState, useEffect, useContext, useRef} from 'react';
import { ModelContext } from '../../contexts/ModelContext';
import { MenuContext } from '../../contexts/MenuContext';
import { RouteContext } from '../../contexts/RouteContext';
import { DateContext } from '../../contexts/DateContext';
import { ErrorContext } from '../../contexts/ErrorContext';
import { UserContext } from '../../contexts/UserContext';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import _ from "lodash";
import ListFeaturePanel from "./ListFeaturePanel";
import SearchBar from "./SearchBar";
import withLoading from "../withLoading";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';


function TabContainer(props) {
    return (
        <Typography component="div" style={{height:'100%'}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    rootList: {
        position: "fixed",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
    rootTable: {
        position: "fixed",
        height: "calc(100vh - 250px)",
        marginTop: "64px",
        backgroundColor: "#F3F6FA",
        zIndex: 5000,
    },
    tabRoot: {
        minWidth: 50,
    },
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 8px)",
        height: "50px",
        margin: "4px"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

const FeaturePanel = (props) => {
    const {classes} = props;

    const modelContext = useContext(ModelContext);
    const menuContext = useContext(MenuContext);
    const dateContext = useContext(DateContext);
    const routeContext = useContext(RouteContext);
    const errorContext = useContext(ErrorContext);
    const userContext = useContext(UserContext);

    const [value, setValue] = useState(0);
    const [assetFilter, setAssetFilter] = useState('');
    const [tableLoading, setTableLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    //Custom function for React hooks (similar to prevProps, prevState)
    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }

    const handleChange = (event, value) => {
        setValue(value);
    }

    function createSelectItems(statusTypes) {
        if (statusTypes) {  
            let items = [];  
            statusTypes.forEach(function(status) {
                items.push(<MenuItem key={status.id} value={status}>{status.displayName}</MenuItem>); 
            });
              
            return items;
        }
    }

    function handleChangeHighlight (event) {
        const status = event.target.value;
        //Update status, clear selected item when switching highlights (status types)
        modelContext.updateValues({
            selectedStatus: status
        });
    }

    function setFilter(input) {
        setAssetFilter(input);
        modelContext.updateValue('assetFilter', input);
    }

    function applyListFilter(assets) {
        const {selectedFeature} = modelContext.state.value;

        let filteredList = [];

        if (!_.isEmpty(assets)) {
            filteredList = assets.filter(a => {
                if(!selectedFeature || !selectedFeature.displayField) return false;
                if(!a.properties || !a.properties[selectedFeature.displayField]) return false;
                return a.properties[selectedFeature.displayField].toLowerCase().includes(assetFilter.toLowerCase());
            });
        }
        
        return filteredList;
    }

    const { 
        assets, 
        selectedStatus, 
        statusTypes, 
        statusResults,
        assetsError, 
        selectedFeature, 
        selectedItem    
    } = modelContext.state.value;

    const { collapsedDrawerWidth } = menuContext;
    const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

    return (
        <Paper square elevation = {2} style={{width: props.panelWidth,marginLeft: drawerWidth + 'px'}} classes={{
                root: classNames(classes.rootList)}}>
                
                <SearchBar placeholderText={'Find an asset'} setFilter={setFilter} />

                <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: {display: 'none'}}}>
                    <Tab label="List" classes={{ root: classes.tabRoot }}/>
                </Tabs>

                <Divider />
                <FormControl className={classes.formControl} disabled={!statusTypes || statusTypes.length <= 0}>
                    <InputLabel>Highlight</InputLabel>
                    <Select 
                        value={selectedStatus || {}}
                        name="feature"
                        onChange={handleChangeHighlight}
                        displayEmpty
                        className={classes.selectEmpty}>
                        {createSelectItems(statusTypes)} 
                    </Select>
                </FormControl>
                <Divider/>

                {assetsError && value === 0 &&
                    <div style={{width: props.panelWidth, height:'100%'}}> 
                        <Paper square elevation = {2} style={{ minHeight: '100%', width: "100%", position: 'absolute'}}>
                            <div style={{ color: 'red', fontSize: '22px', lineHeight: '22px', padding: '8px 12px' }}>
                                There was an error loading assets.
                            </div>
                        </Paper>
                    </div>
                }

                {(!assetsError && value === 0) && 
                    <TabContainer>
                        <ListFeaturePanel 
                            showPerformanceAttributes={true}
                            isLoading={_.isEmpty(assets)}
                            statusResults={statusResults}
                            model={modelContext}
                            date={dateContext}
                            assets={applyListFilter(assets)}
                            selectedItem={selectedItem || {}} 
                            selectedFeature={selectedFeature || {}}
                            statusResults={statusResults}
                            match={props.match}
                            history={props.history}
                            location={props.location}
                            errorContext={errorContext}
                            routeContext={routeContext}
                            />
                    </TabContainer>
                }
        </Paper>
    )
}

FeaturePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FeaturePanel);