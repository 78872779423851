import React from 'react';

const openDrawerWidth = 240;
const collapsedDrawerWidth = 0;

const defaultOpenMenu = {
    drawerWidth: openDrawerWidth, 
    lockOpen: true,
    floatOpen: false,
    isDesktop: true,
    modelsExpanded: true,
    dashboardsExpanded: true,
    operationsExpanded: true,
    usersExpanded: false,
    adminPanelOpen: false,
    reportingExpanded: true,
    settingsPanelOpen: false
}

const defaultCloseMenu = {
    drawerWidth: collapsedDrawerWidth, 
    lockOpen: false,
    floatOpen: false,
    isDesktop: false,
    modelsExpanded: false,
    dashboardsExpanded: false,
    operationsExpanded: false,
    usersExpanded: false,
    adminPanelOpen: false,
    reportingExpanded: false,
    settingsPanelOpen: false
}

export const MenuContext = React.createContext(defaultCloseMenu);

class MenuContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = defaultCloseMenu;
    }

    setDesktop = (isDesktop) => {
        if (isDesktop) {
            this.setState(defaultOpenMenu);
        }
        else {
            this.setState(defaultCloseMenu);
        }
    }

    getOpen = () => {
        return this.state.lockOpen || this.state.floatOpen;
    }

    getLockOpen = () => {
        return this.state.lockOpen;
    }

    toggleDashboards = () => {
        if (this.getOpen())
        {
            this.setState({
                dashboardsExpanded: !this.state.dashboardsExpanded
            });
        }
        else //touch screen
        {
            this.floatOpenDrawer(!this.state.isDesktop);
        }
    }

    toggleOperations = () => {
        if (this.getOpen())
        {
            this.setState({
                operationsExpanded: !this.state.operationsExpanded
            });
        }
        else //touch screen
        {
            this.floatOpenDrawer(!this.state.isDesktop);
        }
    }

    toggleModels = () => {
        if (this.getOpen())
        {
            this.setState({
                modelsExpanded: !this.state.modelsExpanded
            });
        }
        else //touch screen
        {
            this.floatOpenDrawer(!this.state.isDesktop);
        }
    }

    toggleUsers = () => {
        if (this.getOpen())
        {
            this.setState({
                usersExpanded: !this.state.usersExpanded
            });
        }
        else //touch screen
        {
            this.floatOpenDrawer(!this.state.isDesktop);
        }
    }

    lockOpenDrawer = () => {
        this.setState({ 
            lockOpen: true,
            floatOpen: false,
            drawerWidth: openDrawerWidth,
            modelsExpanded: true,
            dashboardsExpanded: true,
            operationsExpanded: true,
            adminPanelOpen: false,
            reportingExpanded: true,
            settingsPanelOpen: false
        });
    }

    lockCloseDrawer = () => {
        this.setState({ 
            lockOpen: false,
            drawerWidth: collapsedDrawerWidth,
            modelsExpanded: false,
            dashboardsExpanded: false,
            operationsExpanded: false,
            usersExpanded: false,
            adminPanelOpen: false,
            reportingExpanded: false,
            settingsPanelOpen: false
        });
    }

    floatOpenDrawer = (doEvent) => {
        if (!this.getOpen() && doEvent)
        {
            this.setState({ 
                floatOpen: true,
                modelsExpanded: true,
                dashboardsExpanded: true,
                operationsExpanded: true
            });
        }
    }

    floatCloseDrawer = () => {
        if (!this.state.lockOpen && this.state.floatOpen)
        {
            this.setState({ 
                floatOpen: false,
                modelsExpanded: false,
                dashboardsExpanded: false,
                operationsExpanded: false,
                usersExpanded: false,
                adminPanelOpen: false,
                reportingExpanded: false,
                settingsPanelOpen: this.state.settingsPanelOpen
            });
        }
    }

    swapLockAndFloatDrawer = () => {
        this.setState({
            lockOpen: !this.state.lockOpen,
            floatOpen: !this.state.floatOpen
        });
    }

    toggleSettingsPanel = () => {
        this.setState({
            settingsPanelOpen: !this.state.settingsPanelOpen
        });
    }

    toggleAdminPanel = () => {
        this.setState({
            adminPanelOpen: !this.state.adminPanelOpen
        });
    }

    render () {
        return (
            <MenuContext.Provider value={{
                state: this.state,
                collapsedDrawerWidth: collapsedDrawerWidth,
                openDrawerWidth: openDrawerWidth,
                setDesktop : this.setDesktop,
                swapLockAndFloatDrawer: this.swapLockAndFloatDrawer,
                floatCloseDrawer: this.floatCloseDrawer,
                floatOpenDrawer: this.floatOpenDrawer,
                lockCloseDrawer: this.lockCloseDrawer,
                lockOpenDrawer: this.lockOpenDrawer,
                toggleUsers: this.toggleUsers,
                toggleModels: this.toggleModels,
                toggleDashboards:  this.toggleDashboards,
                toggleOperations: this.toggleOperations,
                getOpen: this.getOpen,
                getLockOpen: this.getLockOpen,
                toggleAdminPanel: this.toggleAdminPanel,
                toggleReporting: this.toggleReporting,
                toggleSettingsPanel: this.toggleSettingsPanel
            }}>
                {this.props.children}
            </MenuContext.Provider>
        )
    }
}

export default MenuContextProvider;



