import React from 'react';
import _ from 'lodash';
import {getCMOMSummaryData} from '../api/ApiWorker';

export const MaintenanceContext = React.createContext(null);

const initialSettings = {
    isLoadingSummaryData: null,
    summaryData: [],
    summaryDataError: null
};

class MaintenanceContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialSettings
        };
    }

    updateValues = async toUpdate => {
        const nextState = {};
        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && this.state[key] != value) {
                nextState[key] = value;
            }
        }

        this.setState(nextState);
    }

    async getSummaryData() {
        const requestId = Math.random();
        try
        {
            const {users, model, date, currentDashboard} = this.props;
            const {selectedModel, selectedFeature} = model;
            const tenant = users.find(u => u.id == sessionStorage.getItem('tenant'));

            //If the current layer does not belong to the dashboard and has not been updated yet, refrain from making API calls with said layer.
            if (currentDashboard && currentDashboard.limitLayers && !currentDashboard.limitLayers.includes(selectedFeature.layerName))
            {
                return;
            }
    
            let summaryData = [];
            try
            {
                this.summaryDataAbortController = new AbortController();
                this.summaryDataAbortController.id = requestId;
                this.setState({ 
                    summaryData: [],
                    isLoadingSummaryData: true
                 });
    
                summaryData = await getCMOMSummaryData(tenant.tenantName, selectedModel, selectedFeature, date.startDate, date.realEndDate, this.summaryDataAbortController);
        
                summaryData = (Object.keys(summaryData).length === 0 && summaryData.constructor === Object) ? [] : summaryData;
            }
            catch (e) {
                // if aborted, cancel execution
                if(e.name == 'AbortError') return;
                throw e;
            }
    
            const newState = Object.assign({}, this.state, {
                summaryData: summaryData,
                isLoadingSummaryData: false,
                summaryDataError: null
            });
    
            //ensure we're still current before updating state
            this.summaryDataAbortController.id == requestId && this.updateValues(newState);
        }
        catch (e) {
            const newState = Object.assign({}, this.state, {
                summaryData: [],
                isLoadingSummaryData: null,
                summaryDataError: 'There was an error loading the summary data'
            });

            //ensure we're still current before updating state
            this.summaryDataAbortController.id == requestId && this.updateValues(newState);
        }
    }

    componentDidMount() {
        const {model, tenant} = this.props;
        model && model.selectedFeature && model.selectedFeature.layerName && this.getSummaryData();
    }

    componentDidUpdate(prevProps, prevState) {
        const {date, model, tenant, match} = this.props;
        const {isLoadingSummaryData, summaryData, summaryDataError} = this.state;

        // If current selectedFeature exists and either date change or tenant change occurred, load CMOM data.
        if (model && model.selectedFeature && model.selectedFeature.layerName && 
            (!_.isEqual(match.params.layerName, prevProps.match.params.layerName) ||
             !_.isEqual(date.startDate, prevProps.date.startDate) ||
             !_.isEqual(date.endDate, prevProps.date.endDate) || 
            (tenant && !_.isEqual(tenant, prevProps.tenant)) ||
            (isLoadingSummaryData == null && _.isEmpty(summaryData) && summaryDataError == null) ||
            (isLoadingSummaryData == false && _.isEmpty(summaryData) && summaryDataError) ) ) {
            this.getSummaryData();
        }
    }

    render() {
        return (
            <MaintenanceContext.Provider value={{
                state: this.state,
                updateValues: this.updateValues
            }}>
                {this.props.children}
            </MaintenanceContext.Provider>
        )
    }
}

export default MaintenanceContextProvider;