import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import withLoading from "../withLoading";
import _ from 'lodash';
import moment from 'moment-timezone';
import FlexTable from '../flexTable/FlexTable';
import { getGenericRequestData } from '../../api/ApiWorker';
import ApiHelper from '../../api/ApiHelper';
import { useQuery } from 'react-query';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading('div');

const styles = () => ({
    text: {
        position: "absolute",
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontSize: "1.5rem",
        lineHeight: '24px',
        letterSpacing: '.15px',
        top: "50%",
        left: "50%",
        color: 'rgba(0,0,0,0.6)',
        transform: "translate(-50%,-50%)"
    }
});
const columns = [
    {
        extraData: null,
        header: 'Date',
        units: null,
        dataKey: 'timestamp', // TBD
        dataType: 'date',
        order: 0,
        width: 100
    },
    {
        extraData: null,
        header: 'OPR',
        units: null,
        dataKey: 'value', // TBD
        dataType: 'number',
        order: 1,
        width: 100
    },
    {
        extraData: null,
        header: 'Priority',
        units: null,
        dataKey: 'attributes.Priority', // TBD
        dataType: 'text',
        order: 2,
        width: 100
    },
    {
        extraData: null,
        header: 'Recommendation',
        units: null,
        dataKey: 'attributes.Recommendation', // TBD
        dataType: 'text',
        order: 3,
        width: 100
    },
    {
        extraData: null,
        header: 'Asset',
        units: null,
        dataKey: 'identifier', // TBD
        dataType: 'text',
        order: 4,
        width: 100
    }
];

function TableCard(props) {
    const { classes, model, api, userContext, dateContext, title } = props;

    var url = "";
    if (!_.isEmpty(model.state.value.highlightedAsset)) {
        const apiKey = Object.keys(api)[0];
        var urlPattern = api[apiKey].urlPattern;
        const tenant = userContext.getTenant();
        urlPattern = urlPattern.split("[startDate]").join(moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE)).format("YYYY-MM-DD"));
        urlPattern = urlPattern.split("[endDate]").join(moment.tz(dateContext.realEndDate, sessionStorage.getItem(TENANT_TIMEZONE)).format("YYYY-MM-DD"));
        urlPattern = urlPattern.split("[tenantName]").join(tenant.tenantName);
        urlPattern = urlPattern.split("[modelName]").join(model.selectedModel.name);
        urlPattern = urlPattern.split("[layerName]").join(model.selectedFeature.layerName);
        urlPattern = urlPattern.split("[assetId]").join(model.state.value.highlightedAsset);
        url = (new URL(urlPattern, ApiHelper.getUrlPath())).toString();
    }

    var { isLoading, data } = useQuery(url, async () => await getGenericRequestData(url), { enabled: !_.isEmpty(model.state.value.highlightedAsset) });

    var getCardContent = (childContent) => (
        <CardContent>
            <Typography variant='h6' style={{ position: 'absolute', top: '.5rem', left: '1.5rem', fontSize: '20px', fontFamily: 'Roboto' }}>
                {(((model.state.value.selectedFeature && model.state.value.selectedFeature.layerName) ? `${model.state.value.selectedFeature.layerName} ` : "") + title)}
            </Typography>
            {childContent}
        </CardContent>
    );

    var content;
    if (_.isEmpty(model.state.value.highlightedAsset)) {
        content = getCardContent(<Typography className={classes.text}>Select an asset to show more</Typography>);
    } else {
        if (_.isEmpty(data)) {
            data = [];
        } else {
            const dateColumn = columns.find(x => x.dataType == 'date');
            data.forEach(x => {
                x[dateColumn.dataKey] = moment.tz(x[dateColumn.dataKey], sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD hh:mm a z')
            });
            data.sort((a, b) => (new Date(a[dateColumn.dataKey]) > new Date(b[dateColumn.dataKey])) ? -1 : 1);
        }

        content = getCardContent(
            <>
                <div style={{ position: 'absolute', top: '3rem', left: '1.5rem', fontSize: '18px', fontFamily: 'Roboto', display: 'flex' }}>
                    <Typography variant='h6' style={{ whiteSpace: 'nowrap' }}>
                        {`${data.length} inspections`}
                    </Typography>
                    <Typography variant="body1" style={{ lineHeight: 2.1, paddingLeft: '4px', whiteSpace: 'nowrap' }}>
                        {` at ${model.state.value.highlightedAsset} ${model.state.value.selectedFeature.layerName.toLowerCase()}`}
                    </Typography>
                </div>
                <div style={{ position: 'absolute', top: '4rem', left: '0', right: '0', bottom: '0', width: '100%', height: 'calc(100% - 4rem)' }}>
                    <AutoSizer>
                        {({ width, height }) => (
                            <FlexTable
                                width={width}
                                height={height}
                                columns={columns}
                                data={data} />
                        )}
                    </AutoSizer>
                </div>
            </>
        );

        if (isLoading) {
            content = (
                <LoadingDiv style={{ top: 0, bottom: 0, left: 0, right: 0, position: 'absolute', display: 'block' }} isLoading={isLoading}>
                    {content}
                </LoadingDiv>
            );
        }
    }

    return (
        <>{content}</>
    );
}

const InspectionTableCard = withStyles(styles)(TableCard);
export default InspectionTableCard;