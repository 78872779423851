import React from 'react';

export default (props) => {
    const { height, width, margin, clipPathId } = props;

    const h = height - (margin.top + margin.bottom);
    const w = width - (margin.left + margin.right);

    return (
        <clipPath id={clipPathId} >
            <rect height={h} width={w}></rect>
        </clipPath>
    );
}
