import simplify from '../../../util/simplify-date';
import { getGenericRequestData } from '../../../api/ApiWorker';
import _ from 'lodash';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

function formatBarData(data) {
    const results = [];
    let start;

    if (data[0].value) {
        start = data[0].date;
    }

    for (let i = 1; i < data.length; i++) {
        const prevValue = data[i - 1].value;
        const currentValue = data[i].value;
        const nextValue = data[i + 1] ? data[i + 1].value : null;

        if (currentValue == prevValue && nextValue != null) continue;

        if (!start) {
            if (currentValue) {
                if (currentValue == nextValue) {
                    start = data[i - 1].date;
                } else {
                    results.push({
                        value: data[i].value,
                        start: data[i - 1].date,
                        end: data[i].date,
                        date: data[i].date
                    });
                }
            }
        } else {
            results.push({
                value: prevValue,
                start,
                end: data[i - 1].date,
                date: data[i - 1].date
            });
            start = null;
        }
    }

    return results;
}

export function formatData(rawData, getColor) {
    const toReturn = Object.assign({}, rawData);

    if (toReturn.ChartType == 'line' || toReturn.ChartType == 'curveStep' || toReturn.ChartType == 'area' || toReturn.ChartType == 'dashedLine') {
        const values = rawData.Data.map(f => f.value);
        const tolerance = (values.reduce((total, value) => total + value, 0) / values.length) * 0.75;
        toReturn.brushData = simplify(rawData.Data, tolerance, false);
    } else if (toReturn.ChartType == 'bar') {
        toReturn.brushData = formatBarData(rawData.Data);
        toReturn._formattedData = formatBarData(rawData.Data);
    } else if (toReturn.ChartType == "alert") {
        //TODO no alerts in brush bar for now
        // update: currently alerts do not need formatting
    }

    toReturn.Color = (toReturn.Color != undefined) ? getColor(toReturn.Color) : "#000000"

    return toReturn;
}

export async function getData(apiKey, url, errorContext, title) {
    try {
        const results = await getGenericRequestData(url);

        if (results.data == null) return null;

        let returnData = Object.keys(results.data).map(key => {
            return {
                date: moment.tz(key, sessionStorage.getItem(TENANT_TIMEZONE)),
                value: results.data[key]
            };
        })
            .sort((a, b) => {
                return a.date - b.date;
            });

        return {
            asset: results.asset,
            data: returnData,
            includesSubstitutions: results.includesSubstitutions ? results.includesSubstitutions : false
        };
    } catch (ex) {
        console.log(ex);
        throw ex;
    }
}

function inputDataIsValid(api) {
    return !_.isEmpty(api);
}

function formatExtraData(extraData, apiKey) {
    const extraDataFormatted = {};

    for (const key of Object.keys(extraData)) {
        if (!_.isArray(extraData[key])) {
            if (_.isArray(extraData[key].value)) {
                const obj = extraData[key].value.find(v => v.name == apiKey);
                extraDataFormatted[extraData[key].name] = obj?.value;
            } else {
                extraDataFormatted[extraData[key].name] = extraData[key].value;
            }
        } else {
            extraData[key].forEach(value => {
                if (_.isArray(value)) {
                    const matchingValue = value.find(v => v.name == apiKey);
                    extraDataFormatted[matchingValue.name] = matchingValue?.value;
                } else {
                    extraDataFormatted[value.name] = value.value;
                }
            });
        }
    }

    return extraDataFormatted;
}

export function getDataHelper(api, apiKey, colors, extraData, url, title, errorContext) {
    if (!inputDataIsValid(api)) {
        console.error('Input data invalid; Returning empty array');
        return [];
    }

    return new Promise((resolve, reject) => getData(apiKey, url, errorContext, title).then(returnObj => {
        if (!returnObj) {
            return resolve({ Name: apiKey, ChartType: 'NA' });
        }

        const extraDataFormatted = formatExtraData(extraData, apiKey);
        const result = {
            Name: apiKey,
            Url: "",
            Asset: returnObj.asset,
            Data: returnObj.data ? returnObj.data : [],
            IncludesSubstitutions: returnObj.includesSubstitutions,
            Id: Math.random(),
            Color: colors[apiKey],
            ...extraDataFormatted
        };

        return resolve(result);
    }).catch(e => {
        console.log('An error occurred retrieving chart data');
        return reject(e);
    }));

}