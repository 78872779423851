import React from "react";

const ModalBackground = props => {

    const Modal = {
        position: "fixed",  
        zIndex: props.zIndex || 6000, // The fullscreen modal is 6000
        left: 0,
        top: 0,
        width: "100vw",  
        height: "100vh", 
        backgroundColor: "rgba(0,0,0,0.4)", // Black w/ opacity
        transform: "translate(0px,0px)"
    }

    return (
        <span onClick={props.onClick} style={Modal}>
        </span>
    )
}

export default ModalBackground;