import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ModelsSubMenu from "./topsection/ModelsSubMenu";
import DashboardsSubMenu from "./topsection/DashboardsSubMenu"
import Divider from '@material-ui/core/Divider';
import OperationsSubMenu from './topsection/OperationsSubMenu';
import ReportingSubMenu from './topsection/ReportingSubMenu';

const styles = theme => ({
    root: {
        width: '100%',
        height: '85%',
        overflowX: 'hidden',
        overflowY: 'auto',
        maxWidth: 360,
        backgroundColor: "rgba(255,255,255,0)",
        color: "white"
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
});


class ListMenuTop extends React.Component {

    render() {
        const { classes, dashboards, menuContext, errorContext, routeContext, model } = this.props;

        let operationDashboards = dashboards.filter(x => x.tags != null && x.tags.includes('O&M'));
        let reportingDashboards = dashboards.filter(x => x.tags != null && x.tags.includes('Reporting'));

        return (
            <List component="nav" className={classes.root}>
                {
                    model && model.state.value.allModels.length > 1 && 
                    <ModelsSubMenu 
                    menuContext={menuContext}
                    routeContext={routeContext}
                    errorContext={errorContext}/>
                }
                <DashboardsSubMenu 
                    menuContext={menuContext}
                    loadDashboard={this.props.loadDashboard} 
                    dashboards={this.props.dashboards}
                    selectedDashboard={this.props.selectedDashboard}
                    routeContext={routeContext}/>
                {
                    operationDashboards.length > 0 &&
                    <OperationsSubMenu
                    loadDashboard={this.props.loadDashboard} 
                    dashboards={operationDashboards}
                    menuContext={menuContext}
                    selectedDashboard={this.props.selectedDashboard}
                    routeContext={routeContext}/>
                }
                {
                    true && reportingDashboards.length > 0 &&
                    <ReportingSubMenu 
                    loadDashboard={this.props.loadDashboard} 
                    selectedDashboard={this.props.selectedDashboard}
                    dashboards={reportingDashboards}/>
                }

                {/* <AlertsSubMenu open={this.props.open}/>
                <ReportsSubMenu open={this.props.open}/> */}
                {/* <PrintButton />
                <ShareButton/> */}
            </List>
        );
    }
}

ListMenuTop.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListMenuTop);
