import { useQueries } from 'react-query';

// Note: This wrapper should only be used for class components.
// If your component is a functional component, you can directly use:
// import { useQueries } from 'react-query';

/*
<Queries
    queries={[
    {
        queryKey: "q1",
        queryFn: () => {wait()},
        options: {}
    },
    {
        queryKey: "q2",
        queryFn: () => {},
        options: {}
    }
    ]}
>
    {(results) => {
    return (<div>
    {results.map((result, i) => {
        if (result.isLoading) return (<h1>Loading {i}</h1>);
        return (
        <div className="App">
            <h1>
            Cache {i} {result.data}
            </h1>
        </div>
        );
    })
    }
    </div>)
    }}
</Queries>
*/

const UseQueries = ({ children, queries }) => {
    return children(useQueries(queries));
};

export default UseQueries;