import React from 'react';
import NewFeaturePanel from './featurepanel/NewFeaturePanel';
import FeaturePanel from "./featurepanel/FeaturePanel";
import DashboardFrame from "./DashboardFrame";
import TopMenu from "./topmenu/TopMenu";
import NewTopMenu from './topmenu/NewTopMenu';
import SideMenu from "./sidemenu/SideMenu";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getTheme, getModels, getDashboards, getAssets } from "../api/ApiWorker";
import _ from 'lodash';
import useMediaQuery  from '@material-ui/core/useMediaQuery';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

class Dashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            layout: [],
        }
    }

    componentDidMount() {
        const {userContext, routeContext, model, match} = this.props;
        
        const tenant = userContext.getTenant();
        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'performance',
            viewType: match.params.dashboardName,
            layerName: match.params.layerName,
            assetName: match.params.assetName
        });
    }

    componentWillUnmount() {
        this.props.model.updateValues({
            selectedItem: null,
            highlightedAsset: null,
            selectedStatus: null
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {dashboards, match, selectedDashboard, model, routeContext, userContext, history} = this.props;
        const {baseModel, selectedModel} = model;

        if (prevState.layout !== this.state.layout)
        {
            this.setState({layout: this.props.layout});
        }

        const currentModel = selectedDashboard && selectedDashboard.baseModelExclusive ? baseModel : selectedModel;

        if (selectedDashboard && selectedDashboard.limitLayers && model.selectedFeature && model.selectedFeature.layerName && !selectedDashboard.limitLayers.includes(model.selectedFeature.layerName))
        {
            let defaultLayerName = selectedDashboard.limitLayers[0];
            const defaultLayer = currentModel.layers.find(x => x.layerName == defaultLayerName);
            if(defaultLayer) {
                const layerName = defaultLayer.layerName;
                const layer = layerName ? (currentModel.layers.find(x => x.isSelectableAssetType && x.layerName == layerName) || currentModel.layers.find(x => x.isSelectableAssetType)) : currentModel.layers.find(x => x.isSelectableAssetType);
                model.updateValues({
                    selectedFeature: layer
                });
                routeContext.updateValues({
                    layerName: layer.layerName
                });
            }
        }

        if (currentModel && currentModel.layers.length > 0 && (!model.selectedFeature || _.isEmpty(model.selectedFeature) || !model.selectedFeature.isSelectableAssetType))
        {
            const layerName = match.params.layerName || null;
            const layer = layerName ? (currentModel.layers.find(x => x.isSelectableAssetType && x.layerName == layerName) || currentModel.layers.find(x => x.isSelectableAssetType)) : currentModel.layers.find(x => x.isSelectableAssetType);
            model.updateValues({
                selectedFeature: layer
            });
        }

        if (routeContext.state.tenant && match.params.tenantName && !_.isEqual(routeContext.state.tenant, match.params.tenantName))
        {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            if (!_.isEmpty(newTenant)) {
                userContext.switchTenant(newTenant.id);
                routeContext.updateValues({
                    tenant: newTenant.tenantName
                });
            }
            else {
                history.push('/unknown');
            }

        }

        if (dashboards.length > 0 && (selectedDashboard == null || (match.params.dashboardName != prevProps.match.params.dashboardName)) )
        {
            let newDashboard = dashboards.find(x=> x.dashboardName == match.params.dashboardName);
            if (newDashboard && newDashboard.tags.includes('Dashboards')) {
                this.props.loadDashboard(newDashboard.dashboardCards, newDashboard);
            } else {
                history.push('/unknown');
            }
        }

    }

    render() {
        const { classes, model, match, location, history, menuContext, themeContext, date, routeContext, userContext, dashboards, selectedDashboard, mapType, errorContext, ...rest } = this.props;
        const { collapsedDrawerWidth } = menuContext;

        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;

        let panelWidth = "15vw";
        let frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)"; 
        let frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";

        if (selectedDashboard && !_.isEmpty(selectedDashboard.featurePanelConfiguration))
        {
            panelWidth = "35vw";
        }

        if (selectedDashboard?.extraData?.DisableFeaturePanel) {
            frameOffset = "calc(" + drawerWidth + "px)";
            frameWidth = "calc(100vw - " + drawerWidth + "px)";
        }

        return (
            <div className={classes.root}>
                {
                    (selectedDashboard && !_.isEmpty(selectedDashboard.topMenuConfiguration) && !_.isEmpty(selectedDashboard.featurePanelConfiguration))
                    ? <>
                    <NewTopMenu
                        modelContext={model}
                        userContext={userContext}
                        dateContext={date}
                        themeContext={themeContext}
                        configuration={selectedDashboard.topMenuConfiguration}
                        menuContext={menuContext}
                        selectedDashboard={selectedDashboard}
                    />
                    { !selectedDashboard.extraData.DisableFeaturePanel && <NewFeaturePanel
                        dashboards={dashboards}
                        dateContext={date}
                        modelContext={model}
                        themeContext={themeContext}
                        configuration={selectedDashboard.featurePanelConfiguration}
                        panelWidth={panelWidth}
                        menuContext={menuContext}
                        selectedDashboard={selectedDashboard} 
                        /> }
                    </>
                    : <> 
                    <TopMenu
                    selectedDashboard={selectedDashboard}
                    displayFeatureSelector={true}
                    model={model}
                    menuContext={menuContext}
                    match={match}
                    location={location}
                    history={history} />
                    { !selectedDashboard?.extraData?.DisableFeaturePanel && <FeaturePanel 
                        panelWidth={panelWidth}
                        match={match}
                        history={history}
                        location={location}
                        /> }
                    </> 
                }
                <DashboardFrame 
                    selectedDashboard={selectedDashboard}
                    menuContext={this.props.menuContext}
                    routeContext={this.props.routeContext}
                    frameOffset={frameOffset}
                    frameWidth={frameWidth}
                    mapType={mapType}
                    importLayout={this.props.layout}
                    snackbarContext={this.props.snackbarContext}
                    /> 
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const DashboardComponent = withStyles(styles, { withTheme: true })(Dashboard);
function useDesktopLayout(props) {
    const matches = useMediaQuery('(min-width:1367px)');
    return (<DashboardComponent{...props} isDesktop={matches}></DashboardComponent>);
}

export default useDesktopLayout;