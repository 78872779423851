import React from 'react';
import { ModelContext } from '../../contexts/ModelContext';
import { MapContext } from '../../contexts/MapContext';
import { ThemeContext } from '../../contexts/ThemeContext';
import { withStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import { Layers } from '@material-ui/icons';
import { Print, Close } from '@material-ui/icons';
import NetworkIcon from '../cardcontainer/NetworkIconButton';
import StylesButton from "../cardcontainer/StylesButton";
import NetworkTracingTool from './tools/NetworkTracingTool';
import LayerLegendTool from './tools/LayerLegendTool';
import {  CircularProgress } from '@material-ui/core';

const styles = theme => ({
    networkTracingToolContainer: {
        position: 'absolute',
        left: "5px",
        top: "5px",
        bottom: "5px",
        minWidth: "350px",
        zIndex: 6001,
        backgroundColor: 'white',
        borderRadius: "16px"
    },
    layerLegendContainer: {
        id: "Layers Button",
        position: 'absolute',
        left: "5px",
        top: "5px",
        zIndex: 6000,
        width: "40px",
        height: "40px",
        backgroundColor: 'white',
        boxShadow: "0px 0px 0px 2px rgba(0,0,0,.1)",
        borderRadius: "16px"
    },
    networkIconContainer: {
        id: "Network Button",
        position: 'absolute',
        left: "5px",
        top: "53px",
        zIndex: 6000,
        width: "40px",
        height: "40px",
        boxShadow: "0px 0px 0px 2px rgba(0,0,0,.1)",
        backgroundColor: 'white',
        borderRadius: "16px"
    },
    printButtonContainer: {
        id: 'Print Button',
        position: 'absolute',
        top: '5px',
        right: '53px',
        zIndex: 6000,
        width: '40px',
        height: '40px',
        boxShadow: '0px 0px 0px 2px rgba(0,0,0,.1)',
        backgroundColor: 'white',
        borderRadius: '16px'
    },
    stylesButtonContainer: {
        position: 'absolute',
        left: "5px",
        bottom: "5px",
        zIndex: 6000,
        width: "200px",
        height: "33px"
    },
    layerLegendToolContainer: {
        position: 'absolute',
        left: "5px",
        top: "5px",
        bottom: "5px",
        minWidth: "250px",
        zIndex: 6000,
        backgroundColor: 'white',
        borderRadius: "16px"
    },
    selectedAssetContainer: {
        position: 'absolute',
        right: "5px",
        bottom: "5px",
        zIndex: 6000,
        backgroundColor: 'white',
        borderRadius: "16px",
        padding: '2px 12px',
        fontWeight: 'bold'
    }
})

const MapControls = (props) => (
    <MapContext.Consumer>
        {(mapContext) => (
            <ModelContext.Consumer>
                {(model) => (
                    <ThemeContext.Consumer>
                        {({getColor}) => (
                            <>
                                <div className={props.classes.printButtonContainer}>
                                    <IconButton
                                        aria-label='Print Button'
                                        style={{ color: getColor('primary.Primary'), padding: '8px' }}
                                        onClick={props.handlePrintClick}>
                                        <Print />
                                    </IconButton>
                                </div>

                                {
                                    mapContext.showNetworkTracing && //Layers Table When Clicked
                                    <div className={props.classes.networkTracingToolContainer}>
                                        <NetworkTracingTool model={model} mapContext={mapContext}></NetworkTracingTool>
                                    </div>
                                }

                                {
                                    !props.hideLayerLegend &&
                                    <div className={props.classes.layerLegendContainer}>
                                        <IconButton
                                            aria-label="Layer Legend"
                                            style={{ color: getColor('primary.Primary'), padding: '8px' }}
                                            onClick={mapContext.toggleLegend}>
                                            <Layers />
                                        </IconButton>
                                    </div>
                                }
                                {
                                    !props.hideNetworkTracing &&
                                    <div className={props.classes.networkIconContainer}>
                                        <IconButton
                                            onClick={mapContext.toggleTracing}
                                            style={{ color: getColor('primary.Primary'), padding: '8px' }}>
                                            <NetworkIcon />
                                        </IconButton>
                                    </div>
                                }

                                <div className={props.classes.stylesButtonContainer}>
                                    <StylesButton viewType={props.viewType} toggleViewType={viewType => props.toggleViewType(viewType)} />
                                </div>

                                {
                                    mapContext.showLegend && //Layers Table When Clicked
                                    <div className={props.classes.layerLegendToolContainer}>
                                        <LayerLegendTool
                                            layers={props.layers}
                                            legendTitle={props.legendTitle}
                                            legendSubtitle={props.legendSubtitle}
                                            toggleSystem={props.toggleSystem}
                                            showSystem={props.showSystem}
                                            toggleModel={props.toggleModel}
                                            showModelSystem={props.showModelSystem}
                                            mapOptions={props.mapOptions}
                                            model={model}
                                            mapContext={mapContext}
                                            hideModelSystemGroups={props.hideModelSystemGroups}></LayerLegendTool>
                                    </div>
                                }

                                {
                                    !props.isLoading && props.routeContext?.state.assetName &&
                                    <div className={props.classes.selectedAssetContainer}>
                                        <span>{props.routeContext?.state.assetName}</span>
                                        <IconButton 
                                            onClick={() => {
                                                props.updateSelectedItem && props.updateSelectedItem(null);
                                            }}
                                            style={{ color: getColor('primary.Primary'), padding: '8px' }}>
                                            <Close />
                                        </IconButton>
                                    </div>
                                }

                                {
                                    props.isLoading &&
                                    <div className={props.classes.selectedAssetContainer}>
                                        <CircularProgress> </CircularProgress>
                                    </div>
                                }
                            </>
                        )}
                    </ThemeContext.Consumer>
                )}
            </ModelContext.Consumer>
        )}
    </MapContext.Consumer>
);

export default withStyles(styles)(MapControls);