import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PipeCastIcon from '../../img/pipeCAST_logo_inverse.svg';
import PushPinIcon from '../../img/push_pin.svg';
import PushPinFilledIcon from '../../img/push_pin_filled.svg';
import ListMenuTop from "./ListMenuTop";
import ListMenuBottom from "./ListMenuBottom";

const drawerWidth = 240;

const styles = theme => ({
    drawerPaper: {
        position: 'fixed',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        zIndex : 5500,
        background: "linear-gradient(180deg, #043443 0%, #0c596d 100%)",
        boxShadow: "0px 1px 10px rgba(5,40,56, .2), 0px 4px 5px rgba(5,40,56, 0.12), 0px 2px 4px rgba(5,40,56, 0.14)",
        color:"white",
        overflow: 'hidden'
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
        },
        color: "white"
        
    },
    toolbar: {
        display: 'flex',
        background: "#052838",
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    title: {
        fontWeight: "450",
        fontSize: "1.5rem",
        color: "white",
    }
});

class SideMenu extends React.Component {

    constructor(props) {
        super(props);
    }

    render () {
        const { classes, menuContext, routeContext, errorContext } = this.props;

        let open = menuContext.getOpen();
        let lockOpen = menuContext.getLockOpen();
        let timeout;
        
        return (
            <Drawer variant="persistent" 
                    classes={{
                        paper: classNames(classes.drawerPaper, !(open) && classes.drawerPaperClose),
                    }}
                    open={open}
                    lockOpen={lockOpen}
                    BackdropProps={{ invisible: true }}>
                <div style={{height: '100%', width: '100%', display: 'block'}}
                    onMouseLeave={() => { timeout = setTimeout(function(){ menuContext.floatCloseDrawer(); }, 300); }}
                    onMouseEnter={() => { clearTimeout(timeout) }}>
                    <div className={classes.toolbar}>

                        {!lockOpen && <IconButton onClick={menuContext.floatCloseDrawer} style={{left: '30px', color: 'white'}}>
                            <img src={PipeCastIcon} style={{width: 150, height: 30}}/>
                        </IconButton>}
                        
                        {lockOpen && <IconButton onClick={menuContext.lockCloseDrawer} style={{left: '30px', color: 'white'}}>
                            <img src={PipeCastIcon} style={{width: 150, height: 30}}/>
                        </IconButton>}

                        {<IconButton onClick={lockOpen ? menuContext.swapLockAndFloatDrawer : menuContext.lockOpenDrawer} style={{color: 'white', marginLeft: '25px'}}>
                            {
                                lockOpen ?
                                    <img src={PushPinFilledIcon} style={{width: 30, height: 30}}/>
                                :
                                    <img src={PushPinIcon} style={{width: 30, height: 30, transform: 'rotate(90deg)'}}/>
                            }
                        </IconButton>}
                    </div>
                    <div style={{height: '100%', width: '100%', display: 'block'}}> 
                        <Divider />
                        <ListMenuTop 
                            model={this.props.model}
                            loadDashboard={this.props.loadDashboard} 
                            dashboards={this.props.dashboards}
                            selectedDashboard={this.props.selectedDashboard}
                            menuContext={menuContext}
                            routeContext={routeContext}
                            errorContext={errorContext}/>
                        <ListMenuBottom
                            menuContext={menuContext}
                            defaultDashboard={this.props.defaultDashboard}/>
                    </div>
                </div>
            </Drawer>
        )
    }
}


SideMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideMenu);