import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import DateSelector from './DateSelector';
import Grid from "@material-ui/core/Grid";
import _ from 'lodash';
import {DateContext} from '../../contexts/DateContext';
import {ErrorContext} from '../../contexts/ErrorContext';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

const DateRangeSelectorComponent = (props) => {

    const dateContext = useContext(DateContext);
    const errorContext = useContext(ErrorContext);

    const [startDate, setStartDate] = useState(dateContext.startDate);
    const [endDate, setEndDate] = useState(dateContext.endDate);

    const [contextStartDate, setContextStartDate] = useState(dateContext.startDate);
    const [contextEndDate, setContextEndDate] = useState(dateContext.endDate);

    const onStartDateChange = (selectedDate, event) => {
        try {
            setStartDate(selectedDate.toISOString().split('T')[0]);
        } catch (err) {
            setStartDate(startDate);
        }
    }

    const onEndDateChange = (selectedDate, event) => {
        try {
            setEndDate(selectedDate.toISOString().split('T')[0]);
        } catch (err) {
            //Ignore errors. By not updating state, the date won't update to an invalid date
        }
    }

    const isValidDates = () => {
        return startDate <= endDate;
    }

    const isDirty = () => {
        return ((startDate != dateContext.startDate) || 
               (endDate != dateContext.endDate));
    }

    const getPastDays = (days, date) => {
        var newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() - days + 1);
        return newDate;
    }

    const applyUpdates = (timescale, startDate, endDate) => {
        var endDate = new Date(endDate);
        var startDate = new Date(startDate);
        var numDays = Math.round((endDate - startDate)/(1000*60*60*24)) + 1;
        var prevEndDate = getPastDays(1,startDate);
        var prevStartDate = getPastDays(numDays+1, prevEndDate);
        var realEndDate = getPastDays(0,endDate);
        dateContext.updateDates(
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0],
            prevStartDate.toISOString().split('T')[0],
            prevEndDate.toISOString().split('T')[0],
            realEndDate.toISOString().split('T')[0],
            numDays,
            timescale
        );
    }

    const clearChanges = () => {
        setStartDate(dateContext.startDate);
        setEndDate(dateContext.endDate);
    }

    useEffect( () => {
        if (dateContext.startDate != contextStartDate || dateContext.endDate != contextEndDate) {
            errorContext.updateValues({ errors: [] });
            setStartDate(dateContext.startDate);
            setEndDate(dateContext.endDate);
            setContextStartDate(dateContext.startDate);
            setContextEndDate(dateContext.endDate);
            applyUpdates(dateContext.timescale, dateContext.startDate, dateContext.endDate);
        }
    });

    useEffect( () => {
        if(props.history) {
            let query = new URLSearchParams(props.history.location.search);
            
            let timescale = query.get("timescale");
            let startDate = query.get("startDate");
            let endDate = query.get("endDate");

            if(props.history.action == "POP" && (timescale != dateContext.timescale || startDate != dateContext.startDate || endDate != dateContext.endDate)) {
                setStartDate(startDate);
                setEndDate(endDate);
                setContextStartDate(startDate);
                setContextEndDate(endDate);
                applyUpdates(timescale, startDate, endDate);
            }
        }
    });

    useEffect( () => {
        if(props.history) {
            let query = new URLSearchParams(props.history.location.search);
        
            let startDate = query.get("startDate");
            let endDate = query.get("endDate");

            if(props.history.action == "POP" && (startDate != dateContext.startDate || endDate != dateContext.endDate)) {
                setStartDate(startDate);
                setEndDate(endDate);
                setContextStartDate(startDate);
                setContextEndDate(endDate);
                applyUpdates(startDate, endDate);
            }
        }
    });

    return (
        <Grid container 
            spacing={1} 
            wrap={'nowrap'} 
            alignContent={'stretch'} 
            justify={'flex-start'} 
            alignItems={'flex-start'}
            margin={"0 8px 8px 0"} 
        >
            <Grid item style={{marginLeft:"-2px"}}>
                <DateSelector label="Start Date"
                    isDisabled={props.isDisabled} 
                    selectedDate={startDate}
                    error={!isValidDates()}
                    onChange={onStartDateChange}>
                </DateSelector>
            </Grid>
            <Grid item style={{marginLeft:"-4px"}}>
                <DateSelector label="End Date"
                    isDisabled={props.isDisabled} 
                    selectedDate={endDate}
                    error={!isValidDates()}
                    onChange={onEndDateChange}>
                </DateSelector>
            </Grid>
            {isDirty() && (
                <Grid item style={{marginTop:"10px", marginLeft:"-6px"}} >
                    <Button color="primary" variant="contained" disabled={!isValidDates()} onClick={ () => {applyUpdates('custom', startDate, endDate)}} >Apply</Button>
                </Grid>)}
            {isDirty() && (
                <Grid item style={{marginTop:"10px", marginLeft:"-3px"}}>
                    <Button variant="contained" onClick={clearChanges} >Clear</Button> 
                </Grid>)}
        </Grid>
    );
}

export default withStyles(styles)(DateRangeSelectorComponent);