import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { height, width, margin, colors, getColor, tooltipRef, extraData } = props;

    if(!colors) colors = {};

    const asDonut = extraData.find(a => a.name == "AsDonut")?.value;

    const radius = Math.round(Math.min(width - (margin.left + margin.right), height - (margin.top + margin.bottom)) / 2);

    const arc = d3.arc()
        .innerRadius(asDonut ? radius * 0.45 : 1)
        .outerRadius(radius * 0.98);

    const HoveredArc = d3.arc()
        .innerRadius(asDonut ? radius * 0.43 : 1)
        .outerRadius(radius);

    const asClass = str => str ? str.replaceAll(' ', '-') : "";
        
    const pie = d3.pie().value(d =>  d.value);
    const key = function(d){ return d.label; };

    const gId = 'slices-group';

    function draw() {
        let { data } = props;
        const valueSum = data.reduce((sum, d) => sum += d.value, 0);
        const primaryChart = d3.select(primaryChartRef.current);

        const g = primaryChart.select("#" + gId);

        var path = g.selectAll("path.slice")
            .data(pie(data), key)
            .join("path")
            .attr('d', arc)
            .style("fill", function (d) { 
                return colors[d.data.label] ? getColor(colors[d.data.label]) : '#A9A9A9';
            })
            .attr("class", d => "slice " + asClass(d.data.label))
            .attr("stroke", "white")
            .style("stroke-width", "2px")

        path.enter()
            .join("path")
            .attr('d', arc)
            .style("fill", function (d) { 
                return colors[d.data.label] ? getColor(colors[d.data.label]) : '#A9A9A9';
            })
            .attr("class", d => "slice " + asClass(d.data.label))
            .attr("stroke", "black")
            .style("stroke-width", "1px")

        var tooltip = d3.select(tooltipRef.current)
            .attr("class", "pie-chart-tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-color", "#ccc")
            .style("border-width", "1px")
            .style("padding", "10px")
            .style("position", "absolute")
            .style("z-index", 1001)
            .style("opacity", 0);

        path
            .on("mouseover", d => { 
                tooltip.style("display", null).style("opacity", 1); 
                const thisPath = g.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', HoveredArc)
            })
            .on("mouseout", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = g.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mouseleave", d => { 
                tooltip.style("display", "none").style("opacity", 0); 
                const thisPath = g.selectAll("path." + asClass(d.data.label))
                thisPath.attr('d', arc)
            })
            .on("mousemove", function(d) {
                tooltip.style("display", null); 
                tooltip.style("opacity", 1);

                let mouseLocation = d3.mouse(primaryChartRef.current.parentElement);
                tooltip.style('left', Math.abs(mouseLocation[0] + 10) + 'px')
                    .style('top',  Math.abs(mouseLocation[1]+ 10) + 'px')
                     
                const html = d.data.label + ': ' + d.data.value + ' (' + Math.round((d.data.value / valueSum)*100) + '%' + ')';
                tooltip.html(html);
            });

        path.exit().remove();
    }

    function init() {
        draw();
    }

    useEffect(init, [primaryChartRef]);

    return (
        <g id={gId}></g>
    )
})
