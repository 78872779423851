import React, { createContext, useState } from "react";
import moment from 'moment'
import _ from 'lodash'
import depth from '../assets/images/depth.png'
import flow from '../assets/images/wind.png'
import rain from '../assets/images/rainy-cloud.png'
import overflow from '../assets/images/stack-overflow.png'
import dry from '../assets/images/dry.png'
import { UserContext } from "./UserContext";
import { ModelContext } from "./ModelContext";
import { Notification, toaster } from 'rsuite';
import { getHeaders } from "../api/ApiWorker";
import ApiHelper from "../api/ApiHelper";

export const ExplorerContext = createContext({});

export const ExplorerProvider  = ({children}) => {


    const userContext = React.useContext(UserContext)
    const tenant = userContext.getTenant()

    const modelContext = React.useContext(ModelContext)

    const [charts, setCharts] = useState([{ id: 0 }])
    const [models, setModels] = useState([]);
    const [defaultModel, setDefaultModel] = useState({});
    const [observedModel, setObservedModel] = useState({});

    let translatedAttributes = {
        'rainfall': 'rainfall',
        'observed depth': 'depth',
        'observed flow': 'flow',
        'simulated depth': 'depth',
        'simulated flow': 'flow',
        'forecast depth': 'forecast/modeled',
        'forecast flow': 'forecast/modeled',
        'overflow level': 'structure-depth',
        'substituted rainfall': 'rainfall',
        'dry period': 'dry-days',
        'rainfall forecast': 'forecast/latest',
    }


    React.useEffect(() => {
            
        let newModels = modelContext.allModels.filter((model) => {
            model.tags && model.tags.forEach((tag) => {
                if (tag === 'Observed') setObservedModel(model)
            })
            if (model.extraData && model.extraData.DisableSelection) return false
            return true
        })
        setDefaultModel(modelContext.selectedModel)
        setModels(newModels)

    }, [])

    const getApiInfo = (asset, layer, attribute, model, startDate, endDate) => {
    
        let filters = {
            ...(attribute.includes('forecast') && {
                metric: attribute.includes('rainfall') ? 'rainfall' : (attribute.includes('depth')  || attribute.includes(' flow') ? attribute.split(' ')[1] : null),
            }),
            ...(!attribute.includes('forecast') && {
                startDate: startDate,
                endDate: endDate
            }),
            ...((attribute === 'rainfall' || attribute === 'simulated rainfall') && {
                useMainRainGauge: attribute === 'rainfall' ? true : false,
                useSubRainGauge: attribute === 'rainfall' ? false : true,
            }),
            ...((attribute.includes(' depth')) && {
                units: 'ft'
            }),
            ...((attribute.includes(' flow')) && {
                units: 'ft³/s'
            }),
    
        }
        
        let pattern = `https://app.stg.pipecast.io/pipecast/api/v2/[tenantName]/[model]/assets/[layerName]/[assetName]/timeseries/[timeSeriesName]?`

        Object.keys(filters).forEach((key, i) => {
            if (i !== 0) pattern += '&'
            pattern += key + `=[${key}]`
        })

        return {
            UrlPattern: pattern,
            Parameters: {
                tenantName: tenant.tenantName,
                model: model === null ? observedModel.name : model,
                layerName: layer,
                assetName: asset,
                timeSeriesName: translatedAttributes[attribute],
                attribute: attribute,
                ...(filters)
            },
            Options : {
                ...(attribute.includes(' forecast') && {
                    IsForecast : true,
                    IsModeled : true
                }),
                ...(attribute.includes(' simulated') && {
                    IsModeled : true
                }),
            }
        }
    }
    
    const getSeriesData = async (ApiInfo) => {
    
        try {

            let { UrlPattern, Parameters } = ApiInfo

            let url = new URL(UrlPattern);
        
            url = ApiHelper.fillFromObj(url, Parameters)

            const res = await fetch(url, {
                method: 'get',
                credentials: 'same-origin',
                headers: new Headers(getHeaders()),
            })

            if (res.ok) {

                let data = await res.json();

                data = Object.keys(data.data).map(key => {
                    return {
                        date: moment.tz(key, sessionStorage.getItem(TENANT_TIMEZONE)).format(),
                        value: data.data[key]
                    };
                }).sort((a, b) => {
                    return a.date - b.date;
                })

                return data

            } else {
                throw new Error(res.statusText);
            }
    
        } catch (err) {
            return null
        }

    }

    const findSeriesIndex = (seriesObjects, asset, attribute, model) => {

        let index = -1

        seriesObjects && seriesObjects.forEach((seriesConfig, i) => {
            if (asset === seriesConfig.asset && attribute === seriesConfig.attribute && (model === seriesConfig.model || (model === null && seriesConfig.model === 'Observed'))) index = i
        })
    
        return index
    }

    const getConfig = (ApiInfo, resData) => {

        let asset = ApiInfo.Parameters.assetName
        let layer = ApiInfo.Parameters.layerName
        let attribute = ApiInfo.Parameters.attribute
        let model = ApiInfo.Parameters.model

        let config = {
            'asset': asset,
            'layer': layer,
            'attribute': attribute,
            'model': model,
            'options': {},
            'data': resData
        }

        return config
    }

    const updateData = async (seriesObjects, startDate, endDate) => {

        // fill out seriesObjects with new date range :/
        let newSeriesObjects = _.cloneDeep(seriesObjects)
        let funcs = []
        for (let i in seriesObjects) {
            let obj = seriesObjects[i]
            if (obj === null) continue
            let ApiInfo = getApiInfo(obj.asset, obj.layer, obj.attribute, obj.model, startDate, endDate)
            funcs.push(getSeriesData(ApiInfo))
        }
        await Promise.all(funcs)
            .then((values) => {
                values.forEach((data, i) => {
                    let obj = seriesObjects[i]
                    if (data) {
                        obj.data = data
                        newSeriesObjects[i] = obj
                    }
                })
            })
            .catch(err => { console.log(err) })
        

        return newSeriesObjects
    }

    const getChartObject = (name, seriesObjects, properties, options) => {

        const { x, y, width, height } = properties
    
        let Api = {}
        let Colors = {}
        let Order = {}
        let Axis = {}
        let ChartType = {}
        let Options = {}
        seriesObjects.forEach((obj) => {
            let seriesName = options.assetIndependant ? getSeriesName(obj).split(' : ').splice(0, 1).join(' : ') : getSeriesName(obj)
            Api[seriesName] = getApiInfo(obj.asset, obj.layer, obj.attribute, obj.model, null, null)
            Colors[getSeriesName(obj)] = obj.options.color
            Order[getSeriesName(obj)] = getOrder(obj.attribute)
            Axis[getSeriesName(obj)] = [3, 4, 5].includes(getYAxis(obj.attribute)) ? 'left' : 'right'
            ChartType[getSeriesName(obj)] = getChartType(obj.attribute)
            Options[getSeriesName(obj)] = obj.options
        })
    
        return {
            "_id" : `LUUID(${name})`,
            "Title" : `${name}`,
            "Type" : "TimeSeries",
            "SubType" : "Self Service",
            "IsExpandable" : true,
            "IsDraggable" : false,
            "IsDownloadable" : true,
            "X" : x,
            "Y" : y,
            "Width" : width,
            "Height" : height,
            "Api" : Api,
            "Colors" : Colors,
            "LeftAxisLabel" : "",
            "RightAxisLabel" : "rainfall (in)",
            "Order" : Order,
            "Axis" : Axis,
            "ChartType" : ChartType,
            "Options" : Options,
        }
    }
    
    const getOrder = (attribute) => {
    
        let order = 0
        let allAttributes = getAttributes('')
    
        for (let i = 0; i < allAttributes.length; i++) {
            order += 10
            if (attribute === allAttributes[i]) return order
        }
    
        order += 10
        return order
    }
    
    const getDashStyle = (attribute) => {
        /*
            'Solid',
            'ShortDash',
            'ShortDot',
            'ShortDashDot',
            'ShortDashDotDot',
            'Dot',
            'Dash',
            'LongDash',
            'DashDot',
            'LongDashDot',
            'LongDashDotDot'
        */
        if (attribute.includes('rainfall forecast')) return 'Dot'
        if (attribute.includes('forecast')) return 'ShortDot'
        if (attribute.includes('simulated')) return 'LongDash'

        return 'Solid'
    }
    
    const getSuffix = (attribute) => {
    
        if (attribute.includes('rainfall')) return ' in'
        if (attribute.includes('overflow level')) return ' ft'
        if (attribute.includes(' depth')) return ' ft'
        if (attribute.includes(' flow')) return ' mdg'
    
        return ''
    }
    
    const getChartType = (attribute) => {
    
        switch (attribute) {
            case 'rainfall':
                return 'column'
            case 'substituted rainfall':
                return 'column'
            case 'dry period':
                return 'arearange'
            case 'overflow level':
                return 'line'
            case 'rainfall forecast':
                return 'column'
            default:
                return 'line'
        }
    }

    const getYAxis = (attribute) => {

        if (attribute.includes('rain')) return 2
        if (attribute.includes('dry period')) return 1
        if (attribute.includes(' depth')) return 3
        if (attribute.includes(' flow')) return 4
    
        return 0
    }
    
    const getSeriesName = (seriesObject) => {
    
        let name = seriesObject.asset + ' : ' + seriesObject.attribute
    
        if (seriesObject.attribute.includes('simulated')) {
            name += (' : ' + seriesObject.model)
        }
    
        return name
    }
    
    const getAttributes = (layer) => {
    
        let attributes
        switch (layer) {
            case 'Regulators':
                attributes = [
                    'observed depth',
                    'simulated depth',
                    'forecast depth',
                    'overflow level',
                    'rainfall',
                    'substituted rainfall',
                    'rainfall forecast',
                    'dry period',
                ]
                break
            case 'RainGauges':
                attributes = [
                    'rainfall',
                    'substituted rainfall',
                    'rainfall forecast',
                ]
                break
            case 'Flow Meters':
                attributes = [
                    'observed depth',
                    'simulated depth',
                    'forecast depth',
                    'observed flow',
                    'simulated flow',
                    'forecast flow',
                    'rainfall',
                    'substituted rainfall',
                    'rainfall forecast',
                ]
                break
            default:
                attributes = [
                    'observed depth',
                    'simulated depth',
                    'forecast depth',
                    'observed flow',
                    'simulated flow',
                    'forecast flow',
                    'overflow level',
                    'rainfall',
                    'substituted rainfall',
                    'rainfall forecast',
                    'dry period',
                ]
                break
        }
    
        return attributes
    }

    const getMetric = (attribute) => {

        let metric = null

        if (attribute.includes('depth')) metric = 'depth'
        if (attribute.includes('flow')) metric = 'flow'
        if (attribute.includes('rainfall')) metric = 'rainfall'

        return metric
    }

    const removeChart = (id) => {
        setCharts(charts.filter((chart) => chart.id !== id))
    }

    const addChart = () => {
        let newCharts = [...charts]
        newCharts.push({
            id: charts[charts.length - 1].id + 1
        })
        setCharts(newCharts)
    }

    const getSizingStyle = (index) => {

        let sizeStyles 

        sizeStyles = {
            height: '50%',
            width: '50%',
        }

        if (charts.length - charts.length %4 <= index)  {
            if (charts.length % 4 === 1) { // one chart
                sizeStyles.height = '100%'
                sizeStyles.width = '100%'
            } else if (charts.length % 4 === 2) { // both have to be the same
                sizeStyles.width = '100%'
                return sizeStyles
            } else if (charts.length % 4 === 3 && index % 4 === 2) {
                sizeStyles.width = '100%'
            }
        }

        return sizeStyles
    }

    const getIcon = (attribute) => {

        let ICONS = {
            'observed depth': depth,
            'simulated depth': depth,
            'forecast depth': depth,
            'observed flow': flow,
            'simulated flow': flow,
            'forecast flow': flow,
            'rainfall': rain,
            'overflow level': overflow,
            'substituted rainfall': rain,
            'dry period': dry,
            'rainfall forecast': rain
        }
    
        return ICONS[attribute]
    }

    const openNotification = (result, action) => {

        let actionSuccessMap = {
            'save': 'your view has been saved',
            'load': 'your view has been loaded',
            'delete': 'your view has been deleted',
            'edit-series': 'series display has been updated successfully',
            'ref': 'reference line added successfully',
            'chart': 'item successfully added'
        }
    
        let actionErrorMap = {
            'save': 'you must give this view a name',
            'load': 'you must pick a view',
            'delete': 'you must pick a view',
            'edit-series': 'you must pick a series to apply changes to',
            'ref': 'all fields must contain a value, or be of the correct value type',
            'general': 'something went wrong',
            'press-views': 'you have no saved views',
            'chart': 'could not load data for this item'
        }
    
        let message = (
            <Notification type={result} header={result} closable>
                <span> {result === 'success' ? actionSuccessMap[action] : actionErrorMap[action]} </span>
            </Notification>
        )
    
        toaster.push(message, { placement: 'topEnd'})
    }
    
    return (
        <ExplorerContext.Provider value={{ models, defaultModel, observedModel, openNotification, getIcon, charts, addChart, removeChart, getSizingStyle, getApiInfo, getSeriesData, findSeriesIndex, getConfig, updateData, getOrder, getChartObject, getDashStyle, getSuffix, getChartType, getYAxis, getSeriesName, getAttributes, getMetric}}>
            {children}
        </ExplorerContext.Provider>
    )
}