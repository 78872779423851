import React, {useEffect, useContext} from 'react';
import _ from 'lodash';
import { withStyles } from '@material-ui/styles';
import { UserContext } from '../../contexts/UserContext';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '../../img/copy_two_tone.svg';
import { getAPIVersion } from '../../api/ApiWorker';
import ApiHelper from '../../api/ApiHelper';

const styles = theme => ({
    header: {
        fontSize: '24px', 
        lineHeight: '28px', 
        letterSpacing: '0.25px', 
        margin: '0px 16px 16px 16px'
    },
    subHeader: {
        top: '44px', 
        fontSize: '18px', 
        lineHeight: '24px', 
        margin: '16px'
    },
    contactButton: {
        position: 'relative', 
        width: '246px', 
        height: '40px', 
        background: '#FFFFFF', 
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '200px', 
        margin: '16px'
    },
    mailLink: {
        cursor: 'pointer',
        position: 'absolute', 
        width: '218px', 
        height: '16px',  
        fontWeight: '500', 
        fontSize: '14px', 
        lineHeight: '16px', 
        letterSpacing: '0.75px', 
        textTransform: 'uppercase', 
        color: '#556EB5',
        textDecoration: 'none', 
        left: '14px', 
        right: '14px', 
        top: 'calc(50% - 16px/2)'
    },
    emailDirectly: {
        fontSize: '18px', 
        lineHeight: '24px', 
        margin: '16px'
    },
    versionContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'flex-start', 
        position: 'static', 
        width: '190px',
        background: 'rgba(255, 255, 255, 0.7)', 
        borderRadius: '10px', 
        marginLeft: '16px'
    },
    versionNumbers: {
        position: 'static', 
        width: '160px', 
        height: '40px', 
        fontSize: '14px', 
        lineHeight: '20px', 
        marginLeft: '10px'
    },
    versionButton: {
        borderRadius: '10px', 
        backgroundColor: 'transparent', 
        color: '#556EB5', 
        fontSize: '14px', 
        lineHeight: '20px', 
        fontWeight: 'bold', 
        padding: '5px 16px', 
        marginTop: '7px'
    }
});

const HelpAndFeedback = (props) => {
    const {classes} = props;

    const userContext = useContext(UserContext);

    // Mount
    useEffect( () => {
        const user = userContext.getTenant();
        const {tokenParsed} = userContext.currentUser;
        const firstName = tokenParsed.given_name;
        const lastName = tokenParsed.family_name;

        getAPIVersion().then(value => {
            document.getElementById('mail_link').setAttribute('href', `mailto:support@pipecast.io?subject=Support Request for ${firstName} ${lastName} ${user.tenantName} &body=%20%0A%20%0A--- Please type your message above this line ---%0ADashboard%3A %23 ${ApiHelper.getDashboardVersion()} %0AAPI%3A%20%23 ${value.toString()} %0A%20`);
            document.getElementById('version_numbers').innerHTML = `Dashboard: #${ApiHelper.getDashboardVersion()} <br>Api: # ${value.toString()}`;
        });
    }, []);

    // Tenant Change
    useEffect( () => {
        const user = userContext.getTenant();
        const {tokenParsed} = userContext.currentUser;
        const firstName = tokenParsed.given_name;
        const lastName = tokenParsed.family_name;

        getAPIVersion().then(value => {
            document.getElementById('mail_link').setAttribute('href', `mailto:support@pipecast.io?subject=Support Request for ${firstName} ${lastName} ${user.tenantName} &body=%20%0A%20%0A--- Please type your message above this line ---%0ADashboard%3A %23 ${ApiHelper.getDashboardVersion()} %0AAPI%3A%20%23 ${value.toString()} %0A%20`);
        });
    }, [userContext.tenant]);

    function copyToClipboard() {
        var r = document.createRange();
        r.selectNode(document.getElementById('version_numbers'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    return ( 
        <>
            <div className={classes.header}>
                <span>
                    Is something not working right?
                </span>
            </div>
            <div className={classes.subHeader}>
                <span>
                    Our team is committed to PipeCAST’s continual improvement and availability.
                </span>
                <br />
                <span>
                    If you’re having issues with PipeCAST, please let us know right away. We’ll review your message and follow-up with you as soon as possible.
                </span>
            </div>
            <div className={classes.contactButton}>
                <a id='mail_link' className={classes.mailLink}>
                    Contact PipeCAST Support
                </a>
            </div>
            <div className={classes.emailDirectly}>
                <span>
                    You can also email us directly at <a href='mailto:support@pipecast.io' style= {{cursor: 'pointer', color: '#556EB5'}}>support@pipecast.io</a>.
                </span>
                <br />
                <span>
                    Please include the version numbers below in your initial message.
                </span>
            </div>
            <div className={classes.emailDirectly}>
                
            </div>
            <div className={classes.versionContainer}>
                <span id='version_numbers' className={classes.versionNumbers}></span>
            </div>
            <div>
                <IconButton className={classes.versionButton} onClick={() => {copyToClipboard()}}>
                    <img src={CopyIcon} style={{cursor: 'pointer', width: 16, height: 16}}/> Copy Version Numbers
                </IconButton>
            </div>
        </>
    )
}
export default withStyles(styles)(HelpAndFeedback);