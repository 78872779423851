import React, {useState, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {ModelContext} from "../../contexts/ModelContext";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginRight: 24,
        marginLeft: 0,
        paddingTop: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        borderRadius: theme.shape.borderRadius,
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(5),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
});

const SearchBar = (props) => {
    const [value, setValue] = useState('');

    useEffect( () => {
        props.setFilter(value);
    }, [value]);

    const handleChange = event => {
        setValue(event.target.value);
    }

    return (
        <div className={props.customStyle || props.classes.search}>
            <TextField placeholder={props.placeholderText || ''}
                onChange={handleChange}
                classes={{
                    root: props.classes.inputRoot,
                }}
                value={value}
                variant="outlined"
                InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon /></InputAdornment>,
                }}/>
        </div>
    );
}

export default withStyles(styles)(SearchBar);