import React from "react";
import "./css/App.css";
import ModelContextProvider from "./contexts/ModelContext";
import ThemeContextProvider from "./contexts/ThemeContext";
import UserContextProvider, { UserContext } from "./contexts/UserContext";
import MenuContextProvider from "./contexts/MenuContext";
import DateContextProvider from './contexts/DateContext';
import ErrorContextProvider from './contexts/ErrorContext';
import SnackbarContextProvider from './contexts/SnackbarContext';
import {DateContext} from './contexts/DateContext';
import RouteContextProvider, { RouteContext } from './contexts/RouteContext';
import Pipecast from "./components/Pipecast";
import Keycloak from 'keycloak-js';
import { getKeycloakIdentity } from '../src/api/ApiWorker';
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            keycloak: null
        };

        queryClient.setDefaultOptions({
            queries: {
                staleTime: 1000 * 60 * 5,
            },
        });

        // queryClient.setDefaultOptions({
        //     queries: {
        //         staleTime: Infinity,
        //         refetchOnWindowFocus: false,
        //         cacheTime: 0,
        //     },
        // });
    }

    componentDidMount() {
        getKeycloakIdentity().then( (value) => {
            this.setState({
                keycloak: new Keycloak ({ url:value.url, realm:value.realm, clientId:value.clientId })
            });
        });
    }

    render() {
        const {keycloak} = this.state;
        
        if (keycloak != null) {
            return (     
                <div className = "App">      
                    <QueryClientProvider client={queryClient}> 
                        <RouteContextProvider>
                            <RouteContext.Consumer>
                                {(routeContext) => (
                                    <DateContextProvider>
                                        <DateContext.Consumer>
                                            {(date) => (
                                                <SnackbarContextProvider>
                                                    <ErrorContextProvider date={date}>
                                                            <ThemeContextProvider>
                                                                <UserContextProvider routeContext={routeContext} keycloak={keycloak} initConfig={{onLoad: 'login-required', timeSkew: 0}}>
                                                                    <MenuContextProvider>
                                                                        <UserContext.Consumer>{
                                                                            (userContext) => {
                                                                                if(userContext.authenticated && userContext.users.length > 0) {
                                                                                    return (
                                                                                    <ModelContextProvider userContext={userContext} date={date} routeContext={routeContext} >
                                                                                        <Pipecast userContext={userContext} tenant={userContext.tenant} />
                                                                                    </ModelContextProvider>
                                                                                    );
                                                                                }
                                                                            }
                                                                        }</UserContext.Consumer>
                                                                    </MenuContextProvider>
                                                                </UserContextProvider>
                                                            </ThemeContextProvider>
                                                    </ErrorContextProvider>
                                                </SnackbarContextProvider>
                                            )}
                                        </DateContext.Consumer>
                                    </DateContextProvider>
                                )}
                            </RouteContext.Consumer>
                        </RouteContextProvider>
                    </QueryClientProvider> 
                </div>  
            );
        }
        else {
            return null;
        }
    };
}

export default App;
