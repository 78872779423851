import React, { useEffect, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import _ from 'lodash';

export default forwardRef((props, primaryChartRef) => {
    let { series, scales, dataProp, clipPathId, colors, getColor, domain } = props;

    if(!dataProp) dataProp = 'Data';

    const seriesName = series.Name.split(' ').join('-').split('.').join('-');

    function drawBarSeries(newScales, hiddenSeriesList = []) {        
        scales = newScales;

        const seriesData = series[dataProp];        

        draw(seriesData, hiddenSeriesList);
    }

    drawBarSeries.isSeries = true;

    function draw(seriesData, hiddenSeriesList = []) {
        const primaryChart = d3.select(primaryChartRef.current);
        
        const scale = scales.yScale;

        const keys = domain.map(d => d.tooltipLabel);
        const colorsByKey = {};
        
        domain.forEach(d => {
            colorsByKey[d.tooltipLabel] = colors[d.colorLabel];
        });
    
        const g1 = primaryChart.select("#" + seriesName);

        g1.selectAll("g.Date").selectAll("rect").remove();

        const g2 = g1.append("g")
            .attr("class", "Date")
            .selectAll("g.Date")
            .data(seriesData)
            .enter().append("g")
            .attr("class", "Date")
            .attr("transform", d => {
                var scaledValue = scales.x0Scale(d.Date);
                if(!scaledValue) scaledValue = 0;
                return `translate(${scaledValue},0)`;
            });

        g2.selectAll("rect")
            .data(d => keys.map(key => { return { key: key, value: d[key] }; }))
            .enter().append("rect")
                .attr("fill", d => { 
                    return getColor(colorsByKey[d.key])
                })
                .attr("clip-path", "url(#" + clipPathId + ")")
                .style("opacity", .9)
                .attr("x", d => scales.x1Scale(d.key))
                .attr("y", d => scale(d.value))
                .attr("width", scales.x1Scale.bandwidth())
                .attr("height", d => scale(0) - scale(d.value))
                .attr("visibility", d => hiddenSeriesList.indexOf(d.key) > -1 ? "hidden" : "visible");
    }

    function init() {
        primaryChartRef.current.redrawFns.push(drawBarSeries);        
   
        draw(series[dataProp]);
    }

    useEffect(init, [primaryChartRef]);
    useEffect(() => draw(series[dataProp]), [scales]);

    return (
        <g id={seriesName}></g>
    )
})
