import clsx from "clsx";
import React, { PureComponent } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { createStyles } from "@material-ui/styles";
import { IconButton, withStyles, InputAdornment } from "@material-ui/core";
import { subDays, addDays, format, isValid, isSameDay, startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getValidSensorDates } from "../../api/ApiWorker";

const styles = createStyles(theme => ({
    dayWrapper: {
        position: "relative",
    },
    day: {
        width: 36,
        height: 36,
        fontSize: 12,
        margin: "0 2px",
        color: "inherit",
    },
    customDayHighlight: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "2px",
        right: "2px",
        border: "1px solid #ACACAC",
        borderRadius: "50%",
    },
    nonCurrentMonthDay: {
        color: 'gray',
    },
    highlightNonCurrentMonthDay: {
        color: '#676767',
    },
    nonSelectableDate: {
        color: 'gray',
    },
    highlightNonSelectableDate: {
        color: '#676767',
    },
    dateWithData: {
        color: 'blue'
    },
    nonCurrentMonthDateWithData: {
        color: '#8080FF'
    },
    highlight: {
        background: '#ACACAC'
    },
    firstHighlight: {
        extend: "highlight",
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
    },
    endHighlight: {
        extend: "highlight",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
    }
}));

class WeeklySelector extends PureComponent{
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const {dateContext} = this.props;
        const {validDates, validDatesError} = dateContext;

        if (!_.isEmpty(validDates)) {
            this.handleWeekChange(validDates[validDates.length - 1]); //Default to earliest date if available
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {dateContext, modelContext, userContext, currentDashboard} = this.props;

        const {validDates, validDatesError} = dateContext;
        const {baseModel, selectedFeature} = modelContext;

        const tenantName = userContext.getTenant().tenantName;
        const modelName = baseModel.name;
        const defaultLayerName =  !_.isEmpty(currentDashboard.limitLayers) ? currentDashboard.limitLayers[0] : null;
        const layerName = !_.isEmpty(defaultLayerName) ? defaultLayerName : !_.isEmpty(baseModel.layers) ? baseModel.layers[0].layerName : null;
        const {dataMetric, dateRangeLimit} = currentDashboard;

        if (_.isEmpty(validDates) && !validDatesError && !!tenantName && !!modelName && !!layerName && !!dataMetric && !!dateRangeLimit)
        {
            return new Promise((resolve, reject) => {
                getValidSensorDates(tenantName, modelName, layerName, dataMetric, dateRangeLimit).then(dates => {
                    dateContext.updateValues({
                        validDates: dates,
                        validDatesError: null
                    });
                    if (!_.isEmpty(dates)) {
                        this.handleWeekChange(dates[dates.length - 1]);
                    } else {
                        this.handleWeekChange(new Date());
                    }
                }).catch((err) => {
                    dateContext.updateValues({
                        validDates: [],
                        validDatesError: 'Error retrieving valid dates.'
                    });
                });
            });
        }

        if(!_.isEmpty(dateContext.validDates) && !_.isEqual(dateContext.validDates, prevProps.dateContext.validDates)) {
            this.handleWeekChange(dateContext.validDates[dateContext.validDates.length - 1]); // Defaults to most recent date with data available (dates sorted asc)
        }
    }

    handleWeekChange = date => {
        //Set date as selected day
        date = new Date(date);
        this.setState({ selectedDate: date });

        // Calculate start/end dates for date context
        // This isn't right. Need to correctly format start/end prevStart/prevEnd date ranges (off because of timezone)
        var start = startOfWeek(date);
        var end = endOfWeek(date);
        var prevStart = subDays(start, 7);
        var prevEnd = subDays(end, 7);
        var realEndDate = addDays(end, 1);

        this.props.dateContext.updateDates(
            moment.tz(start, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD'),
            moment.tz(end, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD'),
            moment.tz(prevStart, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD'),
            moment.tz(prevEnd, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD'),
            moment.tz(realEndDate, sessionStorage.getItem(TENANT_TIMEZONE)).format('YYYY-MM-DD'),
            7,
            'custom'
        );
    };

    formatWeekSelectLabel = (date, invalidLabel) => {
        let dateClone = new Date(date);

        return dateClone && isValid(dateClone)
            ? `${format(dateClone, "MM/dd/yyyy")}`
            : invalidLabel;
    };

    renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth) => {
        const { classes, dateContext } = this.props;
        let dateClone = new Date(date);
        let selectedDateClone = new Date(selectedDate);

        const start = startOfWeek(selectedDateClone);
        const end = endOfWeek(selectedDateClone);

        const dayIsBetween = isWithinInterval(dateClone, { start, end });
        const isFirstDay = isSameDay(dateClone, start);
        const isLastDay = isSameDay(dateClone, end);

        const firstValidDate = !_.isEmpty(dateContext.validDates) ? startOfWeek(new Date(dateContext.validDates[0])) : new Date("1/1/1970");
        const lastValidDate = !_.isEmpty(dateContext.validDates) ? endOfWeek(new Date(dateContext.validDates[dateContext.validDates.length - 1])) : new Date();

        const wrapperClassName = clsx({
            [classes.highlight]: dayIsBetween,
            [classes.firstHighlight]: isFirstDay,
            [classes.endHighlight]: isLastDay,
        });
        
        const dayClassName = clsx(classes.day, {
            [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
            [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
            [classes.nonSelectableDate]: !isWithinInterval(dateClone, {start: firstValidDate, end: lastValidDate}),
            [classes.highlightNonSelectableDate]: !isWithinInterval(dateClone, {start: firstValidDate, end: lastValidDate}),
            [classes.dateWithData]: dateContext.validDates.some(x => isSameDay(dateClone, new Date(x))),
            [classes.nonCurrentMonthDateWithData]: !dayInCurrentMonth && dateContext.validDates.some(x => isSameDay(dateClone, new Date(x)))
        });

        return (
            <div className={wrapperClassName}>
                <IconButton className={dayClassName}>
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    };

    render() {
        const { selectedDate } = this.state;
        const { dateContext, themeContext } = this.props;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils} >
                <DatePicker
                    format="MM/dd/yyyy"
                    label="Display Week"
                    value={selectedDate}
                    onChange={this.handleWeekChange}
                    renderDay={this.renderWrappedWeekDay}
                    labelFunc={this.formatWeekSelectLabel}
                    minDate={!_.isEmpty(dateContext.validDates) ? startOfWeek(new Date(dateContext.validDates[0])) : new Date("1/1/1970")}
                    maxDate={!_.isEmpty(dateContext.validDates) ? endOfWeek(new Date(dateContext.validDates[dateContext.validDates.length - 1])) : new Date()}
                    style={{
                        width: '118px',
                        float: 'right'
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <DateRangeTwoToneIcon style={{color: themeContext.getColor('primary.Primary')}} />
                            </InputAdornment>
                        )
                    }}
                />
            </MuiPickersUtilsProvider>
        );
    }
}

export default withStyles(styles)(WeeklySelector);