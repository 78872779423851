import React from 'react';
import OperationsFeaturePanel from './featurepanel/OperationsFeaturePanel';
import OperationsDashboardFrame from "./OperationsDashboardFrame";
import TopMenu from "./topmenu/TopMenu";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import AlertContextProvider from '../contexts/AlertContext';
import {AlertContext} from '../contexts/AlertContext';
import { ErrorContext } from '../contexts/ErrorContext';
import DashboardFrame from './DashboardFrame';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        position: 'relative',
        display: 'flex',
    }
});

class OperationsDashboard extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            layout: [],
        };
    }

    componentDidMount() {
        const {userContext, routeContext, model, match} = this.props;

        const tenant = userContext.getTenant();
        routeContext.updateValues({
            tenant: tenant.tenantName,
            pageType: 'dashboards',
            layoutType: 'operations',
            viewType: match.params.viewType,
            assetName: match.params.assetName
        });
    }

    //Since performance & o&m dashboards are currently sharing the same model context,
    //clear the ModelContext when selecting an asset on o&m and then switching back to the performance dashboard & vice versa
    componentWillUnmount() {
        this.props.model.updateValues({
            selectedItem: null,
            highlightedAsset: null
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {layout, dashboards, selectedDashboard, match, model, routeContext, userContext, history} = this.props;
        const {baseModel, selectedModel} = model;

        const currentModel = selectedDashboard && selectedDashboard.baseModelExclusive ? baseModel : selectedModel;

        if (layout != this.state.layout)
        {
            this.setState({layout: layout});
        }

        if (currentModel && currentModel.layers.length > 0 && (!model.selectedFeature || _.isEmpty(model.selectedFeature)))
        {
            const layerName = match.params.layerName || null;
            const layer = layerName ? (currentModel.layers.find(x => x.layerName == layerName) || currentModel.layers[0]) : currentModel.layers[0];
            model.updateValues({
                selectedFeature: layer
            });
        }

        if (routeContext.state.tenant && match.params.tenantName && !_.isEqual(routeContext.state.tenant, match.params.tenantName))
        {
            const newTenant = userContext.users.find(u => u.tenantName == match.params.tenantName);
            userContext.switchTenant(newTenant.id);
            routeContext.updateValues({
                tenant: newTenant.tenantName
            });
        }

        if (dashboards.length > 0 && (selectedDashboard == null || (match.params.viewType != prevProps.match.params.viewType))) {
            switch (match.params.viewType)
            {
                case 'Overview':
                    const OMDashboard = dashboards.find(x => x.dashboardName == 'O&M');
                    if(OMDashboard) {
                        this.props.loadDashboard(OMDashboard.dashboardCards, OMDashboard);
                    }
                    break;
                case 'Event':
                    const EventDashboard = dashboards.find(x => x.dashboardName == 'Event');
                    if(EventDashboard) {
                        this.props.loadDashboard(EventDashboard.dashboardCards, EventDashboard);
                    }
                    break;
                case 'SSO':
                    const ssoDashboard = dashboards.find(x => x.dashboardName == 'SSO Dashboard');
                    if(ssoDashboard) {
                        this.props.loadDashboard(ssoDashboard.dashboardCards, ssoDashboard);
                    }
                    break;
                default:
                    history.push('/unknown');
            }
        }
    }

    render(){
        const { classes, date, match, history, location, model, userContext, menuContext, themeContext, routeContext, dashboards, selectedDashboard, mapType, ...rest } = this.props;

        var panelWidth = "0vw";
        var panelType = match.params.viewType;
        var panelTabs = [];

        switch (match.params.viewType)
        {
            case 'Overview': //O&M Map-List View
                panelWidth = "40vw";
                panelTabs= ['List', 'Map'];
                break;
            case 'Event': //O&M Event view for selected asset
                panelWidth = "25vw";
                panelTabs = ['Events'];
                break;
            case 'SSO': //SSO view for selected asset
                panelWidth = "0vw";
                panelTabs = ['SSO'];
                break;
            default:
                history.push('/unknown');
        }

        const { collapsedDrawerWidth } = menuContext;

        const drawerWidth = menuContext.state.lockOpen ? menuContext.state.drawerWidth : collapsedDrawerWidth;
        var frameOffset = "calc(" + panelWidth + " + " + drawerWidth + "px)"; 
        var frameWidth = "calc(100vw - " + drawerWidth + "px - " + panelWidth + ")";

        return (
            <ErrorContext.Consumer>
                {(errorContext) => (
                    <AlertContextProvider users={userContext.users} tenant={userContext.tenant} date={date}>
                        <AlertContext.Consumer>
                            {(alertContext) => (
                            <div className={classes.root}>
                                <TopMenu
                                    selectedDashboard={selectedDashboard}
                                    displayFeatureSelector={false}
                                    menuContext={menuContext}
                                    match={match}
                                    location={location}
                                    history={history}
                                    alertContext={alertContext}>
                                </TopMenu>
                                { match.params.viewType != 'SSO' && <OperationsFeaturePanel 
                                    loadDashboard={this.props.loadDashboard}
                                    dashboards={this.props.dashboards}
                                    panelWidth={panelWidth}
                                    panelType={panelType}
                                    panelTabs={panelTabs}
                                    context={this.context}
                                    menuContext={menuContext}
                                    dateContext={date}
                                    themeContext={themeContext}
                                    tenant={userContext.tenant}
                                    match={match}
                                    location={location}
                                    history={history}
                                    alertContext={alertContext}
                                    errorContext={errorContext}
                                    routeContext={routeContext}
                                    userContext={userContext}
                                    model={model}/> }
                                { match.params.viewType != 'SSO' && <OperationsDashboardFrame 
                                    frameOffset={frameOffset}
                                    frameWidth={frameWidth}
                                    mapType={mapType}
                                    menuContext={menuContext}
                                    importLayout={this.state.layout}
                                    alertContext={alertContext}
                                    history={history}/> }
                                { match.params.viewType == 'SSO' && <DashboardFrame 
                                        selectedDashboard={selectedDashboard}
                                        menuContext={menuContext}
                                        routeContext={routeContext}
                                        frameOffset={frameOffset}
                                        frameWidth={frameWidth}
                                        importLayout={this.props.layout}
                                        snackbarContext={this.props.snackbarContext}
                                        /> 
                                }
                            </div>
                            )}
                        </AlertContext.Consumer>
                    </AlertContextProvider>
                )}
            </ErrorContext.Consumer>
        );
    }
}

OperationsDashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const OperationsDashboardComponent = withStyles(styles, { withTheme: true })(OperationsDashboard);
function useDesktopLayout(props) {
    const matches = useMediaQuery('(min-width:1367px)');
    return (<OperationsDashboardComponent{...props} isDesktop={matches}></OperationsDashboardComponent>);
}

export default useDesktopLayout;