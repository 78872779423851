import React, { useState,useEffect,useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import {useLocation} from 'react-router-dom';
import {DateContext} from '../../contexts/DateContext';
import {ModelContext} from '../../contexts/ModelContext';
import {RouteContext} from '../../contexts/RouteContext';

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        height: "100%",
        margin: "0 8px 6px 0"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

//A bit hacky, but putting the appropriate calls to update the parent state in the menu items triggers a re-render of the menu items, 
//which then re-renders the parent state and creates an infinite loop. For this reason, the list item values have to be mapped 
//individually and then called after the selection
const listItemValues = {
    PAST7: '1',
    PAST30: '2',
    PAST365: '3',
    YTD: '4',
    CUSTOM: '5',
    PAST7_FCST2: '6'
}

const today = new Date();

// for easy testing
// const today = new Date('9/7/2017');

const TimeScaleSelector = (props) => {
    const [filter, setFilter] = useState('5');
    const [query, setQuery] = useState(new URLSearchParams(useLocation().search));

    const dateContext = useContext(DateContext);
    const modelContext = useContext(ModelContext);
    const routeContext = useContext(RouteContext);

    const handleChange = event => {
        setFilter(event.target.value);
        let value = '';
        let numDays = 0;
        let timePeriod = '';
        let endDate = today;
        switch (event.target.value) {
            case listItemValues.PAST7:
                value = getPastDays(7, today);
                numDays = 7;
                timePeriod = 'past7';
                break;
            case listItemValues.PAST7_FCST2:
                var thisToday = new Date();
                thisToday.setDate(thisToday.getDate() + 2);
                endDate = thisToday;
                value = getPastDays(9, thisToday);
                numDays = 9;
                timePeriod = 'past7fcst2';
                break;
            case listItemValues.PAST30:
                value = getPastDays(30, today);
                numDays = 30;
                timePeriod = 'past30';
                break;
            case listItemValues.PAST365:
                value = getPastDays(365, today);
                numDays = 365;
                timePeriod = 'past365';
                break;
            case listItemValues.YTD:
                value = getYearToDate();
                var difference_ms = today - value;
                const one_day = 1000 * 60 * 60 * 24;
                var daysBetween = Math.round(difference_ms / one_day) + 1;
                var prevStartDate = new Date(today.getFullYear() - 1, 0, 1);
                var prevEndDate = new Date(today.getFullYear() - 1,
                    today.getMonth(),
                    today.getDate());
                prevEndDate = getPastDays(0, prevEndDate);
                var realEndDate = getPastDays(0, today);
                dateContext.updateDates(value.toISOString().split('T')[0],
                    today.toISOString().split('T')[0],
                    prevStartDate.toISOString().split('T')[0],
                    prevEndDate.toISOString().split('T')[0],
                    realEndDate.toISOString().split('T')[0],
                    daysBetween,
                    'ytd'
                );
                break;
            case listItemValues.CUSTOM:
                value = getCustomDateRange();
        }
        endDate = new Date(endDate);

        if (value && event.target.value != listItemValues.CUSTOM
            && event.target.value != listItemValues.YTD) {
            prevEndDate = getPastDays(1, value);
            prevStartDate = getPastDays(numDays + 1, prevEndDate);
            realEndDate = getPastDays(0, endDate);
            dateContext.updateDates(value.toISOString().split('T')[0],
                endDate.toISOString().split('T')[0],
                prevStartDate.toISOString().split('T')[0],
                prevEndDate.toISOString().split('T')[0],
                realEndDate.toISOString().split('T')[0],
                numDays,
                timePeriod
            );
        }
    }

    useEffect( () => {
        let value;
        let startDate = today;
        let endDate = today;
        let timescale = query.get('timescale') ? query.get('timescale') : 'past7';
        switch(timescale) {
            case 'past7':
                startDate = getPastDays(7, today);
                value = listItemValues.PAST7;
                break;
            case 'past7fcst2':
                var thisToday = new Date();
                thisToday.setDate(thisToday.getDate() + 2);
                endDate = thisToday;
                startDate = getPastDays(9, thisToday);
                value = listItemValues.PAST7_FCST2;
                break;
            case 'past30':
                startDate = getPastDays(30, today);
                value = listItemValues.PAST30;
                break;
            case 'past365':
                startDate = getPastDays(365, today);
                value = listItemValues.PAST365;
                break;
            case 'ytd':
                let difference_ms = today - new Date('01/01/' + new Date().getFullYear()).getTime();
                startDate = getPastDays(Math.round(difference_ms / (1000*60*60*24)) + 1, today);
                value = listItemValues.YTD;
                break;
            default:
                startDate = query.get('startDate');
                endDate = query.get('endDate');
                value = listItemValues.CUSTOM;
        }

        endDate = new Date(endDate);
        startDate = new Date(startDate);
        let numDays = Math.round((endDate - startDate)/(1000*60*60*24)) + 1;
        let prevEndDate = getPastDays(1, startDate);
        let prevStartDate = getPastDays(numDays + 1, prevEndDate);
        let realEndDate = getPastDays(0, endDate);
        dateContext.updateDates(
            startDate.toISOString().split('T')[0],
            endDate.toISOString().split('T')[0],
            prevStartDate.toISOString().split('T')[0],
            prevEndDate.toISOString().split('T')[0],
            realEndDate.toISOString().split('T')[0],
            numDays,
            timescale
        );

        setFilter(value);
        
        // Setting the action to a PUSH here because when changing the URL by hand,
        // the mount records the action as a POP, but it's needed to be a push.
        props.history.action = 'PUSH';
    }, []);
    
    useEffect( () => {
        if(dateContext.timescale && dateContext.startDate && dateContext.endDate && props.location.search) {
            let baseUrl = props.match.url; //Current matching route (defined in PipecastLoader)

            let query = new URLSearchParams(props.location.search);
            let timescale = props.history.action == 'POP' ? query.get('timescale') : dateContext.timescale;
            let startDate = props.history.action == 'POP' ? query.get('startDate') : timescale == 'custom' ? dateContext.startDate : null;
            let endDate = props.history.action == 'POP' ? query.get('endDate') : timescale == 'custom' ? dateContext.endDate : null;
            
            let startDateString = startDate ? '&startDate=' + startDate : '';
            let endDateString = endDate ? '&endDate=' + endDate : '';
            let timescaleString = 'timescale=' + timescale;
            let eventType = (query.has('eventType')) ? ('&eventType=' + query.get('eventType')) : '';

            if(props.history.action == 'POP' || baseUrl + '?' + timescaleString + startDateString + endDateString + eventType != props.history.location.pathname.concat(props.history.location.search)) {
                if(props.history.action == 'PUSH') {
                    props.history.push(baseUrl + '?' + timescaleString + startDateString + endDateString + eventType);
                }

                let value;
                switch(timescale) {
                    case 'past7':
                        startDate = getPastDays(7, today);
                        endDate = today;
                        value = listItemValues.PAST7;
                        break;
                    case 'past7fcst2':
                        var thisToday = new Date();
                        thisToday.setDate(thisToday.getDate() + 2);
                        startDate = getPastDays(9, thisToday);
                        endDate = thisToday;
                        value = listItemValues.PAST7_FCST2;
                        break;
                    case 'past30':
                        startDate = getPastDays(30, today);
                        endDate = today;
                        value = listItemValues.PAST30;
                        break;
                    case 'past365':
                        startDate = getPastDays(365, today);
                        endDate = today;
                        value = listItemValues.PAST365;
                        break;
                    case 'ytd':
                        let difference_ms = today - new Date('01/01/' + new Date().getFullYear()).getTime();
                        startDate = getPastDays(Math.round(difference_ms / (1000*60*60*24)) + 1, today);
                        endDate = today;
                        value = listItemValues.YTD;
                        break;
                    default:
                        value = listItemValues.CUSTOM;
                }

                endDate = new Date(endDate);
                startDate = new Date(startDate);
                let numDays = Math.round((endDate - startDate)/(1000*60*60*24)) + 1;
                let prevEndDate = getPastDays(1,startDate);
                let prevStartDate = getPastDays(numDays+1, prevEndDate);
                let realEndDate = getPastDays(0,endDate);
                dateContext.updateDates(
                    startDate.toISOString().split('T')[0],
                    endDate.toISOString().split('T')[0],
                    prevStartDate.toISOString().split('T')[0],
                    prevEndDate.toISOString().split('T')[0],
                    realEndDate.toISOString().split('T')[0],
                    numDays,
                    timescale
                );

                setFilter(value);
            }

            if(timescale == 'custom') {
                props.showCustomDateRange();
            } else {
                props.hideCustomDateRange();
            }
        }
    }, [dateContext.timescale, dateContext.startDate, dateContext.endDate, props.location.search]);

    useEffect(() => {
        try {
            const { selectedDashboard } = props;
            const { TimePeriodOptions } = selectedDashboard?.extraData;
            if (TimePeriodOptions?.MaxmimumTimeRangeDaysOnNavigation 
                && listItemValues[TimePeriodOptions.TimeRangeDefault.toUpperCase()]
                && TimePeriodOptions?.TimeRangeDefault
                && dateContext.daysBetweenStartEnd > Number(TimePeriodOptions.MaxmimumTimeRangeDaysOnNavigation))
                {
                    handleChange({ target: { value: listItemValues[TimePeriodOptions.TimeRangeDefault.toUpperCase()] }});
                }
        } catch {
            console.log("Unable to update default time range based on TimePeriodOptions")
        }
    }, [props.selectedDashboard])

    const getPastDays = (days, date) => {
        var newDate = new Date(date.getTime());
        newDate.setDate(newDate.getDate() - days + 1);
        props.hideCustomDateRange();
        return newDate;
    }

    const getYearToDate = () => {
        var date = new Date();
        var yearToDate = new Date(date.getFullYear(), 0, 1);
        props.hideCustomDateRange();
        return yearToDate;
    }

    const getCustomDateRange = () => {
        //Trigger the date selector UI in the parent
        props.showCustomDateRange();
    }

    return (
        <FormControl className={props.classes.formControl}>
            <InputLabel>Time Period</InputLabel>
            <Select
                value={filter}
                onChange={handleChange}
                name="filter"
                displayEmpty
                className={props.classes.selectEmpty}>
                <MenuItem value={listItemValues.PAST7}>Past 7 days</MenuItem>
                <MenuItem value={listItemValues.PAST7_FCST2}>Past 7 days with forecast</MenuItem>
                <MenuItem value={listItemValues.PAST30}>Past 30 days</MenuItem>
                <MenuItem value={listItemValues.PAST365}>Past 365 days</MenuItem>
                <MenuItem value={listItemValues.YTD}>Year to date</MenuItem>
                <MenuItem value={listItemValues.CUSTOM}>Custom date range</MenuItem>
            </Select>
        </FormControl>
    );

}

TimeScaleSelector.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TimeScaleSelector);