import _ from 'lodash'
import randomColor from 'randomcolor'
import React from 'react'
import { Checkbox, InputNumber, Modal, SelectPicker, Button } from 'rsuite'
import { ExplorerContext } from '../../../contexts/ExplorerContext'

const EditSeriesModal = (props) => {

    const { seriesObj, seriesObjects, setSeriesObjects, open, setOpen, setReferenceLineModalOpen, referenceMetric } = props
    const explorerContext = React.useContext(ExplorerContext)
    const { getMetric, findSeriesIndex } = explorerContext

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let components = seriesObj.current.name ? seriesObj.current.name.split(' : ') : []
    let asset = components[0]
    let attribute = components[1]
    let model = null
    let color = seriesObj.current.color
    let thicknessMap = {
        spline: seriesObj.current.options ? seriesObj.current.options.lineWidth : 1,
        line: seriesObj.current.options ? seriesObj.current.options.lineWidth : 1,
        column: seriesObj.current.options ? seriesObj.current.options.pointWidth : 1,
    }
    let thickness = thicknessMap[seriesObj.current.type]
    let visible = seriesObj.current.options ? (seriesObj.current.options.visible != null ? seriesObj.current.options.visible : true) : false
    let metric = attribute ? getMetric(attribute) : null

    if (components.length === 3) { // simulated
        model = components[2]
    }

    const onDelete = () => {
        let index = findSeriesIndex(seriesObjects, asset, attribute, model)
        let newSeriesObjects = _.cloneDeep(seriesObjects)
        index > -1 && delete newSeriesObjects[index]
        if (newSeriesObjects[0] === undefined && newSeriesObjects.length === 1) newSeriesObjects = []
        newSeriesObjects = newSeriesObjects.filter(n => n) // remove 'Empty' from array
        setSeriesObjects(newSeriesObjects)
        handleClose()
    }

    const onConfirm = () => {
        let newSeriesObjects = _.cloneDeep(seriesObjects)
        let index = findSeriesIndex(newSeriesObjects, asset, attribute, model)
        if (index > -1) {
            newSeriesObjects[index].options = {
                thickness: thickness != null && thickness > 0 ? thickness : newSeriesObjects[index].options.thickness,
                color: color != null ? color : newSeriesObjects[index].options.color,
            }
            setSeriesObjects(newSeriesObjects)
        }
        handleClose()
    }

    const onHide = () => {
        let newSeriesObjects = _.cloneDeep(seriesObjects)
        let index = findSeriesIndex(newSeriesObjects, asset, attribute, model)
        if (index !== -1) {
            newSeriesObjects[index].options.visible = !visible
            setSeriesObjects(newSeriesObjects)
        }
        handleClose()
    }

    return (
        
        <Modal
            open={open}
            onClose={handleClose}
            backdrop={true}
        >

            <Modal.Header>
                <Modal.Title> Edit Series </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ margin: '1rem 0rem 0rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center', fontWeight: 600 }} > { seriesObj.current.name } </span>
                    <span style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '.2rem', alignSelf: 'center', margin: '1rem 0 0 0'}}>
                        { metric && <Button style={{backgroundColor: '#b7e7a4', borderColor: '#b7e7a4'}} appearance='subtle' onClick={() => { handleClose(); referenceMetric.current = metric; setReferenceLineModalOpen(true)}} > add reference line </Button> }
                        <Button style={{backgroundColor: '#fdff7a', borderColor: '#fdff7a'}} onClick={onHide}> { visible ? 'hide' : 'show'} </Button>
                        <Button style={{backgroundColor: '#da7b7b', borderColor: '#da7b7b'}} onClick={onDelete}> delete </Button>
                    </span>
                    <span style={{ margin: '1rem 0rem 0rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        color
                        <SelectPicker 
                            data={ randomColor({luminosity: 'light',count: 27}).map((color, i) => ({ label: (<span style={{ backgroundColor: color, width: '100%', height: '100%' }}>{color}</span>), value: color, role: 'Master' })) } 
                            defaultValue={color} 
                            style={{backgroundColor: {color}}} 
                            onChange={(value) => { color = value}}/>
                    </span>
                    { attribute !== 'dry period' && 
                        <span style={{ margin: '1rem 0rem 0rem 0rem', width: '100%', display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                            thickness
                            <InputNumber defaultValue={thickness} onChange={(value) => { thickness = value }} />
                        </span>
                    }
                    <span style={{ height: '.4rem', backgroundColor: '#7373b9', margin: '2rem 3rem 1rem 3rem'}}/> 
                </span>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={onConfirm} appearance="primary">
                    Confirm
                </Button>
                <Button onClick={handleClose} appearance="subtle">
                    Cancel
                </Button>
            </Modal.Footer>

      </Modal>
    )
}

export default EditSeriesModal


