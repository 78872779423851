import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ModelContext } from "../../contexts/ModelContext";
import VirtualList from 'react-tiny-virtual-list';
import withLoading from "../withLoading";
import OMListItem from './OMListItem';

const VirtualListWithLoading = withLoading(VirtualList);

const styles = theme => ({
	list: {
		overflow: "hidden",
		width: "100%",
		height: "calc(100vh - 270px)",
	},
	listItem: {
		cursor: 'pointer',
		'&:hover': {
			border: '2px solid black',
		},
		paddingTop: '8px',
		paddingBottom: '8px',
	},
});

class OperationsListFeaturePanel extends React.Component {

	static contextType = ModelContext;

	constructor(props) {
		super(props);
		this.state = {
			anchor: 'left',
			selectedItem: null,
			clientHeight: 1,
			clientWidth: 1,
			shouldRedirect: false,
		};
	}

	componentDidMount() {
		this.setState({
			clientHeight: this.refs.listContainer.clientHeight,
			clientWidth: this.refs.listContainer.clientWidth
		});
	}

	listItemRenderer = ({ index, style }) => {
		const { classes, match, location, assets, selectedEvent } = this.props;
		const item = assets[index];

		if (!item) return null;

		return (
			<>
				<OMListItem
					key={Math.random()}
					index={index}
					style={style}
					classes={classes}
					match={match}
					item={item}
					selectedEvent={selectedEvent}
					setFilter={this.props.setFilter}
					loadDashboard={this.props.loadDashboard}
					setSelectedEvent={this.props.setSelectedEvent}
					dashboards={this.props.dashboards}
				/>
				<div style={{ visibility: 'hidden' }}>{item.asset}</div>
			</>
		);

		
	}

	getSelectedIndex(item) {
		for (let i = 0; i < this.props.assets.length; i++) {
			if (item && this.props.assets[i].id == item.id)
				return i;
		}
		return null;
	}

	componentDidUpdate() {
		const { match, location, assets } = this.props;

		//Selected asset through asset name dropdown, but no specific event selected - sets selected event to assets[0].
		if (assets.length > 0 && match.params.viewType == 'Event' && match.params.eventType == null && match.params.eventTime == null && this.state.shouldRedirect == false) {
			this.setState({ shouldRedirect: true });
		}
	}

	render() {
		const { classes, userContext, history, date, assets, } = this.props;
		const selectedFeatureIndex = this.getSelectedIndex(this.context.selectedItem);

		//Trigger when asset is selected through asset name dropdown, but no specific event selected - sets selected event to assets[0].
		//Possibly filter the list in ascending (first to last) order, then set selected event to [0] (earliest)
		if (this.state.shouldRedirect == true) {
			this.setState({ shouldRedirect: false });
			this.props.setSelectedEvent(assets[0]);
			let basePath =`/${userContext.getTenant().tenantName}/dashboards/operations/Event/`.concat(assets[0].asset).concat('/').concat(assets[0].eventType).concat('/').concat(assets[0].startDate);
			let startDate = date.timescale == 'custom' && date.startDate ? '&startDate=' + date.startDate : '';
			let endDate = date.timescale == 'custom' && date.endDate ? '&endDate=' + date.endDate : '';

			history.push(basePath + '?' + 'timescale=' + date.timescale + startDate + endDate);
		}

		return (
			<>
				<div className={classes.list} ref="listContainer">
					<VirtualListWithLoading isLoading={this.props.isLoading}
						width={this.state.clientWidth}
						height={this.state.clientHeight}
						items={assets}
						itemCount={this.props.assets.length}
						itemSize={50}
						renderItem={this.listItemRenderer}
						selectedfeature={this.props.selectedFeature}
						selecteditem={this.props.selectedItem}
						scrollToIndex={selectedFeatureIndex}
						scrollToAlignment={'auto'}
						extradata={this.props}
					/>
				</div>
			</>
		);
	}
}

OperationsListFeaturePanel.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OperationsListFeaturePanel);
