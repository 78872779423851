import React, {useState, useContext, useEffect} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Chart from '@material-ui/icons/InsertChartTwoTone';
import { ThemeContext } from '../../contexts/ThemeContext';
import _ from 'lodash';

export default function TemplateListItem(props) {

    const {style, classes, match, item, selectedFeature, selectedItem, setSelectedItem } = props;
    
    const handleChange = (event) => {
        setSelectedItem(event.target.value);
    }

    return (
    <ThemeContext.Consumer>
        {({getColor}) => (
                <ListItem 
                    button={false}
                    style={props.style}
                    divider={true}>
                    <Grid container direction='row' justify='space-between' alignItems='center'>
                        <Radio
                            style={{color: getColor(item.color)}}
                            checked={_.isEmpty(selectedItem) ? false : item.name == selectedItem.properties[selectedFeature.displayField]}
                            onChange={handleChange}
                            value={item.name}
                            />
                        <Grid item xs>
                            <ListItemText
                                primary={item.name} />
                        </Grid>
                        <Grid item xs>
                            <ListItemText
                                style={{color: getColor(item.color), marginLeft: '50px'}}
                                primary={(item.value > 0) ? '+' + item.value.toFixed(2) + '%' : item.value.toFixed(2) + '%'} />
                        </Grid>
                    </Grid>	
                </ListItem> 
            )}
    </ThemeContext.Consumer>
    );
	
}