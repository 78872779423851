import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
});

const AlertDialogButton = (props) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleConfirmAndClose = () => {
        props.confirmOnClick();
        setOpen(false);
    };

    return (
        <>
            <Button onClick={handleClickOpen} color={props.buttonColor || 'primary'}>
                {props.buttonText || 'Confirm'}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogTitle>
                        {props.dialogTitle || 'Are you sure?'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {props.dialogText || ''}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='secondary'>
                        {props.rejectText || 'Cancel'}
                    </Button>
                    <Button onClick={handleConfirmAndClose} color='primary' autoFocus>
                        {props.confirmText || 'Continue'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default withStyles(styles)(AlertDialogButton);