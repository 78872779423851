import ApiHelper from '../../../api/ApiHelper';
import _ from 'lodash';
import { getRepresentedDates } from '../chartComponents/util';
import moment from 'moment-timezone';
import { TENANT_TIMEZONE } from '../../../constants/TimezoneConstants';

export function generateUrl(urlPattern, model, userContext, replacements) {
    const tenant = userContext.getTenant();
    urlPattern = urlPattern.split("[tenantName]").join(tenant.tenantName);
    urlPattern = urlPattern.split("[modelNames]").join(model.baseModel.name + "," + model.selectedModel.name);
    urlPattern = urlPattern.split("[assetName]").join(_.isEmpty(model.selectedItem) ? null : encodeURIComponent(model.selectedItem.properties[model.selectedFeature.idField]));
    urlPattern = urlPattern.split("[layerName]").join(model.selectedFeature.layerName);

    const url = ApiHelper.fillWithBaseModel(urlPattern, replacements, model);

    return new URL(url, ApiHelper.getUrlPath());
}

export function formatQueryResults(apiObj, results, replacements, extraData, dateContext, colors, getColor) {
    try {
        let data = results.result[replacements.statName];

        if(data == null) return null;

        var entries = [];
        Object.keys(data).forEach(dataKey => {
            return Object.keys(data[dataKey]).forEach(asset => {
                return Object.keys(data[dataKey][asset]).forEach(date => {
                    var formattedDate = moment.tz(date, sessionStorage.getItem(TENANT_TIMEZONE)).format();
                    entries.push({
                        dataKey: dataKey,
                        asset: asset,
                        date: formattedDate,
                        value: data[dataKey][asset][date]
                    })
                })
            })
        });

        let startDate = moment.tz(dateContext.startDate, sessionStorage.getItem(TENANT_TIMEZONE));
        let endDate = moment.tz(dateContext.endDate, sessionStorage.getItem(TENANT_TIMEZONE));
        let allDates = getRepresentedDates(startDate, endDate);
        let x1Domain = results.domain.map(d => d.tooltipLabel);
        var formattedEntries = [];

        allDates.forEach((date) => {
            var formattedDate = moment.tz(date, sessionStorage.getItem(TENANT_TIMEZONE)).format();
            let matches = entries.filter(entry => entry.date == formattedDate);
            let dateObj = {'Date' : formattedDate};
            matches.forEach((datum) => {
                var match = results.domain.find(x => x.legendGroup === datum.asset && x.dataKey === datum.dataKey);
                if (typeof(match) !== 'undefined') {
                    var key = match.tooltipLabel;
                    dateObj[key] = datum.value;
                }
            });
            
            x1Domain.forEach((datum) => {
                if (!(datum in dateObj)) {
                    dateObj[datum] = 0;
                }
            });
            formattedEntries.push(dateObj);
        });


        let formattedData = formattedEntries;
        let domain = results && results.domain ? results.domain : [];

        if(!formattedData) return [null, null];

        const extraDataFormatted = {};

        for(const key of Object.keys(extraData)) {
            if(!_.isArray(extraData[key])) { 
                if(_.isArray(extraData[key].value)) {
                    extraDataFormatted[extraData[key].name] =  extraData[key].value.find(v => v.name == apiObj.title).value
                } else {
                    extraDataFormatted[extraData[key].name] = extraData[key].value;
                }
            } else {
                extraData[key].forEach(value => {
                    if(_.isArray(value)) {
                        const matchingValue =  value.find(v => v.name == apiObj.title);
                        extraDataFormatted[matchingValue.name] = matchingValue.value;
                    } else {
                        extraDataFormatted[value.name] = value.value;
                    }
                });
            }
        }

        for(const d of domain) {
            d.color = getColor(colors[d.colorLabel]);
        }

        const result = {
            Name: apiObj.title,
            Url: "",
            Data: formattedData,
            Id: Math.random(),
            Domain: domain,
            ...extraDataFormatted
        };

        return [result, null];
    } catch {
        return null;
    }
}

export function formatBarData(data) {
    const results = [];
    let start;

    if(data[0].value) {
        start = data[0].date;
    }

    for(let i = 1; i < data.length; i++) {
        const prevValue = data[i - 1].value;
        const currentValue = data[i].value;
        const nextValue = data[i + 1] ? data[i + 1].value : 0;

        if(currentValue != prevValue) {
            if(!start) {
                if(currentValue) {
                    if(currentValue == nextValue) {
                        start = data[i].date;
                    } else if (data[i + 1] && currentValue != nextValue) {
                        results.push({
                            value: data[i].value,
                            start: data[i].date,
                            end: data[i + 1].date,
                            date: new Date(+data[i].date + (+data[i + 1].date - +data[i].date))
                        });
                    }
                }
            } else {
                results.push({
                    value: prevValue,
                    start, 
                    end: data[i - 1].date,
                    date: new Date(start + (+data[i - 1].date - start))
                });
                start = null;
            } 
        }
    }

    return results;
}