import React, {useContext, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Increase } from "../../assets/images/increase.svg";
import { ReactComponent as Decrease } from "../../assets/images/decrease.svg";
import { ModelContext } from "../../contexts/ModelContext";
import { DateContext } from '../../contexts/DateContext';
import { UserContext } from '../../contexts/UserContext';
import ApiHelper from '../../api/ApiHelper';
import moment from 'moment-timezone';
import { useQueries } from 'react-query';
import { getGenericRequestData } from '../../api/ApiWorker';
import withLoading from '../withLoading';
import { TENANT_TIMEZONE } from '../../constants/TimezoneConstants';

const LoadingDiv = withLoading('div');

const styles = {
    root: {
        padding: "1rem",
    },
    Title: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "2.2rem",
        lineHeight: "1.5rem"
    },
    Value: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "2.4rem",
        fontWeight: "500",
        marginRight: ".5rem",
    },
    Label: {
        fontFamily: "Roboto",
        display: "inline-block",
        fontSize: "1.5rem"
    },
    Notes: {
        position: "absolute",
        left: "4rem",
        bottom: "1.2rem",
        fontFamily: "Roboto",
        fontSize: "1.2rem",
        lineHeight: "1rem",
        color: "rgba(0, 0, 0, 0.6)",
    },
    Icon: {
        position: "absolute",
        width: "1.6rem",
        height: "1.6rem",
        bottom: "1.2rem"
    },
    progress: {
        top: 25,
        left: 150,
        margin: "0rem",
        position: 'absolute',
        color: 'rgba(0,0,0,.2)',
    },
    progressParent: {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%"
    }
};

const ObservationKpi = (props) => {
    const {classes, api, title} = props;

    const modelContext = useContext(ModelContext);
    const dateContext = useContext(DateContext);
    const userContext = useContext(UserContext);

    const apiKey = Object.keys(api)[0];
    const urlPattern = api[apiKey].urlPattern;

    const getKpiUrl = (urlParams, apiKey, isCurrentRange = true) => {
        let startDate = dateContext.startDate;
        let endDate = dateContext.realEndDate;

        if (!isCurrentRange) {
            startDate = dateContext.prevRangeStartDate;
            endDate = dateContext.prevRangeEndDate;
        }

        const parameters= api[apiKey].parameters;
        let urlPattern = urlParams;
        var tz = sessionStorage.getItem(TENANT_TIMEZONE);
        urlPattern = urlPattern.split("[startDate]").join(moment.tz(startDate, tz).format());
        urlPattern = urlPattern.split("[endDate]").join(moment.tz(endDate, tz).format());
        const tenant = userContext.getTenant();
        urlPattern = urlPattern.split("[tenantName]").join(tenant.tenantName);

        const url = ApiHelper.fillWithBaseModel(urlPattern, parameters, modelContext.state.value);

        return new URL(url, ApiHelper.getUrlPath());
    }
    
    const formatKpiResult = (kpiResult, apiKey) => {
        let kpiValue = kpiResult.data;
        if(typeof kpiValue === "object") {
            let data = kpiValue["data"];
            if(data) {
                data = Object.values(data);
                let stat = api[apiKey].parameters.statistic;
                switch(stat) {
                    case "sum":
                        kpiValue = data.reduce(function(a, b){
                            return a + b;
                        }, 0);
                        break;
                    case "mean":
                        kpiValue = data.reduce(function(a, b){
                            return a + b;
                        }, 0);
                        kpiValue /= data.length;
                        break;
                    case "max":
                        kpiValue = data.reduce(function(a, b) {
                            if(a > b) {
                                return a;
                            } else {
                                return b;
                            }
                        });
                        break;
                    case "min":
                        kpiValue = data.reduce(function(a, b) {
                            if(a < b) {
                                return a;
                            } else {
                                return b;
                            }
                        });
                        break;
                    default:
                        kpiValue = 0;
                }
            } else {
                kpiValue = 0;
            }
        }

        kpiValue = (kpiValue.data) ? kpiValue.data : kpiValue;
        kpiValue = (kpiValue.toString().includes(".") ? kpiValue.toFixed(2) : kpiValue);

        return kpiValue;
    }

    // Format results
    // If results is empty or still loading data, return the loading state.
    // Else, handle any error states when applicable, then format and return data.
    const formatData = (results) => {
        if (_.isEmpty(results) || results.find(x => x.isLoading)) return;

        const kpiResult = results[0];
        const differenceResult = results[1];

        let kpiValue = 0;
        let differenceValue = 0;
        let note = 'Unable to locate resource';

        if (kpiResult.isSuccess && differenceResult.isSuccess) {
            kpiValue = formatKpiResult(kpiResult, apiKey);
            differenceValue = formatKpiResult(differenceResult, apiKey);

            let diff = kpiValue - differenceValue;
            diff = (diff.toString().includes(".") ? diff.toFixed(2) : diff);
    
            note = diff + " over previous " + dateContext.daysBetweenStartEnd + " days";
    
            if (diff >= 0) {
                note = "+" + note;
            }
        }

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                        <Typography variant="h5" className={classes.Value} >
                            <span>{kpiValue}</span>
                        </Typography>
                        <Typography variant="body1" className={classes.Label} style={{ textOverflow: 'ellipsis', lineHeight: 1, marginTop: '7px' }}>
                            <span>{title}</span>
                        </Typography>
                </div>
                {((note + "").charAt(0) == '+') && <Increase className={classes.Icon} />}
                {((note + "").charAt(0) == '-') && <Decrease className={classes.Icon} />}
                <Typography variant="subtitle2" className={classes.Notes}>
                    <span>{note}</span>
                </Typography>
            </>
        )
    }

    const kpiUrl = getKpiUrl(urlPattern, apiKey);
    const differenceUrl = getKpiUrl(urlPattern, apiKey, false);

    const generatedQueries = [
        //KPI Data (Current timeframe)
        {
            queryKey: kpiUrl.toString(),
            queryFn: async () => await getGenericRequestData(kpiUrl)
        },
        //Difference Data (Previous timeframe)
        {
            queryKey: differenceUrl.toString(),
            queryFn: async () => await getGenericRequestData(differenceUrl)
        }
    ];

    const results = useQueries(generatedQueries);
    const Content = formatData(results);

    return (
        <LoadingDiv style={{top: 0, bottom:0, left:0, right:0, position: 'absolute', display: 'block'}} isLoading={results.find(x => x.isLoading)}>
            <CardContent className={classes.root}>
                {Content}
            </CardContent>
        </LoadingDiv>
    );
}

export default withStyles(styles)(ObservationKpi);