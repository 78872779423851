import React, {useState, useContext} from 'react';
import _, { times } from "lodash";
import { Menu, MenuItem, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuHorizontalIcon from '@material-ui/icons/MoreHoriz';
import Button from '@material-ui/core/Button';
import { DateContext } from '../../contexts/DateContext';
import { UserContext } from '../../contexts/UserContext';

const ITEM_HEIGHT = 48;
const styles = theme => ({
    systemSummary: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '.75px'
    }
});

const SystemSummaryBar = (props) => {

    const {classes} = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const dateContext = useContext(DateContext);
    const userContext = useContext(UserContext);

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleChange(event) {
        //When menu item is clicked
    }

    const systemSummaryClick = () => {
        const timescale = dateContext.timescale;
        const startDate = dateContext.startDate;
        const endDate = dateContext.endDate;
        
        const timescaleString = 'timescale=' + timescale;
        const startDateString = timescale == 'custom' && startDate ? '&startDate=' + startDate : '';
        const endDateString = timescale == 'custom' && endDate ? '&endDate=' + endDate : '';

        const basePath = `/${userContext.getTenant().tenantName}/dashboards/operations/Overview`;

        props.history.push(basePath + '?' + timescaleString + startDateString + endDateString);

        props.reset();
    }

    return (
        <Grid container direction='row' justify='space-between' alignItems='center'>

            <Grid item lg={10}>
                <Button  onClick={systemSummaryClick} style={{marginLeft: '15px', marginTop: '15px', marginBottom: '25px'}} size = 'small' color = 'primary' variant = 'text'>
                    <span className={classes.systemSummary}> {"< System Summary"} </span>
                </Button>
            </Grid>

            <Grid item xs>
                <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleMenuClick}>
                <MenuHorizontalIcon color='primary' width={30} height={30} />
            </IconButton>
                <Menu
            id='long-menu'
            anchorEl={anchorEl}
            keepMounted
            onChange={handleChange}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 300,
                },
            }}>
                <MenuItem onClick={handleClose}>
                    {'View in Performance Dashboard'}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {'View in Another Dashboard'}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {'Open Google Street View'}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    {'Share this asset'}
                </MenuItem>
            </Menu>
            </Grid>
        </Grid>

    )
}

export default withStyles(styles)(SystemSummaryBar);
