import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import _ from "lodash";
import {ModelContext} from "./../../contexts/ModelContext";
import { Menu, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import MenuHorizontalIcon from '@material-ui/icons/MoreHoriz';
import VirtualizedAssetList from './VirtualizedAssetList';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    regulatorType: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '23px',
        letterSpacing: '.15px',
        color: 'rgba(0,0,0,0.6)',
        mixBlendMode: 'normal',
        opacity: '0.87',
    },
});

const AssetInfoBar = (props) => {

    const {classes, match, assets, setFilter, model} = props;

    const [matchingAssetName, setMatchingAssetName] = useState(match.params.assetName);

    useEffect(() => {
        setMatchingAssetName(match.params.assetName);
    }, [match.params.assetName]);

    if (assets && assets.length > 0 && !assets.includes(matchingAssetName) && matchingAssetName != 'N/A') {
        //SnackBar here to signal no matching regulator number found for given url
        setMatchingAssetName('N/A');
    }

    return (
        <Grid container direction='row' justify='space-around' alignItems='center' style={{marginBottom: '16px'}}>

            <Grid item xs={4} style={{marginLeft: '15px'}}>
            {assets.length > 0 &&
            <VirtualizedAssetList style={{marginLeft: '15px', width: '100px'}}
            options={assets.map( name => (name != null && name != " ") ? ({value: name, label: name}) : ({value: null, label: 'Not Found'}))}
            setFilter={setFilter} 
            value={{value:'x', label: matchingAssetName}}/> 
            || 
            <Select style={{marginLeft: '15px', width: '100px'}}/> } 
            </Grid>

            <Grid item xs>
                <Typography variant='h6' className={classes.regulatorType} > {"CSO Regulator"} </Typography>
            </Grid>
        </Grid>
    )
}
export default withStyles(styles)(AssetInfoBar);