import React from 'react';
import { CircularProgress } from '@material-ui/core';

const withLoading = (WrappedComponent) => {
    const container = {
        margin: "0rem",
        position: 'relative',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0, 
        height: '100%'
    };

    const progressParent = {
        margin: "0rem",
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: "rgba(255,255,255,.8)",
        zIndex: 100,
        width: "100%",
        height: "100%",
        textAlign: "center",
        verticalAlign: "middle",
    };

    class WithLoading extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = {
                clientHeight: 0,
                clientWidth: 0,
            }
        }

        componentDidMount() {
            this.setState({
                clientHeight: this.refs.container.clientHeight,
                clientWidth: this.refs.container.clientWidth
            });
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(this.state.clientHeight != this.refs.container.clientHeight ||
                this.state.clientWidth != this.refs.container.clientWidth) {
                    this.setState({
                        clientHeight: this.refs.container.clientHeight,
                        clientWidth: this.refs.container.clientWidth
                    });
                }
        }

        render() {
            const { forwardedRef, isLoading, ...rest } = this.props;
            const size = 0.6 * Math.min(this.state.clientHeight, this.state.clientWidth);
            const leftSetting = (this.state.clientWidth - size)/2;
            const topSetting = (this.state.clientHeight - size)/2;
            return (
                <div style={container} ref="container">
                    <WrappedComponent ref={forwardedRef} {...rest} />
                    {isLoading && <div style={progressParent} > 
                            <CircularProgress 
                                size={size}
                                style={{ left: leftSetting, top: topSetting, color: 'rgba(0,0,0,.2)', margin: "0rem", position: 'absolute', }}/>
                        </div>}
                </div>
            );
        }
    }

    return WithLoading;
}

export default withLoading;