import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Card, CardContent, CardActions, Typography, Collapse, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Warning from '@material-ui/icons/Warning';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
    spacer: {
        width: `${theme.spacing(1)}px`,
        height: `${theme.spacing(1)}px`,
    },
    card: {
        maxHeight: 350
    },
    elementList: {
        width: '100%',
        maxWidth: 360,
    },
});

class NetworkTracingToolResultElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            layerId: null
        };
    }

    handleMouseEnter() {
        const { mapContext, element } = this.props;
        const interactiveMap = mapContext.interactiveMap;

        if(this.state.layerId === null) {
            const layerId = interactiveMap.addExternalLayerToMap(element, [{
                type: "line",
                paint: {
                    "line-color": "#f96835",
                    "line-opacity": 0.5,
                    "line-width": 10.0
                },
                layout: {
                    "line-cap": "round",
                    "line-join": "round",
                    "visibility": 'visible',
                }
            }]);
            this.setState({
                layerId: layerId
            });
        } 
        
    }

    handleMouseLeave() {
        const { mapContext, element } = this.props;
        const interactiveMap = mapContext.interactiveMap;

        if(this.state.layerId !== null) {
            interactiveMap.removeExternalLayer(this.state.layerId);
            this.setState({
                layerId: null
            });
        }
    }

    componentWillUnmount() {
        const { layerId } = this.state;
        const { mapContext } = this.props;
        if(layerId) {
            mapContext.interactiveMap.removeExternalLayer(layerId);
        }
    }

    render() {
        const element = this.props.element;
        return (
            <ListItem button onMouseEnter={this.handleMouseEnter.bind(this)} onMouseLeave={this.handleMouseLeave.bind(this)}>
                <div style={{width: '100%'}} >
                    <Typography>
                        <span>{element.extraData.id}</span> <span>({element.extraData.assetType})</span>
                    </Typography>
                </div>
            </ListItem>);
    }
}

class NetworkTracingToolResultCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openAssets: false,
            openErrors: false,
            traceVisible: false,
            traceId: null
        }
    }
    
    showTrace = (result) => {
        const { model, mapContext } = this.props;
        let traceId = mapContext.interactiveMap.addExternalLayerToMap(result.feature, [{
            type: "line",
            paint: {
                "line-color": "#f96835",
                "line-opacity": 0.5,
                "line-width": 5.0
            },
            layout: {
                "line-cap": "round",
                "line-join": "round",
                "visibility": 'visible',
            }
        }]);
        
        this.setState({
            traceVisible: true,
            traceId: traceId,
        });
    }

    hideTrace = (result) => {
        const { traceVisible, traceId } = this.state;
        const { mapContext } = this.props;
        if(traceVisible && traceId) {
            mapContext.interactiveMap.removeExternalLayer(traceId);
        }
        this.setState({
            traceVisible: false,
            traceId: null,
        })
    }

    componentWillUnmount() {
        const { traceVisible, traceId } = this.state;
        const { mapContext } = this.props;
        if(traceVisible && traceId) {
            mapContext.interactiveMap.removeExternalLayer(traceId);
        }
    }


    render() {
        const { result, classes, index } = this.props;
        const { openAssets, openErrors, traceVisible } = this.state;
        const showTrace = this.showTrace.bind(this);
        const hideTrace = this.hideTrace.bind(this);
        return (
            <>
                <ListItem button 
                    style={{width: '100%'}}
                    onClick={() => {this.setState({openAssets: !openAssets})}}>
                    {(result.messages && result.messages.length > 0) && <ListItemIcon>
                        <Warning/>
                    </ListItemIcon>}
                    <ListItemText primary={`Trace ${index + 1}`} secondary={`${result.results.length} Assets`}>
                    </ListItemText>
                    {!traceVisible && <Button onClick={(e) => { e.stopPropagation(); showTrace(result); }}>View Trace</Button>}
                    {traceVisible && <Button onClick={(e) => { e.stopPropagation(); hideTrace(result); }}>Hide Trace</Button>}
                    {openAssets ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openAssets} style={{ width: '100%' }} unmountOnExit>
                    <List className={classes.elementList}>
                        {result.results.map((result, idx) => {
                            return (<NetworkTracingToolResultElement element={result} mapContext={this.props.mapContext} ></NetworkTracingToolResultElement>)
                        })}
                    </List>
                </Collapse>
            </>);
    }
}

export default withStyles(styles)(NetworkTracingToolResultCard);