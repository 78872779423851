import React, {useState, useRef, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
    },
    formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    },
    selectEmpty: {
    marginTop: theme.spacing(2),
    },
}));

export default function TemplateDropDown(props) {
    const classes = useStyles();
    const [value, setValue] = useState('None');

    const inputLabel = useRef(null);
    const [labelWidth, setLabelWidth] = useState(0);

    let {title, menuItems} = props;

    useEffect(() => {
        if(inputLabel.current) {
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);

    useEffect( () => {
        if (_.isEmpty(menuItems)) return;
        try {
            const list = menuItems.sort((a, b) => (a.order > b.order) ? 1 : -1);
            setValue(list[0].displayName);
        }
        catch {
            setValue(menuItems[0].displayName);
        }
    }, [menuItems])

    useEffect( () => {
        if (value == 'None') return;
        props.handleChange(value);
    }, [value]);

    function handleChange(event) {
        setValue(event.target.value);
    }

    function createMenuItems() {
        let items = [];

        if (menuItems && menuItems.length > 0) {  
            menuItems.forEach(function(item) {
                items.push(
                        <MenuItem key={Math.random()} value={item.displayName}>
                        {item.displayName}
                        </MenuItem>); 
                    }); 
            }
        return items;
    }

    return (
        <form className={classes.root} autoComplete="off">
            {
                menuItems.length > 1 ?
                    <FormControl style ={{marginTop: '20px', width: '10.5vw'}} variant="outlined" className={classes.formControl}>
                        <InputLabel ref={inputLabel}>
                            {title}
                        </InputLabel>
                        <Select
                            value={value}
                            onChange={handleChange}
                            input={<OutlinedInput labelWidth={labelWidth} />}
                        >
                            {createMenuItems()}
                        </Select>
                    </FormControl>
                :
                    <FormControl style ={{marginTop: '20px', width: '10.5vw'}} className={classes.formControl}>
                        <TextField
                            readOnly
                            value={value}
                            label={title}
                            style={{marginTop: '20px'}}
                            InputProps={{disableUnderline: false}}
                        >
                        </TextField>
                    </FormControl>
            }
        </form>
    );
}