import React from 'react';
import _ from 'lodash';
import ErrorModal from '../components/ErrorModal';
import SnackbarContext from './SnackbarContext';

export const ErrorContext = React.createContext(null);

const initialSettings = {
    errors: [],
    isModalOpen: false
};

class ErrorContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...initialSettings
        };

        this.updateErrors = this.updateErrors.bind(this);
    }

    updateErrors(toAddError, cardType, metric) {
        const snackbarContext = React.useContext(SnackbarContext);
        const oldErrors = this.state.errors;
        var newErrors = oldErrors.filter(x => x.metric !== metric || x.cardType !== cardType);

        var error = {
            'cardType' : cardType,
            'metric' : metric,
            'errors': toAddError
        }
        newErrors.push(error);

        this.updateValues({ "errors": newErrors });
        snackbarContext.addMessage(
            "The system has detected potential data quality issues in the displayed information.",
            "error",
            {
                text: "Learn More",
                callback: () => {
                    this.updateValues({ "isModalOpen": true });
                }
            }
        );
    }

    updateValues = async toUpdate => {
        const nextState = this.state;
        let updateValues = [];
        if (!Array.isArray(toUpdate)) {
            for (const key of Object.keys(toUpdate)) {
                updateValues.push({
                    key,
                    value: toUpdate[key]
                });
            }
        } else {
            updateValues = toUpdate;
        }

        for (const { key, value } of updateValues) {
            if (!!key && nextState[key] != value) {
                nextState[key] = value;
            }
        }

        this.setState(nextState);
    }

    render() {
        return (
            <ErrorContext.Provider value={{
                state: this.state,
                updateValues: this.updateValues,
                updateErrors: this.updateErrors,
            }}>
                <ErrorModal open={this.state.isModalOpen} handleClose={() => this.updateValues({ "isModalOpen": false })} errors={this.state.errors} constantval={1} />
                {this.props.children}
            </ErrorContext.Provider>
        )
    }
}

export default ErrorContextProvider;