import React, { forwardRef, useRef } from 'react';
import _ from 'lodash';
import {
    ClipPath,
    PieSlices,
    PieChartValueLabels,
    PieChartLegend
} from '../chartComponents';


export default forwardRef((props, primaryChartRef) => {
    let { margin, height, width, extraData } = props;
    const legendPosition = extraData.find(x => x.name === 'LegendPosition')?.value;

    const tooltipRef = useRef(null);

    const setRef = node => {
        if(node) primaryChartRef.current = node;
    }

    return (
        <>
            <div ref={tooltipRef} transform={`translate(${width/(legendPosition == 'left' ? 1.5 : 3)}, ${(height/2)+20})`} className="pie-chart-tooltip" style={{position: "relative"}} />  
            <svg width={width} height={height} >
                <g ref={setRef} width={width - margin.left} height={height - margin.top} transform={`translate(${width/(legendPosition == 'left' ? 1.5 : 3)}, ${(height/2)+20})`} >
                    <ClipPath {...props}></ClipPath>
                    <PieSlices ref={primaryChartRef} tooltipRef={tooltipRef} {...props}></PieSlices>
                    <PieChartValueLabels ref={primaryChartRef} tooltipRef={tooltipRef} {...props}></PieChartValueLabels>
                    <PieChartLegend ref={primaryChartRef} {...props}></PieChartLegend>
                </g>
            </svg>
        </>
    );
})
