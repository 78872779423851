import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormLabel, FormControl, FormControlLabel, FormGroup, Checkbox} from '@material-ui/core';
import { getEmailSubscriber } from '../../api/ApiWorker';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  button: {
      margin: theme.spacing(1),
  },
});

const EmailPreferences = (props) => {
 
  const {classes, setOccurrence, isLoading} = props;

  const [state, setState] = useState({
    Daily: false,
    Weekly: false
  });

  // Get/Set current email preferences for current user
  useEffect( () => {
    async function getEmailSubscription() {
      const emailSubscriber = await getEmailSubscriber();
      const preference = emailSubscriber.preferences;
      if (preference) setState({
        Daily: !!preference.Daily,
        Weekly: !!preference.Weekly
      });
    }

    getEmailSubscription();
  }, []);

  const handleChange = (event) => {
    let newState = {...state, [event.target.name]: event.target.checked };
    setState(newState);
    setOccurrence(newState);
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Email Preferences </FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox color='primary' disabled={isLoading} checked={state.Daily} onChange={handleChange} name="Daily" />}
            label="Daily"
          />
          <FormControlLabel
            control={<Checkbox color='primary' disabled={isLoading} checked={state.Weekly} onChange={handleChange} name="Weekly" />}
            label="Weekly"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}
export default withStyles(styles)(EmailPreferences);