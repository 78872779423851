import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {ReactComponent as Model } from '../../../assets/images/model.svg';
import {ModelContext} from "../../../contexts/ModelContext";

const styles = theme => ({
    header: {
        "&:focus": {
            backgroundColor: "transparent"
        },
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    nested: {
        paddingLeft: theme.spacing(5),
        paddingTop: "0px",
        paddingBottom: "0px",
        color: "white",
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    text: {
        fontFamily: "Roboto",
        color: "white",
    },
    listItem: {
        borderRadius: "200px",
        padding: "8px",
        paddingLeft: "13px",
        paddingRight: "13px",
        fontFamily: "Roboto",
    }
});

class ModelsSubMenu extends React.Component {

    static contextType = ModelContext;

    handleModelClick = (event, modelObj) => {
        var model = this.context.state.value.allModels.find(obj => {
            return obj.name === modelObj.name;
        });

        var currentFeature = model.layers.find(x => x.layerName == this.context.state.value.selectedFeature.layerName);

        this.props.errorContext.updateValues({errors:[]});
        this.context.updateModelFeature(model, currentFeature ? currentFeature : model.layers[0]);
    } 

    createModelListItemFromJson(jsonObject) {
        const { classes, match, routeContext } = this.props;
        if(typeof(jsonObject) === 'undefined') {
            return null;
        }
        if (jsonObject.tags && jsonObject.tags.includes('Observed'))
        {
            return null;
        }
        return(
            <ListItem button onClick={event => this.handleModelClick(event, jsonObject)} className={classes.nested} key={jsonObject.id}>
                <Tooltip title={jsonObject.description ? jsonObject.description : jsonObject.name}>
                    <div 
                    className={classes.listItem} 
                    style={{
                        backgroundColor: ((this.context.state.value.selectedModel.id == jsonObject.id) 
                            && (routeContext.state.layoutType == 'performance' || routeContext.state.layoutType == 'reports')) 
                                ? "rgba(0, 0, 0, 0.34)" 
                                : 'transparent',
                        fontSize: (jsonObject.name.length >= 20) ? '.8rem' : '1rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                        }}>
                        {jsonObject.name}
                        </div>
                </Tooltip>
                
            </ListItem>
        )
    }

    shouldDisplayModel(model) 
    {
        return _.isEmpty(model.extraData) || model.extraData.DisableSelection !== true;
    }
    
    render ()
    {
        const { classes, menuContext } = this.props;

        return (
            <div style={{cursor: 'pointer'}}>
                {/*Top Level Menu with Icon*/}
                <ListItem button className={classes.header} onClick={menuContext.toggleModels}>
                    <ListItemIcon className={classes.text}>
                        <Model />
                    </ListItemIcon>
                    <ListItemText variant = "inset" disableTypography className={classes.text}>Models</ListItemText>
                    {menuContext.state.modelsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                {/*Sub Menu*/}
                <Collapse in={menuContext.state.modelsExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {this.context.state.value.allModels
                            // filter out from display if extraData.DisableSelection is strictly equal to true
                            .filter(model => this.shouldDisplayModel(model))
                            .map(model => this.createModelListItemFromJson(model))}
                    </List>
                </Collapse>
            </div>
        ) 
    }
}

ModelsSubMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ModelsSubMenu);
