import React from 'react';
import SideMenu from "./sidemenu/SideMenu";
import Dashboard from './Dashboard';
import OperationsDashboard from './OperationsDashboard';
import PrecipiCASTDashboard from './PrecipiCAST/PrecipiCASTDashboard';
import CMOMDashboard from './CMOMDashboard';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getTheme, getModels, getDashboards, getAssets, getValidDates } from "../api/ApiWorker";
import _ from 'lodash';
import PipecastSnackbar from './PipecastSnackbar';
import useMediaQuery  from '@material-ui/core/useMediaQuery';
import {Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import NotFound from './NotFound';
import withQuery from './withQuery';
import withTenantName from './withTenantName';
import SettingsPanel from './settingsPanel/SettingsPanel';
import AdminPanel from './adminPanel/AdminPanel';
import CSODischarge from './Reporting/CSODischarge';
import DataExplorer from './DataExplorer';

const RedirectWithTenantNameWithQuery = withQuery(withTenantName(Redirect));

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        position: 'relative',
        display: 'flex',
    },
    snackbar: {
        zIndex: 10000
    }
});

class PipecastLoader extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            layout: {},
            featureData: {},
            dashboards: [],
            selectedDashboard: null,
        };
        this.loadDashboard = this.loadDashboard.bind(this);
        this.loadDashboards = this.loadDashboards.bind(this);
    }

    loadDashboard (jsonObject, dashboard) {
        const {model, errorContext, routeContext} = this.props;
        const {dashboards} = this.state;

        errorContext.updateValues({errors: []});
        switch (dashboard.dashboardName)
        {
            case 'O&M':
                routeContext.updateValues({
                    viewType: 'Overview'
                });
            break;
            case 'Event':
                routeContext.updateValues({
                    viewType: 'Event'
                });
            break;
            case 'SSO Dashboard':
                routeContext.updateValues({
                    viewType: 'SSO'
                });
            break;
            default:
                routeContext.updateValues({viewType: dashboard.dashboardName});
            break;
        }

        const baseModelExclusive = !!dashboard.baseModelExclusive;
        
        this.setState({ 
            layout: jsonObject,
            selectedDashboard: dashboard
        });

        model.updateValues({
            baseModelExclusive : baseModelExclusive
        });
    }

    loadColorData = () => {
        getTheme().then(colors => {
            this.props.themeContext.updateTheme(colors);
        }).catch(e=> {

        });
    }

    loadSystem = ({selectedModel, model}) => {
        if(!selectedModel) {
            return;
        }

        let basePromises = model.baseModel.layers.reduce((allLayersPromises, layer, idx) => {
            let layerData = {layer: layer, name: layer.layerName, data: null}
            layerData.data = getAssets(model.baseModel.id, layer)
            .then(assets => {
                return assets;
            });
            allLayersPromises.push(layerData);
            return allLayersPromises;
        }, []);

        let promises = selectedModel.layers.reduce((allLayersPromises, layer, idx) => {
            let layerData = { layer: layer, name: layer.layerName, data: null}

            layerData.data = getAssets(selectedModel.id, layer)
                .then(assets=>{
                    return assets;
                });
            allLayersPromises.push(layerData);
            return allLayersPromises;
        }, []);

        model.updateValues({
            layers: _.uniqBy(promises.concat(basePromises), 'name')
        });

        return Promise.all(promises.map(a=>a.data));
    }

    loadDashboards() {
        return new Promise((resolve, reject) => {
            getDashboards().then(dashboards => {
                this.setState({
                    dashboards: dashboards,
                    layout: {},
                    selectedDashboard: null
                })
            }).catch((err) => {
                this.setState({
                    dashboards: [],
                    layout: {}
                })
            })
        });
    }

    updateModels() {
        const {model} = this.props;

        return new Promise((resolve, reject) => {
            getModels().then((data) => {
                let baseModel = data.find(a=>a.tags && a.tags.includes('Observed'));
                baseModel = baseModel ? baseModel : data[0];
                
                let selectedModel = data.find(a=>a.tags && a.tags.includes('Default'));

                selectedModel = selectedModel ? selectedModel : (data[1] ? data[1] : baseModel);
    
                model.updateValues({
                    allModels: data,
                    selectedModel: selectedModel,
                    selectedFeature: {},
                    baseModel: baseModel
                }).then(() => {
                    resolve({ selectedModel: selectedModel, selectedFeature: {}, model: model });
                });
            }).catch(err => {
                console.error(err);
                
                model && model.updateValues({
                    allModels: [],
                    //selectedModel: null,
                    selectedFeature: {}
                    //baseModel: null
                }).then(() => {
                    resolve(null);
                });
            });
        })
        
    }

    componentDidUpdate(prevProps, prevState) {
        const {isDesktop, menuContext, userContext, model} = this.props;

        if (isDesktop != prevProps.isDesktop) {
            menuContext.setDesktop(isDesktop)
        }
        if(!_.isEmpty(userContext.tenant) && !_.isEqual(userContext.tenant, prevProps.userContext.tenant)) {
            this.loadColorData();
            this.updateModels();
            this.loadDashboards();
        }
        if(model.selectedModel && !_.isEqual(model.selectedModel, prevProps.model.selectedModel)) {
            
            this.loadSystem({
                selectedModel: model.selectedModel, 
                selectedFeature: {},
                model: model
            });
        }
    }

    componentDidMount () {
        const {model, userContext} = this.props;
        const {currentUser} = userContext;

        if(currentUser) {
            this.loadColorData();
            this.updateModels()
                .then(() => {
                    this.loadDashboards();
                    this.loadSystem({
                        selectedModel: model.selectedModel, 
                        selectedFeature: {},
                        model: model
                    });
                });
        }
    }

    render(){
        const { classes, model, theme, date, userContext, menuContext, routeContext, errorContext, snackbarContext } = this.props;
        
        // Selects default dashboard if the default tag exists, otherwise selects the first fashboard. If no dashboards are available this sets the dashboard to be null.
        let defaultDashboard = this.state.dashboards.find(x => x.tags != null && x.tags.includes('Default'));
        
        // Selects CMOM dashboard if the default tag exists, otherwise this sets the CMOM dashboard to be null.
        let cmomDashboard = this.state.dashboards.find(x => x.tags != null && x.tags.includes('CMOM'));

        // Selects first tenant for initial load.
        let defaultTenant = userContext.getTenant();
        let defaultTenantName = defaultTenant.tenantName ? defaultTenant.tenantName : null;

        return (
            <div className={classes.root}>
                {defaultDashboard
                    ?   <Switch>
                            {/* Defaults to first dashboard in dashboard list showing Regulators, ? denotes optional variables */}
                            <RedirectWithTenantNameWithQuery exact from='/:tenantName?' to={`/${defaultTenantName}/dashboards/performance/${defaultDashboard.dashboardName}`} />
                            <RedirectWithTenantNameWithQuery exact from='/:tenantName/dashboards' to={`/:tenantName/dashboards/performance/${defaultDashboard.dashboardName}`}/>
                            <RedirectWithTenantNameWithQuery exact from='/:tenantName/dashboards/performance' to={`/:tenantName/dashboards/performance/${defaultDashboard.dashboardName}`}/>
                            <Route exact path={`/:tenantName/dashboards/PrecipiCAST/:assetName?`} render = {(route) => <PrecipiCASTDashboard {...route} mapType={'PrecipiCAST'} layout={this.state.layout} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} loadDashboard={this.loadDashboard} />}/>
                            { cmomDashboard && <Route exact path={`/:tenantName/dashboards/performance/${cmomDashboard.dashboardName}/:layerName?/:assetName?`} render = {(route) => <CMOMDashboard {...route} mapType={'CMOM'} date={date} dashboards={this.state.dashboards} selectedDashboard={cmomDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} model={model} themeContext={theme} userContext={userContext} menuContext={menuContext} routeContext={routeContext} errorContext={errorContext}/>}/> }
                            <Route exact path='/:tenantName/dashboards/performance/:dashboardName/:layerName?/:assetName?' render = {(route) =><Dashboard {...route} mapType={'status'} date={date} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} model={model} themeContext={theme} userContext={userContext} menuContext={menuContext} routeContext={routeContext} errorContext={errorContext} snackbarContext={snackbarContext}/>}/>
                            <Route exact path='/:tenantName/dashboards/operations/:viewType/:assetName?/:eventType?/:eventTime?' render = {(route) => <OperationsDashboard {...route} mapType={'alert'} date={date} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} model={model} themeContext={theme} userContext={userContext} menuContext={menuContext} routeContext={routeContext} errorContext={errorContext}/>}/>
                            <Route exact path='/:tenantName/dashboards/operations/SSO/:assetName?' render = {(route) => <OperationsDashboard {...route} mapType={'alert'} date={date} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} model={model} themeContext={theme} userContext={userContext} menuContext={menuContext} routeContext={routeContext} errorContext={errorContext}/>}/>
                            <Route exact path={`/:tenantName/dashboards/reports/:viewType/:layerName?/:assetName?`} render = {(route) => <CSODischarge {...route} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} loadDashboards={this.loadDashboards} model={model}/>}/> 
                            <Route exact path={`/:tenantName/dashboards/DataExplorer`} render = {(route) => <DataExplorer {...route} dashboards={this.state.dashboards} selectedDashboard={this.state.selectedDashboard} layout={this.state.layout} loadDashboard={this.loadDashboard} loadDashboards={this.loadDashboards} model={model}/>}/> 
                            <Route exact path='/:tenantName/unknown' render = {(route) => <NotFound {...route} theme={theme} menuContext={menuContext} defaultDashboard={defaultDashboard}/>}/>
                            <Route path='*' render = {(route) => <NotFound {...route} theme={theme} menuContext={menuContext} defaultDashboard={defaultDashboard}/>}/>
                        </Switch>
                    :   null
                }  
                <SideMenu 
                    model={model}
                    loadDashboard={this.loadDashboard}
                    dashboards={this.state.dashboards}
                    selectedDashboard={this.state.selectedDashboard}
                    menuContext={menuContext}
                    routeContext={routeContext}
                    errorContext={errorContext}
                    defaultDashboard={defaultDashboard}>
                </SideMenu>
                <div className={classes.snackbar}>
                    <PipecastSnackbar />
                </div>

                <div>
                    <SettingsPanel />
                </div>

                {
                    menuContext.state.adminPanelOpen && 
                    <AdminPanel />
                }

            </div>
        );
    }
}

PipecastLoader.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const PipecastLoaderComponent = withStyles(styles, { withTheme: true })(PipecastLoader);
function useDesktopLayout(props) {
    const isDesktop = useMediaQuery('(min-width:1367px)');
    return (<PipecastLoaderComponent{...props} isDesktop={isDesktop}></PipecastLoaderComponent>);
}

export default useDesktopLayout;